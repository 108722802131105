import React from 'react';
import { Box, Typography } from '@mui/material';
import { SnackButton } from './Buttons';
import { Close, Error, CheckCircle, Timer } from '@mui/icons-material';

const Toast = ({ message, buttonConfig, icon }) => (
  <Box display={'flex'} alignItems={'center'}>
    {icon === 'success' ? <CheckCircle /> : icon === 'warn' ? <Timer /> : <Error />}
    <Box pl={3} flex={'1 0 0'}>
      <Typography>{message}</Typography>
    </Box>
    {buttonConfig && (
      <Box flex={'0 0 1'} pl={2}>
        <SnackButton
          style={{ height: '40px' }}
          variant="outlined"
          onClick={() => buttonConfig.action()}
        >
          <Typography>{buttonConfig.title}</Typography>
        </SnackButton>
      </Box>
    )}
    <Box flex={'0 0 1'} pt={1} pl={2}>
      <Close />
    </Box>
  </Box>
);

export default Toast;
