import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TranslatedTypography = ({ children, i18nKey, ...rest }) => {
  // t from useTranslation is much easier to mock for testing than the <Trans/> component
  const { t } = useTranslation();
  if (typeof children === 'string' && i18nKey) {
    return <Typography {...rest}>{t(i18nKey, children)}</Typography>;
  } else if (i18nKey) {
    return <Typography {...rest}>{t(i18nKey)}</Typography>;
  }
  return typeof children === 'string' ? (
    <Typography {...rest}>{t(children)}</Typography>
  ) : (
    <Typography {...rest}>{children}</Typography>
  );
};
