import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { FourOhFourHeaderImage } from '../icons/svg';
import {
  HELP_CENTER_URL,
  SUPPORT_EMAIL,
  SUPPORT_PHONE_NUMBER,
  SUPPORT_HOURS,
} from '../../data/SupportContactInfo';
import { TranslatedTypography } from './TranslatedTypography';
import { Trans } from 'react-i18next';

const formatPhoneToE164 = (phone) => `+1${phone.replace(/\D/g, '')}`;

const ErrorDisplay = ({ title, description }) => {
  return (
    <Box textAlign={'center'} maxWidth={'648px'} mt={{ xs: '61px', md: '154px' }} mx={4}>
      <FourOhFourHeaderImage />
      <Box mt={1.5} mb={2.5}>
        <TranslatedTypography variant={'h2'}>{title}</TranslatedTypography>
      </Box>
      <Box mb={2.5}>
        <TranslatedTypography variant={'body1'}>{description}</TranslatedTypography>
      </Box>
      <Box>
        <Typography variant={'h4'}>
          <Box component={'span'} lineHeight={'1.5em'}>
            <Trans
              i18nKey="webapp:errorDisplaySupportMessage"
              defaults="<bold>Need a bit of help?</bold> You can email us at <email>{{supportEmail}}</email> or 
              call our toll-free number <phone>{{supportPhone}}</phone> ({{supportHours}}) to speak with a member of our team."
              values={{
                supportEmail: SUPPORT_EMAIL,
                supportPhone: SUPPORT_PHONE_NUMBER,
                supportHours: SUPPORT_HOURS,
              }}
              components={{
                bold: <b></b>,
                email: <Link href={`mailto:${SUPPORT_EMAIL}`} underline={'none'} noWrap />,
                phone: (
                  <Link
                    href={`tel:${formatPhoneToE164(SUPPORT_PHONE_NUMBER)}`}
                    underline={'none'}
                    noWrap
                  />
                ),
              }}
            />
          </Box>
        </Typography>
      </Box>
      <Box mt={1.5}>
        <Typography variant={'h4'}>
          <Box component={'span'} lineHeight={'1.5em'}>
            <Trans
              i18nKey="webapp:errorHelpCenterMessage"
              defaults="You can also visit our <help>Help Center</help> for more information."
              components={{
                help: (
                  <Link
                    href={HELP_CENTER_URL}
                    underline={'none'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    noWrap
                  />
                ),
              }}
            />
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorDisplay;
