import React from 'react';

const Triangle = (props) => {
  const { up, right, width = 7, height = 6, ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      {...restProps}
    >
      <g
        transform={
          up
            ? `rotate(180, ${width / 2}, ${height / 2})`
            : right
            ? `rotate(270, ${width / 2}, ${height / 2})`
            : undefined
        }
      >
        <path fill="currentColor" d={`M 0 0 L ${width / 2} ${height} L ${width} 0 L 0 0`} />
      </g>
    </svg>
  );
};

export default Triangle;
