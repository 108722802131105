import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { Avatar } from './Avatar';
import { RoleLabel } from './RoleLabel';
import { TranslatedTypography } from './TranslatedTypography';

export const FaqAuthoredBy = ({ role, avatarUrl, authorName, bio, open, isGenericContent }) => {
  const isDesktop = useIsDesktop();
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {open && <Box mr={2}>{<Avatar source={avatarUrl} />}</Box>}
      <Box display="flex" flexDirection={'column'} alignItems={'flex-start'}>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems={{ xs: '', sm: '', md: 'center' }}
        >
          {!isGenericContent && (
            <TranslatedTypography variant="body2" component={'span'}>
              Answered by
            </TranslatedTypography>
          )}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            alignItems={{ xs: '"flex-start"', sm: '"flex-start"', md: 'center' }}
            // Since we remove the Answered By if this FAQ is for generic content, lets also remove the margin on the author's name to make things line up better.
            ml={{ xs: 0, sm: 0, md: isGenericContent ? 0 : 1.5 }}
          >
            <Typography variant="body1">{authorName}</Typography>
            <Box ml={{ xs: 0, sm: 0, md: 1.5 }} display="flex" alignItems="flex-start">
              <RoleLabel role={role} />
            </Box>
          </Box>
        </Box>
        {isDesktop && open && <Typography variant="body1">{bio}</Typography>}
      </Box>
    </Box>
  );
};
