import React from 'react';
import { BaseButton } from '../atoms/Buttons';
import { Box } from '@mui/material';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import LanguageToggle from '../atoms/LanguageToggleButton';

export const ResetPasswordTokenExpired = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        src="https://cdn.crickethealth.com/1/img/icons/invalid-link.svg"
        alt="This link is invalid"
      />
      <Box position="absolute" top="16px" right="16px">
        <LanguageToggle />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        margin={2}
      >
        <Box>
          <TranslatedTypography variant="h2">Expired Link</TranslatedTypography>
        </Box>

        <Box
          maxwidth="50%"
          margin={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
        >
          <TranslatedTypography variant="body2">
            For security reasons, password reset links expire after 24 hours. To request a new
            password reset link click the button below.
          </TranslatedTypography>
        </Box>

        <Box display="flex" justifyContent="center">
          <BaseButton
            onClick={() => {
              window.location = '/login?directToResetPassword=true';
            }}
          >
            Request new link
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
};
