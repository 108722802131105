import { Box } from '@mui/material';
import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import CareTeamMessageEntry from '../molecules/CareTeamMessageEntry';
import CareTeamMessageHeader from '../molecules/CareTeamMessageHeader';
import CareTeamMessagesWindow from '../molecules/CareTeamMessagesWindow';
import { useCareTeamContext } from '../pages/CareTeam';

export const CareTeamMessageFrame = () => {
  const { showBio, showFileHistory, selectedGroupId } = useCareTeamContext();

  const isSideContentShown = showBio || showFileHistory;

  // Every time we get a new message we push it into the list, so these *should* be in temporal order
  // To note, we might run into an issue where another group user sends a message that ends up at the back of the stack
  return (
    <Box
      borderRadius={isSideContentShown ? '0' : '0 12px 12px 0'}
      mr={isSideContentShown ? '1px' : 0}
      id="careTeamMessageFrame"
      display={'flex'}
      maxHeight={'100%'}
      flex={1}
      maxWidth="100%"
      flexDirection="column"
      alignItems="flex-start"
      bgcolor={MyCricketColors.lightGrayMist}
    >
      <Box
        flex={'0 0 1'}
        width={'100%'}
        position={'sticky'}
        boxShadow="0px 3px 4px rgba(0, 62, 81, 0.1)"
        zIndex={2}
      >
        <CareTeamMessageHeader />
      </Box>
      <Box flex={'1 0 0'} width={'100%'}>
        {/* Key the CareTeamMessagesWindow on the selectedGroupId, so when
          The group changes, it will force a remount of the component
          and we won't be stuck with state from the previous group */}
        <CareTeamMessagesWindow key={`window-${selectedGroupId}`} />
      </Box>
      <Box flex={'0 0 1'} width={'100%'} maxHeight={'50%'} position={'sticky'} zIndex={2}>
        <CareTeamMessageEntry />
      </Box>
    </Box>
  );
};

export default CareTeamMessageFrame;
