import React from 'react';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import GroupCard from '../molecules/GroupCard';
import {
  DiscussionGroupNames,
  DiscussionGroupDisplayNames,
  metaTexts,
} from '../../data/groupTypeNames';
import { ReactComponent as ChatIcons } from '../icons/ChatIcons.svg';
import { ReactComponent as MedsLabsIcon } from '../icons/MedsLabsIcon.svg';
import { ReactComponent as DietNutritionIcon } from '../icons/DietNutritionIcon.svg';
import { ReactComponent as TransplantIcon } from '../icons/TransplantIcon.svg';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

function GroupCardList(props) {
  const { setCurrentlySelectedGroup, discussionGroupIds } = props;
  const isDesktop = useIsDesktop();

  //TODO look up the correct text https://crickethealth.atlassian.net/browse/PE-317
  const groupCardFactory = (title, text, groupId, icon) => {
    if (!groupId) {
      console.error('Must provide a group ID');
      return;
    }
    const fillerText =
      text ??
      'Vivamus elementum semper nisi. Aenean vulpu tate eleif end tellus per nisi. Aenean vulpu tate end tellus';
    return (
      groupId && (
        <GroupCard
          key={groupId}
          title={title}
          bodyText={fillerText}
          setCurrentlySelectedGroup={setCurrentlySelectedGroup}
          groupId={groupId}
          Icon={icon}
        />
      )
    );
  };
  //we have to match the groups to their icons and default if we have none assigned
  //TODO get the icons for the rest of the groups https://crickethealth.atlassian.net/browse/PE-317
  const iconLookup = (typeName) => {
    switch (typeName) {
      case DiscussionGroupNames.GENERAL:
        return ChatIcons;
      case DiscussionGroupNames.MEDICATION_LABS:
        return MedsLabsIcon;
      case DiscussionGroupNames.DIET:
        return DietNutritionIcon;
      case DiscussionGroupNames.TRANSPLANTS:
        return TransplantIcon;
      default:
        return ChatIcons;
    }
  };
  return (
    <Box
      component={'main'}
      display="flex"
      flexDirection="column"
      margin="auto"
      marginTop={4}
      marginBottom={4}
      maxWidth={800}
    >
      <Box marginBottom={isDesktop ? 4 : 3} marginLeft={isDesktop ? 0 : 2}>
        <TranslatedTypography variant="h1">Group Discussions</TranslatedTypography>
      </Box>
      {Object.keys(discussionGroupIds).map((type) => {
        return groupCardFactory(
          DiscussionGroupDisplayNames[type],
          metaTexts[type]?.description,
          discussionGroupIds[type],
          iconLookup(type),
        );
      })}
    </Box>
  );
}

GroupCardList.propTypes = {
  setCurrentlySelectedGroup: PropTypes.func.isRequired,
};

export default GroupCardList;
