import { myCricketAuthedFetch } from './fetchingUtils';

export const getCurrentUserIdToken = () => {
  return window.localStorage.getItem('idToken');
};

export const renewToken = (cleanHouse) => {
  return async () => {
    try {
      const result = await myCricketAuthedFetch(`/i/account/refresh_token`, {
        method: 'GET',
      });
      if (result) {
        localStorage.setItem('idToken', result.id_token);
        return result.id_token;
      }
    } catch (error) {
      console.error('Failed to renew token:', error);
      try {
        const { TTL } = getTTL()();
        // If we have a token already, don't clean house unless it is expired.
        if (TTL <= 0) {
          cleanHouse?.();
        }
      } catch {
        cleanHouse?.();
      }
    }
  };
};

export const getTTL = (cleanHouse) => {
  return () => {
    const token = localStorage.getItem('idToken');
    if (!token) {
      cleanHouse?.();
      throw new Error('No idToken found');
    }
    try {
      const tokenBuffer = Buffer.from(token.split('.')[1], 'base64');
      const decodedToken = JSON.parse(tokenBuffer.toString());
      const now = Math.floor(Date.now() / 1000);
      const age = now - decodedToken.iat;
      const TTL = decodedToken.exp - now;
      const warnTTL = decodedToken.warn ? decodedToken.warn - now : TTL;
      return { age, TTL, warnTTL, iat: decodedToken.iat };
    } catch (error) {
      cleanHouse?.();
      throw new Error(`Error decoding idToken: ${error}`, { cause: error });
    }
  };
};
