import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Avatar } from './Avatar';
import { Roles } from '../../data/Roles';
import { RoleLabel } from './RoleLabel';
import { classesPropType } from '../../utils/patientPropTypes';
import GroupLikePostButton from './GroupLikePostButton';
import MyCricketColors from '../../data/MyCricketColors';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { safelyLinkifyURLsInString } from '../../utils/urlUtils';
import { TimestampFromNow } from './Timestamp';
import { NewBadge } from './NewBadge';
import { useGroupDiscussionContext } from '../pages/GroupPage';
import { paths } from '../../utils/routing/routes';

const styles = () => ({
  avatar: {
    minHeight: '54px',
    minWidth: '54px',
  },
});

function GroupPostCardOriginalPost(props) {
  const { classes, groupName, messageId } = props;
  const { getUserProfile, getMessageById } = useChirpContext();
  const [messageText, setMessageText] = useState(null);
  const [senderId, setSenderId] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const _message = getMessageById(messageId);
    if (_message) {
      setMessage(_message);
      _message.messageText && setMessageText(_message.messageText);
      _message.senderId && setSenderId(_message.senderId);
    }
  }, [getMessageById, messageId]);
  const { newGroupMessagesArray } = useGroupDiscussionContext();

  const messageWithLinks = safelyLinkifyURLsInString(messageText);

  const {
    avatarURI: messageSenderAvatarUri,
    nickName: messageSenderNickname,
    roleName: messageSenderRolename,
  } = getUserProfile(senderId);

  const isPatient =
    messageSenderRolename && messageSenderRolename.toLowerCase() === Roles.PATIENT.toLowerCase();

  return (
    <Box
      bgcolor={MyCricketColors.white}
      data-testid={`groupPostCardWrapper-${messageId}`}
      data-message-link={`${_cc?.patient?.url}${paths.groupMessage(groupName, messageId)}`}
      boxSizing={'border-box'}
      boxShadow={'0px 3px 4px rgba(0, 62, 81, 0.1)'}
      borderRadius={'12px 12px 0 0'}
      width="100%"
    >
      <Box width="100%" display="flex" minHeight={56} padding={3}>
        <Avatar source={messageSenderAvatarUri} className={classes.avatar} />
        <Box alignSelf="center" marginLeft={2} display="flex" flexDirection="column">
          <Typography
            variant="subtitle1"
            className={classes.nickname}
            data-test-id={`groupPostCardNickname-${messageId}`}
            aria-level="3"
          >
            {messageSenderNickname}
          </Typography>
          {!isPatient && (
            <Box
              data-testid={`groupPostCardRoleLabelWrapper-${messageId}`}
              display={{ md: 'none', xs: 'auto' }}
            >
              <RoleLabel role={messageSenderRolename} />
            </Box>
          )}
        </Box>
        {!isPatient && (
          <Box
            alignSelf="center"
            marginLeft={1.5}
            data-testid={`groupPostCardRoleLabelWrapper-${messageId}`}
            display={{ xs: 'none', md: 'flex' }}
          >
            <RoleLabel role={messageSenderRolename} />
          </Box>
        )}
        <Box
          display="flex"
          flex={'1 0 0'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          marginRight={1}
        >
          <Box display={{ xs: 'none', md: 'flex' }}>
            {message ? <TimestampFromNow message={message} /> : null}
          </Box>
          {newGroupMessagesArray.includes(messageId) && <NewBadge />}
        </Box>
      </Box>
      <Box marginLeft={3} marginRight={3} paddingBottom={2}>
        <Box marginLeft={1}>
          <Typography
            variant="body2"
            data-test-id={`groupPostCardMessageText-${messageId}`}
            data-test-type="groupPostCardMessageText"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: messageWithLinks }}
          />
        </Box>
        <Box alignSelf="center" marginLeft={1} marginTop={1} display={{ md: 'none', xs: 'auto' }}>
          {message ? <TimestampFromNow message={message} /> : null}
        </Box>
        {message ? <GroupLikePostButton message={message} /> : null}
      </Box>
    </Box>
  );
}

GroupPostCardOriginalPost.propTypes = {
  classes: classesPropType.isRequired,
};

export default withStyles(styles)(GroupPostCardOriginalPost);
