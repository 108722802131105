import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import MyCricketColors from '../../data/MyCricketColors';
import HorizontalDividerLine from '../atoms/HorizontalDivider';
import { BaseButton } from '../atoms/Buttons';
import { paths } from '../../utils/routing/routes';
import { useHistory } from 'react-router';
import { BackNav } from '../atoms/BackNav';
import { DropdownSelect } from '../atoms/Selects';
import { supportedLanguageMaps } from '../../data/SupportedLanguages';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { TrackingEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils.js';

const LanguagePreferences = () => {
  const { logEvent } = useClientEventLoggerContext();
  const { siteLanguage, updateSystemInfo } = usePatientContext();
  const history = useHistory();
  const [currentLanguage, setCurrentLanguage] = useState(siteLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState(siteLanguage);

  const [options] = useState(
    supportedLanguageMaps.map(({ name, code }) => ({
      displayValue: name,
      value: code,
    })),
  );

  /**
   * onSave doesn't call setCurrentLanguage - instead, these values are updated after i18n has
   * changed the language.
   */
  useEffect(() => {
    setCurrentLanguage(siteLanguage);
    setSelectedLanguage(siteLanguage);
  }, [siteLanguage]);

  const onChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const onSave = async () => {
    if (selectedLanguage === currentLanguage) return;

    logEvent({
      predicate: TrackingEvent.TRACKING_CHANGE_LANGUAGE,
      object: URIUtils.toMyCricketPageURI(`LanguagePreference`),
      prepositions: {
        langCode: selectedLanguage,
        page: URIUtils.toMyCricketPageURI(window.location.pathname),
      },
    });
    // the patient context provider handles updating the DB site language and i18n cookie
    updateSystemInfo({ siteLanguage: selectedLanguage });
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box component="main" mt={3} px={2} width={'640px'}>
        <BackNav history={history} link={paths.me()} data-test-id="backNav" />
        <Box
          p={{ xs: 3, md: 6 }}
          bgcolor={MyCricketColors.white}
          borderRadius={'6px'}
          maxWidth={'640px'}
          style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
        >
          <TranslatedTypography variant="h2">Site Language</TranslatedTypography>
          <DropdownSelect
            handleChange={onChange}
            defaultValue={currentLanguage}
            options={options}
            data-test-id="languageSelection"
          />
          <HorizontalDividerLine />
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
          >
            <Box mr={3}>
              <BaseButton onClick={() => history.push(paths.me())} variant="outlined">
                {'Cancel'}
              </BaseButton>
            </Box>
            <BaseButton
              onClick={onSave}
              data-test-id={'langSubmitButton'}
              type="submit"
              style={{ minWidth: '105px' }}
            >
              {'Save'}
            </BaseButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LanguagePreferences;
