import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { careTeamMessagePropType } from '../../utils/patientPropTypes';
import GroupPostCardOriginalPost from '../atoms/GroupPostCardOriginalPost';
import GroupPostCardReply from '../atoms/GroupPostCardReply';
import GroupReplyCompositionArea from './GroupReplyCompositionArea';
import ExpandGroupRepliesButton from '../atoms/ExpandGroupRepliesButton';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';

function GroupPost({ message, groupName, userName, firstShownReplyId, expandMessages }) {
  const { getRepliesForMessage, getMessageById } = useChirpContext();
  const [shownReplyIds, setShownReplyIds] = useState([]);
  const [hiddenReplyIds, setHiddenReplyIds] = useState([]);
  const freshMessage = getMessageById(message.id);

  useEffect(() => {
    const replyIds = getRepliesForMessage(message.id).map((reply) => reply.id);
    if (replyIds.length > 0) {
      const firstShownReplyIndex = replyIds.findIndex((replyId) => replyId === firstShownReplyId);
      setShownReplyIds(replyIds.slice(firstShownReplyIndex));
      setHiddenReplyIds(replyIds.slice(0, firstShownReplyIndex));
    } else {
      setShownReplyIds([]);
      setHiddenReplyIds([]);
    }
  }, [firstShownReplyId, message.id, getRepliesForMessage]);

  return (
    <Box width="100%">
      {freshMessage && (
        <Box>
          <GroupPostCardOriginalPost messageId={message.id} groupName={groupName} />
          {hiddenReplyIds.length > 0 && firstShownReplyId && (
            <ExpandGroupRepliesButton
              numberOfReplies={hiddenReplyIds.length}
              onClick={() => expandMessages(hiddenReplyIds.length)}
            />
          )}
          {hiddenReplyIds.length > 0 &&
            !firstShownReplyId &&
            hiddenReplyIds.map((replyId) => (
              <GroupPostCardReply key={replyId} messageId={replyId} groupName={groupName} />
            ))}
          {shownReplyIds.map((replyId) => (
            <GroupPostCardReply key={replyId} messageId={replyId} groupName={groupName} />
          ))}
          <GroupReplyCompositionArea userName={userName} messageBeingRepliedTo={message} />
        </Box>
      )}
    </Box>
  );
}

GroupPost.propTypes = {
  message: careTeamMessagePropType.isRequired,
};

export default GroupPost;
