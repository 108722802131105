import { Box, Typography } from '@mui/material';
import { ArrowDownward, Close } from '@mui/icons-material';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import MyCricketColors from '../../data/MyCricketColors';
import {
  getDateAndMinuteFromUnixTimestamp,
  groupTheMessagesBySenderAndDate,
} from '../../utils/chatUtils';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import CareTeamMessageBubble from '../atoms/CareTeamMessageBubble';
import CareTeamMessageImage from '../atoms/CareTeamMessageImage';
import CareTeamMessageFile from '../atoms/CareTeamMessageFile';
import CareTeamMessageTimestamp from '../atoms/CareTeamMessageTimestamp';
import CareTeamNewMessagesLine from '../atoms/CareTeamNewMessagesLine';
import { useCareTeamContext } from '../pages/CareTeam';
import { Trans } from 'react-i18next';
import { usePrevious } from '../../utils/hooks/usePrevious';
import { SegmentedButton } from '../atoms/Buttons';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { isImageFile, fetchFile } from '../../utils/fileUtils';

const CareTeamMessageItemType = {
  MESSAGE: 'message',
  TIMESTAMP: 'timestamp',
  NEW_MESSAGES: 'new-messages',
};

// So that the messages and timestamps can both be used as rows in the list
// we convert the nested object into a flat list here. We also convert each
// message or timestamp into an object with a type so that we can safely
// check each one before deciding what component to render.
const convertGroupedMessagesToLinearItems = (groupedMessages) => {
  const linearMessageItems = [];
  for (const key of Object.keys(groupedMessages)) {
    const messages = groupedMessages[key];
    for (const message of messages) {
      linearMessageItems.push({ type: CareTeamMessageItemType.MESSAGE, message });
    }
    linearMessageItems.push({ type: CareTeamMessageItemType.TIMESTAMP, timestamp: key });
  }
  return linearMessageItems;
};

const CareTeamMessagesWindow = () => {
  const { getGroupMessages, readAllMessagesForGroup, messagesLoaded } = useChirpContext();
  const { userId } = usePatientContext();
  const {
    selectedGroupId,
    initialFirstUnreadMessageId,
    firstUnreadMessageId,
    cachedFileResults,
    setCachedFileResults,
  } = useCareTeamContext();

  const list = useRef(null);
  const [isLastItemVisible, setIsLastItemVisible] = useState(false);
  const [numberOfNewMessages, setNumberOfNewMessages] = useState(0);
  const [numberOfUnReadMessages, setNumberOfUnReadMessages] = useState(0);
  const [shouldShowViewNewMessagesButton, setShouldShowViewNewMessagesButton] = useState(false);
  const previousNumberOfUnReadMessages = usePrevious(numberOfUnReadMessages);
  const [messages, setMessages] = useState([]);
  const [storedLinearMessageItems, setStoredLinearMessageItems] = useState([]);
  const pendingImages = useRef({});

  const lastItemRef = useCallback((lastItem) => {
    const isVisible = Boolean(lastItem);
    setIsLastItemVisible(isVisible);
    setShouldShowViewNewMessagesButton(false);
    setNumberOfNewMessages(0);
  }, []);

  const initialFirstUnreadMessageIndex = storedLinearMessageItems.findIndex((item) => {
    if (item.type !== CareTeamMessageItemType.MESSAGE) {
      return false;
    }
    return item.message.id === initialFirstUnreadMessageId;
  });

  useEffect(() => {
    setMessages(getGroupMessages(selectedGroupId));
  }, [getGroupMessages, selectedGroupId]);

  useEffect(() => {
    // Cloning so we don't corrupt the data in the context with the sendDate and confuse the sockets process
    const messagesWithSendDate = messages.map((message) => {
      const sendDate = getDateAndMinuteFromUnixTimestamp(message.clientTimestamp);
      return { ...message, sendDate };
    });
    const groupedMessages = groupTheMessagesBySenderAndDate(messagesWithSendDate);
    const linearItems = convertGroupedMessagesToLinearItems(groupedMessages);
    setStoredLinearMessageItems(linearItems);
    /* eslint-disable */
  }, [messages.length]); //including messages as a dependency creates too many re-renders. Disabling lint to prevent it from inserting messages here
  /* eslint-enable */

  useEffect(() => {
    const _unreadMessagesCount = messages.filter((m) => !m.isRead).length;
    // When the number of messages changes, add however many unreads we got (but only set it if it's greater than 0)
    // Note this won't update when a message is read, but that's handled in the useEffect below.
    if (_unreadMessagesCount > 0) {
      setNumberOfUnReadMessages(_unreadMessagesCount);
    }
    /* eslint-disable */
  }, [messages.length]); //including messages as a dependency creates too many re-renders. Disabling lint to prevent it from inserting messages here
  /* eslint-enable */

  useEffect(() => {
    if (firstUnreadMessageId) {
      // Only scroll to the new messages line when the oldest unread message
      // at the initial load of the screen is the same as the current oldest
      // unread message.
      if (firstUnreadMessageId === initialFirstUnreadMessageId) {
        list.current?.scrollToIndex({ index: initialFirstUnreadMessageIndex, align: 'start' });
      }
    }
  }, [
    firstUnreadMessageId,
    initialFirstUnreadMessageId,
    initialFirstUnreadMessageIndex,
    list,
    selectedGroupId,
  ]);

  useEffect(() => {
    if (numberOfUnReadMessages > 0) {
      // Always read the messages if there are unreads.
      readAllMessagesForGroup(selectedGroupId);
    }
  }, [numberOfUnReadMessages, readAllMessagesForGroup, selectedGroupId]);

  useEffect(() => {
    // When numberOfUnreads changes to a positive value it means we got new unreads, so add it to newMessages
    if (numberOfUnReadMessages > 0) {
      setNumberOfNewMessages(numberOfNewMessages + numberOfUnReadMessages);
      !isLastItemVisible && setShouldShowViewNewMessagesButton(true);
      // reset unread count to 0, because we've processed that message
      setNumberOfUnReadMessages(0);
    }
  }, [
    numberOfNewMessages,
    numberOfUnReadMessages,
    // Prevents the "View New Message" banner from appearing after delayed updates
    previousNumberOfUnReadMessages,
    isLastItemVisible,
    selectedGroupId,
  ]);

  // Check against -1 to ensure we actually found an index of an unread message.
  // Check against 0 because if the first unread is the first message, we don't need a line.
  const shouldShowNewMessagesLine =
    initialFirstUnreadMessageIndex !== 0 && initialFirstUnreadMessageIndex !== -1;

  const linearMessageItems = [...storedLinearMessageItems];
  if (shouldShowNewMessagesLine) {
    const newMessagesLineItem = { type: CareTeamMessageItemType.NEW_MESSAGES };
    linearMessageItems.splice(initialFirstUnreadMessageIndex, 0, newMessagesLineItem);
  }

  const scrollToBottom = useCallback(
    () => list.current?.scrollToIndex(linearMessageItems.length),
    [list, linearMessageItems.length],
  );

  useEffect(() => {
    // Whenever messages are updated, and the last message is visible on screen
    // scroll to the bottom again. If it is not visible, we will show the button instead.
    if (messages.length && isLastItemVisible) {
      scrollToBottom();
    }
  }, [messages.length, isLastItemVisible, scrollToBottom]);

  const fetchImage = useCallback(
    async (fileId) => {
      if (cachedFileResults[fileId]?.url || pendingImages.current[fileId]) {
        return;
      }
      pendingImages.current[fileId] = true;
      try {
        const url = await fetchFile(fileId);
        setCachedFileResults((prev) => ({ ...prev, [fileId]: { url } }));
      } catch (error) {
        setCachedFileResults((prev) => ({ ...prev, [fileId]: { error } }));
      }
    },
    [cachedFileResults, setCachedFileResults],
  );

  const DisclaimerText = () => (
    <Box display="flex" textAlign="center" justifyContent="center" mr={4} ml={4} mt={2} mb={3}>
      <TranslatedTypography
        i18nKey={`careTeamDisclaimerHeader`}
        variant="h3"
        style={{ color: MyCricketColors.mediumGray }}
      >
        Your care team reviews your messages Monday through Friday 9am to 5pm. For any emergency,
        please call 911 immediately.
      </TranslatedTypography>
    </Box>
  );

  const Row = ({ index }) => {
    //offsetting by 1 to also include the disclaimer text
    const item = linearMessageItems[index - 1];
    const isLastItem = index === linearMessageItems.length;
    const isMessage = index !== 0 && item.type === CareTeamMessageItemType.MESSAGE;
    const isFile = isMessage && item.message.file;
    const isImage = isFile && isImageFile(item.message.file.fileType);
    const isTimestamp = index !== 0 && item.type === CareTeamMessageItemType.TIMESTAMP;
    const isNewMessagesLine = index !== 0 && item.type === CareTeamMessageItemType.NEW_MESSAGES;

    return (
      <Box>
        <Box overflow={'auto'}>
          {index === 0 && <DisclaimerText />}
          {isImage ? (
            <ImageRow
              message={item.message}
              fileResult={cachedFileResults[item.message.file.fileId]}
              index={index}
              userId={userId}
              fetchImage={fetchImage}
            />
          ) : isFile ? (
            <FileRow message={item.message} index={index} userId={userId} />
          ) : isMessage ? (
            <MessageRow
              message={item.message}
              index={index}
              userId={userId}
              selectedGroupId={selectedGroupId}
            />
          ) : isTimestamp ? (
            <TimestampRow
              timestamp={item.timestamp}
              index={index}
              userId={userId}
              isLastItem={isLastItem}
              lastItemRef={lastItemRef}
            />
          ) : isNewMessagesLine ? (
            <NewMessagesRow />
          ) : null}
        </Box>
      </Box>
    );
  };

  return (
    <MyCricketLoadingContainer loading={!messagesLoaded}>
      <Box
        position={'relative'}
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        bgcolor={MyCricketColors.lightGrayMist}
      >
        {linearMessageItems.length === 0 && <DisclaimerText />}
        {linearMessageItems.length !== 0 && (
          //we have to offset the actual length by +1 because we're adding a disclaimer in here too
          <Virtuoso
            initialItemCount={linearMessageItems.length < 20 ? linearMessageItems.length + 1 : 21}
            totalCount={linearMessageItems.length + 1}
            itemContent={(index) => <Row index={index} />}
            initialTopMostItemIndex={linearMessageItems.length}
            ref={list}
          />
        )}
        {shouldShowViewNewMessagesButton && (
          <Box
            position={'absolute'}
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
            mt={1.5}
            px={4}
          >
            <SegmentedButton
              segments={[
                {
                  props: {
                    sx: { p: '6px 12px 6px 12px' },
                    startIcon: <ArrowDownward />,
                    onClick: () => scrollToBottom(),
                  },
                  children: (
                    <Typography variant="subtitle1">
                      <Trans
                        i18nKey={
                          numberOfNewMessages === 1
                            ? 'viewNewMessages_one'
                            : 'viewNewMessages_other'
                        }
                        defaults={`View ${numberOfNewMessages} New Messages`}
                        values={{ count: numberOfNewMessages }}
                      />
                    </Typography>
                  ),
                },
                {
                  props: {
                    sx: { p: '6px 12px 6px 12px' },
                    onClick: () => setShouldShowViewNewMessagesButton(false),
                  },
                  icon: <Close />,
                },
              ]}
            />
          </Box>
        )}
      </Box>
    </MyCricketLoadingContainer>
  );
};

const MessageRow = ({ index, message, userId, selectedGroupId }) => {
  const isOtherPerson = +message.senderId !== +userId;

  // Add greater top padding if it's the first message in the list.
  return (
    <Box pt={index === 0 ? 3 : 1}>
      <CareTeamMessageBubble
        key={`messageBubble-${message.messageUuid}`}
        message={message}
        isOtherPerson={isOtherPerson}
        selectedGroupId={selectedGroupId}
        dataTestIndex={`${index}`}
      />
    </Box>
  );
};

const ImageRow = ({ index, message, fileResult, userId, fetchImage }) => {
  const isOtherPerson = +message.senderId !== +userId;

  fetchImage(message.file.fileId);

  return (
    <Box pt={index === 0 ? 3 : 1}>
      <CareTeamMessageImage
        key={`messageImage-${message.messageUuid}`}
        imageURL={fileResult?.url}
        error={fileResult?.error}
        isOtherPerson={isOtherPerson}
      />
    </Box>
  );
};

const FileRow = ({ index, message, userId }) => {
  const isOtherPerson = +message.senderId !== +userId;

  return (
    <Box pt={index === 0 ? 3 : 1}>
      <CareTeamMessageFile
        key={`messageFile-${message.messageUuid}`}
        fileId={message.file.fileId}
        fileName={message.file.fileName}
        isOtherPerson={isOtherPerson}
      />
    </Box>
  );
};

const TimestampRow = ({ index, timestamp, userId, isLastItem, lastItemRef }) => {
  const messageGroupSplitToTimestampAndSender = timestamp.split(',');
  const timestampToDisplay = messageGroupSplitToTimestampAndSender[0];
  const messageSender = messageGroupSplitToTimestampAndSender[1];
  const isOtherPerson = +messageSender !== +userId;

  // The last item will always be a timestamp, so assign lastItemRef if this is the
  // last one in the list.
  const timestampRef = isLastItem ? lastItemRef : undefined;

  // Add greater bottom padding if its the last timestamp in the list.
  return (
    <Box pt={1} pb={isLastItem ? 3 : 1} ref={timestampRef}>
      <CareTeamMessageTimestamp
        isOtherPerson={isOtherPerson}
        timestampToDisplay={timestampToDisplay}
      />
    </Box>
  );
};

const NewMessagesRow = () => (
  <Box pt={2} pb={1}>
    <CareTeamNewMessagesLine />
  </Box>
);

export default CareTeamMessagesWindow;
