import { Box } from '@mui/material';
import { marked } from 'marked';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { parseISO, format } from 'date-fns';
import { FaqAnswer } from '../atoms/FaqAnswer';
import { FaqAuthoredBy } from '../atoms/FaqAuthoredBy';
import { FaqTitle } from '../atoms/FaqTitle';
import { useTranslation } from 'react-i18next';
import { StyledCard } from '../atoms/StyledCard';
import { ModuleDetailContext } from '../pages/ModuleDetail';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { PatientEvent } from '../../data/Events';
import VideoPlayer from '../organisms/VideoPlayer';
import PlayButton from '../icons/PlayButton';
import MyCricketColors from '../../data/MyCricketColors';
import URIUtils from '../../utils/URIUtils.js';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const THUMBNAIL_IMAGE_SIZE = {
  small: { width: '90px', height: '72px' },
  large: { width: '260px', height: '174px' },
};

export const FaqItem = ({
  faq,
  shouldScrollIntoView,
  shouldStartOpen,
  isGenericContent,
  useLargeDisplay,
  ariaHeaderLevel,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [bio, setBio] = useState('');
  const [answer, setAnswer] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [video, setVideo] = useState(null);
  const [authorName, setAuthorName] = useState('');
  const [role, setRole] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');

  const { recommendedModuleIds } = usePatientContext();

  const { t } = useTranslation();

  const faqRef = useRef(null);
  const { setSelectedFaqId, selectedModuleId, courseId } = useContext(ModuleDetailContext);
  const { logEvent } = useClientEventLoggerContext();

  const isDesktop = useIsDesktop();

  useEffect(() => {
    const {
      title,
      author,
      answer,
      thumbnail,
      video,
      es_video: esVideo,
      updated_at: updatedAt,
    } = faq;
    setTitle(title);

    if (author) {
      author.name && setAuthorName(author.name);
      author.role && setRole(author.role.replace(/\s/g, ''));
      author.picture && author.picture.url && setAvatarUrl(author.picture.url);
      author.bio && setBio(author.bio);
    }

    const answerHtml = marked.parse(t(answer));
    setAnswer(answerHtml);

    if (thumbnail) {
      setThumbnailUrl(thumbnail.formats ? thumbnail.formats.thumbnail.url : thumbnail.url);
    }

    if (video || esVideo) {
      setVideo({
        id: { default: video?.id, es: esVideo?.id },
        videoURL: { default: video?.media.url, es: esVideo?.media.url },
        mimeType: { default: video?.media.mime, es: esVideo?.media.mime },
        posterURL: { default: video?.poster.url, es: esVideo?.poster.url },
      });
    } else {
      setVideo(null);
    }

    if (updatedAt) {
      const date = parseISO(updatedAt);
      if (date) {
        setUpdatedDate(format(date, 'MM/dd/yyyy'));
      }
    }
  }, [faq, t]);

  const toggleOpen = useCallback(
    (open) => {
      setOpen(open);
      if (open) {
        logEvent({
          predicate: PatientEvent.OPENED_FAQ,
          object: `faq://${faq.id}`,
          prepositions: {
            faqId: faq.id,
            page: URIUtils.toMyCricketPageURI(window.location.pathname),
            courseId: courseId,
            moduleId: selectedModuleId,
            recommendedModule: recommendedModuleIds.includes(selectedModuleId),
          },
        });
      }
    },
    [courseId, faq.id, recommendedModuleIds, selectedModuleId, logEvent],
  );

  useEffect(() => {
    if (shouldStartOpen) {
      toggleOpen(true);
    }
  }, [shouldStartOpen, toggleOpen]);

  useEffect(() => {
    if (shouldScrollIntoView) {
      toggleOpen(true);
      faqRef.current?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
      setSelectedFaqId(null);
    }
  }, [faqRef, shouldScrollIntoView, toggleOpen, setSelectedFaqId]);

  const imageSize = useLargeDisplay ? THUMBNAIL_IMAGE_SIZE.large : THUMBNAIL_IMAGE_SIZE.small;

  return (
    <StyledCard
      onClick={() => toggleOpen(!open)}
      scrollToRef={faqRef}
      display={'flex'}
      alignItems={'center'}
      minHeight={useLargeDisplay ? '224px' : undefined}
    >
      <Box p={3} display="flex" flexDirection="row" alignItems="center" width="100%">
        {thumbnailUrl && !open ? (
          <Box
            width={imageSize.width}
            height={imageSize.height}
            pr={3}
            mr={3}
            position={'relative'}
          >
            {video ? (
              <Box
                position={'absolute'}
                display={'flex'}
                width={'100%'}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <PlayButton height={'50%'} width={'50%'} color={MyCricketColors.mint} alt="Play" />
              </Box>
            ) : null}
            <img width={imageSize.width} height={imageSize.height} src={thumbnailUrl} alt={title} />
          </Box>
        ) : null}
        <Box display="flex" flexDirection="column" width="100%">
          <FaqTitle open={open} title={title} ariaHeaderLevel={ariaHeaderLevel} />
          {video ? (
            <Box width="100%" display="flex" justifyContent="center" flexDirection="row">
              {video && open ? (
                <Box maxWidth={'100%'} onClick={(event) => event.stopPropagation()}>
                  <VideoPlayer {...video} />
                </Box>
              ) : null}
            </Box>
          ) : null}
          {/* Normally we'd conditionally render this only if open is true, but that will break motion as it needs the component mounted before it starts its animation.*/}
          {/* Also, previously we just directly changed the display value, but that broke the text wrapping for some reason */}
          <FaqAnswer answer={answer} updatedDate={updatedDate} open={open} />
          {(isDesktop || open) && (
            <FaqAuthoredBy
              isGenericContent={isGenericContent}
              role={role}
              avatarUrl={avatarUrl}
              authorName={authorName}
              bio={bio}
              open={open}
            />
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};
