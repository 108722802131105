export const patientPasswordRules = [
  {
    displayValue: '8-24 characters',
    validator: (password) => password && password.length > 7 && password.length < 25,
  },
  {
    displayValue: 'Lowercase letter',
    validator: (password) => password && password.toUpperCase() !== password,
  },
  {
    displayValue: 'Uppercase letter',
    validator: (password) => password && password.toLowerCase() !== password,
  },
  {
    displayValue: 'Number',
    validator: (password) => password && Boolean(password.match(/[0-9]/)),
  },
];

export const validatePassword = (
  password,
  setPasswordErr,
  dirty,
  strict,
  isSignupForm,
  loggingFuncs = {},
  userRoleName,
  confirmPassword = null,
  setConfirmPasswordErr = null,
) => {
  const unmetPasswordRules = patientPasswordRules.filter(({ validator }) => !validator(password));
  const unmetPasswordRulesPayload = unmetPasswordRules.reduce(
    (acc, { displayValue, validator }) => ({
      ...acc,
      [displayValue]: Boolean(validator(password)),
    }),
    {},
  );
  // These logging functions are provided by the caller and will log different events based on the page
  const { logPasswordTooWeak, logPasswordDoesNotMatch } = loggingFuncs;
  // only show if there are no normal password errors
  if (setConfirmPasswordErr) {
    setConfirmPasswordErr(null);
  }

  if (strict && password.length === 0) {
    setPasswordErr('Please create a password to sign up');
    if (logPasswordTooWeak) {
      logPasswordTooWeak(unmetPasswordRulesPayload, isSignupForm);
    }
    return false;
  }
  // don't show error if form hasn't been touched
  if (!dirty['password'] && !strict) {
    return;
  }
  if (unmetPasswordRules.length > 0) {
    if (strict && logPasswordTooWeak) {
      logPasswordTooWeak(unmetPasswordRulesPayload, isSignupForm);
    }
    // using the i18n key here to be used in component
    setPasswordErr('SeePasswordRules');
    return false;
  }

  setPasswordErr(null);

  if (setConfirmPasswordErr) {
    // Not actually doing a password validation here
    // eslint-disable-next-line security/detect-possible-timing-attacks
    if (confirmPassword !== password) {
      // using the i18n key here to be used in component
      if (logPasswordDoesNotMatch) {
        logPasswordDoesNotMatch();
      }
      setConfirmPasswordErr('mismatchedPasswords');
      return false;
    } else {
      setConfirmPasswordErr(null);
    }
  }

  return true;
};

export const validateNickName = (name, nickNameDirty) => {
  if (!nickNameDirty) {
    return;
  }
  if (name.length < 2) {
    return 'display_name_too_short';
  } else if (name.length > 36) {
    return 'display_name_too_long';
  }

  return null;
};
