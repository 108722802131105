import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const UpdateUserNameForm = ({ newUserName, setNewUserName, errorMessage, clearError }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <Box width={'100%'}>
      <Box mb={4} width={'100%'}>
        <TextField
          name={'display-name'}
          variant={'outlined'}
          placeholder={t('displayName', 'Display Name')}
          style={{ width: !isDesktop ? '100%' : undefined, marginTop: '8px' }}
          error={!!errorMessage}
          value={newUserName}
          onChange={(event) => {
            setNewUserName(event.target.value);
            clearError();
          }}
        />
      </Box>
      {errorMessage && (
        <Box mt={3} color={MyCricketColors.notificationRed}>
          <Typography variant={'body1'}>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpdateUserNameForm;
