import { TextField, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';

const useStyles = makeStyles((theme) => ({
  textFieldLabel: {
    // this will be applied when input focused (label color change)
    '&$textFieldLabelFocused': {
      color: MyCricketColors.lake,
    },
  },
  textFieldLabelFocused: {},
  textFieldRoot: {
    'fontWeight': '400',
    'lineHeight': '25px',
    // this will applied when hovered (input border color change)
    '&:hover $textFieldNotchedOutline': {
      borderColor: MyCricketColors.lake,
    },
    // this will be applied when focused (input border color change)
    '&$textFieldFocused $textFieldNotchedOutline': {
      borderColor: MyCricketColors.lake,
    },
  },
  textFieldFocused: {},
  // borderless: { border: 'none' },
  textFieldNotchedOutline: {},
  borderless: {
    'fontWeight': '400',
    'lineHeight': '25px',
    '& $textFieldNotchedOutline': {
      borderColor: 'transparent',
    },
    // this will applied when hovered (input border color change)
    '&:hover $textFieldNotchedOutline': {
      borderColor: MyCricketColors.lake,
    },
    // this will be applied when focused (input border color change)
    '&$textFieldFocused $textFieldNotchedOutline': {
      borderWidth: '1px',
      borderColor: MyCricketColors.lake,
    },
  },
}));

export const DecoratedChatInput = (props) => {
  const classes = useStyles();
  const {
    charLimit = 7999,
    borderless = false,
    isMultiline = true,
    style,
    blockButton,
    value,
    'data-test-id': dataTestId,
    'data-test-type': dataTestType,
    inputStyle,
    ...rest
  } = props;
  const [charsRemaining, setCharsRemaining] = useState(0);
  const [shouldBlockButton, setShouldBlockButton] = useState(false);
  useEffect(() => {
    // https://dev.to/ihavecoke/how-to-caculate-emoji-length-36df
    // lodash to account for emoji
    const _charsRemaining = charLimit - _.toArray(value).length;
    setCharsRemaining(_charsRemaining);
    setShouldBlockButton(_charsRemaining < 0);
  }, [value, charLimit]);

  useEffect(() => {
    blockButton && blockButton(shouldBlockButton);
  }, [shouldBlockButton, blockButton]);

  return (
    <Box display="flex" flexDirection="column" width={'100%'}>
      <TextField
        multiline={isMultiline}
        variant={'outlined'}
        style={{
          backgroundColor: MyCricketColors.white,
          ...style,
        }}
        resize={'none'}
        InputProps={{
          style: {
            paddingTop: '12px',
            paddingBottom: '12px',
            borderRadius: '6px',
            ...inputStyle,
          },
          classes: {
            root: borderless ? classes.borderless : classes.textFieldRoot,
            focused: classes.textFieldFocused,
            notchedOutline: classes.textFieldNotchedOutline,
          },
        }}
        type="text"
        fullWidth
        // Any non null value for error results in the error UI 🤷‍♀️
        error={shouldBlockButton ? true : null}
        value={value}
        inputProps={{ 'data-test-id': dataTestId, 'data-test-type': dataTestType }}
        {...rest}
      />
      {charsRemaining <= 100 && (
        <Box mt={1}>
          <Typography
            variant="h3"
            style={{
              color:
                charsRemaining > 20 ? MyCricketColors.mediumGray : MyCricketColors.notificationRed,
            }}
          >
            {shouldBlockButton ? (
              <Trans
                i18nKey={'charactersExceeded'}
                defaults={`Your message is currently {{count}} characters too long`}
                count={charsRemaining * -1}
              />
            ) : (
              <Trans
                i18nKey={'charactersRemaining'}
                values={{ count: charsRemaining }}
                count={charsRemaining}
                defaults={`{{count}} characters remaining`}
              />
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
