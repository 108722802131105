import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClickableBox } from '../atoms/Buttons';
import MyCricketColors from '../../data/MyCricketColors';
import { HorizontalChevronSlider, HorizontalChevron } from '../atoms/Chevrons';

const LearnModuleCard = ({ moduleTitle, isRecommended, onClick }) => {
  const [isHovered, setHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <ClickableBox
      display={'flex'}
      flexDirection={{ xs: 'row', md: 'column' }}
      alignItems={{ xs: 'center', md: 'flex-start' }}
      bgcolor={MyCricketColors.white}
      border={`1px solid ${MyCricketColors.lightGraySmoke}`}
      borderRadius={'6px'}
      p={3}
      width={'100%'}
      minHeight={{ xs: '0', md: '150px' }}
      sx={{
        'boxShadow': { xs: '0', md: '0 1px 3px 0px #003E511A' },
        'transition': 'box-shadow 0.25s',
        '&:hover': {
          boxShadow: { xs: '0', md: '0 1px 3px 2px #003E511A' },
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <Box flex={'1 0 0'}>
        <Typography variant={'subtitle1'} aria-level={'2'}>
          {moduleTitle}
        </Typography>
        {isRecommended ? (
          <Typography
            variant={'subtitle2'}
            sx={{ mt: 0.5, fontStyle: 'italic', fontWeight: 400 }}
            aria-level={'3'}
          >
            {t('Recommended')}
          </Typography>
        ) : null}
      </Box>
      <Box
        flex={'0 0 1'}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        width={{ xs: 'auto', md: '100%' }}
      >
        <Box display={'flex'} alignItems={'center'} fontSize={'40px'}>
          <HorizontalChevronSlider hovered={isHovered}>
            <HorizontalChevron />
          </HorizontalChevronSlider>
        </Box>
      </Box>
    </ClickableBox>
  );
};

export default LearnModuleCard;
