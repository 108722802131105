export default {
  gold: '#FAAC28',
  salmon: '#F9695C',
  salmonWhite: '#FEFAFA',
  lightOrangePink: '#FFF8F7',
  orange: '#ED8B2A',
  urgentOrange: '#EA3901',
  brightYellow: '#E9D500',
  yellow: '#F5AD29',
  red: '#CE0B24',
  actionRed: '#FF0000',
  backgroundRed: '#FEF0EE',
  green: '#47BA21',
  backgroundGreen: '#DAF1D3',
  lightGreen: '#F9FDF8',
  darkPurple: '#2B0B7D',
  purple: '#2F166D',
  lightPurple: '#552DB9',
  brightPurple: '#7A4FE5',
  lavender: '#CAC1E1',
  paleLavender: '#F8F5FF',
  magnolia: '#F8F6FF',
  paleWhite: '#E6EAEE',
  paleGreyThree: '#FAFBFC',
  opaqueGray: '#7F8FA4',
  opaqueueGray: '#94a4b8',
  blueWhite: '#C8D2DF',
  lightGrayBlue: '#9A9CB9',
  grayBlue: '#464870',
  darkGrayBlue: '#30314F',
  lightBlue: '#4698FA',
  fadedBlue: '#D7E9FF',
  blue: '#157EFB',
  actionBlue: '#083D77',
  inkyBlue: '#22244A',
  midniteBlue: '#0B0634',
  lightSkyBlue: '#4E9FFF',
  deepSkyBlue: '#157EFB',
  icyBlue: '#CEE4FE',
  chatBubbleBlue: '#94C4FD',
  backgroundGray: '#7F8FA4',
  white: '#FFFFFF',
  lightWhite: '#F8F9FA',
  offWhite: '#FAFAFA',
  lightGray: '#F6F4F4',
  mediumGray: '#D9D8D7',
  paleGrey: '#EFF3F6',
  gray: '#9E9D9D',
  mediumDarkGray: '#5B5655',
  darkGray: '#403B39',
  dividerGray: '#c8c9d1',
  charcoal: '#141414',
  paleGrayFour: '#F6FAFF',
  lightGrayFog: '#f0f3f6',
  dark: '#232548',
  blueyGrey: '#8A9AAE',
  weatheredStone: '#C4C4C4',
  darkBlue: '#2C2D46',
  shieldBlue: '#003B5C',
  oceanBlue: '#0071BC',
  steelersBlue: '#00529b',
  whiteSmoke: '#f5f5f5',
  caribbeanBlue: '#0095DA',
  basalt: '#2C2A29',
  toryBlue: '#17479E',
  coldGreyBlue: '#212529',
  tealGray: '#809FA8',
  teal: '#71C5E8',
  mediumTeal: '#1A6479',
  //rebrand colors
  rebrandGreyBackground: '#EFF3F6',
  cricketDarkBlue: '#2C7D93',
  cricketLightGreen: '#A9D15D',
  cricketDarkGreen: '#248848',
  cricketDeepBlue: '#003E51',
  cricketLightBlue: '#A4D7E4',
  cricketBlue: '#07AAD7',
  cricketRoyalBlue: '#2288D2',
  linkTextBlue: '#157EFB',
  cricketMidnightBlue: '#0f54b6',
  orangeRed: '#F5AC28',
  notificationRed: '#D74C34',
};
