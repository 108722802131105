import React from 'react';
import * as Sentry from '@sentry/react';
import { Redirect, Route } from 'react-router-dom';
import { paths } from './routes';
import { usePatientContext } from '../contexts/PatientContextProvider';
import LoadingPage from '../../components/templates/LoadingPage';

const SentryRoute = Sentry.withSentryRouting(Route);

const InnerPrivateRoute = ({ component: Component, routeComponent: RouteComponent, ...rest }) => {
  const { idTokenHasBeenSet, pcLoading } = usePatientContext();

  if (pcLoading) {
    // We are unsure if we have a token or not, or are in the process of validating it. Show loading.
    return <Route component={() => <LoadingPage {...rest} />} />;
  } else if (idTokenHasBeenSet) {
    // We have a valid token! Go to the intended page.
    return <RouteComponent {...rest} render={(props) => <Component {...props} />} />;
  } else {
    // We don't have a token to use, redirect to login.
    return (
      <RouteComponent
        {...rest}
        render={({ location }) => (
          <Redirect
            push
            to={{
              pathname: paths.login(),
              search: location.search,
              state: ![paths.logout(), paths.login()].includes(location.pathname)
                ? { referrer: location.pathname }
                : {},
            }}
          />
        )}
      />
    );
  }
};

/**
 * A route that requires an authentication token to use, or redirect back to login screen.
 * Does not include any error monitoring. Use for dev routes and tests.
 */
export const PrivateRoute = (props) => <InnerPrivateRoute {...props} routeComponent={Route} />;

/**
 * A route that requires an authentication token to use, or redirect back to login screen.
 * Includes Sentry error monitoring. Use in standard application routes.
 */
export const SentryPrivateRoute = (props) => (
  <InnerPrivateRoute {...props} routeComponent={SentryRoute} />
);
