import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Avatar } from './Avatar';
import { Roles } from '../../data/Roles';
import { RoleLabel } from './RoleLabel';
import { classesPropType } from '../../utils/patientPropTypes';
import MyCricketColors from '../../data/MyCricketColors';
import GroupLikePostButton from './GroupLikePostButton';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { useGroupDiscussionContext } from '../pages/GroupPage';
import { safelyLinkifyURLsInString } from '../../utils/urlUtils';
import { TimestampFromNow } from './Timestamp';
import { NewBadge } from './NewBadge';
import { paths } from '../../utils/routing/routes';

const styles = (theme) => ({
  groupPostCardWrapper: {
    backgroundColor: theme.palette.lightGrayMist,
  },
  smallAvatar: {
    minHeight: '42px',
    minWidth: '42px',
  },
});

function GroupPostCardReply(props) {
  const { classes, groupName, messageId } = props;
  const { getUserProfile, readMessage, getMessageById } = useChirpContext();
  const { newGroupMessagesArray, setNewGroupMessagesArray } = useGroupDiscussionContext();
  const [messageText, setMessageText] = useState(null);
  const [senderId, setSenderId] = useState(null);
  const [message, setMessage] = useState(null);
  const [isRead, setIsRead] = useState(null);

  useEffect(() => {
    const _message = getMessageById(messageId);
    if (_message) {
      setMessage(_message);
      _message.messageText && setMessageText(_message.messageText);
      _message.senderId && setSenderId(_message.senderId);
      _message.isRead && setIsRead(_message.isRead);
    }
  }, [getMessageById, messageId]);

  useEffect(() => {
    // using message.id here instead of messageId to reduce the dependency array, and to make sure we dont read before we have the message
    if (message && !isRead && newGroupMessagesArray.indexOf(message.id) === -1) {
      // this ensure replies are read as soon as they are rendered to the screen.
      readMessage(message);
      setNewGroupMessagesArray([...newGroupMessagesArray, message.id]);
    }
  }, [isRead, message, newGroupMessagesArray, readMessage, setNewGroupMessagesArray]);

  const messageWithLinks = safelyLinkifyURLsInString(messageText);

  const {
    avatarURI: messageSenderAvatarUri,
    nickName: messageSenderNickname,
    roleName: messageSenderRolename,
  } = getUserProfile(senderId);

  const isPatient =
    messageSenderRolename && messageSenderRolename.toLowerCase() === Roles.PATIENT.toLowerCase();

  return (
    <Box
      className={classes.groupPostCardWrapper}
      data-testid={`groupPostCardWrapper-${messageId}`}
      data-message-link={`${_cc?.patient?.url}${paths.groupMessage(groupName, messageId)}`}
      display={'flex'}
      borderTop={`1px solid ${MyCricketColors.lightGrayHaze}`}
      boxShadow={'0px 3px 4px rgba(0, 62, 81, 0.1)'}
      width={'100%'}
    >
      <Box marginLeft={3} marginRight={1} marginTop={3}>
        <Avatar source={messageSenderAvatarUri} className={classes.smallAvatar} />
      </Box>
      <Box width="100%">
        <Box marginTop={2} paddingLeft={1} width="100%" display="flex" flexDirection="row">
          <Box alignSelf="center">
            <Typography
              variant="subtitle1"
              className={classes.nickname}
              data-test-id={`groupPostCardNickname-${messageId}`}
              aria-level="3"
            >
              {messageSenderNickname}
            </Typography>
            {!isPatient && (
              <Box
                data-testid={`groupPostCardRoleLabelWrapper-${messageId}`}
                display={{ md: 'none', xs: 'auto' }}
              >
                <RoleLabel role={messageSenderRolename} isReply={true} />
              </Box>
            )}
          </Box>
          {!isPatient && (
            <Box
              alignSelf="center"
              marginLeft={1.5}
              data-testid={`groupPostCardRoleLabelWrapper-${messageId}`}
              display={{ xs: 'none', md: 'flex' }}
            >
              <RoleLabel role={messageSenderRolename} isReply={true} />
            </Box>
          )}
          <Box
            display="flex"
            flex={'1 0 0'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            marginRight={4}
          >
            <Box display={{ xs: 'none', md: 'flex' }}>
              {message ? <TimestampFromNow message={message} /> : null}
            </Box>
            {newGroupMessagesArray.includes(messageId) && <NewBadge />}
          </Box>
        </Box>
        <Box paddingBottom={2} marginRight={3}>
          <Box marginLeft={1}>
            <Typography
              variant="body2"
              data-test-id={`groupPostCardMessageText-${messageId}`}
              data-test-type="groupPostCardReplyMessageText"
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: messageWithLinks }}
            />
          </Box>
          <Box alignSelf="center" marginLeft={1} marginTop={1} display={{ md: 'none', xs: 'auto' }}>
            {message ? <TimestampFromNow message={message} /> : null}
          </Box>
          {message ? <GroupLikePostButton message={message} /> : null}
        </Box>
      </Box>
    </Box>
  );
}

GroupPostCardReply.propTypes = {
  classes: classesPropType.isRequired,
};

export default withStyles(styles, { withTheme: true })(GroupPostCardReply);
