import React, { useEffect, useState } from 'react';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import MyCricketColors from '../../data/MyCricketColors';
import { CareTeamBioAvatar } from '../atoms/Avatar';
import { ScrollBox } from '../atoms/ScrollBox';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { CareTeamBioAboutMe } from '../molecules/CareTeamBioAboutMe';
import { CareTeamCloseButton } from '../molecules/CareTeamCloseButton';
import { CareTeamBioProfessionalTitle } from '../molecules/CareTeamBioProfessionalTitle';
import { CareTeamBioTeamMemberName } from '../molecules/CareTeamBioTeamMemberName';
import { CareTeamBioMyJourney } from '../molecules/CareTeamBioMyJourney';
import { useCareTeamContext } from '../pages/CareTeam';
import HorizontalDividerLine from '../atoms/HorizontalDivider';
import CareTeamBioExperience from '../molecules/CareTeamBioExperience';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { PatientEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils.js';

const BioFrame = ({ children }) => {
  return (
    <ScrollBox
      width={{ xs: '100%', sm: '100%', md: '30%' }}
      minHeight={'100%'}
      maxHeight={'100%'}
      bgcolor={MyCricketColors.white}
      ml={{ xs: 0, sm: 0, md: '1px' }}
      borderRadius={{ xs: 0, sm: 0, md: '0 12px 12px 0' }}
      display={'flex'}
      flexDirection={'column'}
      alignItems="center"
      pb={{ xs: 0, sm: 0, md: 4 }}
    >
      {children}
    </ScrollBox>
  );
};

export const CareTeamBio = () => {
  const { selectedCareTeamMember, triggerChatToShow } = useCareTeamContext();
  const { avatarURI, cricketUserId, nickName } = selectedCareTeamMember;

  const [aboutMe, setAboutMe] = useState('');
  const [bioTitle, setBioTitle] = useState('');
  const [credentials, setCredentials] = useState([]);
  const [education, setEducation] = useState();
  const [personalPicOneUri, setPersonalPicOneUri] = useState('');
  const [personalPicTwoUri, setPersonalPicTwoUri] = useState('');
  const [loading, setLoading] = useState(false);
  const [experience, setExperience] = useState();
  //We only use nickName as a fallback because it is not always the full name of the care team member
  const [fullName, setFullName] = useState(nickName);
  //only mentors have these
  const [myJourney, setMyJourney] = useState();
  const [treatmentExperience, setTreatmentExperience] = useState('');

  const { logEvent } = useClientEventLoggerContext();

  useEffect(() => {
    // TODO - Right now this just returns static info (indicated by ⚠️), as we dont currently posess any of this info
    // PE-243 will add the relevant info (ideally to a new table) and add actual implementation
    const fetchData = async () => {
      const _bioInfo = await myCricketAuthedFetch(`/i/admin/careTeamBio/${cricketUserId}`, {
        method: 'GET',
      });
      _bioInfo.experience && setExperience(_bioInfo.experience);
      _bioInfo.aboutMe && setAboutMe(_bioInfo.aboutMe);
      _bioInfo.title && setBioTitle(_bioInfo.title);
      _bioInfo.experience && setExperience(_bioInfo.experience);
      _bioInfo.credentials && setCredentials(_bioInfo.credentials);
      _bioInfo.education && setEducation(_bioInfo.education);
      _bioInfo.personalPicOneUri && setPersonalPicOneUri(_bioInfo.personalPicOneUri);
      _bioInfo.personalPicTwoUri && setPersonalPicTwoUri(_bioInfo.personalPicTwoUri);
      _bioInfo.fullName && setFullName(_bioInfo.fullName);
      _bioInfo.myJourney && setMyJourney(_bioInfo.myJourney);
      _bioInfo.treatmentExperience && setTreatmentExperience(_bioInfo.treatmentExperience);
      setLoading(false);
    };
    setLoading(true);
    logEvent({
      predicate: PatientEvent.OPEN_PROFILE,
      object: URIUtils.toUserURI(cricketUserId),
    });
    try {
      fetchData();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [cricketUserId, logEvent]);

  return (
    <BioFrame>
      <CareTeamCloseButton
        title={'Close'}
        onClick={() => triggerChatToShow()}
        ariaLabel={'Close care team bio'}
      />
      {loading ? (
        <TranslatedTypography variant={'h1'} i18nKey="loading">
          Loading...
        </TranslatedTypography>
      ) : (
        <>
          <CareTeamBioAvatar source={avatarURI} alt={`Profile photo of ${fullName}`} />
          <CareTeamBioTeamMemberName nickName={fullName} />
          {bioTitle && <CareTeamBioProfessionalTitle bioTitle={bioTitle} />}
          <HorizontalDividerLine />
          {
            //accessibility TODO write up more descirptive, custom alt text for photos in the bio. Possibly unique per photo
          }
          <CareTeamBioExperience
            experience={experience ? experience : treatmentExperience}
            picSrc={personalPicOneUri}
            credentials={credentials}
            education={education}
            picAlt={`photo of ${fullName} around the office`}
          />
          {aboutMe && <HorizontalDividerLine />}
          {aboutMe && (
            <CareTeamBioAboutMe
              aboutMe={aboutMe}
              picSrc={personalPicTwoUri}
              picAlt={`photo of ${fullName} outside the office`}
            />
          )}
          {myJourney && <HorizontalDividerLine />}
          {myJourney && <CareTeamBioMyJourney myJourney={myJourney} />}
        </>
      )}
    </BioFrame>
  );
};

export default CareTeamBio;
