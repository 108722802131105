import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReactComponent as ExclamationCircle } from '../icons/ExclamationCircle.svg';

const UnderlineLink = styled('span')({ textDecoration: 'underline', cursor: 'pointer' });

const AccountLocked = (props) => {
  const { handleSendResetPassword } = props;
  return (
    <Box textAlign={'center'} data-test-id="accountLockedContainer">
      <ExclamationCircle />
      <Box mt={2}>
        <Typography variant="h2">
          <Trans i18nKey={'accountLocked'}>Account Temporarily Locked</Trans>
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant={'body1'}>
          <Trans i18nKey={'accountLockedDescription'}>
            For security reasons, access to your account has been temporarily restricted.
          </Trans>{' '}
          {/* FIXME This should be an an anchor or a button */}
          <UnderlineLink
            role="link"
            tabIndex={0}
            onClick={handleSendResetPassword}
            onKeyPress={() => {}}
            data-testid="lockedAccountPWReset"
          >
            <Trans i18nKey={'clickHere'}>Click here</Trans>
          </UnderlineLink>{' '}
          <Trans i18nKey={'toResetPWandAccess'}>to reset your password and regain access</Trans>.
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountLocked;
