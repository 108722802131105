import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { logClientError } from './errorLogging';
import ErrorDisplay from '../components/atoms/ErrorDisplay';

export const useForceErrorThrow = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);
  return setError;
};

export default class PatientErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logClientError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ErrorDisplay
            title={'An error has occurred'}
            description={'Try refreshing the page or check back later.'}
          />
        </Box>
      );
    }
    return this.props.children;
  }
}
