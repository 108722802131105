import React, { Component } from 'react';

import { ChirpContext } from './Chirp';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

// HOC to add the event senders from chirp to the wrapped component
export default function withChirpEventSenders(InnerComponent) {
  return class extends Component {
    static displayName = `withChirpEventSenders(${getDisplayName(InnerComponent)})`;

    // This gives this component access to the context chirp instance
    static contextType = ChirpContext;

    state = {
      authenticated: false,
    };

    componentDidMount() {
      const chirp = this.context;
      if (chirp.authenticated) {
        this.handleAuthentication();
      }
      chirp.on('connect', this.handleAuthentication);
    }

    componentWillUnmount() {
      const chirp = this.context;
      chirp.off('connect', this.handleAuthentication);
    }

    handleAuthentication = () => {
      this.setState({
        authenticated: true,
      });
    };

    render() {
      if (!this.state.authenticated) {
        return <InnerComponent {...this.props} />;
      }

      const chirp = this.context;

      return (
        <InnerComponent
          {...this.props}
          chirpSetGroupMessagesAsRead={chirp.setGroupMessagesAsRead.bind(chirp)}
          chirpHideMessage={chirp.hideMessage.bind(chirp)}
          chirpSetMessageAsRead={chirp.setMessageAsRead.bind(chirp)}
          chirpSetMessageAsUnread={chirp.setMessageAsUnread.bind(chirp)}
          chirpLikeMessage={chirp.likeMessage.bind(chirp)}
          chirpUnlikeMessage={chirp.unlikeMessage.bind(chirp)}
          chirpOpenPublicProfile={chirp.openPublicProfile.bind(chirp)}
          chirpSendMessage={chirp.sendMessage.bind(chirp)}
          chirpSetHideFromPro={chirp.setHideFromPro.bind(chirp)}
          chirpHideNote={chirp.hideNote.bind(chirp)}
          chirpChangeUsername={chirp.changeUsername.bind(chirp)}
        />
      );
    }
  };
}
