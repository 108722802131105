import React from 'react';
import clsx from 'clsx';
import { NativeSelect, Radio, Box, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdCheck } from '@react-icons/all-files/md/MdCheck';
import MyCricketColors from '../../data/MyCricketColors';

export const DropdownSelect = (props) => {
  const { handleChange, options, defaultValue, placeholder } = props;
  return (
    <NativeSelect
      id="select"
      data-test-id="dropdown-editor"
      onChange={handleChange}
      defaultValue={defaultValue || ''}
    >
      {defaultValue == null && (
        <option value={''} disabled>
          {placeholder}
        </option>
      )}
      {options.map((option) => {
        if (typeof option === 'string') {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        } else {
          return (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          );
        }
      })}
    </NativeSelect>
  );
};

const useRadioStyles = makeStyles(() => ({
  root: {
    '&:hover, &.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .innerIcon': {
      border: `2px solid ${MyCricketColors.tealMain}`,
    },
  },
  icon: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    border: `2px solid ${MyCricketColors.lightGraySmoke}`,
  },
  checkedIcon: {
    border: `2px solid ${MyCricketColors.tealMain}`,
    background: MyCricketColors.teal,
    backgroundClip: 'content-box',
    padding: '3px',
  },
}));

export const BaseRadioButton = (props) => {
  const { className } = props;
  const classes = useRadioStyles();
  return (
    <Radio
      className={clsx(classes.root, className)}
      icon={<div className={clsx(classes.icon, 'innerIcon')}></div>}
      checkedIcon={<div className={clsx(classes.icon, classes.checkedIcon)}></div>}
      disableTouchRipple
      {...props}
    />
  );
};

const useCheckboxStyles = makeStyles({
  root: {
    '&:hover, &.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .innerIcon': {
      border: `2px solid ${MyCricketColors.tealMain}`,
    },
  },
});

export const BaseCheckbox = ({ className, size, ...rest }) => {
  const classes = useCheckboxStyles();
  return (
    <Checkbox
      className={clsx(classes.root, className)}
      icon={
        <Box
          width={size || '44px'}
          height={size || '44px'}
          bgcolor={'#FFFFFF'}
          border={`2px solid ${MyCricketColors.lightGraySmoke}`}
        />
      }
      checkedIcon={
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={size || '44px'}
          height={size || '44px'}
          bgcolor={'#FFFFFF'}
          border={`2px solid ${MyCricketColors.tealMain}`}
        >
          <MdCheck size={size || 44} color={MyCricketColors.tealMain} />
        </Box>
      }
      disableTouchRipple
      {...rest}
    />
  );
};
