import { isSameDay, parseISO } from 'date-fns';
import { DiscussionGroupDisplayNames, MultipartyGroupTypes } from '../data/groupTypeNames';
import { logClientError } from './errorLogging';

export function generateChatBubbleDisplayDate(groupDateString) {
  const firstSplit = groupDateString.split('-');
  const secondSplit = firstSplit[2].split('T');
  const thirdSplit = secondSplit[1].split(':');
  const year = firstSplit[0];
  const month = firstSplit[1].length === 1 ? `0${firstSplit[1]}` : firstSplit[1];
  const day = secondSplit[0].length === 1 ? `0${secondSplit[0]}` : secondSplit[0];
  const hour = thirdSplit[0].length === 1 ? `0${thirdSplit[0]}` : thirdSplit[0];
  const minutes = thirdSplit[1].length === 1 ? `0${thirdSplit[1]}` : thirdSplit[1];
  const amOrPm = +hour >= 12 ? 'PM' : 'AM';
  const now = new Date();
  const passedInDate = new Date(`${year}-${month}-${day}T${hour}:${minutes}:00`);
  if (isSameDay(now, passedInDate)) {
    return `${+hour % 12 === 0 ? 12 : +hour % 12}:${minutes} ${amOrPm}`;
  } else {
    return `${+month}/${+day}/${year}, ${+hour % 12 === 0 ? 12 : +hour % 12}:${minutes} ${amOrPm}`;
  }
}

export function getDateAndMinuteFromUnixTimestamp(unixTimestamp) {
  let timeStamp = +unixTimestamp;
  if (unixTimestamp.toString().length === 10) {
    timeStamp = +unixTimestamp * 1000;
  }
  const date = new Date(timeStamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const min = date.getMinutes();
  return `${year}-${month}-${day}T${hour}:${min}`;
}

export function groupTheMessagesBySenderAndDate(messages) {
  if (!messages) {
    const error = new Error(`No messages when we expect at least one. Messages obj does not exist`);
    logClientError(error);
  }
  const groupedMessages = {};
  // Separator guarantees that when a message comes in within the same minute but from a different
  // user that they are grouped appropriately. For demonstration, comment out the separator stuff
  // and just run the tests in chatUtils.test.js.
  let separator = 1;
  for (let i = 0; i < messages.length; i += 1) {
    const prepareMessagesArray = () => {
      if (!groupedMessages[`${messages[i].sendDate},${messages[i].senderId},${separator}`]) {
        groupedMessages[`${messages[i].sendDate},${messages[i].senderId},${separator}`] = [];
      }
    };
    if (
      i > 0 &&
      messages[i].sendDate === messages[i - 1].sendDate &&
      messages[i].senderId !== messages[i - 1].senderId
    ) {
      separator += 1;
      prepareMessagesArray();
      groupedMessages[`${messages[i].sendDate},${messages[i].senderId},${separator}`].push(
        messages[i],
      );
    } else {
      prepareMessagesArray();
      groupedMessages[`${messages[i].sendDate},${messages[i].senderId},${separator}`].push(
        messages[i],
      );
    }
  }
  if (messages.length > 0 && Object.values(groupedMessages).length === 0) {
    const error = new Error(`Failed to group ${messages.length} messages.`);
    logClientError(error);
  }
  return groupedMessages;
}

export function groupTheMessagesByInReplyTo(messages) {
  const groupedMessages = {};
  messages.forEach((message) => {
    if (!message?.inReplyTo) {
      groupedMessages[message?.messageId] = message;
    } else {
      if (!groupedMessages[message?.inReplyTo]?.replies) {
        groupedMessages[message?.inReplyTo].replies = [];
      }
      const replyIds = groupedMessages[message.inReplyTo].replies.map((reply) => reply.messageId);
      if (!replyIds.includes(message.messageId)) {
        groupedMessages[message.inReplyTo].replies.push(message);
      }
    }
  });

  return groupedMessages;
}

// Maybe move this to the ChirpContextProvider so we can remove directMessageGroups
export function getCountOfUnreadMessagesInUnhiddenGroups(directMessageGroups, userId, isCareTeam) {
  return Object.values(directMessageGroups)
    .filter((group) => !group.groupIsHidden)
    .filter((group) =>
      isCareTeam
        ? !Object.values(MultipartyGroupTypes).includes(group.type)
        : Object.values(MultipartyGroupTypes).includes(group.type),
    )
    .map((group) => group.messages)
    .flat()
    .filter((message) => !message.isRead && message.senderId !== userId).length;
}

export function mapGroupTypeNamesToGroupIds(directMessageGroups) {
  //find everything that's not a direct message group
  const groupsArray =
    directMessageGroups &&
    Object.values(directMessageGroups).filter(
      (group) =>
        Object.values(MultipartyGroupTypes).includes(group.type) &&
        Object.keys(DiscussionGroupDisplayNames).includes(group.name),
    );
  const groupTypeNamesToGroupIds = {};
  groupsArray.forEach((group) => {
    groupTypeNamesToGroupIds[group.name] = group.cricketGroupId;
  });
  return groupTypeNamesToGroupIds;
}

export function sortMessagesByDeliveryDate(messages, isDescending = false) {
  return messages.sort((a, b) => {
    const deliveryTimeA = a.deliveryDate ? parseISO(a.deliveryDate).getTime() : 0;
    const deliveryTimeB = b.deliveryDate ? parseISO(b.deliveryDate).getTime() : 0;
    return isDescending ? deliveryTimeB - deliveryTimeA : deliveryTimeA - deliveryTimeB;
  });
}
