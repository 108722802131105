import React from 'react';
import { Button, Badge, styled } from '@mui/material';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { NavLink } from 'react-router-dom';
import MyCricketColors from '../../data/MyCricketColors';

const StyledInnerButton = styled(Button)(({ theme }) => ({
  'justifyContent': 'center',
  'textAlign': 'center',
  'backgroundColor': MyCricketColors.darkTeal,
  'color': MyCricketColors.white,
  'borderRadius': '12px',
  'height': '100%',
  'width': '100%',
  /**
   * On a wide viewport, buttons are in a 64px toolbar. In order to allow
   * coachmarks to use this component as a ref and support all viewports,
   * we add 12px top and bottom margin so that the button takes up 64px.
   *
   * This pushes the coachmarks exactly to the border of the toolbar. We
   * still want to use this component rather than the toolbar as the ref
   * for horizontal alignment, so this is the solution that supports all.
   */
  [theme.breakpoints.up('lg')]: {
    height: '40px',
    margin: '12px 0',
  },
  '& svg': {
    opacity: 0.5,
  },
  '& .MuiButton-label p': {
    opacity: 0.8,
    fontSize: '18px',
    fontWeight: 800,
  },
  '& .MuiBadge-badge': {
    opacity: 1.0,
    borderRadius: '2px',
    fontSize: '16px',
    fontWeight: 800,
    border: `3px solid ${MyCricketColors.darkTeal}`,
    left: '-10%',
    padding: '0px',
    height: '22px',
  },
  [theme.breakpoints.down('md')]: {
    'display': 'grid',
    'padding': '12px 0px 9px 0px',
    'borderRadius': '0px',
    '& .MuiButton-label p': {
      margin: '0 auto',
      fontSize: '16px',
      fontWeight: 800,
    },
    '& .MuiButton-startIcon': {
      margin: '0 auto',
      paddingBottom: theme.spacing(1),
    },
  },
  '&:hover': {
    'backgroundColor': MyCricketColors.teal,
    '& svg': {
      opacity: 1.0,
    },
    '& .MuiButton-label p': {
      opacity: 1.0,
    },
    '& .MuiBadge-badge': {
      border: `3px solid ${MyCricketColors.teal}`,
    },
  },
  '&.active': {
    'backgroundColor': MyCricketColors.teal,
    '& svg': {
      opacity: 1.0,
    },
    '& .MuiButton-label p': {
      opacity: 1.0,
    },
    '& .MuiBadge-badge': {
      border: `3px solid ${MyCricketColors.teal}`,
    },
  },
}));

const NavButton = ({ text, to, icon, notificationCount, activeOn }) => {
  const rest = {};
  if (activeOn) {
    rest.isActive = (match, location) => {
      const parts = location.pathname.split('/');
      const routeParent = parts[1];
      return !!activeOn.includes(routeParent);
    };
  }
  return (
    <StyledInnerButton
      role={'button'}
      data-test-id={`nav-button-${text?.replace(/\s/, '')}`}
      data-testid={`nav-button-${text?.replace(/\s/, '')}`}
      startIcon={
        <Badge
          data-testid={`nav-button-badge-${text?.replace(/\s/, '')}`}
          data-test-id={`nav-button-badge-${text?.replace(/\s/, '')}`}
          badgeContent={notificationCount}
          color="error"
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {icon}
        </Badge>
      }
      component={NavLink}
      to={to}
      {...rest}
    >
      <TranslatedTypography variant={'subtitle1'} style={{ lineHeight: 1.2 }}>
        {text}
      </TranslatedTypography>
    </StyledInnerButton>
  );
};

export default NavButton;
