import { Box } from '@mui/material';
import { CareTeamGroups } from '../../data/groupTypeNames';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { paths } from '../../utils/routing/routes';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { ProviderMatchingBioCard } from '../atoms/ProviderMatchingBioCard';
import ProviderMatchingLoader from '../atoms/ProviderMatchingLoader';
import { isSidebarGroup } from '../organisms/CareTeamSidebar';
import { ProviderMatchCoachMarkId } from '../../data/CoachMarks';

// This coach mark determines if the patient has seen the matching screen or not.

export const ProviderMatching = () => {
  const [progress, setProgress] = useState(2);
  const [loadingDone, setLoadingDone] = useState(false);
  const [careTeamMemberAvatarUri, setCareTeamMemberAvatarUri] = useState(null);
  const [careTeamMemberName, setCareTeamMemberName] = useState(null);
  const [careTeamMemberRole, setCareTeamMemberRole] = useState(null);
  const [alreadyMatched, setAlreadyMatched] = useState(null);
  const { userId, manageCoachmarkHistory, coachMarkHistory, featureFlags } = usePatientContext();

  const { directMessageGroups, getUserProfile } = useChirpContext();

  useEffect(() => {
    const theGroupVals = Object.values(directMessageGroups);
    const onlyCareTeamGroups = theGroupVals.filter((groupObject) => isSidebarGroup(groupObject));
    const firstGroupByType = theGroupVals.filter(
      (groupObject) => groupObject.groupName === CareTeamGroups.NURSE_PT,
    );
    const group = firstGroupByType[0] || onlyCareTeamGroups[0];
    if (group) {
      const memberIdToDisplay = group.members.filter((member) => member !== userId)[0];
      const memberToDisplay = getUserProfile(memberIdToDisplay);
      setCareTeamMemberAvatarUri(memberToDisplay.avatarURI);
      setCareTeamMemberName(memberToDisplay.name);
      setCareTeamMemberRole(memberToDisplay.roleName);
    }
  }, [directMessageGroups, getUserProfile, userId]);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        const diff = Math.random();
        return Math.min(oldProgress + diff, 100);
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // Mark the matching coachMark as clicked on page load.
    manageCoachmarkHistory(ProviderMatchCoachMarkId);
  }, [manageCoachmarkHistory]);

  // Ignoring exhaustive deps here so we only set this if we load the component with the coachMark already seen
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAlreadyMatched(coachMarkHistory[ProviderMatchCoachMarkId]), []);

  return alreadyMatched ? (
    <Redirect to={paths.homepage()} />
  ) : (
    <Box
      role="main"
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      p={{ xs: 3, sm: 3, md: 8 }}
    >
      {
        //right now only show the loader when we don't show the menu
      }
      {!loadingDone && !featureFlags?.['my-cricket-menu'] ? (
        <ProviderMatchingLoader progress={progress} completeLoading={() => setLoadingDone(true)} />
      ) : (
        <ProviderMatchingBioCard
          shouldUseSimplifiedCard={featureFlags?.['my-cricket-menu']}
          careTeamMemberAvatarUri={careTeamMemberAvatarUri}
          careTeamMemberName={careTeamMemberName}
          careTeamMemberRole={careTeamMemberRole}
        />
      )}
    </Box>
  );
};

export default ProviderMatching;
