import { Box } from '@mui/material';
import React from 'react';
import { AnimatedCricketC } from '../icons/CricketC';
import MyCricketColors from '../../data/MyCricketColors';

export const MyCricketLoadingIndicator = (props) => {
  return (
    //  The double box here is just to guarantee the logo will be dead center in w/e component it's placed
    <Box
      width={'100%'}
      height={'100%'}
      display="flex"
      flexDirection={'column'}
      justifyContent={'center'}
      role={'progressbar'}
      aria-busy={true}
      aria-label={'Loading'}
    >
      <Box flexDirection="row" display="flex" justifyContent="center" width={'100%'}>
        <AnimatedCricketC
          width={'124px'}
          height={'140px'}
          fill={MyCricketColors.tealGray}
          releaseAnimation={props.releaseAnimation}
        />
      </Box>
    </Box>
  );
};

export const MyCricketLoadingContainer = ({ loading, children }) => {
  return !loading ? children : <MyCricketLoadingIndicator />;
};
