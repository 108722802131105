let _lastActivity = Date.now();

function updateLastActive() {
  _lastActivity = Date.now();
}

function addListener(eventName) {
  window.addEventListener(eventName, updateLastActive);
}

function removeListener(eventName) {
  window.removeEventListener(eventName, updateLastActive);
}

const validEvents = ['click', 'scroll', 'mousemove', 'keydown', 'keyup', 'touchstart', 'touchmove'];

module.exports = {
  updateLastActive,
  start: function start() {
    validEvents.forEach(removeListener);
    validEvents.forEach(addListener);
  },
  stop: function stop() {
    validEvents.forEach(removeListener);
  },
  get: function get() {
    return Date.now() - _lastActivity;
  },
};
