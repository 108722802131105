export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber == null) {
    return '';
  }
  const phoneNumberString = phoneNumber.toString().replace(/\D/g, '');
  const areaCode = phoneNumberString.slice(0, 3);
  const secondThree = phoneNumberString.slice(3, 6);
  const lastFour = phoneNumberString.slice(6, 10);
  return `(${areaCode}) ${secondThree}-${lastFour}`;
};
