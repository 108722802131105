import { logClientError } from '../../utils/errorLogging';
import shuffle from '../../utils/shuffleArray';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SurveyComplete from '../molecules/SurveyComplete';
import SurveyQuestionsContainer from './SurveyQuestionsContainer';
import SurveyWelcomeContainer from './SurveyWelcomeContainer';

const SurveyState = {
  START: 'start',
  QUESTION: 'question',
  COMPLETE: 'complete',
};

const Survey = ({
  questions,
  questionAnswers,
  questionAnswerDependencies,
  getSurveyAnswers,
  submitSurveyAnswer,
  initialSurveyState,
  saveSurveyState,
  clearSurveyState,
  completeSurvey,
  injectedAnswerState,
  shouldShowSurveyCompleteScreen,
}) => {
  const [surveyState, setSurveyState] = useState(
    initialSurveyState || { state: SurveyState.START },
  );

  const [answerState, setAnswerState] = useState(injectedAnswerState);
  const [answersGroupedByQuestionId, setAnswersGroupedByQuestionId] = useState({});
  const [savedSurveyAnswerIds, setSavedSurveyAnswerIds] = useState([]);
  const [error, setError] = useState(null);
  useTranslation();

  const goToQuestion = (questionId) => setSurveyState({ state: SurveyState.QUESTION, questionId });
  const goToStart = () => setSurveyState({ state: SurveyState.START });
  const goToComplete = () => setSurveyState({ state: SurveyState.COMPLETE });
  const finishSurvey = async () => {
    await completeSurvey();
    clearSurveyState && clearSurveyState();
  };

  useEffect(() => {
    saveSurveyState && saveSurveyState(surveyState);
  }, [surveyState, saveSurveyState]);

  useEffect(() => {
    const effect = async () => {
      try {
        const res = await getSurveyAnswers();
        const questionIds = questions.map((q) => q.questionId);
        const { answers } = res;
        const initialAnswerState = questionIds.reduce((acc, currQuestionId) => {
          const myAnswerIds = questionAnswers
            .filter((a) => a.questionId === currQuestionId)
            .map((a) => a.answerId);
          const myCurrentAnswers = answers
            ?.filter(({ answerId }) => myAnswerIds.includes(answerId))
            .map(({ answerId, booleanValue, textValue }) => ({
              answerId,
              answerType: questionAnswers.find((a) => a.answerId === answerId).answerType,
              answerValue: booleanValue || textValue,
            }));
          return { ...acc, [currQuestionId]: myCurrentAnswers };
        }, {});
        setAnswerState(initialAnswerState);
        // Note, though the frontend tries to prevent this, it's possible
        // (for legacy reason) that answers have multiple surveyAnswerIds,
        // the below code guarantees that the most recent surveyAnswerId is
        // used by cascading the object key
        setSavedSurveyAnswerIds(
          answers.reduce((acc, curr) => ({ ...acc, [curr.answerId]: curr.surveyAnswerId }), {}),
        );
        const randomOrderAnswersGroupedByQuestionId = groupBy(questionAnswers, 'questionId');
        Object.keys(randomOrderAnswersGroupedByQuestionId).map((id) => {
          const answers = randomOrderAnswersGroupedByQuestionId[id];
          if (answers.some((a) => a.randomizeAnswerOrder)) {
            const answersGroupedByAnswerGroupId = groupBy(answers, 'answerGroupId');
            const answersChunkedByAnswerGroupId = Object.values(answersGroupedByAnswerGroupId);
            answersChunkedByAnswerGroupId.map((answerGroup, index) => {
              if (answerGroup.some((a) => a.randomizeAnswerOrder)) {
                answersChunkedByAnswerGroupId[index] = shuffle(answerGroup);
              }
            });
            randomOrderAnswersGroupedByQuestionId[id] = answersChunkedByAnswerGroupId.flat();
          }
        });
        setAnswersGroupedByQuestionId(randomOrderAnswersGroupedByQuestionId);
      } catch (error) {
        logClientError(error);
      }
    };
    effect();
  }, [getSurveyAnswers, questionAnswers, questions]);

  const getQuestionDependenciesMet = (questionId) => {
    const myQuestionAnswerDependencies = questionAnswerDependencies.filter(
      (qad) => parseInt(qad.questionId) === parseInt(questionId),
    );
    const questionDependenciesMet =
      myQuestionAnswerDependencies.length > 0
        ? myQuestionAnswerDependencies.some((qad) =>
            Object.values(answerState)
              .flat()
              .find(({ answerId, answerValue }) => answerId === qad.answerId && answerValue),
          )
        : true;

    return questionDependenciesMet;
  };

  if (!answerState) {
    return null;
  }
  return surveyState.state === SurveyState.START ||
    (surveyState.state === SurveyState.QUESTION && surveyState.questionId === null) ? (
    <SurveyWelcomeContainer
      savedSurveyAnswerIds={savedSurveyAnswerIds}
      questions={questions}
      answerState={answerState}
      getQuestionDependenciesMet={getQuestionDependenciesMet}
      goToQuestion={goToQuestion}
      goToComplete={async () =>
        shouldShowSurveyCompleteScreen ? await finishSurvey() : goToComplete()
      }
    />
  ) : surveyState.state === SurveyState.COMPLETE ? (
    <SurveyComplete
      data-testid="surveyComplete"
      onSubmit={async () => {
        await finishSurvey();
      }}
      onPrevClick={() => goToQuestion(questions[questions.length - 1].questionId)}
    />
  ) : surveyState.state === SurveyState.QUESTION && surveyState.questionId != null ? (
    <SurveyQuestionsContainer
      surveyState={surveyState}
      answersGroupedByQuestionId={answersGroupedByQuestionId}
      getQuestionDependenciesMet={getQuestionDependenciesMet}
      questions={questions}
      goToQuestion={goToQuestion}
      answerState={answerState}
      questionAnswerDependencies={questionAnswerDependencies}
      savedSurveyAnswerIds={savedSurveyAnswerIds}
      setAnswerState={setAnswerState}
      setError={setError}
      goToStart={goToStart}
      error={error}
      submitSurveyAnswer={submitSurveyAnswer}
      setSavedSurveyAnswerIds={setSavedSurveyAnswerIds}
      goToComplete={async () =>
        shouldShowSurveyCompleteScreen ? await finishSurvey() : goToComplete()
      }
    />
  ) : null;
};

export default Survey;
