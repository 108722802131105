import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClickableBox } from '../atoms/Buttons';
import CoursesDisplay from '../organisms/CoursesDisplay';
import GenericLearnContentDisplay from '../organisms/GenericLearnContentDisplay';
import MyCricketColors from '../../data/MyCricketColors';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { MyCricketLoadingIndicator } from '../atoms/MyCricketLoadingIndicator';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { usePageScrollContext } from '../templates/Page';
import { toMyCricketPageURI } from '../../utils/URIUtils';
import { PatientEvent } from '../../data/Events';
import { logClientError } from '../../utils/errorLogging';

const LearnContentCategory = Object.freeze({
  COURSES: 'courses',
  RECIPES: 'recipes',
  ARTICLES: 'articles',
});

const TranslationForCategory = Object.freeze({
  [LearnContentCategory.COURSES]: '0',
  [LearnContentCategory.RECIPES]: '-33.33333%',
  [LearnContentCategory.ARTICLES]: '-66.66666%',
});

export const tabBarHeight = '80px';

const loadCoursesContent = async () => {
  const data = await myCricketAuthedFetch('/i/learn/courses');
  if (data.courseList) {
    const activeCourseName = data.activeCourseName;
    const coursesInOrder = data.courseList
      .sort((c1, c2) => c1.order - c2.order)
      .sort((c1, c2) =>
        c1.scope === activeCourseName ? -1 : c2.scope === activeCourseName ? 1 : 0,
      );
    return coursesInOrder;
  }
  return null;
};

const loadGenericContent = async (moduleId) => {
  const data = await myCricketAuthedFetch(`/i/learn/module/${moduleId}`);
  if (data) {
    const content = [...data.faqs.sort((f1, f2) => f1.order - f2.order)];
    const sortedModules = data.siblings?.sort((m1, m2) => m1.order - m2.order) || [];
    const moduleData = await Promise.all(
      sortedModules.map((module) => myCricketAuthedFetch(`/i/learn/module/${module.id}`)),
    );
    for (const module of moduleData) {
      const sortedFAQs = module.faqs.sort((f1, f2) => f1.order - f2.order);
      content.push(...sortedFAQs);
    }
    // Remove duplicates
    return Array.from(new Map(content.map((item) => [item.id, item])).values());
  }
  return null;
};

const LearnPage = ({ match: { params } }) => {
  const { category, selectedItemId } = params;
  const { logEvent } = useClientEventLoggerContext();
  const { scrollPage } = usePageScrollContext();

  const [contentCategory, setContentCategory] = useState(category || LearnContentCategory.COURSES);
  const [contentTranslation, setContentTranslation] = useState(
    TranslationForCategory[category] || '0',
  );
  const [coursesContent, setCoursesContent] = useState(null);
  const [recipesContent, setRecipesContent] = useState(null);
  const [articlesContent, setArticlesContent] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();
  const location = useLocation();

  const selectCategory = useCallback(
    async (category) => {
      setContentCategory(category);
      setContentTranslation(TranslationForCategory[category]);
      scrollPage(0);
      logEvent({
        predicate: PatientEvent.VIEW_PAGE,
        object: toMyCricketPageURI(`${location.pathname}`.replace(/\s/g, '')),
        prepositions: {
          page: toMyCricketPageURI(location.pathname),
          tab: category,
          referrer: `${location.state?.referrer ?? ''} `,
        },
      });
    },
    [logEvent, scrollPage, location.pathname, location.state?.referrer],
  );

  useEffect(() => {
    const loadContent = async () => {
      setLoading(true);
      try {
        const courses = await loadCoursesContent();
        setCoursesContent(courses);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.COURSES]: false }));
      } catch (error) {
        logClientError(error);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.COURSES]: true }));
      }
      try {
        const recipes = await loadGenericContent(1051);
        setRecipesContent(recipes);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.RECIPES]: false }));
      } catch (error) {
        logClientError(error);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.RECIPES]: true }));
      }
      try {
        const articles = await loadGenericContent(1063);
        setArticlesContent(articles);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.ARTICLES]: false }));
      } catch (error) {
        logClientError(error);
        setErrors((prev) => ({ ...prev, [LearnContentCategory.ARTICLES]: true }));
      }
      setLoading(false);
    };

    loadContent().catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    selectCategory(category || LearnContentCategory.COURSES);
  }, [isLoading, category, selectCategory]);

  return (
    <Box height={isLoading ? '100%' : 'auto'}>
      <Box
        position={'sticky'}
        top={0}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={tabBarHeight}
        bgcolor={MyCricketColors.white}
        borderBottom={`1px solid ${MyCricketColors.lightGraySmoke}`}
        px={2}
        zIndex={1}
      >
        <Box display={'flex'} alignItems={'center'} width={'720px'} textAlign={'center'}>
          <Box flex={'1'} mr={{ xs: 1.5, md: 3 }}>
            <ContentCategoryButton
              title={t('Courses')}
              isSelected={contentCategory === LearnContentCategory.COURSES}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                selectCategory(LearnContentCategory.COURSES);
              }}
            />
          </Box>
          <Box flex={'1'} mr={{ xs: 1.5, md: 3 }}>
            <ContentCategoryButton
              title={t('recipes', 'Recipes')}
              isSelected={contentCategory === LearnContentCategory.RECIPES}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                selectCategory(LearnContentCategory.RECIPES);
              }}
            />
          </Box>
          <Box flex={'1'}>
            <ContentCategoryButton
              title={t('articles', 'Articles')}
              isSelected={contentCategory === LearnContentCategory.ARTICLES}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                selectCategory(LearnContentCategory.ARTICLES);
              }}
            />
          </Box>
        </Box>
      </Box>
      {isLoading && (
        <Box
          component={'main'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={`calc(100% - ${tabBarHeight})`}
        >
          <MyCricketLoadingIndicator />
        </Box>
      )}
      <Box
        component={'main'}
        display={!isLoading ? 'block' : 'none'}
        width={'100%'}
        sx={{ overflowX: 'hidden' }}
      >
        <Box
          display={'flex'}
          width={'300%'}
          sx={{
            transform: `translateX(${contentTranslation})`,
            transition: 'transform 0.4s ease-out',
            overflowY: 'hidden',
          }}
        >
          <Box
            flex={'1'}
            height={
              contentCategory === LearnContentCategory.COURSES
                ? 'auto'
                : `calc(100vh - ${tabBarHeight} * 2)` // Ensures no extra scroll when this is off screen
            }
          >
            {errors[LearnContentCategory.COURSES] ? (
              <ErrorDisplay />
            ) : (
              <CoursesDisplay courses={coursesContent} />
            )}
          </Box>
          <Box
            flex={'1'}
            height={
              contentCategory === LearnContentCategory.RECIPES
                ? 'auto'
                : `calc(100vh - ${tabBarHeight} * 2)` // Ensures no extra scroll when this is off screen
            }
          >
            {errors[LearnContentCategory.RECIPES] ? (
              <ErrorDisplay />
            ) : (
              <GenericLearnContentDisplay
                content={recipesContent}
                initialSelectedItemId={selectedItemId}
                useLargeDisplay
              />
            )}
          </Box>
          <Box
            flex={'1'}
            height={
              contentCategory === LearnContentCategory.ARTICLES
                ? 'auto'
                : `calc(100vh - ${tabBarHeight} * 2)` // Ensures no extra scroll when this is off screen
            }
          >
            {errors[LearnContentCategory.ARTICLES] ? (
              <ErrorDisplay />
            ) : (
              <GenericLearnContentDisplay
                content={articlesContent}
                initialSelectedItemId={selectedItemId}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ErrorDisplay = () => {
  const { t } = useTranslation();
  return (
    <Box color={MyCricketColors.notificationRed} textAlign={'center'} pt={6}>
      <Typography variant={'body1'}>{t('learnContentError')}</Typography>
    </Box>
  );
};

const ContentCategoryButton = ({ title, isSelected, onClick }) => (
  <ClickableBox
    bgcolor={isSelected ? MyCricketColors.tealMain : MyCricketColors.white}
    color={isSelected ? MyCricketColors.white : MyCricketColors.black}
    boxSizing={'border-box'}
    borderRadius={'6px'}
    width={'100%'}
    sx={{
      'cursor': isSelected ? 'default' : 'pointer',
      'transition': 'background 0.25s, color 0.25s, border 0.25s, padding 0.25s',
      'border': `1px solid ${
        isSelected ? MyCricketColors.tealMain : MyCricketColors.lightGraySmoke
      }`,
      'py': '12px',
      'px': '2px',
      '&:hover': {
        border: `3px solid ${
          isSelected ? MyCricketColors.tealMain : MyCricketColors.lightGraySmoke
        }`,
        py: '10px',
        px: '0',
      },
    }}
    onClick={onClick}
  >
    <Typography variant={'h2'}>{title}</Typography>
  </ClickableBox>
);

export default LearnPage;
