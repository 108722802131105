import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { SUPPORT_EMAIL, HELP_CENTER_URL } from '../../data/SupportContactInfo';
import { AccessPausedImage } from '../icons/svg';

const PausedAccess = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign={'center'} data-test-id="pausedAccessMessage">
      <AccessPausedImage />
      <Box mt={3} mb={1}>
        <Typography variant={'h2'} aria-level={'1'}>
          {t('accessPausedTitle', 'Your access to Cricket Health\nhas been paused')}
        </Typography>
      </Box>
      <br />
      <Typography variant={'body2'}>
        <Trans
          i18nKey={'accessPausedBody'}
          defaults={`To resume access to your account please email us at <support>${SUPPORT_EMAIL}</support>. For more information you can also visit our <help>Help Center</help>.`}
          values={{ supportEmail: SUPPORT_EMAIL }}
          components={{
            support: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>,
            help: (
              <a href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
                Help Center
              </a>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};
export default PausedAccess;
