import React from 'react';
import Page from '../../components/templates/Page';
import PageWithoutHeader from '../../components/templates/PageWithoutHeader';
import LoadingPage from '../../components/templates/LoadingPage';
import Home from '../../components/pages/Home';
import UiInventory from '../../components/pages/UiInventory';
import FourOhFourPage from '../../components/pages/404';
import Environment from '../../components/pages/Environment';
import PausedAccessPage from '../../components/pages/PausedAccessPage';
import LearnPage from '../../components/pages/LearnPage';
import ModuleDetail from '../../components/pages/ModuleDetail';
import EmailPreferences from '../../components/pages/EmailPreferences';
import LanguagePreferences from '../../components/pages/LanguagePreferences';
import LoginInformationPage from '../../components/pages/LoginInformationPage';
import UpdateUserNamePage from '../../components/pages/UpdateUserNamePage';
import PhoneContactInfoPage from '../../components/pages/PhoneContactInfoPage';
import SurveyPage from '../../components/pages/SurveyPage';
import PatientMenuPage from '../../components/pages/PatientMenuPage';
import MyCricketLoginPage from '../../components/pages/MyCricketLoginPage';
import MyCricketLogoutPage from '../../components/pages/MyCricketLogoutPage';
import MagicLinkRedirectPage from '../../components/pages/MagicLinkRedirectPage';
import EmailVerificationPage from '../../components/pages/EmailVerificationPage';
import MePage from '../../components/pages/MePage';
import { CareTeam } from '../../components/pages/CareTeam';
import GroupsListPage from '../../components/pages/GroupsListPage';
import GroupPage from '../../components/pages/GroupPage';
import CourseIndex from '../../components/pages/CourseIndex';
import AtomicIntro from '../../components/pages/AtomicIntro';
import ProviderMatching from '../../components/pages/ProviderMatching';
import PdfViewer from '../../components/templates/PdfViewer';
import { SurveyId, SurveyName } from '../../data/SurveyData';
import ResetPassword from '../../components/pages/ResetPasswordPage';
import { useIsDesktop } from '../hooks/useIsDesktop';
import { Redirect, useParams } from 'react-router';

// For consistency's sake we want all these as functions,
// since they will need to be a function to pass URL params
export const paths = {
  homepage: () => '/',
  paused: () => '/paused',
  inventory: () => '/inventory',
  login: () => '/login',
  magicLink: () => '/magic-link',
  learn: (category, selectedItemId) =>
    '/learn' + (category ? `/${category}` : '') + (selectedItemId ? `/${selectedItemId}` : ''),
  module: (moduleId = ':moduleId?', faqId = ':faqId?') => `/module/${moduleId}/${faqId}`,
  courses: () => `/courses`,
  logout: () => '/logout',
  environment: () => '/environment',
  atomic: () => '/atomic',
  matching: () => '/matching',
  careTeam: () => '/careTeam',
  careTeamWithParam: (selectedGroupId = ':selectedGroupId?') => `/careTeam/${selectedGroupId}`,
  me: () => '/me',
  myNephrologistUpdate: () => '/myNephrologistUpdate',
  myPcpUpdate: () => '/myPCPUpdate',
  myLabOrder: () => '/myLabOrder',
  groupsList: () => '/groups',
  group: (groupName = ':groupName?') => `/groups/${groupName}`,
  groupMessage: (groupName = ':groupName?', messageId = ':messageId?') =>
    `/groups/${groupName}/message/${messageId}`,
  emailPreferences: () => '/me/notifications',
  emailPreferences_old: () => '/emailPreferences',
  languagePreferences: () => '/me/language',
  languagePreferences_old: () => '/languagePreferences',
  loginInformation: () => '/me/login-info',
  loginInformation_old: () => '/account/login-info',
  userName: () => '/me/display-name',
  userName_old: () => '/me/userName',
  phoneContactInfo: () => '/me/contact/phone',
  enrollmentSurvey: () => '/survey/enrollment',
  patientMenu: () => '/menu',
  passwordReset: () => `/reset-password`, // Set a new password with a valid ticket query parameter
  recipes: (moduleId = ':moduleId?', faqId = ':faqId?') => `/recipes/${moduleId}/${faqId}`,
  articles: (moduleId = ':moduleId?', faqId = ':faqId?') => `/articles/${moduleId}/${faqId}`,
  emailVerify: () => '/verify-email',
};

export const HomePageRoute = (props) => (
  <Page {...props} title="Welcome" hideNavBarTabs showLanguageButton skipPageEventLog>
    <Home {...props} />
  </Page>
);

export const CareTeamRoute = (props) => (
  <Page title="Care Team">
    <CareTeam {...props} />
  </Page>
);

export const InventoryRoute = (props) => (
  <Page title="UI Inventory">
    <UiInventory {...props} />
  </Page>
);
export const EnvironmentRoute = (props) => (
  <Page title="Environment Variables">
    <Environment {...props} />
  </Page>
);
export const RecipesRoute = (props) => {
  const { moduleId } = useParams();
  const defaultModuleId = 1051;
  // Redirect to homePage if the flag is off
  return (
    // redirect /recipes to /recipes/defaultModuleId (aka set a default recipe module)
    moduleId ? (
      <Page title="Recipes">
        <ModuleDetail {...props} />
      </Page>
    ) : (
      <Redirect to={paths.recipes(defaultModuleId, '')} />
    )
  );
};
export const ArticlesRoute = (props) => {
  const { moduleId } = useParams();
  const defaultModuleId = 1063;
  return (
    // redirect /articles to /articles/defaultModuleId (aka set a default recipe module)
    moduleId ? (
      <Page title="Articles">
        <ModuleDetail {...props} />
      </Page>
    ) : (
      <Redirect to={paths.articles(defaultModuleId, '')} />
    )
  );
};
export const AtomicRoute = (props) => (
  <Page title="Atomic Design Intro">
    <AtomicIntro {...props} />
  </Page>
);
export const ProviderMatchingRoute = (props) => (
  <Page hideNavBarTabs={true} title="Matching you with a provider" disableScroll>
    <ProviderMatching {...props} />
  </Page>
);
export const LoginRoute = (props) => (
  <PageWithoutHeader title="Login">
    <MyCricketLoginPage {...props} />
  </PageWithoutHeader>
);
export const MagicLinkRedirectRoute = () => (
  <LoadingPage title="Login">
    <MagicLinkRedirectPage />
  </LoadingPage>
);
export const LearnRoute = (props) => (
  <Page title="Learn" skipPageEventLog>
    <LearnPage {...props} />
  </Page>
);
export const LearnModuleDetailRoute = (props) => (
  <Page title="Learn">
    <ModuleDetail {...props} />
  </Page>
);
export const AllCoursesRoute = (props) => (
  <Page title="All Courses">
    <CourseIndex {...props} />
  </Page>
);
// Doesn't actually render anything, but does have side effects
export const LogoutRoute = (props) => <MyCricketLogoutPage {...props} />;
export const MeRoute = (props) => (
  <Page title="Me">
    <MePage {...props} />
  </Page>
);
export const MyNephrologistUpdate = (props) => <PdfViewer location="my-nephrologist-update" />;
export const MyPCPUpdate = (props) => <PdfViewer location="my-pcp-update" />;
export const MyLabOrder = (props) => <PdfViewer location="my-lab-order" />;

export const GroupsListRoute = (props) => (
  <Page title="Groups">
    <GroupsListPage {...props} />
  </Page>
);
export const GroupRoute = (props) => (
  <Page title="Groups">
    <GroupPage {...props} />
  </Page>
);
export const EmailPreferencesRoute = (props) => (
  <Page title="Email Preferences">
    <EmailPreferences {...props} />
  </Page>
);
export const LanguagePreferencesRoute = (props) => (
  <Page title="Language Preferences">
    <LanguagePreferences {...props} />
  </Page>
);
export const LoginInformationRoute = (props) => (
  <Page title="Login Information">
    <LoginInformationPage {...props} />
  </Page>
);
export const UpdateUserNameRoute = (props) => (
  <Page title="Update Display Name">
    <UpdateUserNamePage {...props} />
  </Page>
);
export const PhoneContactInfoRoute = (props) => (
  <Page title="Phone Information">
    <PhoneContactInfoPage {...props} />
  </Page>
);
export const EnrollmentSurveyRoute = (props) => {
  const isDesktop = useIsDesktop();
  return (
    <Page
      title="Enrollment Survey"
      hideNavBarTabs={true}
      topNavMessage={isDesktop ? 'Welcome to Cricket Health' : 'Welcome'}
      showLanguageButton={true}
    >
      <SurveyPage {...props} surveyId={SurveyId.ENROLLMENT} surveyName={SurveyName.ENROLLMENT} />
    </Page>
  );
};
export const PatientMenuRoute = (props) => (
  <Page title="How Can We Help?" hideNavBarTabs showLanguageButton>
    <PatientMenuPage {...props} />
  </Page>
);
export const EmailVerificationRoute = (props) => (
  <PageWithoutHeader title="Verify Email">
    <EmailVerificationPage {...props} />
  </PageWithoutHeader>
);
export const PausedRoute = (props) => (
  <PageWithoutHeader title="Paused Access">
    <PausedAccessPage {...props} />
  </PageWithoutHeader>
);
export const PasswordResetRoute = (props) => (
  <PageWithoutHeader title="Reset Password">
    <ResetPassword {...props} />
  </PageWithoutHeader>
);
export const FourOhFourRoute = (props) => (
  <PageWithoutHeader title="Not Found">
    <FourOhFourPage {...props} />
  </PageWithoutHeader>
);
export const Routes = {
  HomePageRoute,
  CareTeamRoute,
  InventoryRoute,
  EnvironmentRoute,
  AtomicRoute,
  ProviderMatchingRoute,
  LoginRoute,
  MagicLinkRedirectRoute,
  LearnRoute,
  LearnModuleDetailRoute,
  AllCoursesRoute,
  LogoutRoute,
  MeRoute,
  MyNephrologistUpdate,
  MyPCPUpdate,
  MyLabOrder,
  GroupsListRoute,
  GroupRoute,
  EmailPreferencesRoute,
  LanguagePreferencesRoute,
  LoginInformationRoute,
  UpdateUserNameRoute,
  PhoneContactInfoRoute,
  EnrollmentSurveyRoute,
  PatientMenuRoute,
  EmailVerificationRoute,
  PausedRoute,
  PasswordResetRoute,
  FourOhFourRoute,
  RecipesRoute,
  ArticlesRoute,
};
