import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

const Checkmark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" {...props}>
    <path
      fill={MyCricketColors.kellyGreen}
      fillRule="evenodd"
      d="M45 0C20.137 0 0 20.137 0 45c0 24.862 20.137 45 45 45 24.862 0 45-20.138 45-45C90 20.137 69.862 0 45 0zm22.444 20.323l8.04 8.223-35.955 36.777-19.206-19.645 8.04-8.223 11.166 11.42 27.915-28.552z"
    />
  </svg>
);

export default Checkmark;
