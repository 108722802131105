import React, { useState } from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import {
  AppointmentCard,
  AppointmentZeroState,
  AppointmentFooter,
} from '../molecules/AppointmentCard';
import useAppointments from '../../utils/hooks/useAppointments';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { ReactComponent as BellIcon } from '../icons/Bell.svg';
import { ReactComponent as Bell } from '../icons/BellAndSoundWaves.svg';

const bellStyles = {
  '@keyframes bell-1': {
    to: {
      transform: 'rotateZ(8deg)',
    },
  },
  '@keyframes bell-2': {
    to: {
      transform: 'rotateZ(-6deg)',
    },
  },
  '@keyframes bell-3': {
    to: {
      transform: 'rotateZ(4deg)',
    },
  },
  '@keyframes bell-4': {
    to: {
      transform: 'rotateZ(-2deg)',
    },
  },
  '@keyframes bell-5': {
    to: {
      transform: 'rotateZ(1deg)',
    },
  },
  '@keyframes bell-6': {
    to: {
      transform: 'rotateZ(0deg)',
      fill: '#000',
    },
  },
  '@keyframes wave-on': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  'root': {
    // @svgr/webpack by default adds the filename as a prefix to IDs and classes
    '& .BellAndSoundWaves_svg__bell': {
      fill: '#D74C34',
      transformOrigin: 'top center',
      animationFillMode: 'forwards',
      animationName: `$bell-1, $bell-2, $bell-3, $bell-4, $bell-5, $bell-6`,
      animationDelay: '800ms, 1200ms, 1600ms, 2000ms, 2400ms, 2500ms, 2600ms',
      animationDuration: '400ms, 400ms, 400ms, 400ms, 100ms, 1s',
      animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '& .BellAndSoundWaves_svg__sound-wave': {
      opacity: 0,
      fill: '#D74C34',
      animationName: `$wave-on, $wave-on, $wave-on, $wave-on`,
      animationDelay: '800ms, 1200ms, 1600ms, 2000ms, 2400ms, 2500ms',
      animationDuration: '400ms, 400ms, 400ms, 400ms, 0s',
    },
  },
};

const AnimatedBell = withStyles(bellStyles)(({ classes }) => <Bell className={classes.root} />);

export const Appointments = () => {
  // TODO Handle errors (follows useQuery API)
  const {
    data: { upcomingAppointments, pastAppointments },
    loading,
  } = useAppointments();
  const { shouldShowSchedulingReminder } = usePatientContext();

  // Store this so that it does not update again when the notification on the Me tab is cleared.
  const [storedShouldShowSchedulingReminder] = useState(shouldShowSchedulingReminder);

  const hasAppointments = upcomingAppointments && upcomingAppointments.length > 0;

  let Content;
  if (loading) {
    Content = null;
  } else if (hasAppointments) {
    Content = (
      <>
        {upcomingAppointments.map((apt, idx) => (
          <AppointmentCard key={idx} appointmentInfo={apt} />
        ))}
      </>
    );
  } else {
    // Business rule is to encourage patient to schedule an appointment
    // if they've never completed an appointment.
    Content = <AppointmentZeroState shouldScheduleAppointment={pastAppointments.length === 0} />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      bgcolor={MyCricketColors.white}
      borderRadius={'6px'}
      style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
    >
      <Box display={'flex'} py={3} px={{ xs: 2, md: 6 }}>
        <Box flex={'1 0 0'} mr={1}>
          <TranslatedTypography variant={'h2'} aria-level={1}>
            Upcoming Appointments
          </TranslatedTypography>
        </Box>
        <Box flex={'0 0 1'} display={'flex'} justifyContent={'flex-end'}>
          {storedShouldShowSchedulingReminder ? (
            <AnimatedBell />
          ) : (
            <BellIcon
              style={{
                fill: storedShouldShowSchedulingReminder
                  ? MyCricketColors.notificationRed
                  : MyCricketColors.black,
              }}
            />
          )}
        </Box>
      </Box>
      {Content}
      {hasAppointments ? <AppointmentFooter /> : null}
    </Box>
  );
};
