import { Box } from '@mui/material';
import { TranslatedTypography } from './TranslatedTypography';
import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

export const NewBadge = () => {
  return (
    <Box
      borderRadius="9999px"
      color={MyCricketColors.royalBlue}
      bgcolor={MyCricketColors.paleBlue}
      display={'flex'}
      justifyContent="center"
      alignItems="center"
      px={1}
      py={0.5}
      marginLeft={2}
    >
      <TranslatedTypography variant="h3" style={{ fontWeight: 'bold' }}>
        New
      </TranslatedTypography>
    </Box>
  );
};
