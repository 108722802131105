import { myCricketAuthedFetch } from './fetchingUtils';
import { toUserURI } from './URIUtils';

export default function logEvent(cricketUserId, payload, signal, debug) {
  let uri = `/i/admin/logEvent`;
  if (debug) {
    uri += `?debug=true`;
  }
  return myCricketAuthedFetch(uri, {
    body: JSON.stringify({
      object: toUserURI(cricketUserId),
      ...payload,
    }),
    method: 'POST',
    signal,
  });
}
