import { createContext, useContext } from 'react';

/**
 * @param {React.Component} component The component to show on the modal view.
 * @param {object} options Additional options for the modal.
 * @param {boolean} options.noEscape Prevents a user from clicking the background to dismiss the modal.
 */
const showModal = (component, options) => {};

const hideModal = () => {};

export const ModalContext = createContext({ showModal, hideModal });

export const useModalContext = () => {
  return useContext(ModalContext);
};
