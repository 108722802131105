import React from 'react';

export default function CloseIcon(props) {
  const { color, strokeWidth } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" {...props}>
      <title>Close Icon</title>
      <g fill="none" fillRule="evenodd" transform="translate(-3 -4)">
        <rect width="21" height="21" rx="6" />
        <g stroke={color || '#7F8FA4'} strokeWidth={strokeWidth || '3'}>
          <path d="M5 5l11 11M16 5L5 16" />
        </g>
      </g>
    </svg>
  );
}
