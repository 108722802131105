import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import { classesPropType } from '../../utils/patientPropTypes';

const styles = () => ({
  'likeHeart': {
    'width': '100%',
    'paddingBottom': '90.5%',
    'backgroundImage': (props) => {
      if (props.isLiking) {
        return "url('https://cdn.crickethealth.com/1/img/sprites/like-sprites.png')";
      } else {
        return props.liked
          ? "url('https://cdn.crickethealth.com/1/img/icons/liked.png')"
          : "url('https://cdn.crickethealth.com/1/img/icons/unliked.png')";
      }
    },
    'animation': (props) => (props.isLiking ? 'like-animation 0.8s steps(18) forwards' : 'none'),
    '&:hover': {
      backgroundImage: (props) => {
        if (!props.liked) {
          return "url('https://cdn.crickethealth.com/1/img/icons/unliked-hover.png')";
        } else {
          return "url('https://cdn.crickethealth.com/1/img/icons/liked.png')";
        }
      },
    },
  },
  '@global': {
    '@keyframes like-animation': {
      '0%': {
        backgroundPosition: 'left',
      },
      '100%': {
        backgroundPosition: 'right',
      },
    },
  },
});

function AnimatedHeart(props) {
  const { classes } = props;
  return (
    <Box width={40}>
      <div className={classes.likeHeart} />
    </Box>
  );
}

AnimatedHeart.propTypes = {
  liked: PropTypes.bool.isRequired,
  isLiking: PropTypes.bool.isRequired,
  classes: classesPropType.isRequired,
};

export default withStyles(styles)(AnimatedHeart);
