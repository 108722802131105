import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Home } from '@mui/icons-material';
import { HorizontalChevron, HorizontalChevronSlider } from '../atoms/Chevrons';
import { ModuleDetailContext } from '../pages/ModuleDetail';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import MyCricketColors from '../../data/MyCricketColors';
import { paths } from '../../utils/routing/routes';

export const LearnModuleNavigator = ({ moduleName, moduleId, isBackToCourses }) => {
  const { changeSelectedModule } = useContext(ModuleDetailContext);
  const [hovered, setHovered] = useState();

  const history = useHistory();
  const isDesktop = useIsDesktop();

  const animationBuffer = 9;
  const animationDistance = isBackToCourses ? -animationBuffer : animationBuffer;
  const rightBuffer = isBackToCourses ? 0 : animationBuffer;
  const leftBuffer = isBackToCourses ? animationBuffer : 0;

  return (
    <Box
      display="flex"
      flexDirection={isBackToCourses ? 'row-reverse' : 'row'}
      justifyContent="flex-end"
      alignContent="flex-end"
      alignItems="center"
      onClick={() =>
        isBackToCourses ? history.push(paths.learn()) : changeSelectedModule(moduleId)
      }
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ml={{ xs: 0, sm: 0, md: leftBuffer + 'px' }}
      mr={{ xs: 0, sm: 0, md: rightBuffer + 'px' }}
      width={'30vw'}
    >
      {isBackToCourses ? (
        <>
          <TranslatedTypography display="block" variant={isDesktop ? 'subtitle1' : 'h3'}>
            Back to Courses
          </TranslatedTypography>
          <Box
            component={'span'}
            display={'flex'}
            alignItems={'center'}
            color={MyCricketColors.mint}
            mr={1}
            sx={{ transition: 'opacity 0.2s', opacity: hovered ? 1.0 : 0.5 }}
          >
            <Home />
          </Box>
        </>
      ) : (
        <>
          <Box maxWidth="30vw" display="flex" flexDirection={'column'} alignItems={'flex-end'}>
            <TranslatedTypography variant="h3">Next Topic</TranslatedTypography>
            {isDesktop && (
              <TranslatedTypography display="block" noWrap variant="subtitle1">
                {moduleName}
              </TranslatedTypography>
            )}
          </Box>
          <Box display={'flex'} alignItems={'center'} fontSize={'40px'}>
            {isDesktop && (
              <HorizontalChevronSlider slideDistance={animationDistance} hovered={hovered}>
                <HorizontalChevron pointsLeft={isBackToCourses} />
              </HorizontalChevronSlider>
            )}
            {/* For simplicity's sake, we just put a chevron with no motion if it's mobile */}
            {!isDesktop && <HorizontalChevron />}
          </Box>
        </>
      )}
    </Box>
  );
};
