import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import i18nCaller from './utils/i18n';
import { resumeSession } from './utils/LoginHelpers';

window._cc = {
  appName: 'MyCricket',
  api: {
    url: __ENV__.endpoints.public.api.url,
  },
  patient: {
    url: __ENV__.endpoints.public.patient.url,
  },
  dream: {
    url: __ENV__.endpoints.public.dream.url,
  },
  chirp: {
    url: __ENV__.endpoints.public.chirp.url,
  },
  ZENDESK_WIDGET_KEY: __ENV__.ZENDESK_WIDGET_KEY,
};

const i18n = i18nCaller({
  useSuspense: true,
  ns: ['faq', 'module', 'course', 'survey'],
});

i18n.on('initialized', async () => {
  await resumeSession();
  ReactDOM.render(<App />, document.getElementById('app'));
});
