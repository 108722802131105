import React from 'react';
import { Redirect } from 'react-router-dom';
import useQuery from '../hooks/useQueryHook';

const RedirectWithQuery = ({ path }) => {
  const query = useQuery();
  return <Redirect to={{ pathname: path, search: query.toString() }} />;
};

export default RedirectWithQuery;
