const SurveyId = {
  MODALITY_DECISION: 1,
  CONDITION_MANAGEMENT: 2,
  ENROLLMENT: 4,
};

const SurveyName = {
  MODALITY_DECISION: 'hope',
  CONDITION_MANAGEMENT: 'cm',
  ENROLLMENT: 'enrollmentSurvey',
};

const activeSurveyIds = [SurveyId.ENROLLMENT];

module.exports = {
  SurveyId,
  SurveyName,
  activeSurveyIds,
};
