import { ReactComponent as LabsIcon } from '../components/icons/Menu-LabsIcon.svg';
import { ReactComponent as MedsIcon } from '../components/icons/Menu-MedsIcon.svg';
import { ReactComponent as NavigationIcon } from '../components/icons/Menu-NavigationIcon.svg';
import { ReactComponent as SupportIcon } from '../components/icons/Menu-SupportIcon.svg';
import { ReactComponent as NutritionIcon } from '../components/icons/Menu-NutritionIcon.svg';
/*
Schema Note: the following choices are named by text content and then version number
for example NUTRITION_IMPORTANCE_1 is the first version of text to learn more about the importance of nutrition
IF YOU UPDATE THIS FILE UPDATE THE VERSION NUMBER IN THE KEY AND ID
*/
const MenuChoice = {
  NUTRITION_IMPORTANCE_1: {
    id: 'NUTRITION_IMPORTANCE_1',
    text: 'Learn more about nutrition and why it is important for my health',
  },
  NUTRITION_GOALS_1: {
    id: 'NUTRITION_GOALS_1',
    text: 'Help me set nutrition or lifestyle goals that will support my health',
  },
  NUTRITION_BALANCE_1: {
    id: 'NUTRITION_BALANCE_1',
    text: 'Help me figure out how to balance my dietary needs with foods I enjoy',
  },
  NUTRITION_ACHIEVE_1: {
    id: 'NUTRITION_ACHIEVE_1',
    text: 'Work with me on how to achieve my current lifestyle and nutrition goals',
  },
  LABS_REVIEW_1: {
    id: 'LABS_REVIEW_1',
    text: 'Review my current labs and help me understand what they mean',
  },
  LABS_PREP_1: {
    id: 'LABS_PREP_1',
    text: 'Help me prepare for conversations with my doctor about my labs',
  },
  MEDS_REVIEW_1: {
    id: 'MEDS_REVIEW_1',
    text: 'Review the role of my current medications and supplements',
  },
  MEDS_STRAT_1: {
    id: 'MEDS_STRAT_1',
    text: 'Work with me on strategies to help me remember when to take my medications and how to manage side effects',
  },
  MEDS_COST_1: {
    id: 'MEDS_COST_1',
    text: 'Help me manage the cost of my medications',
  },
  NAV_DR_1: {
    id: 'NAV_DR_1',
    text: 'Support me in preparing for upcoming doctor visits',
  },
  NAV_FINANCES_1: {
    id: 'NAV_FINANCES_1',
    text: 'Help me access financial support',
  },
  NAV_RESOURCES_1: {
    id: 'NAV_RESOURCES_1',
    text: 'Connect me with other resources my insurance offers',
  },
  SUPPORT_EMOTIONAL_1: {
    id: 'SUPPORT_EMOTIONAL_1',
    text: 'Learn more about getting emotional or social support for my health',
  },
  SUPPORT_CONNECT_1: {
    id: 'SUPPORT_CONNECT_1',
    text: 'Connect with other people like me',
  },
  SUPPORT_DECISIONS_1: {
    id: 'SUPPORT_DECISIONS_1',
    text: 'Help me plan and prepare for important health decisions',
  },
};

const MenuCategory = {
  NUTRITION_1: {
    id: 'nutrition',
    name: 'Nutrition',
    choices: [
      MenuChoice.NUTRITION_IMPORTANCE_1,
      MenuChoice.NUTRITION_GOALS_1,
      MenuChoice.NUTRITION_BALANCE_1,
      MenuChoice.NUTRITION_ACHIEVE_1,
    ],
    Icon: NutritionIcon,
  },
  LABS_1: {
    id: 'labs',
    name: 'Understanding my labs',
    choices: [MenuChoice.LABS_REVIEW_1, MenuChoice.LABS_PREP_1],
    Icon: LabsIcon,
  },
  MEDICATIONS_1: {
    id: 'medications',
    name: 'Medications',
    choices: [MenuChoice.MEDS_REVIEW_1, MenuChoice.MEDS_STRAT_1, MenuChoice.MEDS_COST_1],
    Icon: MedsIcon,
  },
  NAVIGATION_1: {
    id: 'navigation',
    name: 'Navigating the healthcare system',
    choices: [MenuChoice.NAV_DR_1, MenuChoice.NAV_FINANCES_1, MenuChoice.NAV_RESOURCES_1],
    Icon: NavigationIcon,
  },
  SUPPORT_1: {
    id: 'support',
    name: 'Emotional and social support',
    choices: [
      MenuChoice.SUPPORT_EMOTIONAL_1,
      MenuChoice.SUPPORT_CONNECT_1,
      MenuChoice.SUPPORT_DECISIONS_1,
    ],
    Icon: SupportIcon,
  },
};

const menuCategories = Object.values(MenuCategory);

export { MenuCategory, MenuChoice, menuCategories };
