import { useEffect } from 'react';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { PatientEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';

export default function MyCricketLogoutPage() {
  const { clearLocalUserInfo } = usePatientContext();
  const { logEvent } = useClientEventLoggerContext();
  useEffect(() => {
    const performLogoutActions = async () => {
      await logEvent({
        predicate: PatientEvent.LOGOUT,
        object: URIUtils.toMyCricketPageURI('logout'),
        prepositions: {
          page: URIUtils.toMyCricketPageURI('logout'),
        },
      });
      // Make sure this one goes last, because it will trigger the redirect to login
      clearLocalUserInfo();
    };
    performLogoutActions();
  }, [clearLocalUserInfo, logEvent]);

  return null;
}
