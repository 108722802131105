const URI = {
  idFromURI: function idFromURI(URI) {
    return URI.split('/').pop();
  },
  toMessageURI: function toMessageURI(messageId) {
    return 'chirp://message/' + messageId;
  },
  toGroupURI: function toGroupURI(groupId) {
    return 'chirp://group/' + groupId;
  },
  toUserURI: function toUserURI(userId) {
    return 'chirp://user/' + userId;
  },
};

module.exports = URI;
