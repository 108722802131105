import { Box } from '@mui/material';
import {
  CareTeamGroups,
  DiscussionGroupNames,
  RetiredDiscussionGroupNames,
} from '../../data/groupTypeNames';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import CareTeamBio from '../organisms/CareTeamBio';
import CareTeamFileHistory from '../organisms/CareTeamFileHistory';
import { CareTeamMessageFrame } from '../organisms/CareTeamMessageFrame';
import { CareTeamSidebar, isSidebarGroup } from '../organisms/CareTeamSidebar';
import { usePrevious } from '../../utils/hooks/usePrevious';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { useHistory } from 'react-router-dom';
import { paths } from '../../utils/routing/routes';

export const CareTeamContext = React.createContext();
CareTeamContext.displayName = 'CareTeamContext';

export const CareTeam = (props) => {
  //only applicable if somone goes to the care team route with parameters
  let urlProvidedSelectedGroupId;
  try {
    urlProvidedSelectedGroupId = props.match?.params?.selectedGroupId;
    //check that we can parse this but leave it as a string for logic checks
    parseInt(urlProvidedSelectedGroupId);
  } catch (e) {
    console.error('invalid route. Parameter must be an int');
  }
  const [showBio, setShowBio] = useState(false);
  const [showFileHistory, setShowFileHistory] = useState(false);
  const { directMessageGroups, loading, getGroupMessages, getUserProfile } = useChirpContext();
  const { userId } = usePatientContext();
  const [showChat, setShowChat] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const previousSelectedGroupId = usePrevious(selectedGroupId);
  const [selectedCareTeamMember, setSelectedCareTeamMember] = useState({});
  const [cachedFileResults, setCachedFileResults] = useState({});

  // The initialFirstUnreadMessageId is the first unread message at the moment a user
  // clicks on a chat group. The firstUnreadMessageId is an updating value that will
  // change as new messages are read, and more come in.
  //
  // We set to undefined here instead of null, because we use them for different reasons:
  // We define `undefined` as: "we haven't checked yet"
  // We define `null` as: "we checked and there are none"
  const [initialFirstUnreadMessageId, setInitialFirstUnreadMessageId] = useState(undefined);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState(undefined);

  const isDesktop = useIsDesktop();
  const history = useHistory();

  const selectGroup = useCallback((group) => {
    if (group) {
      setSelectedGroupId(group.cricketGroupId);
    } else {
      setSelectedGroupId(null);
    }
  }, []);

  // This useEffect is for setting an initial selected group.
  useEffect(() => {
    const theGroupVals = Object.values(directMessageGroups);

    // We only need to attempt to pick an initial group on desktop views.
    if (theGroupVals.length > 0 && !selectedGroupId && isDesktop) {
      //checks both that the current user has access to this group and we have an input
      if (
        urlProvidedSelectedGroupId &&
        directMessageGroups[urlProvidedSelectedGroupId]?.groupName
      ) {
        const groupName = directMessageGroups[urlProvidedSelectedGroupId].groupName;
        //checks it's a valid care team group
        if (Object.values(CareTeamGroups).includes(groupName)) {
          const group = directMessageGroups[urlProvidedSelectedGroupId];
          selectGroup(group);
          //redirects to appropriate multiparty group
        } else if (
          Object.values(DiscussionGroupNames).includes(groupName) &&
          !Object.values(RetiredDiscussionGroupNames).includes(groupName)
        ) {
          //groups just open up as group path slash group name
          history.push(paths.group(groupName));
        }
      } else {
        const onlyCareTeamGroups = theGroupVals.filter((groupObject) =>
          isSidebarGroup(groupObject),
        );
        const firstGroupByType = theGroupVals.filter(
          (groupObject) => groupObject.groupName === CareTeamGroups.NURSE_PT,
        );
        const group = firstGroupByType[0] || onlyCareTeamGroups[0];
        selectGroup(group);
      }
    }
  }, [
    directMessageGroups,
    selectedGroupId,
    urlProvidedSelectedGroupId,
    userId,
    isDesktop,
    selectGroup,
    history,
  ]);

  useEffect(() => {
    if (selectedGroupId) {
      const _selectedGroup = directMessageGroups[selectedGroupId];
      const careTeamMember = getUserProfile(
        _selectedGroup.members.filter((member) => member !== userId)[0],
      );

      setSelectedCareTeamMember(careTeamMember);
    }
  }, [selectedGroupId, directMessageGroups, userId, getUserProfile]);

  // This useEffect is for finding both the current oldest unread message, and
  // the oldest unread message at the time of group selection that is used to
  // inform the display of the new messages line on the care team chat.
  useEffect(() => {
    if (!selectedGroupId) {
      setInitialFirstUnreadMessageId(undefined);
      setFirstUnreadMessageId(undefined);
      return;
    }
    const groupMessages = getGroupMessages(selectedGroupId);
    const oldestUnreadMessage = groupMessages.find(
      (message) => !message.isRead && message.senderId !== userId,
    );
    const messageId = oldestUnreadMessage ? oldestUnreadMessage.id : null;
    // We want the line to persist if new messages are sent, so
    // only change it when we change the group we are viewing.
    if (initialFirstUnreadMessageId === undefined || selectedGroupId !== previousSelectedGroupId) {
      setInitialFirstUnreadMessageId(messageId);
    }
    setFirstUnreadMessageId(messageId);
  }, [
    selectedGroupId,
    previousSelectedGroupId,
    initialFirstUnreadMessageId,
    userId,
    getGroupMessages,
  ]);

  const triggerChatToShow = () => {
    setShowChat(true);
    setShowBio(false);
    setShowFileHistory(false);
    setShowSideBar(false);
  };
  const triggerSidebarToShow = () => {
    setShowSideBar(true);
    setShowChat(false);
    setShowBio(false);
    setShowFileHistory(false);

    // Reset group back to null when showing the sidebar on mobile.
    selectGroup(null);
  };
  const triggerBioToShow = () => {
    setShowBio(true);
    setShowFileHistory(false);
    setShowChat(false);
    setShowSideBar(false);
  };
  const triggerFileHistoryToShow = () => {
    setShowFileHistory(true);
    setShowChat(false);
    setShowBio(false);
    setShowSideBar(false);
  };

  return (
    <MyCricketLoadingContainer loading={loading}>
      <CareTeamContext.Provider
        value={{
          selectedCareTeamMember,
          selectedGroupId,
          selectGroup,
          showBio,
          showFileHistory,
          setShowChat,
          setShowSideBar,
          triggerChatToShow,
          triggerBioToShow,
          triggerSidebarToShow,
          triggerFileHistoryToShow,
          initialFirstUnreadMessageId,
          firstUnreadMessageId,
          cachedFileResults,
          setCachedFileResults,
        }}
      >
        <Box
          id={'CareTeamContainer'}
          width="100vw"
          maxWidth={'100%'}
          display="flex"
          flex={1}
          minHeight={'100%'}
          maxHeight={'100%'}
          flexDirection="row"
          borderRadius={{ xs: '0', sm: '0', md: '0 12px 12px 0' }}
          padding={{ xs: 0, sm: 0, md: 1.5 }}
          data-test-id={'care-team'}
          data-testid={'care-team'}
        >
          {(isDesktop || (!showChat && !showBio && !showFileHistory)) && <CareTeamSidebar />}
          <Box
            role="main"
            id={'CareTeamWindowMain'}
            width="100vw"
            maxWidth={'100%'}
            display="flex"
            flex={1}
            minHeight={'100%'}
            maxHeight={'100%'}
            flexDirection="row"
          >
            {(isDesktop || (!showSideBar && !showBio && !showFileHistory)) &&
              selectedCareTeamMember &&
              selectedGroupId && <CareTeamMessageFrame />}
            {showBio && <CareTeamBio />}
            {showFileHistory && <CareTeamFileHistory />}
          </Box>
        </Box>
      </CareTeamContext.Provider>
    </MyCricketLoadingContainer>
  );
};

export function useCareTeamContext() {
  return useContext(CareTeamContext);
}
export default CareTeam;
