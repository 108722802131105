import React from 'react';
import { Box } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from './TranslatedTypography';

const CareTeamMessageImage = ({ imageURL, error, isOtherPerson }) => {
  const leftMargin = isOtherPerson ? 4 : 9;
  const rightMargin = isOtherPerson ? 9 : 4;
  const justifyContent = isOtherPerson ? 'flex-start' : 'flex-end';

  return (
    <Box
      display={'flex'}
      justifyContent={justifyContent}
      ml={leftMargin}
      mr={rightMargin}
      height={'300px'}
    >
      <Box display={'flex'} alignItems={'center'} maxWidth={'80%'} height={'100%'}>
        <MyCricketLoadingContainer loading={!imageURL && !error}>
          {error ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              bgcolor={isOtherPerson ? MyCricketColors.white : MyCricketColors.paleBlue}
              height={'100%'}
              py={2}
              px={8}
              border={isOtherPerson ? `1px solid ${MyCricketColors.lightGrayHaze}` : 0}
              borderRadius={'6px'}
            >
              <Box display={'flex'} alignItems={'center'} mb={1.5}>
                <BrokenImage />
              </Box>
              <Box textAlign={'center'}>
                <TranslatedTypography
                  i18nKey={
                    error.status === 401 ? 'chatImageErrorUnauthorized' : 'chatImageErrorUnkonwn'
                  }
                  variant={'body1'}
                >
                  {error.status === 401
                    ? 'This image is private or may have been deleted'
                    : 'There was a problem displaying this image'}
                </TranslatedTypography>
              </Box>
            </Box>
          ) : (
            <Box
              component={'a'}
              display={'flex'}
              justifyContent={'center'}
              height={'100%'}
              borderRadius={'6px'}
              overflow={'hidden'}
              style={{ cursor: 'pointer' }}
              href={imageURL}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <img
                src={imageURL}
                alt="uploaded file"
                style={{ display: 'block', width: 'auto', height: '100%' }}
              ></img>
            </Box>
          )}
        </MyCricketLoadingContainer>
      </Box>
    </Box>
  );
};

export default CareTeamMessageImage;
