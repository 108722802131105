import React from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

const PhoneNumberInput = ({ value, onChange, disabled, ...rest }) => {
  const onChangePhoneNumber = (event) => {
    const text = event.target.value;
    const phoneNumber = text.replace(/\D/g, '').substring(0, 10);
    onChange(phoneNumber);
  };

  return (
    <InputMask
      mask={'( 999 ) 999-9999'}
      disabled={disabled}
      value={value}
      onChange={onChangePhoneNumber}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          type={'tel'}
          placeholder={'( ___ ) ___-____'}
          disabled={disabled}
          {...rest}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumberInput;
