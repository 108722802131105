import { formatDistanceToNow, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';

export const TimestampFromNow = ({ message }) => {
  const { i18n } = useTranslation();
  const [timestamp, setTimestamp] = useState(
    formatDistanceToNow(parseISO(message.deliveryDate), { locale: enUS }),
  );

  useEffect(() => {
    switch (i18n.language) {
      case 'es': {
        setTimestamp(formatDistanceToNow(parseISO(message.deliveryDate), { locale: es }));
        break;
      }
      case 'en': {
        setTimestamp(formatDistanceToNow(parseISO(message.deliveryDate), { locale: enUS }));
        break;
      }
      default: {
        setTimestamp(formatDistanceToNow(parseISO(message.deliveryDate), { locale: enUS }));
        break;
      }
    }
  }, [i18n.language, message.deliveryDate]);

  return (
    <Typography
      style={{ color: MyCricketColors.tealGray }}
      data-test-id="timestampFromNow"
      variant="body1"
    >
      {timestamp}
    </Typography>
  );
};
