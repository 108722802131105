import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Popper, Paper, Fade, Box } from '@mui/material';
import { TranslatedTypography } from './TranslatedTypography';
import Triangle from '../icons/Triangle';
import CloseIcon from '../icons/CloseIcon';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import MyCricketColors from '../../data/MyCricketColors';
import { ClickableBox } from './Buttons';
import { useHistory } from 'react-router-dom';

export const CoachMark = ({ text, coachMarkId, redirectTo, wait, children, onClick }) => {
  const arrowRef = useRef(null);
  const anchorEl = useRef(null);
  const history = useHistory();

  const { manageCoachmarkHistory, coachMarkHistory, featureFlags } = usePatientContext();
  const [shouldShow, setShouldShow] = useState(false);
  const [delayComplete, setDelayComplete] = useState(false);

  useEffect(() => {
    setShouldShow(coachMarkHistory && !coachMarkHistory[coachMarkId]);
  }, [coachMarkHistory, coachMarkId, featureFlags]);

  const onClose = useCallback(() => {
    manageCoachmarkHistory(coachMarkId);
  }, [coachMarkId, manageCoachmarkHistory]);

  const onCoachmarkClick = useCallback(() => {
    manageCoachmarkHistory(coachMarkId);
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [coachMarkId, manageCoachmarkHistory, redirectTo, history]);

  useEffect(() => {
    const timeout = setTimeout(() => setDelayComplete(true), wait);
    return () => clearTimeout(timeout);
  }, [wait]);

  return shouldShow ? (
    <Box height="100%">
      <Box onClick={onClose} ref={anchorEl} height="100%">
        {children}
      </Box>

      {anchorEl.current && (
        <Box zIndex={99}>
          <Fade in={delayComplete} timeout={1000}>
            <Box data-id="overPopperBox">
              <Popper
                data-id="popper"
                open={true}
                anchorEl={anchorEl.current}
                placement={'bottom'}
                disablePortal={true}
                style={{
                  zIndex: 1,
                  top: 0,
                  left: 0,
                }}
                modifiers={[
                  {
                    name: 'flip',
                    enabled: true,
                  },
                  {
                    name: 'arrow',
                    enabled: true,
                    options: {
                      element: arrowRef.current,
                    },
                  },
                ]}
              >
                <Box maxWidth={{ xs: '240px', md: '320px' }} marginTop="9px">
                  <Box
                    left={{ xs: '117px', md: '160px' }}
                    style={{
                      position: 'absolute',
                      marginTop: '-9px',
                      color: MyCricketColors.royalBlue,
                      fontSize: 7,
                    }}
                    ref={arrowRef}
                  >
                    <Triangle up={true} height={9} width={11} />
                  </Box>
                  <Paper
                    style={{
                      backgroundColor: MyCricketColors.royalBlue,
                      color: 'white',
                      borderRadius: '6px',
                    }}
                  >
                    <Box py={1.4} px={1.4}>
                      <CloseIcon
                        style={{
                          cursor: 'pointer',
                          boxSizing: 'initial',
                          float: 'right',
                        }}
                        onClick={onClose}
                        color={'white'}
                        strokeWidth={'2'}
                      />

                      <ClickableBox
                        onClick={onCoachmarkClick}
                        py={2.6}
                        px={1.1}
                        sx={{
                          backgroundColor: MyCricketColors.royalBlue,
                          cursor: 'pointer',
                        }}
                      >
                        <TranslatedTypography variant="subtitle2">{text}</TranslatedTypography>
                      </ClickableBox>
                    </Box>
                  </Paper>
                </Box>
              </Popper>
            </Box>
          </Fade>
        </Box>
      )}
    </Box>
  ) : (
    children
  );
};
