import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from '../../utils/innerHtmlUtils';
import { useHistory } from 'react-router-dom';
import TopNav from '../organisms/TopNav';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { paths } from '../../utils/routing/routes';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import PatientErrorBoundary from '../../utils/PatientErrorBoundary';

const PdfViewer = ({ title, children, location }) => {
  // smartVh and smartVw are a hooks based take on -> https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const [smartVh, setSmartVh] = useState(undefined);
  const [smartVw, setSmartVw] = useState(undefined);
  const [pdfFile, setPdfFile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _pdf = await myCricketAuthedFetch(`/my-forms/${location}`, {
          method: 'GET',
          returnRawResponse: true,
        });
        const pdfBlob = await _pdf.blob();
        const urlObject = await URL.createObjectURL(pdfBlob);
        setPdfFile(urlObject);
        window.location.href = urlObject;
      } catch {
        history.push(paths.homepage());
      }
    };
    fetchData();
  }, [history, location]);

  const { width, height } = useWindowSize();
  useEffect(() => {
    document.title = title ? `${title} | MyCricket` : 'MyCricket';
  }, [title]);
  useEffect(() => {
    +width !== smartVw * 100 && setSmartVw(+width / 100);
    +height !== smartVh * 100 && setSmartVh(+height / 100);
  }, [width, height, smartVw, smartVh]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={100 * smartVw + 'px'}
      alignItems={'center'}
      height={`${100 * smartVh}px`}
      paddingTop={`env(safe-area-inset-top)`}
      paddingLeft={`env(safe-area-inset-left)`}
      paddingRight={`env(safe-area-inset-right)`}
    >
      <TopNav />
      <PatientErrorBoundary>
        <MyCricketLoadingContainer loading={!pdfFile}>
          <Box />
        </MyCricketLoadingContainer>
      </PatientErrorBoundary>
    </Box>
  );
};

export default PdfViewer;
