import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../atoms/Buttons';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import PatientMenuCard from '../molecules/PatientMenuCard';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import MyCricketColors from '../../data/MyCricketColors';

const useStyles = makeStyles({
  nextButton: {
    height: '50px',
  },
  backButton: {
    'height': '50px',
    'color': 'black',
    'borderColor': MyCricketColors.lightGraySmoke,
    '&:hover': {
      'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'background': MyCricketColors.white,
    },
  },
});

const PatientMenu = ({
  categories,
  selectedChoices,
  categoryAdditionalNotes,
  generalAdditionalNotes,
  selectChoiceInCategory,
  updateAdditionalNotesInCategory,
  setGeneralAdditionalNotes,
  completePatientMenu,
  goBack,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box p={2}>
      <Box mb={0.5}>
        <TranslatedTypography
          variant={'h3'}
          i18nKey={'patientMenuFormHeader'}
          style={{ fontWeight: '800' }}
        >
          Most importantly
        </TranslatedTypography>
      </Box>
      <Box mb={2}>
        <TranslatedTypography variant={'h1'} i18nKey={'patientMenuFormTitle'}>
          How can we help?
        </TranslatedTypography>
      </Box>
      <Box mb={5}>
        <TranslatedTypography variant={'body2'} i18nKey={'patientMenuFormDescription'}>
          {
            "Knowing what you need helps your care team build a program that's easy to follow, fits your lifestyle, and will help you achieve your health goals."
          }
        </TranslatedTypography>
      </Box>
      <Box mb={2}>
        <TranslatedTypography
          variant={'subtitle1'}
          i18nKey={'patientMenuFormPrompt'}
          aria-level={2}
        >
          {"Let us know what you're interested in"}
        </TranslatedTypography>
      </Box>
      <Box mb={5}>
        {categories.map((category, index) => (
          <Box key={index} mb={index === categories.length - 1 ? 0 : 1}>
            <PatientMenuCard
              categoryName={category.name}
              choices={category.choices}
              Icon={category.Icon}
              selectedChoices={selectedChoices[category.id] || new Set()}
              setSelectedChoice={(choiceId, isSelected) =>
                selectChoiceInCategory(category.id, choiceId, isSelected)
              }
              additionalNotes={categoryAdditionalNotes[category.id]}
              setAdditionalNotes={(text) => updateAdditionalNotesInCategory(category.id, text)}
            />
          </Box>
        ))}
      </Box>
      <Box>
        <Box mb={1}>
          <TranslatedTypography variant={'h2'} i18nKey={'patientMenuFormAdditionalNotesTitle'}>
            Need something not listed here?
          </TranslatedTypography>
        </Box>
        <Box mb={3}>
          <TranslatedTypography
            variant={'body2'}
            i18nKey={'patientMenuFormAdditionalNotesSubtitle'}
          >
            Let us know in the box below
          </TranslatedTypography>
        </Box>
        <Box mb={7}>
          <DecoratedChatInput
            placeholder={t('patientMenuFormAdditionalNotesPlaceholder', 'How else can we help?')}
            rows={4}
            value={generalAdditionalNotes}
            onChange={(event) => setGeneralAdditionalNotes(event.target.value)}
          />
        </Box>
        {error && (
          <Box color={MyCricketColors.notificationRed} mb={3}>
            <Typography variant={'body1'}>{error}</Typography>
          </Box>
        )}
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <BaseButton
            className={classes.nextButton}
            onClick={() => completePatientMenu()}
            endIcon={<ArrowForward />}
          >
            Continue
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientMenu;
