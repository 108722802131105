import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { isFunction } from 'lodash';

export default function CricketC(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="70" viewBox="0 0 62 70" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h70v70H0z" />
        <path
          fill={'#C8D2DF'}
          d="M35.8 69.205C16.499 69.205.795 53.86.795 35S16.5.795 35.8.795c7.524 0 14.696 2.3 20.74 6.648 5.14 3.7 6.238 10.77 2.452 15.793-3.785 5.023-11.022 6.097-16.161 2.398a11.955 11.955 0 0 0-7.031-2.248c-6.554 0-11.886 5.21-11.886 11.614 0 6.404 5.332 11.614 11.886 11.614 2.558 0 4.99-.778 7.034-2.25 5.14-3.7 12.376-2.627 16.163 2.395 3.786 5.022 2.689 12.093-2.45 15.794-6.047 4.35-13.22 6.652-20.747 6.652"
        />
      </g>
    </svg>
  );
}

export function AnimatedCricketC({ height, width, releaseAnimation, fill }) {
  const animationStrokeProps = useAnimatedPath(releaseAnimation);
  const strokeWidth = '6';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : '65'}
      height={height ? height : '75'}
      viewBox={`-${strokeWidth} -${strokeWidth} 75 85`}
    >
      <style>{'.cls-1{fill:none;stroke-linecap:round;stroke-miterlimit:10;}'}</style>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h70v70H0z" />
        <animated.path
          {...animationStrokeProps}
          stroke={fill ? fill : '#C8D2DF'}
          strokeWidth={strokeWidth}
          style={{
            ...animationStrokeProps.style,
          }}
          d="M35.8 69.205C16.499 69.205.795 53.86.795 35S16.5.795 35.8.795c7.524 0 14.696 2.3 20.74 6.648 5.14 3.7 6.238 10.77 2.452 15.793-3.785 5.023-11.022 6.097-16.161 2.398a11.955 11.955 0 0 0-7.031-2.248c-6.554 0-11.886 5.21-11.886 11.614 0 6.404 5.332 11.614 11.886 11.614 2.558 0 4.99-.778 7.034-2.25 5.14-3.7 12.376-2.627 16.163 2.395 3.786 5.022 2.689 12.093-2.45 15.794-6.047 4.35-13.22 6.652-20.747 6.652"
        />
      </g>
    </svg>
  );
}

const useAnimatedPath = () => {
  const [length, setLength] = useState(null);
  const getRefLength = (ref) => {
    // The ref is `null` on component unmount
    if (ref) {
      isFunction(ref.getTotalLength) ? setLength(ref.getTotalLength()) : setLength(200);
    }
  };
  const animatedStyle = useSpring({
    to: { strokeDashoffset: 0, strokeDasharray: length },
    from: { strokeDashoffset: length, strokeDasharray: length },
    reverse: true,
    config: { friction: 3, mass: 250, tension: 400 },
  });

  return {
    style: animatedStyle,
    ref: getRefLength,
  };
};
