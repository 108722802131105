import React from 'react';
import { Box } from '@mui/material';
import { animated, useSpring } from 'react-spring';

const AnimatedBubble = ({ show, color, size = '40px', children }) => {
  const style = useSpring({
    config: show ? { mass: 0.25, tension: 180, friction: 3 } : { duration: 100 },
    transform: show ? 'scale(1.0)' : 'scale(0.0)',
  });

  return (
    <animated.span style={style}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={color}
        color={'white'}
        width={size}
        height={size}
        borderRadius={'9999px'}
      >
        {children}
      </Box>
    </animated.span>
  );
};

export default AnimatedBubble;
