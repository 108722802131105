import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import { BlankTopNav } from '../organisms/TopNav';
import { BaseButtonNoTranslation as BaseButton } from '../atoms/Buttons';
import ShowHideButton from '../atoms/ShowHideButton';
import EmailUnverifiedIcon from '../icons/EmailUnverified';
import { myCricketFetch } from '../../utils/fetchingUtils';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import MyCricketColors from '../../data/MyCricketColors';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { paths } from '../../utils/routing/routes';
import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from '../../data/SupportContactInfo';

const formatPhoneToE164 = (phone) => `+1${phone.replace(/\D/g, '')}`;

const EmailVerificationPage = () => {
  const { idTokenHasBeenSet, verifyEmailAddress } = usePatientContext();
  const [isVerified, setVerified] = useState(false);
  const [emailToken, setEmailToken] = useState(null);
  const [isConfirmingToken, setConfirmingToken] = useState(true);
  const [emailAddress, setEmailAddress] = useState(null);
  const [password, setPassword] = useState('');
  const [errorCode, setErrorCode] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [isPasswordShown, setPasswordShown] = useState(false);

  const history = useHistory();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  const hash = window.location.hash;
  useEffect(() => {
    const confirmEmailVerification = async () => {
      setConfirmingToken(true);
      try {
        const hashParams = new URLSearchParams(hash?.slice(1));
        const hashToken = hashParams.get('email-token');
        if (!hashToken) {
          // If no token exists, just call it invalid.
          setErrorCode('invalid-jwt');
        } else {
          // If we are logged in on this computer already, attempt to
          // automatically process the request.
          if (idTokenHasBeenSet) {
            try {
              await verifyEmailAddress(hashToken);
              setVerified(true);
              setConfirmingToken(false);
              return;
            } catch {
              // The idToken for the user might be invalid, so just eat the error
              // and try with the password input method.
            }
          }
          const res = await myCricketFetch('/patient/me/email/verification/confirm', {
            method: 'POST',
            body: JSON.stringify({ idToken: hashToken }),
          });
          setEmailToken(hashToken);
          setEmailAddress(res.emailAddress);
        }
      } catch (error) {
        console.error('Failed to confirm token:', error);
        const errorCode = error.responseBody?.errorCode || 'unknown';
        setErrorCode(errorCode);
      } finally {
        setConfirmingToken(false);
      }
    };
    if (idTokenHasBeenSet != null && isConfirmingToken) {
      confirmEmailVerification();
    }
  }, [hash, idTokenHasBeenSet, isConfirmingToken, history, verifyEmailAddress]);

  const submitVerification = async (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    setLoading(true);
    setErrorCode(null);
    try {
      await verifyEmailAddress(emailToken, password);
      history.push(paths.loginInformation());
    } catch (error) {
      const errorCode = error.responseBody?.errorCode || 'unknown';
      setErrorCode(errorCode);
    } finally {
      setLoading(false);
    }
  };

  if (isConfirmingToken) {
    return null;
  }

  if (isVerified) {
    return <Redirect to={{ pathname: paths.loginInformation() }} />;
  }

  if (errorCode === 'invalid-jwt') {
    return <Redirect to={{ pathname: paths.careTeam() }} />;
  }

  let title = t('verifyEmailTitle');
  let description = (
    <Trans
      i18nKey={'verifyEmailDescription'}
      values={{ emailAddress }}
      components={{ bold: <b></b> }}
    />
  );
  if (errorCode === 'link-expired') {
    title = t('verifyEmailLinkExpiredTitle');
    description = t('verifyEmailLinkExpiredDescription');
  } else if (errorCode === 'too-many-attempts') {
    title = t('verifyEmailTooManyAttemptsTitle');
    description = (
      <Trans
        i18nKey={'verifyEmailTooManyAttemptsDescription'}
        values={{ phone: SUPPORT_PHONE_NUMBER, email: SUPPORT_EMAIL }}
        components={{
          phone: <a href={`tel:${formatPhoneToE164(SUPPORT_PHONE_NUMBER)}`}> </a>,
          email: <a href={`mailto:${SUPPORT_EMAIL}`}> </a>,
        }}
      />
    );
  }

  const shouldShowPasswordInput = errorCode !== 'link-expired' && errorCode !== 'too-many-attempts';

  return (
    <Box>
      <BlankTopNav showLanguageButton={true} />
      <Box display={'flex'} justifyContent={'center'}>
        <Box component={'main'} mt={5} px={2} width={'640px'}>
          <Box
            p={{ xs: 3, md: 6 }}
            bgcolor={MyCricketColors.white}
            borderRadius={'6px'}
            maxWidth={'640px'}
            style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <EmailUnverifiedIcon width={'120'} height={'124'} />
            </Box>
            <Box textAlign={'center'} mt={2.5}>
              <Typography variant={'h2'} aria-level={1}>
                {title}
              </Typography>
            </Box>
            <Box textAlign={'center'} mt={2.5}>
              <Typography variant={'body2'}>{description}</Typography>
            </Box>
            {shouldShowPasswordInput ? (
              <form onSubmit={submitVerification}>
                <Box display={'flex'} justifyContent={'center'} mt={5}>
                  <TextField
                    id="current-password"
                    name={'current-password'}
                    variant={'outlined'}
                    type={isPasswordShown ? 'text' : 'password'}
                    placeholder={t('Password')}
                    autoComplete={'current-password'}
                    style={{ width: !isDesktop ? '100%' : undefined }}
                    InputProps={{
                      style: { width: isDesktop ? '360px' : '100%', height: '50px' },
                      endAdornment: (
                        <ShowHideButton isShown={isPasswordShown} setShown={setPasswordShown} />
                      ),
                    }}
                    error={errorCode === 'invalid-login'}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                      if (errorCode === 'invalid-login') {
                        setErrorCode(null);
                      }
                    }}
                  />
                </Box>
                {errorCode && (
                  <Box color={MyCricketColors.notificationRed} textAlign={'center'} mt={2.5}>
                    <Typography variant={'body1'}>
                      {errorCode === 'invalid-login'
                        ? t('passwordErrorIncorrect')
                        : t('genericErrorMessage')}
                    </Typography>
                  </Box>
                )}
                <Box display={'flex'} justifyContent={'center'} mt={5}>
                  <BaseButton
                    type={'submit'}
                    disabled={isLoading || !password}
                    style={{ width: '230px', height: '50px' }}
                  >
                    {t('Submit')}
                  </BaseButton>
                </Box>
              </form>
            ) : (
              <Box display={'flex'} justifyContent={'center'} mt={5}>
                <BaseButton
                  type={'button'}
                  style={{ width: '230px', height: '50px' }}
                  onClick={() => {
                    if (idTokenHasBeenSet) {
                      history.push(paths.loginInformation());
                    } else {
                      history.push(paths.login());
                    }
                  }}
                >
                  {idTokenHasBeenSet ? t('goToSettings') : t('goToLogin')}
                </BaseButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerificationPage;
