import React, { useEffect, useMemo } from 'react';
import { Box, Link } from '@mui/material';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { paths } from '../../utils/routing/routes';
import { AccountSettings, ContactInfoSettings } from '../organisms/PreferencesSettings';
import { Appointments } from '../organisms/Appointments';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import Roles from '../../data/Roles';
import { Link as RouterLink } from 'react-router-dom';

const MePage = () => {
  const { shouldShowSchedulingReminder, clearSchedulingReminder, userRole } = usePatientContext();

  useEffect(() => {
    if (shouldShowSchedulingReminder) {
      clearSchedulingReminder();
    }
  }, [shouldShowSchedulingReminder, clearSchedulingReminder]);

  /**
   * Memoized because <Appointments> performs async calculations and will
   * setState after. If we don't memoize, the component will re-render and
   * try to setState on an unmounted component.
   */
  const AppointmentsBlock = useMemo(
    () => () => {
      // Doesn't make sense for mentors
      if (!Roles.isPatientRole(userRole)) return null;

      return (
        <Box mt={{ xs: 3, md: 6 }}>
          <Appointments />
        </Box>
      );
    },
    [userRole],
  );

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box component="main" width={'640px'} px={2}>
        <Box>
          <AppointmentsBlock />
          {Roles.isPatientRole(userRole) && (
            <Box mt={{ xs: 3, md: 6 }}>
              <ContactInfoSettings />
            </Box>
          )}
          <Box mt={{ xs: 3, md: 6 }}>
            <AccountSettings />
          </Box>
          <Box my={3}>
            <Link
              component={RouterLink}
              to={paths.logout()}
              underline="none"
              data-test-id="MePage-logout"
            >
              <TranslatedTypography variant="body1" color="textPrimary">
                Log out
              </TranslatedTypography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MePage;
