import React from 'react';

const Stethoscope = (props) => {
  const { color } = props;
  return (
    <svg width="61" height="66" viewBox="0 0 61 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.5232 44.2341C56.9907 44.2341 59.8023 47.0637 59.8023 50.5533C59.8023 54.0429 56.9907 56.8724 53.5232 56.8724C50.0558 56.8724 47.2441 54.0429 47.2441 50.5533C47.2441 47.0637 50.0558 44.2341 53.5232 44.2341Z"
        fill="white"
      />
      <path
        d="M54.221 16.1489C56.5328 16.1489 58.407 14.2628 58.407 11.9362C58.407 9.60951 56.5328 7.72339 54.221 7.72339C51.9091 7.72339 50.0349 9.60951 50.0349 11.9362C50.0349 14.2628 51.9091 16.1489 54.221 16.1489Z"
        fill="white"
      />
      <path
        d="M7.47665 48.4468C9.40363 48.4468 10.965 50.0181 10.965 51.9574C10.965 53.8967 9.40363 55.4681 7.47665 55.4681C5.54968 55.4681 3.98828 53.8967 3.98828 51.9574C3.98828 50.0181 5.54968 48.4468 7.47665 48.4468Z"
        fill="white"
      />
      <path
        d="M10.9651 0.702148C12.1204 0.702148 13.0581 1.64581 13.0581 2.80853C13.0581 3.97125 12.1204 4.91491 10.9651 4.91491C9.80974 4.91491 8.87207 3.97125 8.87207 2.80853C8.87207 1.64581 9.80974 0.702148 10.9651 0.702148Z"
        fill="white"
      />
      <path
        d="M29.8023 0.702148C30.9576 0.702148 31.8953 1.64581 31.8953 2.80853C31.8953 3.97125 30.9576 4.91491 29.8023 4.91491C28.6469 4.91491 27.7092 3.97125 27.7092 2.80853C27.7092 1.64581 28.6469 0.702148 29.8023 0.702148Z"
        fill="white"
      />
      <path
        d="M36.9745 15.9706C38.3964 15.9032 39.5698 17.0435 39.5698 18.459C39.5698 28.0458 32.5917 36.0218 23.4787 37.5147V39.013C23.4787 39.8724 23.1326 40.6518 22.5717 41.2149C22.0122 41.7794 21.2378 42.1277 20.3838 42.1277C18.6745 42.1277 17.2889 40.7333 17.2889 39.013V37.5147C8.1759 36.022 1.19775 28.0458 1.19775 18.459C1.19775 17.0435 2.37124 15.9032 3.7931 15.9706C5.11868 16.0338 6.14427 17.1713 6.14845 18.5067C6.17496 26.3846 12.5503 32.7852 20.3838 32.7852C28.2173 32.7852 34.5926 26.3846 34.6191 18.5067C34.6233 17.1713 35.6489 16.0338 36.9745 15.9706Z"
        fill="white"
      />
      <path
        d="M53.5232 48.4468C54.6785 48.4468 55.6162 49.3904 55.6162 50.5532C55.6162 51.7159 54.6785 52.6595 53.5232 52.6595C52.3679 52.6595 51.4302 51.7159 51.4302 50.5532C51.4302 49.3904 52.3679 48.4468 53.5232 48.4468Z"
        fill="white"
      />
      <path
        d="M57.3605 27.383C57.9384 27.383 58.407 26.9115 58.407 26.3298C58.407 25.7481 57.9384 25.2766 57.3605 25.2766C56.7825 25.2766 56.314 25.7481 56.314 26.3298C56.314 26.9115 56.7825 27.383 57.3605 27.383Z"
        fill="white"
      />
      <path
        d="M45.4999 4.91498C46.0779 4.91498 46.5464 4.44345 46.5464 3.86179C46.5464 3.28012 46.0779 2.80859 45.4999 2.80859C44.9219 2.80859 44.4534 3.28012 44.4534 3.86179C44.4534 4.44345 44.9219 4.91498 45.4999 4.91498Z"
        fill="white"
      />
      <path
        d="M45.4999 62.4894C46.0779 62.4894 46.5464 62.0179 46.5464 61.4362C46.5464 60.8546 46.0779 60.3831 45.4999 60.3831C44.9219 60.3831 44.4534 60.8546 44.4534 61.4362C44.4534 62.0179 44.9219 62.4894 45.4999 62.4894Z"
        fill="white"
      />
      <path
        d="M32.2443 51.2553C32.8222 51.2553 33.2908 50.7838 33.2908 50.2021C33.2908 49.6205 32.8222 49.1489 32.2443 49.1489C31.6663 49.1489 31.1978 49.6205 31.1978 50.2021C31.1978 50.7838 31.6663 51.2553 32.2443 51.2553Z"
        fill="white"
      />
      <path
        d="M23.1744 14.7446C23.7524 14.7446 24.221 14.273 24.221 13.6914C24.221 13.1097 23.7524 12.6382 23.1744 12.6382C22.5965 12.6382 22.1279 13.1097 22.1279 13.6914C22.1279 14.273 22.5965 14.7446 23.1744 14.7446Z"
        fill="white"
      />
      <path
        d="M16.1976 61.0851C16.7756 61.0851 17.2441 60.6136 17.2441 60.0319C17.2441 59.4503 16.7756 58.9788 16.1976 58.9788C15.6197 58.9788 15.1511 59.4503 15.1511 60.0319C15.1511 60.6136 15.6197 61.0851 16.1976 61.0851Z"
        fill="white"
      />
      <path
        d="M3.63953 39.319C4.2175 39.319 4.68604 38.8475 4.68604 38.2658C4.68604 37.6842 4.2175 37.2126 3.63953 37.2126C3.06156 37.2126 2.59302 37.6842 2.59302 38.2658C2.59302 38.8475 3.06156 39.319 3.63953 39.319Z"
        fill="white"
      />
      <path
        d="M20.3837 42.8299C18.2925 42.8299 16.5912 41.1178 16.5912 39.0131V38.1027C12.2181 37.2484 8.22563 34.9119 5.29665 31.4797C2.20344 27.8552 0.5 23.2311 0.5 18.459C0.5 16.6474 2.00544 15.1832 3.82581 15.2693C5.51405 15.3499 6.84061 16.7708 6.84605 18.5045C6.8713 25.9916 12.9443 32.083 20.3837 32.083C27.8232 32.083 33.8961 25.9916 33.9214 18.5043C33.9268 16.7708 35.2533 15.3497 36.9413 15.2691C36.9928 15.2667 37.0435 15.2656 37.0941 15.2656C38.8439 15.2656 40.2674 16.6981 40.2674 18.4589C40.2674 23.2308 38.564 27.8551 35.4709 31.4796C32.5418 34.9117 28.5493 37.2483 24.1764 38.1026V39.013C24.1764 40.0337 23.7815 40.9921 23.0645 41.712C22.3502 42.4325 21.3977 42.8299 20.3837 42.8299ZM3.6733 16.67C2.69293 16.67 1.89535 17.4726 1.89535 18.459C1.89535 27.6274 8.41637 35.35 17.4009 36.8219C17.7384 36.8773 17.9865 37.1706 17.9865 37.5149V39.0133C17.9865 40.3435 19.0619 41.4258 20.3837 41.4258C21.0245 41.4258 21.626 41.1748 22.0778 40.7193C22.5317 40.2636 22.7811 39.6582 22.7811 39.0134V37.5151C22.7811 37.1709 23.029 36.8774 23.3667 36.8221C32.3511 35.35 38.8721 27.6274 38.8721 18.459C38.8721 17.4436 38.0278 16.6221 37.0072 16.672C36.0624 16.7171 35.3198 17.5239 35.3167 18.5089C35.289 26.768 28.59 33.4872 20.3837 33.4872C12.1774 33.4872 5.47847 26.7682 5.4507 18.5092C5.44763 17.5239 4.70502 16.7171 3.75995 16.672C3.73107 16.6707 3.70205 16.67 3.6733 16.67Z"
        fill={color}
      />
      <path
        d="M36.9745 16.6728C36.5892 16.6728 36.2768 16.3584 36.2768 15.9707V6.27708C36.2768 4.75164 35.0438 3.5107 33.528 3.5107H31.8954C31.5102 3.5107 31.1978 3.19629 31.1978 2.80857C31.1978 2.42086 31.5102 2.10645 31.8954 2.10645H33.528C35.813 2.10645 37.6722 3.97733 37.6722 6.27708V15.9707C37.6722 16.3584 37.3598 16.6728 36.9745 16.6728Z"
        fill={color}
      />
      <path
        d="M3.79313 16.6728C3.40788 16.6728 3.09546 16.3584 3.09546 15.9707V6.27708C3.09546 3.97733 4.95448 2.10645 7.23965 2.10645H8.8722C9.25746 2.10645 9.56988 2.42086 9.56988 2.80857C9.56988 3.19629 9.25746 3.5107 8.8722 3.5107H7.23965C5.72402 3.5107 4.49081 4.75164 4.49081 6.27708V15.9707C4.49081 16.3584 4.17839 16.6728 3.79313 16.6728Z"
        fill={color}
      />
      <path
        d="M10.965 5.61702C9.42622 5.61702 8.17432 4.35712 8.17432 2.80851C8.17432 1.2599 9.42622 0 10.965 0C12.5038 0 13.7557 1.2599 13.7557 2.80851C13.7557 4.35712 12.5038 5.61702 10.965 5.61702ZM10.965 1.40426C10.1956 1.40426 9.56967 2.0342 9.56967 2.80851C9.56967 3.58282 10.1956 4.21277 10.965 4.21277C11.7344 4.21277 12.3604 3.58282 12.3604 2.80851C12.3604 2.0342 11.7344 1.40426 10.965 1.40426Z"
        fill="white"
      />
      <path
        d="M29.8024 5.61702C28.2636 5.61702 27.0117 4.35712 27.0117 2.80851C27.0117 1.2599 28.2636 0 29.8024 0C31.3412 0 32.5931 1.2599 32.5931 2.80851C32.5931 4.35712 31.3412 5.61702 29.8024 5.61702ZM29.8024 1.40426C29.033 1.40426 28.4071 2.0342 28.4071 2.80851C28.4071 3.58282 29.033 4.21277 29.8024 4.21277C30.5718 4.21277 31.1978 3.58282 31.1978 2.80851C31.1978 2.0342 30.5718 1.40426 29.8024 1.40426Z"
        fill="white"
      />
      <path
        d="M30.3256 65.9999C24.459 65.9999 19.686 61.1965 19.686 55.2925V42.1276C19.686 41.7397 19.9985 41.4255 20.3837 41.4255C20.769 41.4255 21.0814 41.7397 21.0814 42.1276V55.2925C21.0814 60.4222 25.2284 64.5957 30.3256 64.5957C32.7945 64.5957 35.1159 63.6279 36.8619 61.8707C38.6081 60.1134 39.5698 57.7772 39.5698 55.2925V38.2659C39.5698 34.2009 42.8559 30.8936 46.8953 30.8936C48.8511 30.8936 50.6907 31.6603 52.0754 33.0523C53.4591 34.4465 54.2209 36.2977 54.2209 38.2659V48.4467C54.2209 48.8346 53.9085 49.1489 53.5232 49.1489C53.138 49.1489 52.8256 48.8346 52.8256 48.4467V38.2659C52.8256 36.6725 52.2088 35.1736 51.0886 34.0451C49.968 32.9186 48.4785 32.2978 46.8953 32.2978C43.6253 32.2978 40.9651 34.975 40.9651 38.2659V55.2925C40.9651 58.1524 39.8583 60.8413 37.8486 62.8635C35.839 64.8861 33.1672 65.9999 30.3256 65.9999Z"
        fill={color}
      />
      <path
        d="M53.5234 57.5745C49.6764 57.5745 46.5466 54.4248 46.5466 50.5533C46.5466 46.6817 49.6764 43.532 53.5234 43.532C57.3704 43.532 60.5001 46.6817 60.5001 50.5533C60.5001 54.4248 57.3704 57.5745 53.5234 57.5745ZM53.5234 44.9362C50.4458 44.9362 47.942 47.456 47.942 50.5533C47.942 53.6505 50.4458 56.1703 53.5234 56.1703C56.601 56.1703 59.1048 53.6505 59.1048 50.5533C59.1048 47.456 56.601 44.9362 53.5234 44.9362Z"
        fill={color}
      />
      <path
        d="M53.5234 53.3617C51.9846 53.3617 50.7327 52.1018 50.7327 50.5531C50.7327 49.0045 51.9846 47.7446 53.5234 47.7446C55.0622 47.7446 56.3141 49.0045 56.3141 50.5531C56.3141 52.1018 55.0622 53.3617 53.5234 53.3617ZM53.5234 49.1489C52.754 49.1489 52.128 49.7788 52.128 50.5531C52.128 51.3274 52.754 51.9574 53.5234 51.9574C54.2928 51.9574 54.9187 51.3274 54.9187 50.5531C54.9187 49.7788 54.2928 49.1489 53.5234 49.1489Z"
        fill="white"
      />
      <path
        d="M54.2209 16.851C51.528 16.851 49.3372 14.6462 49.3372 11.9361C49.3372 9.22606 51.528 7.02124 54.2209 7.02124C56.9138 7.02124 59.1046 9.22606 59.1046 11.9361C59.1046 14.6462 56.9138 16.851 54.2209 16.851ZM54.2209 8.4255C52.2974 8.4255 50.7325 10.0004 50.7325 11.9361C50.7325 13.8719 52.2974 15.4468 54.2209 15.4468C56.1444 15.4468 57.7093 13.8719 57.7093 11.9361C57.7093 10.0004 56.1444 8.4255 54.2209 8.4255Z"
        fill={color}
      />
      <path
        d="M7.47682 56.1702C5.16863 56.1702 3.29077 54.2803 3.29077 51.9574C3.29077 49.6345 5.16863 47.7446 7.47682 47.7446C9.785 47.7446 11.6629 49.6345 11.6629 51.9574C11.6629 54.2803 9.785 56.1702 7.47682 56.1702ZM7.47682 49.1489C5.93803 49.1489 4.68612 50.4088 4.68612 51.9574C4.68612 53.506 5.93803 54.7659 7.47682 54.7659C9.01561 54.7659 10.2675 53.506 10.2675 51.9574C10.2675 50.4088 9.01561 49.1489 7.47682 49.1489Z"
        fill={color}
      />
    </svg>
  );
};

export default Stethoscope;
