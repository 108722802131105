import React, { useState, useEffect } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { sendResetPassword, sendPasswordlessLoginLink } from '../../utils/LoginHelpers';
import LoginError from '../../data/LoginError';
import cricketInterwellLogo from '../icons/CricketInterwellLogo.png';
import LegalDocs from '../molecules/LegalDocs';
import MyCricketColors from '../../data/MyCricketColors';
import { TOO_MANY_ATTEMPTS, USER_IS_UNENROLLED } from '../../data/ErrorCodes';
import ZendeskWidget from '../molecules/ZendeskWidget';
import LanguageToggleButton from '../atoms/LanguageToggleButton';
import { toMyCricketPageURI } from '../../utils/URIUtils';
import LoginForm from '../molecules/LoginForm';
import ResetPasswordForm from '../molecules/ResetPasswordForm';
import ResetPasswordConfirmation from '../molecules/ResetPasswordConfirmation';
import AccountLocked from '../molecules/AccountLocked';
import PausedAccess from '../organisms/PausedAccess';
import PatientHelpMaterial from '../molecules/PatientHelpMaterial';
import MagicLinkForm from '../molecules/MagicLinkForm';
import Stethoscope from '../icons/Stethoscope';
import { MagicLinkError } from '../atoms/MagicLinkError';
import * as Sentry from '@sentry/react';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const SignInContainer = styled(Box)(({ theme }) => ({
  backgroundColor: MyCricketColors.white,
  borderRadius: '6px',
  margin: '1em auto',
  textAlign: 'left',
  width: '428px',
  minHeight: '410px',
  height: 'fit-content',
  boxShadow: '0px 3px 4px rgba(0, 62, 81, 0.1)',
  padding: '30px 36px',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    border: 'none',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

const AnnouncementBannerContainer = styled(Box)(({ theme }) => ({
  'flex': '4 0 0',
  'display': 'flex',
  'background': MyCricketColors.skyTeal,
  'alignItems': 'center',
  'padding': '12px 42px',
  'border': `1px solid ${MyCricketColors.teal}`,
  'boxShadow': '0px 6px 10px rgba(0, 62, 81, 0.12)',
  'borderRadius': '0px 0px 10px 10px',
  'color': 'white',
  'fontSize': '18px',
  '& a': {
    textDecoration: 'underline',
    color: 'inherit',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '18px 30px',
    marginBottom: '14px',
    boxShadow: '0px 6px 10px rgba(0, 62, 81, 0.12)',
    borderRadius: '0px',
  },
}));

const AnnouncementBannerText = styled(Box)(({ theme }) => ({
  marginLeft: '32px',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

const MagicLinkErrorContainer = styled(Box)(({ theme }) => ({
  backgroundColor: MyCricketColors.white,
  borderRadius: '6px',
  border: '1px solid #D74C34',
  textAlign: 'left',
  width: '428px',
  height: 'fit-content',
  boxShadow: '0px 3px 4px rgba(0, 62, 81, 0.1)',
  [theme.breakpoints.down('md')]: {
    maxWidth: '339px',
    margin: '0 15px 15px',
  },
}));

const Login = ({
  classes,
  login,
  directToResetPassword,
  onAttempted,
  onError,
  onAuthorized,
  featureFlags,
  announcementBanner,
  onLoadResetPasswordForm,
  magicLinkErrorCode,
  isUsingMagicLinkV2,
}) => {
  // Converted from a class component. Several methods below dynamically set the state key so it
  // would be difficult to convert that over to a useState hook. Thus settled for this approach.
  const [state, setState] = useState({
    email: '',
    password: '',
    emailValid: false,
    error: null,
    route: directToResetPassword ? 'forgot' : 'main',
    emailFocused: false,
    passwordFocused: false,
    hasEdited: false,
    resetSent: false,
    passwordlessLinkSent: false,
  });

  useEffect(() => {
    if (magicLinkErrorCode) {
      setState((prevState) => ({
        ...prevState,
        route: 'magic-link',
        showMagicLinkError: true,
      }));
    } else if (isUsingMagicLinkV2) {
      setState((prevState) => ({
        ...prevState,
        route: 'magic-link',
      }));
    }
  }, [magicLinkErrorCode, isUsingMagicLinkV2]);

  const [submitBlocked, setSubmitBlocked] = useState(false);

  const { i18n } = useTranslation();
  const isDesktop = useIsDesktop();

  const {
    emailFocused,
    emailValid,
    hasEdited,
    route,
    resetSent,
    error,
    email,
    password,
    passwordFocused,
  } = state;

  const handleChange = (e) => {
    e.preventDefault();

    setState({
      ...state,
      hasEdited: true,
      [e.target.name]: e.target.value,
      error: null,
      emailValid: e.target.name === 'email' ? e.target.validity.valid : emailValid,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length > 0 && password.length > 0 && emailValid) {
      let error = false;
      onAttempted && onAttempted();
      setSubmitBlocked(true);
      try {
        await login(email, password);
      } catch (err) {
        setSubmitBlocked(false);
        error = true;

        const body = err.responseBody;

        // See below to understand why breadcrumbs can show up as [Filtered] and how to avoid it
        // Also see Known Limitations
        // https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/

        if (body?.reason === TOO_MANY_ATTEMPTS) {
          // Navigate to account locked
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'Too many attempts',
            level: 'info',
          });
          setState({ ...state, route: 'accountLocked' });
        } else if (body?.reason === USER_IS_UNENROLLED) {
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'Un-enrolled user',
            level: 'info',
          });
          setState({ ...state, route: 'pausedAccess' });
        } else if (err.status === 401) {
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'Invalid credentials',
            level: 'info',
          });
          setState({
            ...state,
            emailFocused: false,
            passwordFocused: false,
            error: LoginError.UNAUTHORIZED,
          });
        } else {
          Sentry.addBreadcrumb({
            category: 'login',
            message: 'Unknown error',
            level: 'warning',
          });
          setState({
            ...state,
            emailFocused: false,
            passwordFocused: false,
            error: LoginError.GENERAL,
          });
        }
        onError && onError();
      }
      if (!error && onAuthorized) {
        // Don't need to unblock the submit button on success, because you get redirected anyways
        onAuthorized();
      }
    } else if (email.length === 0) {
      setState({
        ...state,
        emailFocused: false,
        passwordFocused: false,
        error: LoginError.NEED_EMAIL,
      });
    } else if (!emailValid) {
      // Using invalid email logic which is outside this error logic
      setState({ ...state, emailFocused: false, passwordFocused: false, error: null });
    } else if (email.length > 0 && password.length === 0) {
      setState({ ...state, error: LoginError.NEED_PASSWORD });
    }
  };

  const handleSendResetPassword = async (e) => {
    e?.preventDefault();
    if (email.length === 0) {
      setState({ ...state, error: LoginError.NEED_EMAIL });
    } else if (!emailValid) {
      // Using invalid email logic which is outside this error logic
      setState({ ...state, error: null });
    } else {
      setState({ ...state, error: null });
      setSubmitBlocked(true);

      try {
        const res = await sendResetPassword(email, i18n.language);
        if (res.success) {
          setState({ ...state, route: 'forgot', resetSent: true });
        }
      } catch (err) {
        setSubmitBlocked(false);

        setState({
          ...state,
          error: LoginError.RESET_PASSWORD,
        });
      }
    }
  };

  return (
    <Box
      height={'100vh'}
      width={'100vw'}
      background={'top right fixed'}
      bgcolor={MyCricketColors.lightGrayFog}
      data-test-id="proV2-login"
    >
      <Box
        component={'header'}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row' }}
        width={'100%'}
        minHeight={'90px'}
      >
        <Box display={'flex'} width={'100%'}>
          <Box
            flex={'1 0 0'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Box p={'24px 24px 0 24px'}>
              <img
                src={cricketInterwellLogo}
                style={{ height: '50px' }}
                alt={'Cricket Health by Interwell Health Logo'}
              />
            </Box>
          </Box>
          {isDesktop && announcementBanner && (
            <AnnouncementBannerContainer>
              <Box height={'66px'} width={'60px'} mb={{ xs: 4, md: 0 }}>
                <Stethoscope color={'#003E51'} />
              </Box>
              <AnnouncementBannerText dangerouslySetInnerHTML={{ __html: announcementBanner }} />
            </AnnouncementBannerContainer>
          )}
          <Box
            flex={'1 0 0'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'flex-start'}
          >
            <Box p={'24px'}>
              <LanguageToggleButton pageURI={toMyCricketPageURI('login')} />
            </Box>
          </Box>
        </Box>
        {/* mobile banner */}
        {!isDesktop && announcementBanner && (
          <AnnouncementBannerContainer>
            <Box height={'66px'} width={'60px'} mb={{ xs: 4, md: 0 }}>
              <Stethoscope color={'#003E51'} />
            </Box>
            <AnnouncementBannerText dangerouslySetInnerHTML={{ __html: announcementBanner }} />
          </AnnouncementBannerContainer>
        )}
      </Box>
      {featureFlags?.['my-cricket-login-closed'] ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '75vw',
            textAlign: 'center',
            padding: '30px',
            margin: 'auto',
          }}
        >
          Cricket Health&#39;s partnership with your health plan has ended effective January 1,
          2024. If you are a kidney care patient who has engaged with Cricket Health, please contact
          your health insurance provider for information about your kidney care options. Thank you
          for allowing us to support you. We wish you all the best in your health journey.
        </Box>
      ) : (
        <Box component={'main'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          {state.showMagicLinkError && (
            <MagicLinkErrorContainer>
              <MagicLinkError errorCode={magicLinkErrorCode} />
            </MagicLinkErrorContainer>
          )}
          <SignInContainer>
            {route === 'main' && (
              <LoginForm
                state={state}
                setState={setState}
                handleChange={handleChange}
                error={error}
                email={email}
                hasEdited={hasEdited}
                emailFocused={emailFocused}
                emailValid={emailValid}
                handleSubmit={handleSubmit}
                password={password}
                passwordFocused={passwordFocused}
                disabled={submitBlocked}
                onLoadResetPasswordForm={onLoadResetPasswordForm}
                featureFlags={featureFlags}
              />
            )}
            {route === 'forgot' &&
              (!resetSent ? (
                <ResetPasswordForm
                  state={state}
                  setState={setState}
                  handleSendResetPassword={handleSendResetPassword}
                  handleChange={handleChange}
                  error={error}
                  email={email}
                  disabled={submitBlocked}
                  hasEdited={hasEdited}
                  emailFocused={emailFocused}
                  emailValid={emailValid}
                />
              ) : (
                <ResetPasswordConfirmation />
              ))}
            {route === 'magic-link' && (
              <MagicLinkForm
                state={state}
                setState={setState}
                handleChange={handleChange}
                sendPasswordlessLoginLink={sendPasswordlessLoginLink}
                error={error}
                email={email}
                disabled={submitBlocked}
                hasEdited={hasEdited}
                emailFocused={emailFocused}
                emailValid={emailValid}
                isPrimaryLoginPage={isUsingMagicLinkV2}
              />
            )}
            {route === 'accountLocked' && (
              <AccountLocked classes={classes} handleSendResetPassword={handleSendResetPassword} />
            )}
            {route === 'pausedAccess' && <PausedAccess />}
            <Box mt={3}>
              <PatientHelpMaterial email={email} />
            </Box>
            {[LoginError.GENERAL, LoginError.RESET_PASSWORD].includes(error) && (
              <Box color={MyCricketColors.notificationRed} mt={1}>
                <Typography variant={'h3'} data-test-id="generalErrorMessage">
                  <Trans>{error}</Trans>
                </Typography>
              </Box>
            )}
          </SignInContainer>
        </Box>
      )}
      <LegalDocs />
      <ZendeskWidget zendeskKey={window._cc && window._cc.ZENDESK_WIDGET_KEY} />
    </Box>
  );
};

export default Login;
