import React from 'react';
import { Box, Fade } from '@mui/material';
import { useModalContext } from '../../utils/contexts/ModalContext';
import MyCricketColors from '../../data/MyCricketColors';

const ModalOverlayContainer = ({ children, noEscape, hasCloseButton }) => {
  const { hideModal } = useModalContext();
  return (
    <Fade in>
      <Box
        position={'fixed'}
        top={0}
        left={0}
        width={'100%'}
        height={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={'#000000BB'}
        zIndex={1100} // 1100 is exactly enough Z to get over the nav bar
        onClick={() => {
          if (noEscape) {
            return;
          }
          hideModal();
        }}
      >
        <Box
          id={'patientModal'}
          role={'dialog'}
          aria-labelledby={'patientModalTitle'}
          aria-describedby={'patientModalDescription'}
          aria-modal={true}
          width={{ xs: '100%', sm: '100%', md: '480px' }}
          maxWidth={'480px'}
          mx={{ xs: 3, md: 0 }}
          p={{ xs: 3, md: 6 }}
          borderRadius={'6px'}
          bgcolor={MyCricketColors.white}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {children}
        </Box>
      </Box>
    </Fade>
  );
};

export default ModalOverlayContainer;
