import React, { forwardRef, useState } from 'react';
import { Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GrayCircle from '../icons/GrayCircle.svg';
import GreenCheck from '../icons/GreenCheck.svg';
import RedX from '../icons/RedX.svg';
import { patientPasswordRules } from '../../utils/signUpFormValidationFunctions';
import Input from '../atoms/Input';
import ConfirmPassword from '../atoms/ConfirmPassword';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: '9px',
  },
  formInput: {
    marginTop: '6px',
    display: 'flex',
  },
  label: {
    fontSize: '19px',
    marginBottom: 6,
    marginTop: '24px',
    fontWeight: ({ shieldOverride }) => (shieldOverride ? 700 : 'normal'),
  },
  required: { marginLeft: '2px', color: theme.palette.red },
  error: { color: theme.palette.red, fontSize: '19px' },
  showHidePassword: {
    border: 'none',
    borderRadius: '6px',
    backgroundColor: theme.palette.white,
    fontSize: '15px',
    color: theme.palette.opaqueGray,
  },
  passwordInputOverrides: {
    border: 'none',
  },
  passwordContainer: {
    'borderRadius': '3px',
    '&:focus-within': {
      borderColor: theme.palette.blue,
    },
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '16px',
    marginTop: '5px',
  },
  li: { marginBottom: '5px' },
  liGreenCheck: {
    background: `url(${GreenCheck}) no-repeat left center`,
    listStylePosition: 'inside',
  },
  liGrayCircle: {
    marginLeft: 6,
    background: `url(${GrayCircle}) no-repeat left center`,
    listStylePosition: 'inside',
  },
  liRedX: {
    background: `url(${RedX}) no-repeat left center`,
    listStylePosition: 'inside',
  },
  liText: {
    marginLeft: 26,
  },
  liGrayCircleText: { marginLeft: 20 },
  passwordError: {
    border: `1px solid ${theme.palette.red}`,
  },
  passwordRulesContainer: {
    marginTop: '8px',
  },
}));

const PasswordForm = forwardRef(
  (
    {
      inputStyles,
      password,
      onSetPassword,
      passwordErr,
      confirmPassword = null,
      onSetConfirmPassword = null,
      confirmPasswordErr = null,
      onBlur,
      setDirty,
      shieldOverride,
      passwordHeaderText,
      required = false,
      userRoleName,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyles({ shieldOverride });
    const [showPasswordRules, setShowPasswordRules] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = ({ target }) => {
      const { name, value } = target;
      if (name === 'password') {
        onSetPassword(value);
      }
    };

    const handleFocus = ({ target: { name } }) => {
      setShowPasswordRules(true);
      setDirty(name);
    };

    const [showConfirmPWRules, setShowConfirmPWRules] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    let handleConfirmPWChange;
    let handleConfirmPWFocus;
    let confirmPasswordValid;

    if (onSetConfirmPassword) {
      handleConfirmPWChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'confirm-password') {
          onSetConfirmPassword(value);
        }
      };

      handleConfirmPWFocus = ({ target: { name } }) => {
        setShowConfirmPWRules(true);
        setDirty(name);
      };

      confirmPasswordValid = (password, confirmPassword) => {
        return password === confirmPassword && password !== '';
      };
    }
    const hasPasswordErr = patientPasswordRules.every(({ validator }) => validator(password))
      ? false
      : passwordErr;

    return (
      <div
        className={classes.formContainer}
        onBlur={onBlur}
        ref={ref}
        role="textbox"
        aria-label="Input Password Below"
      >
        <div>
          <div className={classes.label}>
            {t(passwordHeaderText)}
            {required && <span className={classes.required}>*</span>}{' '}
          </div>
        </div>
        <div
          className={classNames(classes.formInput, classes.passwordContainer, {
            [inputStyles.root]: !hasPasswordErr,
            [classes.passwordError]: hasPasswordErr,
          })}
        >
          <Input
            className={classNames(classes.passwordInputOverrides, 'no-upscope')}
            autoComplete="new-password"
            name="password"
            onFocus={handleFocus}
            onChange={handleChange}
            placeholder={t('Password')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            data-test-id="registration-password"
          />
          <button
            type="button"
            className={classes.showHidePassword}
            name="showHide"
            onFocus={handleFocus}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? t('HIDE') : t('SHOW')}
          </button>
        </div>

        <Collapse in={showPasswordRules} className={classes.passwordRulesContainer}>
          <strong>{t('Your password must contain the following')}:</strong>
          <ul className={classes.ul}>
            {patientPasswordRules.map(({ displayValue, validator }) => (
              <li
                key={displayValue}
                data-test-id={`password-error-${displayValue}`}
                className={classNames(classes.li, {
                  [classes.liGreenCheck]: validator(password),
                  [classes.liGrayCircle]: !validator(password) && !passwordErr,
                  [classes.liRedX]: !validator(password) && passwordErr,
                })}
              >
                <span
                  className={classNames(classes.liText, {
                    [classes.liGrayCircleText]: !validator(password) && !passwordErr,
                  })}
                >
                  {t(displayValue)}
                </span>
              </li>
            ))}
          </ul>
        </Collapse>

        {onSetConfirmPassword && (
          <ConfirmPassword
            classes={classes}
            confirmPassword={confirmPassword}
            confirmPasswordErr={confirmPasswordErr}
            confirmPasswordValid={confirmPasswordValid}
            handleConfirmPWChange={handleConfirmPWChange}
            handleConfirmPWFocus={handleConfirmPWFocus}
            inputStyles={inputStyles}
            password={password}
            setShowConfirmPassword={setShowConfirmPassword}
            showConfirmPassword={showConfirmPassword}
            showConfirmPWRules={showConfirmPWRules}
          />
        )}

        {passwordErr && (
          <div className={classes.error} data-test-id={'passwordError'}>
            {t(passwordErr)}
          </div>
        )}
        {confirmPasswordErr && (
          <div className={classes.error} data-test-id="confirm-password-err">
            {t(confirmPasswordErr)}
          </div>
        )}
        <br />
      </div>
    );
  },
);
PasswordForm.displayName = 'PasswordForm';

export default PasswordForm;
