import React, { useEffect } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import * as Sentry from '@sentry/react';

const initEventLogger = () => {
  function getLocation() {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}${url.pathname}`;
  }

  ZendeskAPI('webWidget:on', 'userEvent', async function (event) {
    await fetch(`${window._cc.dream.url}/i/logEvent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        predicate: `Zendesk:${event.action.replace(/ /g, '_')}`,
        object: getLocation(),
        prepositions: {
          properties: event.properties,
        },
      }),
    });
  });

  // set language based on i18next
  if (window.i18next) {
    ZendeskAPI('webWidget', 'setLocale', window.i18next.language);
  }

  Sentry.addBreadcrumb({
    category: 'zendesk',
    message: 'Event logger initialized',
    level: 'info',
  });
};

const ZendeskWidget = (props) => {
  useEffect(() => {
    if (!props.zendeskKey) {
      Sentry.addBreadcrumb({
        category: 'zendesk',
        message: 'No API key found',
        level: 'warning',
      });
    }
    return () => {
      if (props.zendeskKey && window.$zopim) {
        // https://developer.zendesk.com/documentation/classic-sdks/chat-widget/customising-the-widget/showing-and-hiding-the-chat-window/
        window.$zopim.livechat.hideAll();
      }
    };
  }, [props.zendeskKey]);

  return props.zendeskKey ? <Zendesk {...props} onLoaded={initEventLogger} role="button" /> : null;
};

export default ZendeskWidget;
