import React from 'react';

const Book = (props) => {
  const { ...restProps } = props;
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M17.5 13.3594V0.890625C17.5 0.39707 17.094 0 16.5893 0H4.14286C2.1317 0 0.5 1.5957 0.5 3.5625V15.4375C0.5 17.4043 2.1317 19 4.14286 19H16.5893C17.094 19 17.5 18.6029 17.5 18.1094V17.5156C17.5 17.2373 17.3672 16.985 17.1623 16.8217C17.0029 16.2502 17.0029 14.6211 17.1623 14.0496C17.3672 13.89 17.5 13.6377 17.5 13.3594ZM5.35714 4.97266C5.35714 4.8502 5.4596 4.75 5.58482 4.75H13.6295C13.7547 4.75 13.8571 4.8502 13.8571 4.97266V5.71484C13.8571 5.8373 13.7547 5.9375 13.6295 5.9375H5.58482C5.4596 5.9375 5.35714 5.8373 5.35714 5.71484V4.97266ZM5.35714 7.34766C5.35714 7.2252 5.4596 7.125 5.58482 7.125H13.6295C13.7547 7.125 13.8571 7.2252 13.8571 7.34766V8.08984C13.8571 8.21231 13.7547 8.3125 13.6295 8.3125H5.58482C5.4596 8.3125 5.35714 8.21231 5.35714 8.08984V7.34766ZM14.9728 16.625H4.14286C3.47121 16.625 2.92857 16.0943 2.92857 15.4375C2.92857 14.7844 3.475 14.25 4.14286 14.25H14.9728C14.9007 14.8846 14.9007 15.9904 14.9728 16.625Z"
        fill="white"
      />
    </svg>
  );
};

export default Book;
