import loadOrganizationList from '../loadOrganizationList';
import Chirp, { ChirpContext as ChirpFetchingContext, connectionStates } from '../Chirp';
import _ from 'lodash';
import URI from '../../utils/URI';
import React, { useEffect, useState } from 'react';
import withChirpEventSenders from '../withChirpEventSenders';
import withChirpFetching from '../withChirpFetching';
import withFetching from '../withFetching';
import withBufferPropsUntilLoaded from '../withBufferPropsUntilLoaded';
import { RetiredDiscussionGroupNames, RetiredGroupTypes } from '../../data/groupTypeNames';
import { isSidebarGroup } from '../../components/organisms/CareTeamSidebar';
import { ChirpContextProviderForApp } from './ChirpContextProvider';
import { usePatientContext } from './PatientContextProvider';
import { v4 as uuidv4 } from 'uuid';
import Debug from 'debug';
const debug = Debug('MyCricket:chirp');

function parseList(list) {
  if (!list) {
    return [];
  }

  return list.split(',').map((item) => parseInt(item));
}

function parseIntList(list) {
  return parseList(list).map((item) => parseInt(item));
}

function parseJSONArray(json) {
  if (!json) {
    return [];
  }

  return JSON.parse(json);
}

function messageIdsToFetch({ groups }) {
  return _.uniq(
    _.flatten(
      Object.values(groups)
        // Don't fetch messages for groups we are going to exclude
        .filter(
          (group) =>
            !RetiredDiscussionGroupNames.includes(group.name) &&
            !RetiredGroupTypes.includes(group.type),
        )
        .map((group) => group.messages),
    ),
  );
}

// (re)Fetches all the data and passes it to the App Context provider.
const ChirpContextProviderWithDataFetching = _.flowRight(
  (Component) =>
    function ChirpFetcher(props) {
      const [uuid] = useState(uuidv4());

      debug('ChirpFetcher [%s]: Rendering', uuid);
      // Don't try to simplify things with useState(new Chirp()) - it won't work!
      // TODO Do we want to pass userId to new Chirp()?
      const [chirp] = useState(() => new Chirp());
      const [chirpConnectionStatus, setChirpConnectionStatus] = useState(null);

      useEffect(() => {
        if (!chirp) {
          return;
        }
        debug('ChirpFetcher [%s]: Registering chirp state handlers', uuid);
        Object.values(connectionStates).map((state) =>
          chirp.on(state, () => setChirpConnectionStatus(state)),
        );

        return () => {
          debug('ChirpFetcher [%s]: Unregistering chirp state handlers', uuid);

          Object.values(connectionStates).map((state) =>
            chirp.off(state, () => setChirpConnectionStatus(state)),
          );
          chirp.disconnect();
        };
      }, [chirp]);

      if (!(chirp instanceof Chirp)) {
        debug('⚠️ ChirpFetcher [%s]: No Chirp - THIS SHOULD NEVER HAPPEN!', uuid);
        return null;
      }

      return (
        <ChirpFetchingContext.Provider value={chirp}>
          <Component {...props} chirpConnectionStatus={chirpConnectionStatus} />
        </ChirpFetchingContext.Provider>
      );
    },
  withFetching({
    loader: (props, signal) => loadOrganizationList(signal),
    propName: 'organizationList',
  }),
  withChirpFetching({
    entityType: 'organizations',
    loader: async (ids, signal, chirp) => {
      debug('withChirpFetching(organizations): Length: %s', ids.length);
      return chirp.fetchPromise('organizations', ids).then((organizations) =>
        organizations.map((organization) => ({
          id: organization.organizationId,
          URI: organization.organizationId,
          groups: parseIntList(organization.groups),
          notesGroups: parseIntList(organization.notesGroups),
        })),
      );
    },
    idsToFetch: (props) => {
      if (!props.organizationList.loaded) {
        return [];
      }

      return props.organizationList.data.map((org) => org.organizationId);
    },
  }),
  withChirpFetching({
    entityType: 'groups',
    loader: async (ids, signal, chirp) => {
      debug('withChirpFetching(groups): Length: %s', ids.length);
      // Can't filter out the groups here because it breaks withChirpFetching (must return same # as your request)
      return await chirp.fetchPromise('groups', ids).then((groups) =>
        groups.map((group) => ({
          ...group,
          id: group.cricketGroupId,
          URI: URI.toGroupURI(group.cricketGroupId),
          members: parseIntList(group.members),
          messages: parseIntList(group.messages),
        })),
      );
    },
    idsToFetch: (props) => {
      return _.uniq(_.flatten(Object.values(props.organizations).map((org) => org.groups)));
    },
  }),
  withChirpFetching({
    entityType: 'messages',
    loader: async (ids, signal, chirp) => {
      debug('withChirpFetching(messages): Length: %s', ids.length);
      const chunks = _.chunk(ids, 1000).map(async (chunkOfIds) => {
        debug('withChirpFetching(messages): Fetching %s messages', chunkOfIds.length);
        const chunk = await chirp.fetchPromise('messages', chunkOfIds);
        debug('withChirpFetching(messages): Fetched %s messages', chunkOfIds.length);
        return chunk;
      });
      const messages = await Promise.all(chunks).then(_.flatten);
      return messages.map((message) => ({
        ...message,
        id: message.messageId,
        URI: URI.toMessageURI(message.messageId),
        replies: parseJSONArray(message.replies),
        components: parseJSONArray(message.components),
      }));
    },
    idsToFetch: messageIdsToFetch,
  }),
  withChirpFetching({
    entityType: 'users',
    loader: async (ids, signal, chirp) => {
      debug('withChirpFetching(users): Length: %s', ids.length);
      const chunks = _.chunk(ids, 1000).map(async (chunkOfIds) =>
        chirp.fetchPromise('users', chunkOfIds),
      );
      return Promise.all(chunks).then(_.flatten);
    },
    idsToFetch: (props) => {
      // the uniq here is very important! The proc this ultimately calls expects a list of unique IDs, and it will break if you give it dupes 👩‍💻
      const messageAuthors = _.uniq(
        Object.values(props.messages).map((message) => message.userId_sender),
      );
      const careTeamMembers = Object.values(props.groups)
        .filter(isSidebarGroup)
        .map((group) => group.members)
        .flat();
      return [...new Set([...careTeamMembers, ...messageAuthors])];
    },
  }),
  withChirpEventSenders,
)(
  withBufferPropsUntilLoaded((props) => {
    // These are all objects keyed by ID
    const { organizations, groups, messages, users } = props;
    debug('withBufferPropsUntilLoaded(organizations, groups, messages, users)');
    const isOrganizationsLoaded = !_.isEmpty(organizations);
    const isGroupsLoaded = !_.isEmpty(groups);
    const isUsersLoaded = !_.isEmpty(users);

    if (isOrganizationsLoaded) {
      // debug('Organizations: %j', organizations);
      debug('Loaded Organizations');
    }

    if (isUsersLoaded) {
      // debug('Users: %j', users);
      debug('Loaded Users');
    }

    if (isGroupsLoaded) {
      // debug('Groups: %j', groups);
      debug('Loaded Groups');
    }

    // This isn't the prettiest, but it addresses the issue where there are no messages
    let isMessagesLoaded = false;

    if (isGroupsLoaded) {
      // This is possible because messages to fetch are declared in the groups object
      // This is the same function as passed to withChirpFetching's idsToFetch
      if (_.isEmpty(messageIdsToFetch({ groups }))) {
        // There are no messages to fetch
        debug('There are no messages to fetch');
        isMessagesLoaded = true;
      } else {
        // Finally, messages will be non-empty only after all messages are fetched
        debug('Waiting for messages to populate');
        isMessagesLoaded = !_.isEmpty(messages);
      }
    }

    if (isMessagesLoaded) {
      // debug('Messages: %j', messages);
      debug('Loaded Messages');
    }

    const isLoaded = isOrganizationsLoaded && isGroupsLoaded && isMessagesLoaded && isUsersLoaded;
    debug('withBufferPropsUntilLoaded: Loaded: %s', isLoaded);
    return isLoaded;
  }, ChirpContextProviderForApp),
);

const ChirpContextFetcherWithGuard = ({ isInitialLoaded, children }) => {
  const { idTokenHasBeenSet, pcLoading } = usePatientContext();
  debug('ChirpContextFetcherWithGuard idTokenHasBeenSet: %s', idTokenHasBeenSet);
  debug('ChirpContextFetcherWithGuard pcLoading: %s', pcLoading);
  if (idTokenHasBeenSet && !pcLoading) {
    debug('🏁 Rendering ChirpContextProviderWithDataFetching');
    return (
      <ChirpContextProviderWithDataFetching isInitialLoaded={isInitialLoaded}>
        {children}
      </ChirpContextProviderWithDataFetching>
    );
  } else {
    return children;
  }
};

export default ChirpContextFetcherWithGuard;
