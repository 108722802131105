import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import UnreadMessageAlertSmall from '../icons/UnreadMessageAlertSmall';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const useStyles = makeStyles((theme) => ({
  groupCardWrapper: {
    backgroundColor: theme.palette.white,
    cursor: 'pointer',
  },
  redDotWrapper: {
    position: 'relative',
    bottom: '3px',
  },
}));

function GroupCard(props) {
  const { title, bodyText, Icon, groupId, setCurrentlySelectedGroup } = props;
  const classes = useStyles();
  const { groupIdsWithUnreads } = useChirpContext();
  const [unreadMessagesPresent, setUnreadMessagesPresent] = useState(false);

  const isDesktop = useIsDesktop();

  const handleClick = () => {
    setCurrentlySelectedGroup(groupId);
  };

  useEffect(() => {
    setUnreadMessagesPresent(groupIdsWithUnreads.indexOf(groupId) > -1);
  }, [groupId, groupIdsWithUnreads]);

  return (
    <>
      {isDesktop && (
        <Box
          onClick={handleClick}
          marginBottom={2}
          display="flex"
          className={classes.groupCardWrapper}
          data-testid={`groupCardWrapper-${title.replace(/\s/g, '')}`}
          maxWidth="800px"
          borderRadius={3}
          style={{
            filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))',
          }}
        >
          <Box margin={4} data-testid={`groupCardIconWrapper-${title.replace(/\s/g, '')}`}>
            <Icon />
          </Box>
          <Box marginTop={4} marginBottom={4} width="100%">
            <Box display="flex" flexDirection="row" width="100%">
              <Box marginRight={2}>
                <TranslatedTypography
                  variant="h2"
                  data-test-id={`groupCardTitle-${title.replace(/\s/g, '')}`}
                >
                  {title}
                </TranslatedTypography>
              </Box>
              {unreadMessagesPresent && (
                <Box marginLeft="auto" marginRight={4} display="flex">
                  <Box
                    marginRight={1}
                    className={classes.redDotWrapper}
                    data-test-id={`unreadMessageAlertWrapper-${title.replace(/\s/g, '')}`}
                  >
                    <UnreadMessageAlertSmall />
                  </Box>
                  <TranslatedTypography variant={'h3'}>New Activity</TranslatedTypography>
                </Box>
              )}
            </Box>
            <Box marginRight={4}>
              <TranslatedTypography data-test-id={`groupCardText-${title.replace(/\s/g, '')}`}>
                {bodyText}
              </TranslatedTypography>
            </Box>
          </Box>
        </Box>
      )}
      {!isDesktop && (
        <Box
          onClick={handleClick}
          data-testid={`groupCardWrapper-${title.replace(/\s/g, '')}`}
          className={classes.groupCardWrapper}
          marginTop={1.5}
          marginRight={2}
          marginLeft={2}
          borderRadius={3}
          style={{
            filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))',
          }}
        >
          <Box
            data-test-id={`groupCardIconWrapper-${title.replace(/\s/g, '')}`}
            display="flex"
            marginLeft={5}
            marginRight={5}
            marginTop={4}
            marginBottom={2}
          >
            <Icon />
            <Box alignSelf={'center'} marginLeft={3}>
              <TranslatedTypography
                variant="h2"
                data-test-id={`groupCardTitle-${title.replace(/\s/g, '')}`}
              >
                {title}
              </TranslatedTypography>
            </Box>
          </Box>
          <Box>
            <Box marginRight={4} marginLeft={4} marginBottom={2.5}>
              <TranslatedTypography data-test-id={`groupCardText-${title.replace(/\s/g, '')}`}>
                {bodyText}
              </TranslatedTypography>
            </Box>
          </Box>
          {unreadMessagesPresent && (
            <Box display="flex" marginLeft={4} marginBottom={3}>
              <Box
                marginRight={1}
                className={classes.redDotWrapper}
                data-test-id={`unreadMessageAlertWrapper-${title.replace(/\s/g, '')}`}
              >
                <UnreadMessageAlertSmall />
              </Box>
              <TranslatedTypography variant={'h3'}>New Activity</TranslatedTypography>
            </Box>
          )}
          <Box marginBottom={1.5} />
        </Box>
      )}
    </>
  );
}

export default GroupCard;
