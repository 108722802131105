// All roles that exist in CricketRole table
const Roles = {
  ADMIN: 'ADMIN',
  RESTRICTED_ADMIN: 'RESTRICTED_ADMIN',
  RESTRICTED_USER: 'RESTRICTED_USER',
  CUSTOMER_SUPPORT_SPECIALIST: 'CUSTOMER_SUPPORT_SPECIALIST',
  DIETITIAN: 'DIETITIAN',
  ENROLLMENT_SERVICE_ACCOUNT: 'ENROLLMENT_SERVICE_ACCOUNT',
  MENTOR: 'MENTOR',
  NURSE: 'NURSE',
  OPERATIONS_ASSOCIATE: 'OPERATIONS_ASSOCIATE',
  PATIENT: 'PATIENT',
  PHARMACIST: 'PHARMACIST',
  PHARMACY_NAVIGATOR: 'PHARMACY_NAVIGATOR',
  PHYSICIAN: 'PHYSICIAN',
  SCHEDULER_SERVICE_ACCOUNT: 'SCHEDULER_SERVICE_ACCOUNT',
  SOCIAL_WORKER: 'SOCIAL_WORKER',
  ENGAGEMENT_SPECIALIST: 'ENGAGEMENT_SPECIALIST',
};

const patientRoles = Object.freeze([Roles.PATIENT]);

const proRoles = Object.freeze([
  Roles.DIETITIAN,
  Roles.NURSE,
  Roles.PHARMACIST,
  Roles.PHARMACY_NAVIGATOR,
  Roles.PHYSICIAN,
  Roles.SOCIAL_WORKER,
]);

const opsRoles = Object.freeze([
  Roles.CUSTOMER_SUPPORT_SPECIALIST,
  Roles.OPERATIONS_ASSOCIATE,
  Roles.ENGAGEMENT_SPECIALIST,
]);

const roleToDisplayName = {
  [Roles.CUSTOMER_SUPPORT_SPECIALIST]: 'Support Specialist',
  [Roles.DIETITIAN]: 'Dietitian',
  [Roles.ENGAGEMENT_SPECIALIST]: 'Engagement Specialist',
  [Roles.MENTOR]: 'Mentor',
  [Roles.NURSE]: 'Nurse',
  [Roles.OPERATIONS_ASSOCIATE]: 'Operations Associate',
  [Roles.PHARMACIST]: 'Pharmacist',
  [Roles.PHARMACY_NAVIGATOR]: 'Navigator',
  [Roles.PHYSICIAN]: 'Physician',
  [Roles.SOCIAL_WORKER]: 'Social Worker',
};
const useRoleDisplayName = (role) => {
  // || provider is a paranoid safeguard
  return roleToDisplayName[role] || role.replace(/_/g, ' ') || 'Contributor';
};
const careTeamRoles = [
  'DIETITIAN',
  'MENTOR',
  'NURSE',
  'PHARMACIST',
  'PHARMACY_NAVIGATOR',
  'SOCIAL_WORKER',
];
const getRoleAbbreviation = (role) => {
  switch (role) {
    case 'DIETITIAN':
      return 'RD';
    case 'NURSE':
      return 'RN';
    case 'SOCIAL_WORKER':
      return 'SW';
    case 'PHARMACIST':
      return 'RX';
    case 'OPERATIONS_ASSOCIATE':
      return 'OA';
    case 'CUSTOMER_SUPPORT_SPECIALIST':
      return 'CSS';
    case 'PHYSICIAN':
      return 'PHYS';
    case 'PHARMACY_NAVIGATOR':
      return 'NAV';
    case 'ENGAGEMENT_SPECIALIST':
      return 'ES';
  }
  throw new Error('Unexpected role key');
};

module.exports = {
  Roles,
  ProRoles: proRoles,
  OpsRoles: opsRoles,
  isPatientRole: (roleName = '') => patientRoles.includes(roleName.toUpperCase()),
  isProRole: (roleName) => proRoles.includes(roleName.toUpperCase()),
  isOpsRole: (roleName) => opsRoles.includes(roleName.toUpperCase()),
  isHumanRole: (roleName) =>
    patientRoles.includes(roleName.toUpperCase()) ||
    proRoles.includes(roleName.toUpperCase()) ||
    opsRoles.includes(roleName.toUpperCase()),
  isCareTeamRole: (roleName) => careTeamRoles.includes(roleName.toUpperCase()),
  isEnrollmentServiceRole: (roleName) => Roles.ENROLLMENT_SERVICE_ACCOUNT === roleName,
  isSchedulerServiceRole: (roleName) => Roles.SCHEDULER_SERVICE_ACCOUNT === roleName,
  isPhysicianRole: (roleName) => roleName === Roles.PHYSICIAN,
  isMentorRole: (roleName) => roleName === Roles.MENTOR,
  roleToDisplayName,
  careTeamRoles,
  getRoleAbbreviation,
  useRoleDisplayName,
  ...Roles,
};
