import React, { forwardRef } from 'react';
import { Box } from '@mui/material';

export const ScrollBox = forwardRef(({ disableScroll, children, ...rest }, ref) => {
  return (
    <Box
      ref={ref}
      overflow={disableScroll ? 'hidden' : 'scroll'}
      style={{ WebkitOverflowScrolling: 'touch' }}
      tabIndex="0"
      {...rest}
    >
      {children}
    </Box>
  );
});
