import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { InsertDriveFile, Image } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { Trans } from 'react-i18next';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import MyCricketColors from '../../data/MyCricketColors';
import { ScrollBox } from '../atoms/ScrollBox';
import { useCareTeamContext } from '../pages/CareTeam';
import CareTeamCloseButton from '../molecules/CareTeamCloseButton';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { isImageFile, fetchFile } from '../../utils/fileUtils';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';

const CareTeamFileHistory = () => {
  const { selectedCareTeamMember, triggerChatToShow, cachedFileResults, setCachedFileResults } =
    useCareTeamContext();
  const { logFEOnlyEvent } = useClientEventLoggerContext();
  const { cricketUserId, nickName } = selectedCareTeamMember;

  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    logFEOnlyEvent({ eventName: 'viewedFileHistory' });
  }, [logFEOnlyEvent]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setLoading(true);
        const res = await myCricketAuthedFetch(
          `/i/admin/patient-file/history?chatPartnerUserId=${cricketUserId}`,
        );
        const sortedFiles =
          res.files?.sort((a, b) => {
            const dateA = a.lastUpdated ? parseISO(a.lastUpdated).getTime() : 0;
            const dateB = b.lastUpdated ? parseISO(b.lastUpdated).getTime() : 0;
            return dateB - dateA;
          }) || [];
        setFiles(sortedFiles);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFiles();
  }, [cricketUserId]);

  const downloadAndViewFile = useCallback(
    async (fileId) => {
      if (cachedFileResults[fileId]?.url) {
        window.open(cachedFileResults[fileId]?.url, '_blank');
        return;
      }
      try {
        setLoading(true);
        const url = await fetchFile(fileId);
        setCachedFileResults((prev) => ({ ...prev, [fileId]: { url } }));
        window.open(url, '_blank');
      } catch (error) {
        setCachedFileResults((prev) => ({ ...prev, [fileId]: { error } }));
      } finally {
        setLoading(false);
      }
    },
    [cachedFileResults, setCachedFileResults],
  );

  return (
    <ScrollBox
      width={{ xs: '100%', sm: '100%', md: '30%' }}
      minHeight={'100%'}
      maxHeight={'100%'}
      bgcolor={MyCricketColors.white}
      ml={{ xs: 0, sm: 0, md: '1px' }}
      borderRadius={{ xs: 0, sm: 0, md: '0 12px 12px 0' }}
      display={'flex'}
      flexDirection={'column'}
      pb={{ xs: 0, sm: 0, md: 4 }}
    >
      <CareTeamCloseButton
        title={'Close'}
        onClick={() => triggerChatToShow()}
        ariaLabel={'Close file history'}
      />
      <Box px={4}>
        <Box mb={2} textAlign={'center'}>
          <Typography variant={'h2'}>
            <Trans
              i18nKey={'filesSharedWithUser'}
              defaults={`Shared with ${nickName}`}
              values={{ user: nickName }}
            />
          </Typography>
        </Box>
        <MyCricketLoadingContainer loading={isLoading}>
          <>
            {files && files.length === 0 ? (
              <Box pt={1} textAlign={'center'}>
                <TranslatedTypography i18nKey={'noFilesPlaceholder'} variant={'body1'}>
                  No files yet!
                </TranslatedTypography>
              </Box>
            ) : (
              <>
                {files.map((file, index) => (
                  <Box
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => downloadAndViewFile(file.id)}
                  >
                    <Box display={'flex'} alignItems={'center'} pt={1}>
                      <Box component={'span'} display={'flex'} alignItems={'center'} mr={1.5}>
                        {isImageFile(file.contentType) ? <Image /> : <InsertDriveFile />}
                      </Box>
                      <Box
                        component={'span'}
                        color={'blue'}
                        style={{ textDecoration: 'underline' }}
                      >
                        <Typography variant={'body1'}>{file.name}</Typography>
                      </Box>
                    </Box>
                    <Box pt={1} pb={1}>
                      <Typography variant={'h4'}>
                        {format(parseISO(file.lastUpdated), 'PPPPp')}
                      </Typography>
                    </Box>
                    <hr height={'1px'} color={MyCricketColors.lightGrayHaze} />
                  </Box>
                ))}
              </>
            )}
          </>
        </MyCricketLoadingContainer>
      </Box>
    </ScrollBox>
  );
};

export default CareTeamFileHistory;
