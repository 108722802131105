import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import Debug from 'debug';

const debug = Debug('MyCricket:Sentry');
let sentryActive = false;
let userSet = false;

function setUp(history) {
  if (sentryActive || !__ENV__.MY_CRICKET_SENTRY_DSN) {
    return;
  }
  Sentry.init({
    dsn: __ENV__.MY_CRICKET_SENTRY_DSN,
    release: __ENV__.GIT_COMMIT_HASH,
    environment: __ENV__.RUNTIME_ENV,
    tracesSampleRate: 1.0,
    debug: false,
    beforeBreadcrumb: function (crumb) {
      if (crumb.type === 'http') {
        if (crumb.data.url.includes('socket.io')) {
          const url = new URL(crumb.data.url);
          crumb.data.url = url.origin + url.pathname;
        }
      }

      // Filter out i18n library warnings
      if (
        crumb.level === 'warning' &&
        crumb.message &&
        (crumb.message.startsWith('i18next::') || crumb.message.startsWith('i18n-missingkey'))
      ) {
        return null;
      }

      return crumb;
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // Chromium
      'ResizeObserver loop completed with undelivered notifications.', // Firefox
      'Object Not Found Matching Id', // Outlook Safe Links
    ],
    integrations: [
      new BrowserTracing({
        beforeNavigate: (context) => {
          const urlParams = new URLSearchParams(window.location.search);
          // https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/
          return {
            ...context,
            name: location.pathname.replace(/\/\d+/g, '/<id>'),
            urlParams,
          };
        },
        tracingOrigins: ['api.dev.crickethealth.com', 'api.crickethealth.com'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      // This one might be too noisy and some log levels may need to be disabled in the future.
      // For now, it'd help to understand what users are experiencing (endless loading issue).
      new CaptureConsoleIntegration({
        // Array of methods that should be captured
        // Defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
      new ExtraErrorDataIntegration({
        // Limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 3,
      }),
      new OfflineIntegration({
        // Limit how many events will be locally saved. Defaults to 30.
        maxStoredEvents: 30,
      }),
    ],
  });
  debug('Ready');
  sentryActive = true;
}

function setUser(id, role) {
  if (id && role && sentryActive && !userSet) {
    userSet = true;
    Sentry.setUser({ id, role });
    debug('Set user (%s) [%s]', id, role);
  }
}

function logout() {
  if (sentryActive && userSet) {
    userSet = false;
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

export default {
  setUp,
  logout,
  setUser,
};
