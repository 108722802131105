import { Box, Typography } from '@mui/material';
import React from 'react';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const CareTeamBioMyJourney = ({ myJourney }) => {
  return (
    <Box
      pt={4}
      pl={3}
      pr={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      width={'100%'}
    >
      <TranslatedTypography variant="subtitle1">My Journey</TranslatedTypography>
      <Box pt={2} width={'100%'}>
        <Typography variant="body2">{myJourney}</Typography>
      </Box>
    </Box>
  );
};
