export default {
  black: '#141414',
  brightMint: '#86E3CE',
  cricketMidnightBlue: '#0f54b6',
  darkTeal: '#003E51',
  disabledGray: '#A6A6A6',
  hoverMint: '#5DCBB1',
  kellyGreen: '#248848',
  lake: '#239DBC',
  lightGrayFog: '#E8EFF4',
  lightGrayHaze: '#DFE6ED',
  lightGrayMist: '#F6F9FC',
  lightGraySmoke: '#D3DBE5',
  mediumGray: '#57686C',
  mediumTeal: '#1A6479',
  mint: '#41C2A3',
  neonGreen: '#A9D15D',
  notificationRed: '#D74C34',
  orangeCitrus: '#ECA934',
  paleBlue: '#D1EAF1',
  paleTeal: '#E6ECEE',
  pressedMint: '#3AA88E',
  skyBlue: '#A4D7E4',
  teal: '#1A6479',
  tealMain: '#2C7D93',
  royalBlue: '#2288D2',
  tealGray: '#809FA8',
  skyTeal: '#2396BC',
  white: '#FFFFFF',
};

export const withAlpha = (color, alpha) => {
  const alphaString = Math.round(alpha * 255)
    .toString(16)
    .toUpperCase();
  return color + alphaString;
};
