import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import MyCricketColors from '../../data/MyCricketColors';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { CareTeamSidebarCard } from '../molecules/CareTeamSidebarCard';
import LegalDocs from '../molecules/LegalDocs';
import HorizontalDividerLine from '../atoms/HorizontalDivider';
import { MultipartyGroupTypes } from '../../data/groupTypeNames';

export const isSidebarGroup = (group) => {
  return (
    !group.groupIsHidden &&
    group.members &&
    group.members.length === 2 &&
    !Object.values(MultipartyGroupTypes).includes(group.type)
  );
};

export const CareTeamSidebar = () => {
  const { directMessageGroups } = useChirpContext();
  const [groupsToDisplay, setGroupsToDisplay] = useState([]);
  useEffect(() => {
    setGroupsToDisplay(Object.values(directMessageGroups).filter(isSidebarGroup));
  }, [directMessageGroups]);

  return (
    <Box
      width={{ xs: '100%', sm: '100%', md: '30%' }}
      minHeight={'100%'}
      maxHeight={'100%'}
      maxWidth={{ xs: '100vw', sm: '100vw', md: '400px' }}
      role="navigation"
    >
      <Box
        role="grid"
        id={'SidebarFrame'}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width={'100%'}
        height={'100%'}
        p={1.5}
        mr={'1px'}
        bgcolor={MyCricketColors.white}
        borderRadius={{ xs: '0', sm: '0', md: '12px 0px 0px 12px' }}
      >
        <Box width={'100%'} p={2} position={'relative'} role="row">
          <Box role="columnheader">
            <TranslatedTypography variant={'subtitle1'}>Care Team Chat</TranslatedTypography>
          </Box>
        </Box>
        <Box flex={1} width={'100%'} position={'relative'}>
          {groupsToDisplay.length === 0 ? null : (
            <Virtuoso
              // initialItemCount is used when performing SSR (such as in tests).
              // Because the data model does not initially populate directMessageGroups
              // we delay rendering until it is populated so that Virtuoso will render
              // the expected initialItemCount as opposed to 0, which it would otherwise
              // be on first render.
              initialItemCount={groupsToDisplay.length < 20 ? groupsToDisplay.length : 20}
              totalCount={groupsToDisplay.length}
              itemContent={(index) => (
                <Box role="row">
                  <CareTeamSidebarCard index={index} group={groupsToDisplay[index]} />
                </Box>
              )}
            />
          )}
        </Box>
        <Box width={'100%'} position={'relative'}>
          <HorizontalDividerLine />
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
            <LegalDocs privacyAndTOSOnly />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CareTeamSidebar;
