import { Box, Typography } from '@mui/material';
import React from 'react';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { CareTeamBioCredentials } from './CareTeamBioCredentials';
import CareTeamBioPersonalPics from './CareTeamBioPersonalPics';
import { CareTeamBioEducation } from './CareTeamBioEducation';

const CareTeamBioExperience = ({ picSrc, experience, credentials, education, picAlt }) => {
  return (
    <Box pt={4} pl={3} pr={4} display="flex" flexDirection="column" width={'100%'}>
      {experience && (
        <TranslatedTypography pt={2} variant="subtitle1" aria-level="2">
          Experience
        </TranslatedTypography>
      )}
      {picSrc && <CareTeamBioPersonalPics picSrc={picSrc} picAlt={picAlt} />}
      {experience && (
        <Box pt={2} width={'100%'}>
          <Typography pt={2} variant="body2">
            {experience}
          </Typography>
        </Box>
      )}
      {credentials && credentials.length > 0 && (
        <CareTeamBioCredentials credentials={credentials} />
      )}
      {education && education.length > 0 && <CareTeamBioEducation education={education} />}
    </Box>
  );
};
export default CareTeamBioExperience;
