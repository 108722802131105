import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

const UnreadMessageAlertSmall = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="12"
        height="12"
        rx="8"
        transform="matrix(-1 0 0 1 12 0)"
        fill={MyCricketColors.notificationRed}
      />
    </svg>
  );
};

export default UnreadMessageAlertSmall;
