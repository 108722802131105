import { Box, Checkbox, FormControlLabel, FormGroup, styled } from '@mui/material';
import clsx from 'clsx';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Check from '../icons/CheckMark';
import MyCricketColors from '../../data/MyCricketColors';
import { paths } from '../../utils/routing/routes';
import { useTealCheckBoxStyles } from '../../utils/StylingUtils';
import { BaseButton } from '../atoms/Buttons';
import HorizontalDividerLine from '../atoms/HorizontalDivider';
import { BackNav } from '../atoms/BackNav';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import URIUtils from '../../utils/URIUtils.js';
import { PatientEvent } from '../../data/Events';
import { useForceErrorThrow } from '../../utils/PatientErrorBoundary';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { toast } from 'react-toastify';
import Toast from '../atoms/Toast';
import { GroupStatus } from '../../data/Subscriptions';
import { useTranslation } from 'react-i18next';

const Container = styled('div')({
  maxWidth: 'calc(640px + 36px)',
  margin: 'auto',
});

const Alignment = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(4),
  alignItems: 'flex-start',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const ButtonRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}));

const EmailPreferences = () => {
  const [emailPreferences, setEmailPreferences] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { logEvent } = useClientEventLoggerContext();
  const forceThrowError = useForceErrorThrow();

  const { t } = useTranslation();

  const fetchPreferences = async () => {
    return await myCricketAuthedFetch('/i/my-account/preferences');
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPreferences();
      result && result.data && setEmailPreferences(result.data);
    };
    fetchData().catch((e) => {
      forceThrowError(e);
    });
  }, [forceThrowError]);

  const handleChange = (target) => {
    const brazeId = target.name;
    const value = target.checked;
    const stagedPreferences = [...emailPreferences];
    const targetedIndex = stagedPreferences.findIndex((pref) => pref.id === brazeId);
    if (targetedIndex >= 0) {
      stagedPreferences[targetedIndex] = {
        ...stagedPreferences[targetedIndex],
        status: value ? GroupStatus.SUBSCRIBED : GroupStatus.UNSUBSCRIBED,
      };
      setEmailPreferences(stagedPreferences);
      setHasChanges(true);
    }
  };

  const optOutOfAllEmails = () => {
    const stagedPreferences = [...emailPreferences];
    stagedPreferences.map((pref) => (pref.status = GroupStatus.UNSUBSCRIBED));
    setEmailPreferences(stagedPreferences);
    setHasChanges(true);
  };

  const submitPreferenceUpdate = async () => {
    setLoading(true);
    try {
      const data = await myCricketAuthedFetch('/i/my-account/preferences', {
        method: 'POST',
        body: JSON.stringify({ data: emailPreferences }),
      });
      if (data.success) {
        logEvent({
          predicate: PatientEvent.UPDATE_EMAIL_PREFERENCES,
          object: URIUtils.toMyCricketPageURI('EmailPreferences'),
          prepositions: {
            updates: emailPreferences,
          },
        });
        setHasChanges(false);
        setLoading(false);
        toast.success(
          () => <Toast icon={'success'} message={t('Your preferences have been saved')} />,
          {
            toastId: 'email-preferences-saved',
            autoClose: 5000,
          },
        );
      } else {
        setLoading(false);
        toast.error(
          () => (
            <Toast message={t('There was an error updating your preference, please try again')} />
          ),
          {
            toastId: 'email-preferences-error',
            autoClose: 5000,
          },
        );
      }
    } catch {
      setLoading(false);
      toast.error(
        () => (
          <Toast message={t('There was an error updating your preference, please try again')} />
        ),
        {
          toastId: 'email-preferences-error',
          autoClose: 5000,
        },
      );
    }
  };

  // Consider DRY-ing this up next time we need a checkbox in MyCricket...
  const StyledCheckBox = ({ pref }) => {
    const classes = useTealCheckBoxStyles();
    return (
      <Checkbox
        color="default"
        checked={pref.status === GroupStatus.SUBSCRIBED}
        data-test-id={'checkBox' + pref.id}
        onChange={(e) => handleChange(e.target)}
        name={pref.id}
        icon={<span className={classes.icon} />}
        checkedIcon={
          <Box
            className={clsx(classes.icon, classes.checkedIcon)}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Check fill={MyCricketColors.mediumTeal} />
            </Box>
          </Box>
        }
      />
    );
  };

  return (
    <Container>
      <Alignment>
        <BackNav history={history} link={paths.me()} />
        <Box p={6} bgcolor={MyCricketColors.white} borderRadius="6px">
          <TranslatedTypography variant="h2">Email preferences</TranslatedTypography>
          <TranslatedTypography variant="body2">
            Please select which topics you’d like to receive email notifications about going forward
          </TranslatedTypography>

          <MyCricketLoadingContainer loading={loading}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <form onSubmit={() => submitPreferenceUpdate()} style={{ width: '100%' }}>
                <FormGroup>
                  {emailPreferences.map((pref) => (
                    <FormControlLabel
                      data-test-id={pref.id}
                      key={pref.id}
                      label={pref.name}
                      control={<StyledCheckBox pref={pref} />}
                    />
                  ))}
                  <Box display="flex" flexDirection="row" justifyContent="center" mt={4}>
                    <TranslatedTypography
                      onClick={() => optOutOfAllEmails()}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      Opt out of all emails
                    </TranslatedTypography>
                  </Box>
                  <HorizontalDividerLine />
                  <ButtonRow>
                    <Box mr={3}>
                      <BaseButton onClick={() => history.push(paths.me())} variant="outlined">
                        Cancel
                      </BaseButton>
                    </Box>
                    <BaseButton
                      data-test-id={'theSubmitButton'}
                      type="submit"
                      disabled={!hasChanges}
                      style={{ minWidth: '105px' }}
                    >
                      Save
                    </BaseButton>
                  </ButtonRow>
                </FormGroup>
              </form>
            </Box>
          </MyCricketLoadingContainer>
        </Box>
      </Alignment>
    </Container>
  );
};

export default EmailPreferences;
