import React, { useEffect } from 'react';
import PatientErrorBoundary from '../../utils/PatientErrorBoundary';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import URIUtils from '../../utils/URIUtils';
import { PatientEvent } from '../../data/Events';
import { useLocation } from 'react-router';

const PageWithoutHeader = ({ title, children }) => {
  const { logEvent } = useClientEventLoggerContext();
  const location = useLocation();
  useEffect(() => {
    document.title = title ? `${title} | MyCricket` : 'MyCricket';
    logEvent({
      predicate: PatientEvent.VIEW_PAGE,
      object: URIUtils.toMyCricketPageURI(`${location.pathname}`.replace(/\s/g, '')),
      prepositions: {
        page: URIUtils.toMyCricketPageURI(location.pathname),
        referrer: `${location.state?.referrer ?? ''} `,
      },
    });
  }, [location.pathname, location.state?.referrer, logEvent, title]);
  return <PatientErrorBoundary>{children}</PatientErrorBoundary>;
};

export default PageWithoutHeader;
