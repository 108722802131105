import EventEmitter from 'events';
import React from 'react';
import * as io from 'socket.io-client';
import logEvent from './logEvent';
import { logClientError } from './errorLogging';
import eventCreators from './chirp/Events';
import { v4 as uuidv4 } from 'uuid';
import Debug from 'debug';
const debug = Debug('MyCricket:chirp');

// These are documented https://socket.io/docs/v4/client-api/#event-connect
export const connectionStates = Object.freeze({
  Connect: 'connect',
  Reconnect: 'reconnect',
  ReconnectAttempt: 'reconnect_attempt',
  ReconnectError: 'reconnect_error',
  ReconnectFailed: 'reconnect_failed',
  ConnectionError: 'connect_error',
  Provision: 'provision',
});

export default class Chirp {
  constructor(cricketUserId) {
    this.uuid = uuidv4();
    debug('💬 new Chirp(%d) [%s]', cricketUserId, this.uuid);
    // The internal event bus for consumers to listen for refetch events
    // Use Chirp.on() and Chirp.off() to add callbacks.
    // These event listeners should persist between different socket
    // connections.
    this._events = new EventEmitter();
    // this._events.setMaxListeners(Infinity);
    this._authenticated = false;
    this.cricketUserId = cricketUserId || false;
    this.state = Object.freeze({
      currentState: 'unknown',
      lastReportedChirpState: '',
      stateMsg: undefined,
    });
    this.connect();
  }

  updateState = (newState, cb) => {
    const { state } = this;
    this.state = Object.freeze({ ...state, ...newState });
    if (cb && typeof cb === 'function') {
      cb(this.state);
    }
  };

  logChirpState = async (state) => {
    if (!this.cricketUserId) {
      return;
    }
    const { lastReportedChirpState, currentState, stateMsg } = state;
    if (lastReportedChirpState === currentState) {
      return;
    }
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await logEvent(
        this.cricketUserId,
        {
          object: window.location.toString(),
          predicate: 'clientChirpConnectionEvent',
          prepositions: {
            state: currentState,
            stateMsg,
          },
        },
        signal,
        true,
      );
      this.updateState({ lastReportedChirpState: currentState, stateMsg: undefined });
    } catch (e) {
      console.error(e);
      // Non-issue: Means the API is most likely down.
      // Might add sentry here if we need more data
    }
  };

  connect() {
    debug('Chirp.connect() [%s]', this.uuid);
    if (this._socket) {
      debug('Chirp.connect() [%s]: connection exists, disconnecting...', this.uuid);
      this._socket.disconnect();
    }

    this.token = window.localStorage.getItem('idToken');

    if (!this.token) {
      debug('Chirp.connect() [%s]: no token exists, taking no action', this.uuid);
    } else {
      const authMessage = {
        type: 'authenticate',
        token: this.token,
      };

      this._socket = io.connect(window._cc.chirp.url, {
        auth: { authMessage: JSON.stringify(authMessage) },
      });

      this._socket.on('connect', () => {
        debug('Chirp [%s]: [socket] connect', this.uuid);
        /*
        This won't work on the same page that is making the changes — it is really a way for other
        pages on the domain using the storage to sync any changes that are made. Pages on other
        domains can't access the same storage objects.
         */
        window.addEventListener('storage', this.updateToken.bind(this));
        this._events.emit('connect');
        this._authenticated = true;
        console.info(`Successfully connected to Chirp websocket`);
        this.updateState(
          {
            currentState: 'connected',
          },
          this.logChirpState,
        );
      });

      this._socket.on('error', (err) => {
        debug('Chirp [%s]: [socket] error', this.uuid);
        if (err === 'TokenExpiredError: jwt expired') {
          setTimeout(this.connect.bind(this), 5 * 1000);
        } else {
          this.updateState(
            {
              currentState: 'error',
              stateMsg: err,
            },
            this.logChirpState,
          );
          logClientError(err);
        }
      });

      this._socket.on('disconnect', (reason) => {
        debug('Chirp [%s]: [socket] disconnect', this.uuid);
        window.removeEventListener('storage', this.updateToken.bind(this));
        this._authenticated = false;
        this._events.emit('disconnect');
        // These reasons need manual reconnects
        console.error(`disconnected with reason=${reason}`);
        this.updateState(
          {
            currentState: 'disconnected',
            stateMsg: reason,
          },
          this.logChirpState,
        );
        if (reason === 'io server disconnect') {
          setTimeout(this.connect.bind(this), 1 * 1000);
        }
      });

      this._socket.on('event', (message) => {
        debug('Chirp [%s]: [socket] event: %j', this.uuid, message);
        if (message.type === 'cricket:client:chirp:refetch') {
          this._refetchHandler(message.data);
        }
        //TODO find type text message, add type handling, and keep going
        this._events.emit('event', message);
      });

      // Allow chirp consumers to subscribe to other events and add
      // additional callbacks that react to event emitter
      ['connect_error', 'provision'].forEach((event) => {
        this._socket.on(event, (message) => {
          debug('Chirp [%s]: [socket] %s', this.uuid, event);
          this.updateState({ currentState: event, stateMsg: message }, this.logChirpState);
          this._events.emit(event, message);
        });
      });

      // The manager emits separate events
      ['reconnect', 'reconnect_attempt', 'reconnect_error', 'reconnect_failed'].forEach((event) => {
        this._socket.io.on(event, (message) => {
          debug('Chirp [%s]: [socket] %s', this.uuid, event);
          this.updateState({ currentState: event, stateMsg: message }, this.logChirpState);
          this._events.emit(event, message);
        });
      });
    }
  }

  disconnect() {
    if (this._socket) {
      this._socket.disconnect();
    } else {
      debug(
        'Chirp.disconnect() [%s]: connection does not exist, so we can not disconnect',
        this.uuid,
      );
    }
  }

  authenticated() {
    return this._authenticated;
  }

  on(event, callback) {
    this._events.on(event, callback);
  }

  off(event, callback) {
    this._events.removeListener(event, callback);
  }

  _refetchHandler = (data) => {
    this._events.emit('refetch', data);
  };

  setGroupMessagesAsRead(groupId, groupType, unreadPosts, unreadReplies) {
    const event = eventCreators.readGroupMessages(groupId, groupType, unreadPosts, unreadReplies);
    this._socket.emit('event', event);
  }

  hideMessage(message) {
    const event = eventCreators.hideMessage(message);
    this._socket.emit('event', event);
  }

  setMessageAsRead(message) {
    const event = eventCreators.readMessage(message);
    this._socket.emit('event', event);
  }

  setMessageAsUnread(message) {
    const event = eventCreators.unreadMessage(message);
    this._socket.emit('event', event);
  }

  likeMessage(message) {
    const event = eventCreators.likeMessage(message);
    this._socket.emit('event', event);
  }

  unlikeMessage(message) {
    const event = eventCreators.unlikeMessage(message);
    this._socket.emit('event', event);
  }

  openPublicProfile(profileUserId) {
    const event = eventCreators.openPublicProfile(profileUserId);
    this._socket.emit('event', event);
  }

  updateToken() {
    const localStorageToken = window.localStorage.getItem('idToken');
    if (this.token !== localStorageToken && localStorageToken) {
      this.token = localStorageToken;
      if (this._socket) {
        this._socket.emit('update_token', this.token);
      }
    }
  }

  async sendMessage(message) {
    debug('Chirp.sendMessage() [%s]', this.uuid);
    //creates the db event for analytics
    const event = eventCreators.sendMessage(message);
    //actually sends a message via chirp
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('sendMessage timed out')), 10 * 1000);
      this._socket.emit('event', event, (response) => {
        debug('Chirp.sendMessage() [%s]: Response received', this.uuid);
        clearTimeout(timeoutId);
        if (response.ok) {
          resolve();
          // TODO Pretty sure the below isn't used in MyCricket
          if (message.callback) {
            debug('Chirp.sendMessage() [%s]: Calling message.callback', this.uuid);
            message.callback();
          }
        } else {
          logClientError(response.err);
          reject(response.err);
        }
      });
    });
  }

  async setHideFromPro(noteId, hideFromPro) {
    const event = eventCreators.setHideFromPro(noteId, hideFromPro);
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('setHideFromPro timed out')), 10 * 1000);
      this._socket.emit('event', event, (response) => {
        clearTimeout(timeoutId);
        if (response.ok) {
          resolve();
        } else {
          logClientError(response.err);
          reject(response.err);
        }
      });
    });
  }

  async hideNote(noteId, reason) {
    const event = eventCreators.hideNote(noteId, reason);
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('hideNote timed out')), 10 * 1000);
      this._socket.emit('event', event, (response) => {
        clearTimeout(timeoutId);
        if (response.ok) {
          resolve();
        } else {
          logClientError(response.err);
          reject(response.err);
        }
      });
    });
  }

  // Reserved for patient application
  /**
   * @deprecated
   * @param {string} field
   * @param {number[]} ids
   * @param {function} callback
   */
  fetch(field, ids, callback) {
    const event = eventCreators.fetch(field, ids);
    this._socket.emit('fetch', event, (res) => {
      if (res.ok && callback) {
        callback(res.data[field]);
      }
    });
  }
  /**
   * @param {string} field
   * @param {number[]} ids
   */
  fetchPromise(field, ids) {
    return new Promise((resolve, reject) => {
      this._socket.emit('fetch', eventCreators.fetch(field, ids), (res) => {
        if (res.ok) {
          resolve(res.data[field]);
        } else {
          reject(new Error('Chirp.fetchPromise response was not ok'));
        }
      });
    });
  }

  async changeUsername(meUserId, nickName) {
    const event = eventCreators.changeUsername(meUserId, nickName);
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('updateNickName timed out')), 10 * 1000);
      this._socket.emit('event', event, (response) => {
        clearTimeout(timeoutId);
        if (response.ok) {
          resolve();
        } else {
          logClientError(response.err);
          reject(response.err);
        }
      });
    });
  }
}

export const ChirpContext = React.createContext();
