import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = (theme) => ({
  legalDocsContainer: {
    textAlign: 'center',
  },
  legalDocsText: {
    fontWeight: '400',
    fontSize: '14px',
    margin: '0 4px',
    display: 'inline',
  },
  legalDocsLink: {
    color: theme.palette.charcoal,
  },
  legalDocsDot: {
    color: theme.palette.lake,
    position: 'relative',
    bottom: '3px',
  },
});

function LegalDocs(props) {
  const { privacyAndTOSOnly } = props;
  // Heads up - when we deprecate the old patient app these hrefs are all going to stop working.
  // These PDFs need to be moved to the API and these URLs and backend functionalities updated.
  // https://crickethealth.atlassian.net/browse/PE-236
  const { classes } = props;
  const dreamBackendUrl = window._cc && window._cc.dream ? window._cc.dream.url : '';
  return (
    <Box className={classes.legalDocsContainer}>
      <Box>
        <Typography className={classes.legalDocsText}>
          <a
            href={`${dreamBackendUrl}/i/agreements/tos`}
            target="_blank"
            rel="noreferrer"
            data-testid="termsOfServiceLink"
            className={classes.legalDocsLink}
          >
            <Trans>terms_of_service</Trans>
          </a>
        </Typography>
        <span className={classes.legalDocsDot}>.</span>
        <Typography className={classes.legalDocsText}>
          <a
            href={`${dreamBackendUrl}/i/agreements/pp`}
            target="_blank"
            rel="noreferrer"
            data-testid="privacyPolicyLink"
            className={classes.legalDocsLink}
          >
            <Trans>privacy_policy</Trans>
          </a>
        </Typography>
      </Box>
      {!privacyAndTOSOnly && (
        <>
          <Box>
            <Typography className={classes.legalDocsText}>
              <a
                href={`${dreamBackendUrl}/i/agreements/icts`}
                target="_blank"
                rel="noreferrer"
                data-testid="informedConsentLink"
                className={classes.legalDocsLink}
              >
                <Trans>icts</Trans>
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.legalDocsText}>
              <a
                href={`${dreamBackendUrl}/i/agreements/npp`}
                target="_blank"
                rel="noreferrer"
                data-testid="noticePrivacyPolicyLink"
                className={classes.legalDocsLink}
              >
                <Trans>npp</Trans>
              </a>
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default withStyles(styles, { withTheme: true })(LegalDocs);
