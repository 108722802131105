import { Avatar as MUIAvatar } from '@mui/material';
import { Person } from '@mui/icons-material';
import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

const BaseAvatar = (props) => (
  <MUIAvatar
    sx={{ minWidth: '60px', minHeight: '60px', background: MyCricketColors.mint }}
    {...props}
  />
);

// This is our default rounded corner button
// Other shape buttons are styled versions of this button, with different a border radius
export const Avatar = (props) => {
  const { source, ...rest } = props;

  // Mentors have a placeholder avatar, indicated by the source containing the substring avatar://
  // If our source is that we want to just return a base avatar
  return source ? (
    source.indexOf('avatar://') >= 0 ? (
      <BaseAvatar {...rest}>
        <Person />
      </BaseAvatar>
    ) : (
      <BaseAvatar src={source} {...rest} />
    )
  ) : (
    <BaseAvatar {...rest}>
      <Person />
    </BaseAvatar>
  );
};

export const CareTeamBioAvatar = (props) => (
  <Avatar sx={{ minWidth: '110px', minHeight: '110px' }} {...props} />
);
export const AppointmentAvatar = (props) => (
  <Avatar sx={{ minWidth: '120px', minHeight: '120px' }} {...props} />
);
