import { Box, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

export const ToolTipContent = withStyles({
  root: {
    fontFamily: 'Avenir',
    fontSize: '18px',
  },
})(Box);

export const StyledTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: '#239DBC',
    padding: '12px',
  },
  arrow: {
    color: '#239DBC',
  },
})(Tooltip);
