import React, { Component } from 'react';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

/*
HOC for fetching data once and managing the loading state. Used like

NewComponent = withFetching({
  // options go here
})(Component)

where the possible options are:

* loader: (required) A function that returns a promise that resolves to some
    data.
* propName: (optional) The name of the prop that will be passed into the inner
    component. Defaults to "data"
* dependencies: (optional) A list of props to buffer the loader on until all
    dependencies have a loaded = true attribute

The inner component receives an object prop named options.propName with the
structure:

* loaded: boolean representing whether data has been loaded yet
* data: the data returned from the loader. Will be null if loaded is false.
* error: boolean representing whether there was an error loading some data
* errorData: if error is true, the error that was thrown loading the data
*/
export default function withFetching(options) {
  return function (InnerComponent) {
    return class extends Component {
      // The generated class name for the component. Mostly useful in
      // debugging. For instance, this will show something like
      //   <getOrganizations(ComponentThatUsesUsers) />
      static displayName = `${options.loader.name}(${getDisplayName(InnerComponent)})`;

      constructor(props) {
        super(props);

        if ('AbortController' in window) {
          this.controller = new AbortController();
        }

        this.hasStartedFetching = false;
        this.state = {
          loaded: false,
          data: null,
          error: false,
          errorData: null,
        };
      }

      dependenciesMet() {
        return options.dependencies
          ? options.dependencies.every((prop) => this.props[prop].loaded)
          : true;
      }

      componentDidMount() {
        this.mounted = true;
        if (!this.hasStartedFetching && this.dependenciesMet()) {
          this.loadData();
        }
      }

      componentDidUpdate(prevProps, prevState) {
        if (!this.hasStartedFetching && this.dependenciesMet()) {
          this.loadData();
        }
      }

      componentWillUnmount() {
        this.controller.abort();
        this.mounted = false;
      }

      loadData = async () => {
        this.hasStartedFetching = true;
        try {
          const data = await options.loader(
            this.props,
            this.controller && this.controller.signal,
            this.state.data,
          );
          if (this.mounted) {
            this.setState({
              loaded: true,
              error: false,
              data,
            });
          }
        } catch (err) {
          if (err.name !== 'AbortError' && this.mounted) {
            this.setState({
              error: true,
              errorData: err,
            });
          }
        }
      };

      render() {
        const propData = {
          loaded: this.state.loaded,
          error: this.state.error,
          errorData: this.state.errorData,
          data: null,
          refetch: this.loadData,
        };
        if (this.state.loaded) {
          propData.data = this.state.data;
        }

        return (
          <InnerComponent
            {...this.props}
            {...{
              [options.propName || 'data']: propData,
            }}
          />
        );
      }
    };
  };
}
