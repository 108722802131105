import React, { useState, useEffect } from 'react';
import useQuery from '../../utils/hooks/useQueryHook';
import ResetPassword from '../molecules/ResetPassword';
import { ResetPasswordTokenExpired } from '../molecules/ResetPasswordTokenExpired';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';

const ResetPasswordPage = (props) => {
  const [isExpired, setIsExpired] = useState();
  const [loading, setIsLoading] = useState(true);
  const query = useQuery();
  const ticketNumber = query.get('ticket');
  useEffect(() => {
    //initially check if our token is valid
    const fetchIsTokenValid = async () => {
      const res = await fetch(
        new URL('/i/account/checkResetPasswordTicketExpiration', window._cc.api.url).toString(),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ticket: ticketNumber }),
        },
      );
      setIsExpired(!res.ok);
      setIsLoading(false);
    };
    fetchIsTokenValid();
  }, [ticketNumber]);
  return (
    <MyCricketLoadingContainer loading={loading}>
      {isExpired ? (
        <ResetPasswordTokenExpired />
      ) : (
        <ResetPassword ticketNumber={ticketNumber} {...props} />
      )}
    </MyCricketLoadingContainer>
  );
};

export default ResetPasswordPage;
