import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PauseButton from '../icons/PauseButton';
import PlayButton from '../icons/PlayButton';
import ReplayButton from '../icons/ReplayButton';
import MyCricketColors from '../../data/MyCricketColors';
import { useTranslation } from 'react-i18next';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { PatientEvent } from '../../data/Events';
import { ModuleDetailContext } from '../pages/ModuleDetail';
import URIUtils from '../../utils/URIUtils.js';

const iconControlButtonSize = (isDesktop) => (isDesktop ? '136px' : '102px');

const useStyles = makeStyles(() => ({
  videoContainer: {
    margin: 0,
    position: 'relative',
    cursor: 'pointer',
  },
  videoOverlay: {
    '&:before': {
      margin: 0,
      content: '""',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.8)',
      width: '100%',
      height: 'calc(100% - 7px)', // The overlay view is always approximately 7px too tall
    },
  },
  videoControlContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  videoControl: {
    position: 'absolute',
    top: (props) => `calc(50% - ${iconControlButtonSize(props.useDesktop)} / 2)`,
    left: (props) => `calc(50% - ${iconControlButtonSize(props.useDesktop)} / 2)`,
    textAlign: 'center',
  },
}));

const VideoPlayer = ({ id, videoURL, mimeType, posterURL, useDesktop }) => {
  const videoRef = useRef(null);
  const [initialPlay, setInitialPlay] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [complete, setComplete] = useState(false);
  const { moduleScope, selectedModuleId, courseId } = useContext(ModuleDetailContext);

  const classes = useStyles({ useDesktop });
  const { i18n } = useTranslation();

  const lang = i18n.language;

  useEffect(() => {
    if (videoURL.default !== videoURL.es && videoRef.current) {
      videoRef.current.load();
      setPlaying(false);
      setInitialPlay(true);
    }
  }, [i18n.language, videoURL.default, videoURL.es]);

  const Play = () => {
    const handlePlaying = () => {
      videoRef.current.play();
      setPlaying(true);
      setInitialPlay(false);
    };
    return (
      <div
        className={classnames(classes.videoControlContainer)}
        onClick={() => {
          handlePlaying();
        }}
        onKeyPress={(e) => {
          if (e.key === ' ') {
            handlePlaying();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className={classnames(classes.videoControl)}>
          <PlayButton
            color={MyCricketColors.mint}
            height={iconControlButtonSize(useDesktop)}
            width={iconControlButtonSize(useDesktop)}
            alt="Play"
          />
        </div>
      </div>
    );
  };

  const Pause = () => {
    const handlePause = () => {
      videoRef.current.play();
      setPlaying(false);
    };
    return (
      <div className={classnames(classes.videoControl)}>
        <PauseButton
          color={MyCricketColors.mint}
          height={iconControlButtonSize(useDesktop)}
          width={iconControlButtonSize(useDesktop)}
          alt="Pause"
          tabIndex={0}
          onClick={() => {
            handlePause();
          }}
          onKeyPress={(e) => {
            if (e.key === ' ') {
              handlePause();
            }
          }}
        />
      </div>
    );
  };

  const Replay = () => {
    const handleReplay = () => {
      videoRef.current.play();
      setPlaying(true);
      setComplete(false);
    };
    return (
      <div className={classnames(classes.videoControl)}>
        <ReplayButton
          color={MyCricketColors.mint}
          height={iconControlButtonSize(useDesktop)}
          width={iconControlButtonSize(useDesktop)}
          alt="Replay"
          tabIndex={0}
          onClick={() => {
            handleReplay();
          }}
          onKeyPress={(e) => {
            if (e.key === ' ') {
              handleReplay();
            }
          }}
        />
      </div>
    );
  };

  const videoId = id[lang] || id.default;
  const VideoSrc = () => (
    <source src={videoURL[lang] || videoURL.default} type={mimeType[lang] || mimeType.default} />
  );
  const videoPoster = posterURL[lang] || posterURL.default;

  const { logEvent } = useClientEventLoggerContext();

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <>
      <div
        className={classnames(classes.videoContainer, { [classes.videoOverlay]: initialPlay })}
        data-state="ready"
      >
        <video
          id={videoId}
          data-id={videoId}
          poster={videoPoster}
          preload="none"
          controls={!initialPlay}
          controlsList="nodownload"
          width={'100%'}
          ref={videoRef}
          onPlay={() => {
            setPlaying(true);
            setComplete(false);
            logEvent({
              predicate: PatientEvent.STARTED_VIEWING_VIDEO,
              object: `${moduleScope}://1/video/${videoId}`,
              prepositions: {
                page: URIUtils.toMyCricketPageURI(window.location.pathname),
                startTime: `${videoRef?.current.currentTime}`,
                percentComplete: `${videoRef?.current.currentTime / videoRef?.current.duration}`,
                courseId: courseId,
                moduleId: selectedModuleId,
                videoId,
              },
            });
          }}
          onPause={() => {
            setPlaying(false);
            logEvent({
              predicate: PatientEvent.STOPPED_VIEWING_VIDEO,
              object: `${moduleScope}://1/video/${videoId}`,
              prepositions: {
                page: URIUtils.toMyCricketPageURI(window.location.pathname),
                stopTime: `${videoRef?.current.currentTime}`,
                percentComplete: `${videoRef?.current.currentTime / videoRef?.current.duration}`,
                courseId: courseId,
                moduleId: selectedModuleId,
                videoId,
              },
            });
          }}
          onEnded={() => {
            setPlaying(false);
            setComplete(true);
            logEvent({
              predicate: PatientEvent.STOPPED_VIEWING_VIDEO,
              object: `${moduleScope}://1/video/${videoId}`,
              prepositions: {
                page: URIUtils.toMyCricketPageURI(window.location.pathname),
                stopTime: `${videoRef?.current.currentTime}`,
                percentComplete: `${videoRef?.current.currentTime / videoRef?.current.duration}`,
                courseId: courseId,
                moduleId: selectedModuleId,
                videoId,
              },
            });
          }}
          onSeeked={() => {
            videoRef.current.play();
            setPlaying(true);
          }}
        >
          {VideoSrc()}
        </video>
        {!playing && (initialPlay ? <Play /> : complete ? <Replay /> : <Pause />)}
      </div>
    </>
  );
};

export default VideoPlayer;
