import { Box, Typography } from '@mui/material';
import React from 'react';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import CareTeamBioPersonalPics from './CareTeamBioPersonalPics';

export const CareTeamBioAboutMe = ({ aboutMe, picSrc, picAlt }) => {
  return (
    <Box
      pt={4}
      pl={3}
      pr={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="left"
      width={'100%'}
    >
      <TranslatedTypography aria-level="2" variant="subtitle1">
        About me
      </TranslatedTypography>
      {picSrc && <CareTeamBioPersonalPics picSrc={picSrc} picAlt={picAlt} />}
      <Box pt={2} width={'100%'}>
        <Typography variant="body2">{aboutMe}</Typography>
      </Box>
    </Box>
  );
};
export default CareTeamBioAboutMe;
