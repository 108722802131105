import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from '@mui/material';
import Survey from '../organisms/Survey';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { paths } from '../../utils/routing/routes';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import useQuery from '../../utils/hooks/useQueryHook';
import _ from 'lodash';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';

const getUserSurveyAnswers = (surveyId) => async () =>
  myCricketAuthedFetch(`/i/user/survey/${surveyId}/answers`, { method: 'GET' });

const submitSurveyAnswer = (surveyId) => async (answers) =>
  myCricketAuthedFetch(`/i/user/survey/${surveyId}/submit-answer`, {
    method: 'POST',
    body: JSON.stringify({ answers }),
  });

const getInitialSurveyState = (surveyId) => {
  const state = localStorage.getItem(`survey-${surveyId}`);
  return state ? JSON.parse(state) : null;
};

// Save the state of the survey, so that if someone refreshes the page, we
// won't direct them back to the start of the survey.
const saveSurveyState = (surveyId) => (state) =>
  localStorage.setItem(`survey-${surveyId}`, JSON.stringify(state));

// Clear the state when we finish the survey.
const clearSurveyState = (surveyId) => () => localStorage.removeItem(`survey-${surveyId}`);

const completeSurvey = async (surveyId) =>
  myCricketAuthedFetch(`/i/user/survey/${surveyId}/complete`, { method: 'POST' });

const SurveyPage = ({ surveyId, surveyName }) => {
  const { surveyCompletionStatus, refreshOnboardingProgress, featureFlags, pcLoading } =
    usePatientContext();
  const query = useQuery();
  const [questions, setQuestions] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [questionAnswerDependencies, setQuestionAnswerDependencies] = useState([]);
  const [redirectLocation, setRedirectLocation] = useState(paths.careTeam());
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!surveyName) {
      return;
    }
    const fetchSurveyContent = async (surveyName) => {
      try {
        const surveyContent = await myCricketAuthedFetch(`/i/survey/${surveyName}`);
        setQuestions(surveyContent.questions || []);
        setQuestionAnswers(surveyContent.questionAnswers || []);
        setQuestionAnswerDependencies(surveyContent.questionAnswerDependencies || []);
        setError(null);
      } catch (error) {
        setError(error);
      }
    };
    fetchSurveyContent(surveyName);
  }, [surveyName]);

  if (error) {
    throw error;
  }

  const needsSurvey =
    surveyCompletionStatus &&
    surveyCompletionStatus[surveyId] &&
    surveyCompletionStatus[surveyId].isNeeded &&
    !surveyCompletionStatus[surveyId].isComplete;

  if (!pcLoading && !needsSurvey) {
    return <Redirect to={{ pathname: redirectLocation, search: query.toString() }} />;
  }

  return (
    <Box role="main" width={'100%'} data-test-id={'enrollment-survey'} height="100%">
      <MyCricketLoadingContainer loading={_.isEmpty(questions) || _.isEmpty(questionAnswers)}>
        <Survey
          questions={questions}
          questionAnswers={questionAnswers}
          questionAnswerDependencies={questionAnswerDependencies}
          getSurveyAnswers={getUserSurveyAnswers(surveyId)}
          submitSurveyAnswer={submitSurveyAnswer(surveyId)}
          initialSurveyState={getInitialSurveyState(surveyId)}
          saveSurveyState={saveSurveyState(surveyId)}
          clearSurveyState={clearSurveyState(surveyId)}
          completeSurvey={async () => {
            await completeSurvey(surveyId);
            setRedirectLocation(paths.careTeam());
            await refreshOnboardingProgress();
          }}
          shouldShowSurveyCompleteScreen={featureFlags?.['my-cricket-menu'] ?? true}
        />
      </MyCricketLoadingContainer>
    </Box>
  );
};

export default SurveyPage;
