export const toClientEvent = function (obj) {
  let eventPrepositions = Object.assign(
    {
      clientTimestamp: Date.now().toString(),
      appName: _cc.appName,
    },
    obj.prepositions,
  );

  if (!('page' in eventPrepositions) && window.CRICKET) {
    // todo: Need to add version to cc object / environment
    const uriPrefix = `dream://${_cc.version}/`;
    eventPrepositions = Object.assign(
      {
        page: uriPrefix + window.location.pathname,
      },
      eventPrepositions,
    );
  }
  const eventPredicate = {
    _type: 'cricket:client:event:predicate:' + obj.predicate,
    prepositions: eventPrepositions,
  };

  // if object is an actual object, we should assume it's a complete instance
  const eventObject =
    typeof obj.object === 'string'
      ? {
          _type: 'cricket:client:event:noun',
          URI: obj.object,
        }
      : obj.object;

  return {
    _type: 'cricket:client:event',
    predicate: eventPredicate,
    object: eventObject,
  };
};
