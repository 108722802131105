import { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react';
import pRetry from 'p-retry';
import { logClientError } from '../errorLogging';
import { ChirpContext } from '../Chirp';

export default function useChirpFetching(props, options) {
  // To prevent having to re-fetch data, we store all of the objects that
  // we've ever loaded by their ID.
  const [loadedData, setLoadedData] = useState({});
  // A set of object IDs that are currently being fetched
  const fetchingIds = useRef({});
  const controller = useRef('AbortController' in window ? new AbortController() : null);
  const [error, setError] = useState(false);
  const chirp = useContext(ChirpContext);

  function getUnloadedIds(dataIds) {
    // If we've already fetched an object or there's a already a request to
    // fetch the given object, we filter it out because we don't need to
    // load it again.
    return dataIds.filter((dataId) => !(dataId in fetchingIds.current) && !(dataId in loadedData));
  }

  function loadData(dataIds) {
    if (dataIds.length === 0) {
      return;
    }

    const fetchData = async () => {
      // Keep track of the ids of objects that are being loaded.
      const requestTimestamp = Date.now();
      for (const dataId of dataIds) {
        fetchingIds.current[dataId] = requestTimestamp;
      }

      const data = await options.loader(dataIds, controller.current?.signal, chirp);
      if (data.length !== dataIds.length) {
        const error = new Error(
          `Returned number of data entities is not the same as requested number (requested: ${dataIds.length}, returned: ${data.length}). User may not have permission for requested ${options.entityType}`,
        );
        logClientError(error);
      }
      const patch = {};
      for (const datum of data) {
        // only use the data if there hasn't been any additional requests for this id
        // since request was originally fired
        if (fetchingIds.current[datum.id] === requestTimestamp) {
          patch[datum.id] = datum;
          delete fetchingIds.current[datum.id];
        }
      }
      setLoadedData((loadedData) => ({ ...loadedData, ...patch }));
    };

    return pRetry(fetchData, {
      onFailedAttempt: (error) => {
        if (error.name === 'AbortError') {
          return;
        }
        logClientError(error, {
          consolePrefix: `Error fetching ${options.entityType} objects:`,
        });
        setError(true);
      },
      retries: 1,
    });
  }

  function handleChirpRefetch(changed) {
    const allChangedIds = changed[options.entityType];
    if (allChangedIds != null) {
      const changedIds = allChangedIds.filter(
        (id) => id in loadedData || id in fetchingIds.current,
      );
      if (changedIds.length > 0) {
        loadData(changedIds);
      }
    }
  }

  useEffect(() => {
    chirp.on('refetch', handleChirpRefetch);
    return () => chirp.off('refetch', handleChirpRefetch);
  }, [handleChirpRefetch]);

  useEffect(() => {
    loadData(getUnloadedIds(options.idsToFetch(props)));
  }, [props]);

  useEffect(() => () => controller.current?.abort(), []);

  //only pass down objects that are in the current idsToFetch result
  //this is in case idsToFetch removes some ids that were previous fetched
  //one scenario where this may occur is if the patient is changed but stay on the same page
  const idsToFetch = options.idsToFetch(props).sort();
  const data = useMemo(
    () =>
      idsToFetch.reduce((acc, dataId) => {
        if (dataId in loadedData) {
          acc[dataId] = loadedData[dataId];
        }
        return acc;
      }, {}),
    [idsToFetch.toString(), loadedData],
  );

  const refetch = useCallback(
    async (ids) => {
      if (ids) {
        await loadData(ids);
      } else {
        await loadData(options.idsToFetch(props));
      }
    },
    [options, props],
  );

  return {
    data,
    error,
    refetch,
  };
}
