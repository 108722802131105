function toGroupURI(groupId) {
  return 'chirp://group/' + groupId;
}

function toMessageURI(messageId) {
  return 'chirp://message/' + messageId;
}

function toEventURI(eventId) {
  return 'chirp://event/' + eventId;
}

function toUserURI(userId) {
  return 'chirp://user/' + userId;
}

function toUnknownUserURI() {
  return 'chirp://unknownuser';
}

function isUserURI(uri) {
  return typeof uri === 'string' && uri.startsWith('chirp://user/');
}

function isGroupURI(uri) {
  return typeof uri === 'string' && uri.startsWith('chirp://group/');
}

function isMessageURI(uri) {
  return typeof uri === 'string' && uri.startsWith('chirp://message/');
}

function isOutreachScheduleURI(uri) {
  return typeof uri === 'string' && uri.startsWith('outreachScheduleEvent://');
}

function toAuth0URI(auth0Id) {
  return 'auth0://' + auth0Id;
}

function isAuth0URI(uri) {
  return typeof uri === 'string' && uri.startsWith('auth0://');
}

function toReferralURI(referralId) {
  return 'referral://' + referralId;
}

function toAgreementURI(type, version) {
  return `agreement://${type}/${version}`;
}

function toLife365OrderURI(orderId) {
  return `Life365Order://${orderId}`;
}

function toPhoneURI(phoneNumberId) {
  return `phoneNumber://${phoneNumberId}`;
}

function toSystemURI(subsystem = 'SYSTEM') {
  return `SYSTEM://${subsystem}`;
}

function toLife365HubURI(hubId) {
  return `Life365Hub://${hubId}`;
}

function toLife365DeviceURI(deviceId) {
  return `Life365Device://${deviceId}`;
}

function toDailyBiometricURI(dailyBiometricId) {
  return `dailyBiometric://${dailyBiometricId}`;
}

function toLobEventURI(lobEventId) {
  return `LobEvent://${lobEventId}`;
}

function toAnonymousURI() {
  return `unknown://user`;
}

function toDreamPageURI(page) {
  return `dream://1/${page}`;
}

function toMyCricketPageURI(page) {
  const pageString = page.startsWith('/') ? page : `/${page}`;
  return `myCricket://1${pageString}`;
}

function toPatientEmailURI(patientEmailId) {
  return `patientEmail://${patientEmailId}`;
}

function toAddressURI(addressId) {
  return `address://${addressId}`;
}

function idFromURI(URI) {
  try {
    return URI.split('/').pop();
  } catch (err) {
    console.log('failed to extract id from URI: ', URI);
  }
}
function toS3Uri(bucket, etag) {
  return `s3://${bucket}:${etag}`;
}
function toClinicalScheduledEventCricketUserURI(clinicalScheduledEventCricketUserId) {
  return `intakeTaskCricketUser://${clinicalScheduledEventCricketUserId}`;
}
function toOutreachScheduleEventURI(outreachScheduleEventId) {
  return `outreachScheduleEvent://${outreachScheduleEventId}`;
}

function toMasterListURI(app) {
  return `masterList://${app}`;
}

function toTaskListURI(app) {
  return `taskList://${app}`;
}

function toPatientTaskListURI(app) {
  return `patientTaskList://${app}`;
}

function toSurveyURI(survey) {
  return `survey://${survey}`;
}

function toUserLabURI(userLabId) {
  return `userLab://${userLabId}`;
}

function toTaskURI(taskId) {
  return `task://${taskId}`;
}

function toPatientProblemURI(patientProblemId) {
  return `patientProblem://${patientProblemId}`;
}

function toWorkflowURI(workflowId) {
  return `workflow://${workflowId}`;
}

function toCommentURI(commentId) {
  return `comment://${commentId}`;
}

function toEMRLinkOrderURI(EMRLinkOrderId) {
  return `EMRLinkOrder://${EMRLinkOrderId}`;
}

function toEMRLinkResultURI(EMRLinkResultId) {
  return `EMRLinkResult://${EMRLinkResultId}`;
}

function toMockEMRLinkOrderURI(EMRLinkOrderID) {
  return `MockEMRLinkOrder://${EMRLinkOrderID}`;
}

function toMockEMRLinkBatchResultsURI(ResultBatchIDs) {
  return `MockEMRLinkBatchResultsURI://${ResultBatchIDs}`;
}

function toMockEMRLinkApiURI() {
  return 'MockEMRLinkAPI://MockEMRLinkAPI';
}

function toFormResultURI(FormResultId) {
  return `FormResult://${FormResultId}`;
}

function toLabOrdersListURI() {
  return `labOrdersList://pro`;
}

function toLabResultsListURI() {
  return `labResultsList://pro`;
}

function toPatientProblemInterventionURI(patientProblemInterventionId) {
  return `PatientProblemIntervention://${patientProblemInterventionId}`;
}

function toPatientProblemSubInterventionURI(patientProblemSubInterventionId) {
  return `PatientProblemSubIntervention://${patientProblemSubInterventionId}`;
}

function toPatientDocumentURI(documentId) {
  return `PatientDocument://${documentId}`;
}

function toPatientChartAuditURI(patientChartAuditId) {
  return `PatientChartAudit://${patientChartAuditId}`;
}

function toPageURI(origin) {
  return `Page://${origin}`;
}

module.exports = {
  toS3Uri,
  toGroupURI,
  toMessageURI,
  toEventURI,
  toUserURI,
  isUserURI,
  isGroupURI,
  isMessageURI,
  toAuth0URI,
  isAuth0URI,
  isOutreachScheduleURI,
  toReferralURI,
  toUnknownUserURI,
  idFromURI,
  toAgreementURI,
  toLife365OrderURI,
  toPhoneURI,
  toSystemURI,
  toLife365HubURI,
  toLife365DeviceURI,
  toDailyBiometricURI,
  toLobEventURI,
  toAnonymousURI,
  toDreamPageURI,
  toMyCricketPageURI,
  toPatientEmailURI,
  toAddressURI,
  toClinicalScheduledEventCricketUserURI,
  toOutreachScheduleEventURI,
  toMasterListURI,
  toSurveyURI,
  toUserLabURI,
  toTaskURI,
  toPatientProblemURI,
  toWorkflowURI,
  toCommentURI,
  toTaskListURI,
  toPatientTaskListURI,
  toEMRLinkOrderURI,
  toEMRLinkResultURI,
  toMockEMRLinkOrderURI,
  toMockEMRLinkApiURI,
  toFormResultURI,
  toMockEMRLinkBatchResultsURI,
  toLabOrdersListURI,
  toLabResultsListURI,
  toPatientProblemInterventionURI,
  toPatientProblemSubInterventionURI,
  toPatientDocumentURI,
  toPatientChartAuditURI,
  toPageURI,
};
