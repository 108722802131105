const Cookies = require('js-cookie');
const { distantFuture } = require('../data/DateConstants');
const cookieNames = [
  'id_token',
  'my_cricket_id_token',
  'i18next',
  'my_cricket_i18next',
  'cricketDeviceId',
  'cricketSessionId',
  'loggingApiKey',
];

const CookieManager = {
  cookie: {},
  /*
   * Look for given cookie names and aggregate unencoded versions into an object literal 'cookie'
   * '.cookie' property will be available to the host object using this mixin.
   * this method uses a 'lossy' approach e.g. reset .cookie to what ever is found in browser cookies
   */
  ingestCookies: function () {
    const cookies = Cookies.get();
    this.cookie = Object.keys(cookies)
      .filter((key) => cookieNames.includes(key))
      .reduce((acc, key) => {
        let value;
        try {
          value = JSON.parse(atob(cookies[key]));
        } catch {
          value = cookies[key];
        }
        return {
          ...acc,
          [key]: value,
        };
      }, {});
  },
  setCookie: function (key, value, secure, domain) {
    //base64 encode and persist
    const newCookie = btoa(JSON.stringify(value));
    // In case where you go to the production environments you get cookies under the crickethealth.com
    // domain. If you then go to any non-production environment there is a chance that you get double
    // cookies because cookies in the crickethealth.com domain are available in sub-domains (ex: dev.crickethealth.com)
    // See https://github.com/js-cookie/js-cookie#domain
    // This can cause some errors (specifically in logging and trying to get the cricketDeviceId).
    Cookies.remove(key, { domain: 'crickethealth.com' });
    Cookies.set(key, newCookie, {
      domain,
      expires: distantFuture,
      secure,
    });
  },
  getRawCookie: function (key) {
    if (cookieNames.includes(key)) {
      return Cookies.get(key);
    }
  },
  deleteCookie: function (key, options) {
    Cookies.remove(key, options);
  },
};
module.exports = CookieManager;
