import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import CourseCard from '../molecules/CourseCard';
import { sortBy } from 'lodash';
import { useForceErrorThrow } from '../../utils/PatientErrorBoundary';

const CourseIndex = ({ match: { params } }) => {
  const [allCourses, setAllCourses] = useState([]);
  const [activeCourseName, setActiveCourseName] = useState('');
  const forceThrowError = useForceErrorThrow();

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = '/i/learn/courses';
      const resp = await myCricketAuthedFetch(fetchUrl);
      if (resp.courseList) {
        const activeCourseName = resp.activeCourseName;
        setActiveCourseName(activeCourseName);
        // put the courses in order by... order 😂
        const coursesInOrder = resp.courseList.sort((m1, m2) => m1.order - m2.order);
        // jump the active course to the front of the line
        const coursesInOrderWithActiveFirst = sortBy(coursesInOrder, (course) =>
          course.scope === activeCourseName ? 0 : 1,
        );
        setAllCourses(coursesInOrderWithActiveFirst);
      }
    };
    fetchData().catch((e) => {
      forceThrowError(e);
    });
  }, [forceThrowError]);

  return (
    <Box pt={5} width={'100%'} mt={3} px={{ xs: 3, sm: 3, md: 4, lg: '10%', xl: '10%' }} pb={8}>
      <Box pb={3}>
        <TranslatedTypography variant="h1">Courses</TranslatedTypography>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {allCourses.map((course) => {
          return (
            <CourseCard
              key={course.id}
              courseName={course.title}
              isActive={course.scope === activeCourseName}
              courseDescription={course.description}
              imgUrl={course.cover?.url}
              courseId={course.id}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default CourseIndex;
