import { myCricketAuthedFetch } from './fetchingUtils';

//intentionally chosen as the only organization loader for MyCricket
export default async function loadOrganizationList(signal) {
  const res = await myCricketAuthedFetch(`/i/chirp/organizations`, {
    method: 'GET',
    signal,
  });
  return res.filter((org) => org.isRegisteredOrganization);
}
