const PatientEvent = Object.freeze({
  ANSWERED_SURVEY_QUESTION: 'answeredSurveyQuestion',
  CHANGED_COURSE_CONTENT: 'changeCourseContent',
  EXPAND_MESSAGE: 'expandMessage',
  EXPAND_REPLIES: 'expandReplies',
  LIKE_MESSAGE: 'likeMessage',
  OPEN_PROFILE: 'openProfile',
  OPENED_FAQ: 'openedFAQ',
  READ_GROUP_MESSAGES: 'readGroupMessages',
  HIDE_MESSAGE: 'hideMessage',
  READ_MESSAGE: 'readMessage',
  SELECT_GROUP: 'selectGroup',
  SEND_MESSAGE: 'sendMessage',
  UNREAD_MESSAGE: 'unreadMessage',
  STARTED_VIEWING_VIDEO: 'startedViewingVideo',
  STOPPED_VIEWING_VIDEO: 'stoppedViewingVideo',
  SURVEY_COMPLETE: 'surveyComplete',
  UNLIKE_MESSAGE: 'unlikeMessage',
  VIEWED_AGREEMENT: 'viewedAgreement',
  ACCEPTED_AGREEMENT: 'acceptedAgreement',
  COMPLETED_CLINICAL_PHONE_CALL: 'completedClinicalPhoneCall',
  ATTEMPTED_CLINICAL_PHONE_CALL: 'attemptedClinicianPhoneCall',
  STARTED_CLINICAL_PHONE_CALL: 'startedClinicianPhoneCall',
  ASSIGNED_MENTOR: 'mentorAssignment',
  ASSIGNED_PATIENT: 'patientAssignment', //for mentors who are assigned patients. only used in SyncEventToBraze
  ASSIGNED_NURSE: 'nurseAssignment',
  ASSIGNED_CLINICIAN: 'assignedClinician', //the clinician is the object
  ASSIGNED_CLINICIAN_PATIENT: 'assignedClinicianToPatient', //the patient is the object
  UNASSIGNED_CLINICIAN: 'unassignedClinician',
  ASSIGNED_OA: 'OAAssignment',
  REMOVED_MENTOR: 'mentorRemoval',
  COMPLETED_OPS_OUTREACH_PHONE_CALL: 'completedOpsOutreachPhoneCall',
  ATTEMPTED_OPS_OUTREACH_PHONE_CALL: 'attemptedOpsOutreachPhoneCall',
  STARTED_OPS_OUTREACH_PHONE_CALL: 'startedOpsOutreachPhoneCall',
  COMPLETED_INBOUND_OPS_CALL: 'completedInboundOpsCall',
  COMPLETED_INBOUND_PRO_CALL: 'completedInboundProCall',
  NAVIGATE_LEARN: 'navigateToLearn',
  NAVIGATE_CHAT: 'navigateToChat',
  LOGOUT: 'logout',
  SIGN_UP_ERROR: 'signUpError',
  VIEW_PAGE: 'viewedPage', //frontend event
  UPDATE_EMAIL_PREFERENCES: 'updatedEmailPreferences', //frontend event
  SENT_SEEDED_MESSAGE: 'sentSeededMessage',
  VIEWED_COACHMARK: 'viewedCoachmark',
  PATIENT_UPLOADED_FILE: 'patientUploadedFile',
  SWITCHED_MODULE: 'switchedModule',
  USERNAME_MODAL_LOADING: 'usernameModalLoading',
  USERNAME_MODAL_LOADED: 'usernameModalLoaded',
  USERNAME_MODAL_SUBMITTIED: 'usernameModalSubmitted',
  USERNAME_MODAL_ABANDONED: 'usernameModalAbandoned',
  USERNAME_MODAL_SUBMIT_ERROR: 'usernameModalSubmitError',
  USERNAME_MODAL_VALIDATION_FAILURE: 'usernameModalValidationFailure',
});

const BiometricEvent = Object.freeze({
  BLOOD_PRESSURE_MEASUREMENT_BY_NURSE: 'medDev:bpMeasurement:nurse',
  BLOOD_PRESSURE_MEASUREMENT_BY_DEVICE: 'medDev:bpMeasurement:device',
  BLOOD_PRESSURE_MEASUREMENT_EDIT_BY_NURSE: 'medDev:bpMeasurement:nurse:edit',
  WEIGHT_MEASUREMENT_BY_NURSE: 'medDev:scaleMeasurement:nurse',
  WEIGHT_MEASUREMENT_BY_DEVICE: 'medDev:scaleMeasurement:device',
  WEIGHT_MEASUREMENT_EDIT_BY_NURSE: 'medDev:scaleMeasurement:nurse:edit',
  HIDE_BIOMETRIC: 'dailyBiometric:hide',
});
const MilestoneEvents = Object.freeze({
  mdOne: 'milestone:mdOne',
  mdTwo: 'milestone:mdTwo',
  mdThree: 'milestone:mdThree',
  mdFour: 'milestone:mdFour',
  mdFive: 'milestone:mdFive',
  apOne: 'milestone:apOne',
});
const InteractionNudgeEvents = Object.freeze({
  atRiskChat: 'nudge:atRiskChat',
  atRiskFaq: 'nudge:atRiskFaq',
  dialysisDecision: 'nudge:dialysisDecision',
  mdpOverview: 'nudge:mdpFeatureOverview',
});
const StateTransitionEvent = Object.freeze({
  TRANSMITTED: 'patientState:transmitted',
  CLOSED: 'patientState:closed',
  REFERRED: 'patientState:referred',
  INVITED: 'patientState:invited',
  SIGNED_UP: 'patientState:signedUp',
  ENROLLED: 'patientState:enrolled',
  OFFBOARDING: 'patientState:offboarding',
  UNENROLLED: 'patientState:unenrolled',
});

const MonthlyActiveUserEvents = [
  PatientEvent.ANSWERED_SURVEY_QUESTION,
  PatientEvent.CHANGED_COURSE_CONTENT,
  PatientEvent.EXPAND_MESSAGE,
  PatientEvent.EXPAND_REPLIES,
  PatientEvent.LIKE_MESSAGE,
  PatientEvent.OPEN_PROFILE,
  PatientEvent.OPENED_FAQ,
  PatientEvent.READ_GROUP_MESSAGES,
  PatientEvent.READ_MESSAGE,
  PatientEvent.SELECT_GROUP,
  PatientEvent.SEND_MESSAGE,
  PatientEvent.STARTED_VIEWING_VIDEO,
  PatientEvent.SURVEY_COMPLETE,
  PatientEvent.UNLIKE_MESSAGE,
  PatientEvent.COMPLETED_CLINICAL_PHONE_CALL,
  BiometricEvent.BLOOD_PRESSURE_MEASUREMENT_BY_DEVICE,
  BiometricEvent.WEIGHT_MEASUREMENT_BY_DEVICE,
  StateTransitionEvent.ENROLLED,
  PatientEvent.COMPLETED_INBOUND_PRO_CALL,
];

const LoginEvent = Object.freeze({
  LOGIN_SUCCESS: 'login:success', // backend evnt
  LOGIN_INVALID_CREDENTIALS: 'login:invalidCredentials', // backend evnt
  LOGIN_UNKNOWN_EMAIL: 'login:unknownEmail',
  LOAD_RESET_PASSWORD_REQUEST_FORM: 'loadResetPasswordRequestForm',
  LOAD_RESET_PASSWORD_FORM: 'loadResetPasswordForm',
  RESET_PASSWORD_REQUEST_SUCCESS: 'resetPasswordRequest:success',
  RESET_PASSWORD_REQUEST_UNKNOWN_EMAIL: 'resetPasswordRequest:unknownEmail',
  SUBMITTED_NEW_PASSWORD_ATTEMPTED: 'submittedNewPassword:attempted',
  SUBMITTED_NEW_PASSWORD_FAILURE: 'submittedNewPassword:failure',
  SUBMITTED_NEW_PASSWORD_SUCCESS: 'submittedNewPassword:success',
  SUBMITTED_NEW_PASSWORD_PASSWORD_DOES_NOT_MATCH: 'submittedNewPassword:passwordDoesNotMatch',
  SUBMITTED_NEW_PASSWORD_WEAK_PASSWORD: 'submittedNewPassword:passwordTooWeak',
  LOGIN_PAGE_LOADED: 'loginPageLoaded', // frontend evnt
  LOGIN_FAILED: 'loginFailed', // frontend evnt
  LOGIN_ATTEMPTED: 'loginAttempted', // frontend evnt
  LOGIN_TOO_MANY_ATTEMPTS: 'login:tooManyAttempts', // backend/Auth0 event,
});
//a notification is an email, sms, etc.
const CommunicationEvent = Object.freeze({
  NEW_REPLY: 'communication:newReply',
  LIKED_MESSAGE: 'communication:likedMessage',
  NEW_DM: 'communication:dm',
  NEW_GROUP_MESSAGE: 'communication:groupMessage',
  ONE_WAY_SMS: 'communication:oneWaySMS',
});

const EnrollmentFormEvent = Object.freeze({
  ENROLLMENT_FORM_LOAD_SUCCESS: 'enrollmentForm:pageLoadSuccess',
  ENROLLMENT_FORM_SUBMIT_SUCCESS: 'enrollmentForm:submitSuccess',
  ENROLLMENT_FORM_SUBMIT_FAILURE: 'enrollmentForm:signupSubmitFailure',
  ENROLLMENT_FORM_SIGNUP_SUBMIT_SUCCESS: 'enrollmentForm:signupSubmitSuccess',
  ENROLLMENT_FORM_SIGNUP_SUBMIT_FAILURE: 'enrollmentForm:submitFailure',
  ENROLLMENT_FORM_VALIDATION_FAILURE: 'enrollmentForm:validationFailure',

  // section events
  ENROLLMENT_FORM_NAME_SECTION_LOADED: 'enrollmentForm:nameSectionLoaded',
  ENROLLMENT_FORM_DOB_SECTION_LOADED: 'enrollmentForm:dobSectionLoaded',
  ENROLLMENT_FORM_INSURANCE_SECTION_LOADED: 'enrollmentForm:insuranceSectionLoaded',
  ENROLLMENT_FORM_CONTACT_SECTION_LOADED: 'enrollmentForm:contactSectionLoaded',
  ENROLLMENT_FORM_ACCOUNT_SECTION_LOADED: 'enrollmentForm:accountSectionLoaded',
  ENROLLMENT_FORM_SUBMITTED_SECTION_LOADED: 'enrollmentForm:submittedSectionLoaded',

  // eligiblity inquiry
  ELIGIBILITY_INQUIRY_SUBMIT_SUCCESS: 'eligibilityInquiry:submitSuccess',
  ELIGIBILITY_INQUIRY_SUBMIT_FAILURE: 'eligibilityInquiry:signupSubmitFailure',
  ELIGIBILITY_INQUIRY_PROCESSED: 'eligibilityInquiry:processedInquiry',
  ELIGIBILITY_INQUIRY_CRITERIA_MET: 'eligibilityInquiry:criteriaMet',
  ELIGIBILITY_INQUIRY_CRITERIA_NOT_MET: 'eligibilityInquiry:criteriaNotMet',

  // provider referral
  PROVIDER_REFERRAL_LOAD_SUCCESS: 'providerReferral:pageLoadSuccess',
  PROVIDER_REFERRAL_SUBMIT_SUCCESS: 'providerReferral:submitSuccess',
  PROVIDER_REFERRAL_SUBMIT_FAILURE: 'providerReferral:submitFailure',
  PROVIDER_REFERRAL_VALIDATION_FAILURE: 'eligibilityInquiry:validationFailure',
});

const TrackingEvent = Object.freeze({
  CLICK_EXTERNAL_LINK: 'tracking:clickExternalLink',
  HOVER: 'tracking:hover',
  CLICK_SENT_EMAIL: 'tracking:clickSentEmail',
  OPEN_SENT_EMAIL: 'tracking:openSentEmail',
  INVITE_CODE_VALIDATION_REQUEST: 'inviteCode:validation:request',
  INVITE_CODE_VALIDATION_OUTCOME: 'inviteCode:validation:outcome',
  MED_DEVICE_LOAD_OPT_IN_PAGE: 'pageLoads:optInPage',
  MED_DEVICE_VALIDATE_NEW_ADDRESS: 'addressValidation:newEntry',
  MED_DEVICE_ADDRESS_PASS_VALIDATION: 'addressValidation:pass',
  MED_DEVICE_ADDRESS_FAIL_VALIDATION: 'addressValidation:fail',
  MED_DEVICE_CONFIRMED_EXISTING_ADDRESS: 'addressValidation:confirmedExisting',
  MED_DEVICE_ACCEPT_AGREEMENTS: 'userAgreements:checkboxSelected',
  MED_DEVICE_OPEN_AGREEMENT: 'userAgreements:openedLink',
  MED_DEVICE_SUBMIT_FORM: 'medDev:submit',
  PRINT_MAIL_PAGE_REQUESTED: 'printMail:requestedPage',
  PRINT_MAIL_PAGE_VALID_EMAIL_ENTERED: 'printMail:validEmailEntered',
  PRINT_MAIL_PAGE_INVALID_EMAIL_ENTERED: 'printMail:invalidEmailEntered',
  PRINT_MAIL_PAGE_NO_EMAIL_ENTERED: 'printMail:noEmailEntered',
  PRINT_MAIL_PAGE_NO_CONFIRM_EMAIL_ENTERED: 'printMail:noConfirmEmailEntered',
  PRINT_MAIL_PAGE_EMAIL_MISMATCH: 'printMail:emailMismatch',
  PRINT_MAIL_PAGE_NEEDS_HELP: 'printMail:needHelp',
  PRINT_MAIL_REGISTRATION_PAGE_REQUESTED: 'printMail:requestedRegistrationPage',
  PRINT_MAIL_PAGE_VIDEO_PLAYED: 'printMail:videoPlayed',
  PRINT_MAIL_PAGE_VIDEO_PAUSED: 'printMail:videoPaused',
  PRINT_MAIL_PAGE_VIDEO_COMPLETED: 'printMail:videoCompleted',
  PRINT_MAIL_PAGE_FAQ_OPENED: 'printMail:faqOpened',
  PRINT_MAIL_DOB_MATCH: 'printMail:dateOfBirthMatch',
  PRINT_MAIL_CONTACT_INFO_UPDATED: 'printMail:contactInfoUpdated',
  PRINT_MAIL_REGISTRATION_INVALID_DOB: 'printMail:invalidDateOfBirth',
  PRINT_MAIL_REGISTRATION_DOB_ATTEMPTS_EXCEEDED: 'printMail:dateOfBirthAttemptsExceeded',
  PRINT_MAIL_EMAIL_VERIFICATION_LINK_EXPIRED: 'printMail:emailVerificationLinkExpired',
  PRINT_MAIL_REGISTRATION_ATTEMPTED: 'printMail:registrationAttempted',
  PRINT_MAIL_REGISTRATION_SUCCESS: 'printMail:registrationSuccess',
  PRINT_MAIL_REGISTRATION_NICKNAME_TOO_LONG: 'printMail:nicknameTooLong',
  PRINT_MAIL_REGISTRATION_NICKNAME_ALREADY_EXISTS: 'printMail:nicknameAlreadyExists',
  PRINT_MAIL_REGISTRATION_PASSWORD_DOES_NOT_MATCH: 'printMail:passwordDoesNotMatch',
  PRINT_MAIL_REGISTRATION_UNPARSEABLE_DOB: 'printMail:unparseableDateOfBirth',
  PRINT_MAIL_REGISTRATION_AGREEMENT_NOT_CHECKED: 'printMail:agreementNotChecked',
  PRINT_MAIL_REGISTRATION_AGREEMENT_VIEWED: 'printMail:agreementViewed',
  PRINT_MAIL_REGISTRATION_WEAK_PASSWORD: 'printMail:passwordTooWeak',
  PRINT_MAIL_SIGNUP_FORM_LOADED: 'printMail:signupFormLoaded',
  PUBLIC_LANDING_PAGE_LOADED: 'publicLandingPage:pageLoaded',
  PUBLIC_LANDING_PAGE_ELIGIBILITY_INQUIRY_REQUESTED:
    'publicLandingPage:eligibilityInquiryRequested',
  PUBLIC_LANDING_PAGE_PRINT_ENROLLMENT_FORM_REQUESTED:
    'publicLandingPage:printEnrollmentFormRequested',
  PUBLIC_LANDING_PAGE_CODE_SUCCESS: 'publicLandingPage:codeSubmitSuccess',
  PUBLIC_LANDING_PAGE_CODE_FAILURE: 'publicLandingPage:codeSubmitFailure',
  EMAIL_CAMPAIGN_SIGNUP_NEEDS_HELP: 'emailCampaignSignup:needHelp',
  EMAIL_CAMPAIGN_SIGNUP_VIDEO_PLAYED: 'emailCampaignSignup:videoPlayed',
  EMAIL_CAMPAIGN_SIGNUP_VIDEO_PAUSED: 'emailCampaignSignup:videoPaused',
  EMAIL_CAMPAIGN_SIGNUP_VIDEO_COMPLETED: 'emailCampaignSignup:videoCompleted',
  EMAIL_CAMPAIGN_SIGNUP_FAQ_OPENED: 'emailCampaignSignup:faqOpened',
  EMAIL_CAMPAIGN_SIGNUP_INVALID_DOB: 'emailCampaignSignup:invalidDateOfBirth',
  EMAIL_CAMPAIGN_SIGNUP_PASSWORD_DOES_NOT_MATCH: 'emailCampaignSignup:passwordDoesNotMatch',
  EMAIL_CAMPAIGN_SIGNUP_UNPARSEABLE_DOB: 'emailCampaignSignup:unparseableDateOfBirth',
  EMAIL_CAMPAIGN_SIGNUP_NICKNAME_ALREADY_TAKEN: 'emailCampaignSignup:nicknameAlreadyTaken',
  EMAIL_CAMPAIGN_SIGNUP_ATTEMPTED: 'emailCampaignSignup:registrationAttempted',
  EMAIL_CAMPAIGN_SIGNUP_SUCCESS: 'emailCampaignSignup:registrationSuccess',
  EMAIL_CAMPAIGN_SIGNUP_AGREEMENT_NOT_CHECKED: 'emailCampaignSignup:agreementNotChecked',
  EMAIL_CAMPAIGN_SIGNUP_AGREEMENT_VIEWED: 'emailCampaignSignup:agreementViewed',
  EMAIL_CAMPAIGN_SIGNUP_NICKNAME_TOO_LONG: 'emailCampaignSignup:nicknameTooLong',
  EMAIL_CAMPAIGN_SIGNUP_DOB_ATTEMPTS_EXCEEDED: 'emailCampaignSignup:dateOfBirthAttemptsExceeded',
  EMAIL_CAMPAIGN_SIGNUP_WEAK_PASSWORD: 'emailCampaignSignup:passwordTooWeak',
  ZENDESK_WEB_WIDGET_OPENED: 'Zendesk:Web_Widget_Opened',
  ZENDESK_WEB_WIDGET_MINIMISED: 'Zendesk:Web_Widget_Minimised',
  ZENDESK_CONTACT_FORM_SHOWN: 'Zendesk:Contact_Form_Shown',
  ZENDESK_CONTACT_FORM_SUBMITTED: 'Zendesk:Contact_Form_Submitted',
  ZENDESK_CONTACT_FORM_MINIMISED: 'Zendesk:Contact_Form_Minimised',
  ZENDESK_CHAT_OPENED: 'Zendesk:Chat_Opened',
  ZENDESK_CHAT_SHOWN: 'Zendesk:Chat_Shown',
  ZENDESK_CHAT_SERVED_BY_OPERATOR: 'Zendesk:Chat_Served_by_Operator',
  ZENDESK_CHAT_RATING_GOOD: 'Zendesk:Chat_Rating_Good',
  ZENDESK_CHAT_RATING_BAD: 'Zendesk:Chat_Rating_Bad',
  ZENDESK_CHAT_RATING_REMOVED: 'Zendesk:Chat_Rating_Removed',
  ZENDESK_CHAT_COMMENT_SUBMITTED: 'Zendesk:Chat_Comment_Submitted',
  MASTER_LIST_SORTED: 'masterList:sort',
  MASTER_LIST_FILTERED: 'masterList:filtered',
  TASK_LIST_SORTED: 'taskList:sort',
  TASK_LIST_FILTERED: 'taskList:filtered',
  LAB_ORDERS_LIST_SORTED: 'labOrdersList:sort',
  LAB_ORDERS_LIST_FILTERED: 'labOrdersList:filtered',
  LAB_RESULTS_LIST_SORTED: 'labResultsList:sort',
  LAB_RESULTS_LIST_FILTERED: 'labResultsList:filtered',
  PATIENT_SEARCH_TAB_SEARCH: 'PatientSearchTab:Search',
  PATIENT_SEARCH_TAB_CLICK_RESULT: 'PatientSearchTab:clickResult',
  PRINT_MAIL_SULP_PAGE_LOAD_SUCCESS: 'printMailSULP:pageLoadSuccess',
  PRINT_MAIL_SULP_ELIGIBILITY_LINK_CLICKED: 'printMailSULP:eligibilityLinkClicked',
  PRINT_MAIL_SULP_CODE_SUBMITTED: 'printMailSULP:codeSubmitted',
  PRINT_MAIL_SULP_CODE_FAILURE: 'printMailSULP:codeSubmissionFailed',
  PRINT_MAIL_SULP_HELP_CENTER_CLICKED: 'printMailSULP:helpCenterClicked',
  AGREEMENTS_DISPLAY_MODAL: 'agreements:displayModal',
  TRACKING_CHANGE_LANGUAGE: 'tracking:changeLanguage',
  SYNC_ENROLLMENT_STATE: 'tracking:syncEnrollmentState',
  REQUESTED_CALENDAR_INVITE: 'requestedCalendarInvite',
  SCHEDULED_PATIENT_APPOINTMENT: 'scheduledPatientAppointment',
  CREATED_NEW_REFERRAL: 'createdNewReferral',
  UPDATED_REFERRAL_INFORMATION: 'updatedReferralInformation',
  DELETED_REFERRAL_INFORMATION: 'deletedReferralInformation',
  CONVERTED_TO_ONLINE_ACCOUNT: 'convertedToOnlineAccount',
  OFFLINE_REGISTRATION_ATTEMPTED: 'offlineRegistrationAttempted',
  OFFLINE_REGISTRATION_NICKNAME_TOO_LONG: 'offlineRegistrationNicknameTooLong',
  OFFLINE_REGISTRATION_NICKNAME_ALREADY_EXISTS: 'offlineRegistrationNicknameAlreadyExists',
  OFFLINE_REGISTRATION_INVALID_DOB: 'offlineRegistrationInvalidDob',
  OFFLINE_REGISTRATION_DOB_ATTEMPTS_EXCEEDED: 'offlineRegistrationDobAttemptsExceeded',
  OFFLINE_REGISTRATION_PAGE_LOADED: 'offlineRegistrationPageLoaded',
  UPDATE_PATIENT_CARE_MODALITY: 'updatePatientCareModality',
  NICKNAME_TOO_LONG: 'nicknameTooLong',
  NICKNAME_ALREADY_TAKEN: 'nicknameAlreadyTaken',
  PARTNER_INVITATION_SENT: 'partnerInvitationSent',
  ...LoginEvent,
  ...EnrollmentFormEvent,
  ...PatientEvent, //these can be frontend events
});

const TagsEvent = Object.freeze({
  ENROLLMENT_BARRIERS_UPDATED: 'tags:enrollmentBarriers:update',
  INTEREST_FACTORS_UPDATED: 'tags:interestFactors:update',
  UNCATEGORIZED_UPDATED: 'tags:uncategorized:update',
});

const PHIProfileEvent = Object.freeze({
  UPDATE_PHIPROFILE: 'PHIProfile:update',
});

const OutreachScheduleEvents = Object.freeze({
  SCHEDULE_EVENT: 'outreachScheduleEvents:scheduleEvent',
  CANCEL_EVENT: 'outreachScheduleEvents:cancelEvent',
  COMPLETE_EVENT: 'outreachScheduleEvents:completeEvent',
});

const PhoneNumberEvent = Object.freeze({
  PHONE_NUMBER_CREATED: 'phoneNumber:created',
  PHONE_NUMBER_UPDATED: 'phoneNumber:updated',
});

module.exports = Object.freeze({
  LoginEvent,
  PatientEvent,
  MonthlyActiveUserEvents,
  CommunicationEvent,
  MilestoneEvents,
  InteractionNudgeEvents,
  TagsEvent,
  PHIProfileEvent,
  OutreachScheduleEvents,
  DebugEvents: {
    Client_Chirp_Connection_Event: 'clientChirpConnectionEvent',
    Client_Handle_Submit_Phone_Note: 'clientHandleSubmitPhoneNote',
    Client_Handle_Submit_Assessment: 'clientHandleSubmitAssessment',
  },
  AdministrativeEvent: Object.freeze({
    CHANGE_PROGRAM_ACCESS: 'changeProgramAccess',
    SENT_EMAIL: 'sentEmail',
    SENT_MAIL: 'sentMailLetter',
    LOB_PROXY_NOT_SUCCESSFUL: 'lobProxyNotSuccessful',
    REQUESTED_FOLLOW_UP_ENROLLMENT_EMAIL: 'requestedFollowUpEnrollmentEmail',
    REQUESTED_SELF_REFERRAL_SIGN_UP_EMAIL: 'requestedSelfReferralSignUpEmail',
    REQUESTED_EMAIL: 'requestedEmail',
  }),
  NavTrackingEvent: Object.freeze({
    AUTHENTICATION: 'navTracking:authentication',
    NAVIGATION: 'navTracking:navigation',
    START_FOCUS: 'navTracking:startFocus',
    END_FOCUS: 'navTracking:endFocus',
    DISCONNECTION: 'navTracking:disconnection',
  }),
  TrackingEvent,
  StateTransitionEvent: StateTransitionEvent,
  Life365OrderEvent: Object.freeze({
    ORDER_REQUESTED: 'Life365Order:requested',
    ORDER_REQUESTED_DELAYED: 'Life365Order:requested-delayed',
    ORDER_PLACED: 'Life365Order:placed',
    ORDER_PLACED_DELAYED: 'Life365Order:placed-delayed',
    ORDER_RECEIVED: 'Life365Order:received',
    ORDER_RECEIVED_DELAYED: 'Life365Order:received-delayed',
    ORDER_SHIPPED: 'Life365Order:shipped',
    ORDER_SHIPPED_DELAYED: 'Life365Order:shipped-delayed',
    ORDER_DELIVERED: 'Life365Order:delivered',
    ORDER_UNDELIVERABLE: 'Life365Order:undeliverable',
    ORDER_RETURNED: 'Life365Order:returned',
    ORDER_OPTED_OUT: 'Life365Order:opted-out',
  }),
  Life365HubEvent: Object.freeze({
    REQUESTED: 'Life365Hub:requested',
    REQUESTED_DELAYED: 'Life365Hub:requested-delayed',
    PLACED: 'Life365Hub:placed',
    PLACED_DELAYED: 'Life365Hub:placed-delayed',
    RECEIVED: 'Life365Hub:received',
    RECEIVED_DELAYED: 'Life365Hub:received-delayed',
    SHIPPED: 'Life365Hub:shipped',
    SHIPPED_DELAYED: 'Life365Hub:shipped-delayed',
    UNDELIVERABLE: 'Life365Hub:undeliverable',
    DELIVERED: 'Life365Hub:delivered',
    RETURNED: 'Life365Hub:returned',
    OPTED_OUT: 'Life365Hub:opted-out',
    NOT_INSTALLED: 'Life365Hub:not-installed',
    ACTIVE: 'Life365Hub:active',
    INACTIVE_SHORT_TERM: 'Life365Hub:inactive-short',
    INACTIVE_LONG_TERM: 'Life365Hub:inactive-long',
    MALFUNCTION: 'Life365Hub:malfunction',
    DEACTIVATED: 'Life365Hub:deactivated',
    LOW_BATTERY: 'Life365Hub:low-battery',
    DEAD_BATTERY: 'Life365Hub:dead-battery',
  }),
  Life365DeviceEvent: Object.freeze({
    REQUESTED: 'Life365Device:requested',
    REQUESTED_DELAYED: 'Life365Device:requested-delayed',
    PLACED: 'Life365Device:placed',
    PLACED_DELAYED: 'Life365Device:placed-delayed',
    RECEIVED: 'Life365Device:received',
    RECEIVED_DELAYED: 'Life365Device:received-delayed',
    SHIPPED: 'Life365Device:shipped',
    SHIPPED_DELAYED: 'Life365Device:shipped-delayed',
    UNDELIVERABLE: 'Life365Device:undeliverable',
    DELIVERED: 'Life365Device:delivered',
    RETURNED: 'Life365Device:returned',
    OPTED_OUT: 'Life365Device:opted-out',
    NOT_INSTALLED: 'Life365Device:not-installed',
    ACTIVE: 'Life365Device:active',
    INACTIVE_SHORT_TERM: 'Life365Device:inactive-short',
    INACTIVE_LONG_TERM: 'Life365Device:inactive-long',
    MALFUNCTION: 'Life365Device:malfunction',
    DEACTIVATED: 'Life365Device:deactivated',
  }),
  BiometricEvent: BiometricEvent,
  PhoneNumberEvent,
  FourOhFour: Object.freeze({
    LOGGED_IN: '404:loggedIn',
    NOT_LOGGED_IN: '404:notLoggedIn',
  }),
  PatientEmail: Object.freeze({
    PATIENT_EMAIL_CREATE_OR_UPDATE_STATUS: 'patientEmail:createdOrUpdateStatus',
    PATIENT_EMAIL_FIRST_EMAIL_CREATED: 'patientEmail:firstEmailCreated',
    PATIENT_EMAIL_VERIFICATION_ATTEMPT: 'patientEmail:verificationAttempt',
    PATIENT_EMAIL_SET_WORKING_STATUS: 'patientEmail:setWorkingStatus',
    PATIENT_EMAIL_SET_SUBSCRIPTION_STATUS: 'patientEmail:setSubscriptionStatus',
  }),
  AddressEvent: Object.freeze({
    CREATE_ADDRESS: 'address:create',
    CHANGE_ADDRESS_TYPE: 'address:changeType',
    SET_INACTIVE_REASON: 'address:setInactive',
    SET_ACTIVE: 'address:setActive',
    UPDATE_ADDRESS_COUNTY: 'address:updateCounty',
  }),
  Billing: Object.freeze({
    UPLOAD_TRUE_UP_REPORT: 'system:trueUpReport:upload',
  }),
  OffboardingActionEvents: Object.freeze({
    MAIL_PRINT_LETTER: 'offboardingActions:mailPrintLetter',
    SEND_EMAIL: 'offboardingActions:sendEmail',
    COLLECT_MEDICAL_DEVICE: 'offboardingActions:collectMedicalDevice',
    PATIENT_VOICEMAIL: 'offboardingActions:patientVoicemail',
    CLINICAL_CHAT: 'offboardingActions:clinicalChat',
    SENT_TRANSITION_CARE_RECORD_TO_PATIENT: 'offboardingActions:sentTransitionCareRecordToPatient',
    SEND_HANDWRITTEN_NOTE: 'offboardingActions:sendHandwrittenNote',
    PROVIDER_VOICEMAIL: 'offboardingActions:providerVoicemail',
    SENT_TRANSITION_CARE_RECORD_TO_PROVIDER:
      'offboardingActions:sentTransitionCareRecordToProvider',
  }),
  UserLabEvents: Object.freeze({
    ADD_USER_LAB: 'userLab:add',
    UPDATE_USER_LAB: 'userLab:update',
    HIDE_USER_LAB: 'userLab:hide',
  }),
  ClinicalScheduledEventEvents: Object.freeze({
    UPDATE_CLINICAL_SCHEDULED_EVENT: 'updateUserIntakeTask',
    ADD_CLINICAL_SCHEDULED_EVENT: 'addUserIntakeTask',
    APPOINTMENT_WINDOW_STARTED: 'appointmentWindowStarted',
  }),
  CKDStageEvent: Object.freeze({
    UPDATE_PATIENT_CKD_STAGE: 'updatePatientCKDStage',
  }),
  ClinicalLicense: Object.freeze({
    SET_USER_CLINICAL_LICENSE: 'setUserClinicalLicense',
  }),
  TaskEvent: Object.freeze({
    CREATE_TASK: 'task:create',
    UPDATE_TASK: 'task:update',
    PATCH_TASK: 'task:patch',
  }),
  CommentEvent: Object.freeze({
    CREATE_COMMENT: 'comment:create',
  }),
  WorkflowEvent: Object.freeze({
    CREATE_WORKFLOW: 'workflow:create',
    UPDATE_WORKFLOW: 'workflow:update',
  }),
  NoteEvent: Object.freeze({
    DOWNLOAD_AS_PDF: 'note:downloadAsPdf',
    PATIENT_DOWNLOAD_NEPHROLOGIST_REPORT: 'note:patientDownloadNephrologistReport',
    PATIENT_DOWNLOAD_PCP_REPORT: 'note:patientDownloadPCPReport',
    CREATE_CARE_PLAN_FROM_ASSESSMENT: 'note:createCarePlan',
    HIDE_NOTE: 'hideNote',
    SET_HIDE_FROM_PRO: 'setHideFromPro',
  }),
  PatientDocumentEvent: Object.freeze({
    DELETE_DOCUMENT: 'deleteDocument',
  }),
  EMRLinkEvent: Object.freeze({
    CREATE_ORDER: 'EMRLinkOrder:create',
    UPDATE_ORDER: 'EMRLinkOrder:update',
    CREATE_RESULT: 'EMRLinkResult:create',
    UPDATE_RESULT: 'EMRLinkResult:update',
  }),
  MockEMRLinkEvent: Object.freeze({
    CREATE_ORDER: 'MockEMRLinkOrder:create',
    SEND_ORDER: 'MockEMRLinkOrder:send',
    MODIFY_ORDER: 'MockEMRLinkOrder:modify',
    REMOVE_ORDER: 'MockEMRLinkOrder:remove',
    DOWNLOAD_RESULTS: 'MockEMRLinkResults:download',
  }),
  RiskAssessmentEvent: Object.freeze({
    UPDATED_RISK_ASSESSMENT: 'riskAssessment:update',
    UPDATED_RISK_CALCULATOR_CATEGORY_FROM_RISK_CALCULATOR: 'riskCalculatorCategory:update',
  }),
  RoundingFrequencyEvent: Object.freeze({
    UPDATED_ROUNDING_FREQUENCY: 'roundingFrequency:update',
  }),
  AssessmentEvent: Object.freeze({
    SIDEBAR_INTERACTION: 'assessment:sidebarInteraction',
  }),
  ChirpSocket: Object.freeze({
    MANUAL_RECONNECT: 'chirpsocket:manual-reconnect',
  }),
  PatientProblemEvent: Object.freeze({
    CREATE_PATIENT_PROBLEM: 'patientProblem:create',
    UPDATE_PATIENT_PROBLEM: 'patientProblem:update',
    HIDE_PATIENT_PROBLEM: 'patientProblem:hide',
    COMPLETED_INTERVENTION: 'patientProblemIntervention:complete',
    COMPLETED_SUB_INTERVENTION: 'patientProblemSubIntervention:complete',
  }),
  Braze: {
    SET_SUBSCRIPTION_GROUP_STATUS: 'braze:setSubscriptionStatus',
    SET_GLOBAL_STATUS: 'braze:setGlobalStatus',
  },
  BrazeNotificationEvent: {
    SEND_SCHEDULED_APPOINTMENT_REMINDER: 'brazeNotificationEvent:sendScheduledAppointmentReminder',
  },
  AuditEvent: {
    ADD_PATIENT_CHART_AUDIT: 'audit:addPatientChartAudit',
  },
});
