import React from 'react';

const HeartFull = (props) => {
  const { ...restProps } = props;
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      alt="icon of filled in graphical heart"
      title="icon of filled in graphical heart"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M22.5 5.66112C22.4428 4.09897 21.7993 2.62494 20.7113 1.56391C19.6233 0.502883 18.1802 -0.058044 16.7 0.00475852C14.6643 -0.0170356 12.7638 1.07766 11.68 2.89626C11.6466 2.96907 11.5767 3.01532 11.5 3.01532C11.4233 3.01532 11.3534 2.96907 11.32 2.89626C10.2362 1.07766 8.33568 -0.0170356 6.3 0.00475852C4.81979 -0.058044 3.37666 0.502883 2.28869 1.56391C1.20072 2.62494 0.557211 4.09897 0.5 5.66112C0.5 5.66112 0.5 5.80886 0.5 5.89328C0.5 5.97771 0.5 6.14655 0.5 6.33651C0.5 12.225 8.78 19 11.5 19C14.22 19 22.5 12.225 22.5 6.33651C22.5 6.18876 22.5 6.06213 22.5 5.93549C22.5 5.80886 22.5 5.74554 22.5 5.66112Z"
        fill="white"
      />
    </svg>
  );
};

export default HeartFull;
