import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashWithX } from '../icons/TrashWithX.svg';
import MyCricketColors from '../../data/MyCricketColors';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { classesPropType } from '../../utils/patientPropTypes';
import { BaseButton } from '../atoms/Buttons';
import UploadFile, { sendFilesToBackEnd } from '../atoms/UploadFileInMessage';
import { useCareTeamContext } from '../pages/CareTeam';
import Toast from '../atoms/Toast';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import { logClientError } from '../../utils/errorLogging';
import { Roles } from '../../data/Roles';

const styles = (theme) => ({
  inputWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  // Unsure why this button needed a custom border but it looks like a rounded button without it
  careTeamMessageEntrySubmitButton: {
    margin: '0 2px 0 6px',
    borderRadius: '12px',
    maxHeight: '50px',
  },
  fileAttachmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 6px 6px 12px',
    backgroundColor: theme.palette.paleTeal,
    width: 'fit-content',
    borderRadius: '6px',
  },
  trashcanWrapper: {
    display: 'flex',
    marginLeft: '11px',
    cursor: 'pointer',
  },
  fieldSet: {
    border: 0,
    padding: 0,
  },
});

function CareTeamMessageEntry(props) {
  const { classes } = props;
  const {
    sendMessage,
    isConnected,
    getGroupFromSelectedId,
    getComposedMessageData,
    setComposedMessageData,
  } = useChirpContext();
  const { selectedGroupId, selectedCareTeamMember } = useCareTeamContext();

  const composedDataKey = `${selectedGroupId}`;
  const { text: initialText, files: initialFiles } = getComposedMessageData(composedDataKey);
  const [messageText, setMessageText] = useState(initialText);
  const [messageFiles, setMessageFiles] = useState(initialFiles);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [shouldBlockButton, setShouldBlockButton] = useState(false);

  const { t } = useTranslation();

  const isMentorPatientGroup =
    selectedCareTeamMember.role === Roles.PATIENT || selectedCareTeamMember.role === Roles.MENTOR;

  useEffect(() => {
    const { text, files } = getComposedMessageData(composedDataKey);
    setMessageText(text);
    setMessageFiles(files);
  }, [composedDataKey, getComposedMessageData]);

  const handleChange = (event) => {
    setMessageText(event.target.value);
    setComposedMessageData(composedDataKey, { text: event.target.value });
  };

  const handleFileUpdate = (files = []) => {
    let newFiles = [...messageFiles, ...files];
    if (newFiles.length > 10) {
      newFiles = newFiles.slice(0, 10);
      toast.error(
        () => (
          <Toast
            message={t('careTeamFileLimitMessage', 'You can only send up to 10 files at a time')}
          />
        ),
        {
          toastId: 'too-many-files',
          autoClose: 5000,
        },
      );
    }
    setMessageFiles(newFiles);
    setComposedMessageData(composedDataKey, { files: newFiles });
  };

  const clearInput = () => {
    setMessageText('');
    setComposedMessageData(composedDataKey, { text: '' });
  };

  const clearMessageFiles = () => {
    setMessageFiles([]);
    setComposedMessageData(composedDataKey, { files: [] });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting || (!messageText && messageFiles.length === 0)) {
      return;
    }
    setSubmitting(true);
    setErrorMessage(null);
    try {
      if (messageText) {
        try {
          await sendMessage({ messageText, selectedGroupId });
          clearInput();
        } catch {
          setErrorMessage(t('sendMessageErrorRetry'));
          //we won't even try to send the files
          return;
        }
      }
      if (messageFiles.length > 0) {
        const group = getGroupFromSelectedId(selectedGroupId);
        try {
          const results = await sendFilesToBackEnd(
            messageFiles,
            group?.name,
            selectedCareTeamMember?.cricketUserId,
          );
          for (const result of results) {
            const fileId = result?.fileId;
            const fileType = result?.fileType;
            if (fileId && fileType) {
              const file = { fileId, fileType, fileName: result?.fileName };
              await sendMessage({
                file,
                selectedGroupId,
                childEventPrepositions: { messageType: 'File Message' },
              });
            }
          }
          clearMessageFiles();
        } catch (error) {
          if (error.status >= 500) {
            setErrorMessage(t('fileUploadErrorGeneric', 'There was an error uploading your file'));
            logClientError(error);
          } else {
            const results = error.responseBody?.results;
            if (results) {
              const firstFailedResult = results.find((result) => !result.success);
              if (firstFailedResult) {
                if (firstFailedResult.reason === 'too-large') {
                  setErrorMessage(
                    t('fileUploadErrorSize', {
                      defaultValue: `The file '${firstFailedResult.fileName}' is too large`,
                      replace: { fileName: firstFailedResult.fileName },
                    }),
                  );
                } else if (firstFailedResult.reason === 'unsupported-type') {
                  setErrorMessage(
                    t('fileUploadErrorType', 'Only PNG, JPEG, GIF or PDF files can be uploaded'),
                  );
                } else {
                  setErrorMessage(
                    t('fileUploadErrorInvalid', {
                      defaultValue: `The file '${firstFailedResult.fileName}' is invalid`,
                      replace: { fileName: firstFailedResult.fileName },
                    }),
                  );
                }
              }
            } else {
              setErrorMessage(
                t('fileUploadErrorGeneric', 'There was an error uploading your file'),
              );
            }
          }
        }
      }
    } catch (e) {
      console.error('Error sending message: ', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      width={'100%'}
      minHeight={'78px'}
      mb={{ xs: 0, sm: 0, md: 2 }}
      px={{ xs: 0, sm: 0, md: 2 }}
    >
      <Box
        padding={2}
        width={'100%'}
        height={'100%'}
        bgcolor={MyCricketColors.white}
        border={`1px solid ${MyCricketColors.lightGrayHaze}`}
        boxSizing="border-box"
        boxShadow="0px 3px 4px rgba(0, 62, 81, 0.1)"
        borderRadius={{ xs: 0, sm: 0, md: '12px' }}
        flexShrink={0}
      >
        <fieldset
          data-test-id="careTeamMessageEntryInputWrapper"
          data-testid="careTeamMessageEntryInputWrapper"
          disabled={!isConnected}
          className={classes.fieldSet}
        >
          <form data-test-id="careTeamMessageEntryForm">
            <Box className={classes.inputWrapper}>
              {isMentorPatientGroup ? <></> : <UploadFile handleFiles={handleFileUpdate} />}
              <DecoratedChatInput
                type="text"
                value={messageText}
                onChange={handleChange}
                data-test-id="careTeamMessageEntryInput"
                maxRows={5}
                placeholder={t('careTeamMessageInputPlaceholder', 'Write message here...')}
                blockButton={setShouldBlockButton}
              />
              {/* This box stops the button from growing in height */}
              <Box>
                <BaseButton
                  role="button"
                  aria-label={
                    isSubmitting ? 'Sending...' : 'Click to Send' + ` message to care provider`
                  }
                  type="submit"
                  data-test-id="careTeamMessageEntrySendButton"
                  className={classes.careTeamMessageEntrySubmitButton}
                  disabled={isSubmitting || shouldBlockButton}
                  onClick={onSubmit}
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </BaseButton>
              </Box>
            </Box>
            {errorMessage && (
              <Box pt={1} color={'red'}>
                {errorMessage}
              </Box>
            )}
            {messageFiles.length > 0 && (
              <Box display={'flex'} flexWrap={'wrap'}>
                {messageFiles.map((file, index) => (
                  <Box
                    key={`file-${index}`}
                    className={classes.fileAttachmentWrapper}
                    mr={1.5}
                    mt={1}
                  >
                    {file.name}
                    <Box
                      className={classes.trashcanWrapper}
                      onClick={() => {
                        const filteredFiles = messageFiles.filter(
                          (_, fileIndex) => fileIndex !== index,
                        );
                        setMessageFiles(filteredFiles);
                        setComposedMessageData(composedDataKey, { files: filteredFiles });
                      }}
                    >
                      <TrashWithX />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </form>
        </fieldset>
      </Box>
    </Box>
  );
}

CareTeamMessageEntry.propTypes = {
  classes: classesPropType.isRequired,
};

export default withStyles(styles, { withTheme: true })(CareTeamMessageEntry);
