import { Box, LinearProgress, Fade, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { useTranslation } from 'react-i18next';

const BorderLinearProgress = styled(LinearProgress)({
  'height': 30,
  'zIndex': 0,
  'borderRadius': '9999px',
  'backgroundColor': MyCricketColors.white,
  '.MuiLinearProgress-bar': {
    height: 30,
    borderRadius: '9999px',
    background: `linear-gradient(${MyCricketColors.brightMint}, ${MyCricketColors.mint})`,
  },
});

export const ProviderMatchingLoader = ({ progress, completeLoading }) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const { t } = useTranslation('match', { useSuspense: false });

  useEffect(() => {
    if (progress === 100) {
      const timer = setTimeout(() => {
        setShouldFadeOut(true);
      }, 1250);
      return () => clearTimeout(timer);
    }
  }, [progress]);

  return (
    <Fade in={!shouldFadeOut} timeout={{ enter: 0, exit: 500 }} onExit={() => completeLoading()}>
      <Box
        width={'100%'}
        height={'100%'}
        pt={34}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box width={{ xs: '100%', md: '400px' }} mb={4} px={{ xs: 7, md: 0 }}>
          {/* setting progress over 100 makes the bar retract, since we let progress go to 120 to have a delay after the bar fills, set the min of progress or 100 */}
          <BorderLinearProgress variant="determinate" value={Math.min(progress, 100)} />
        </Box>
        <Typography variant="subtitle1">{t('matching')}</Typography>
      </Box>
    </Fade>
  );
};

export default ProviderMatchingLoader;
