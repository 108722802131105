import { Box, Link, Typography } from '@mui/material';
import { ReportProblem } from '@mui/icons-material';
import React from 'react';
import { Trans } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { SUPPORT_EMAIL } from '../../data/SupportContactInfo';
import { MagicLinkErrorCodes } from '../pages/MagicLinkRedirectPage';
import { TranslatedTypography } from './TranslatedTypography';

export const MagicLinkError = ({ errorCode }) => {
  return (
    <Box width={'100%'} py={1} display={'flex'} flexDirection={'row'} alignItems={'center'} px={2}>
      <Box pr={2}>
        <ReportProblem style={{ color: MyCricketColors.notificationRed }} />
      </Box>
      {Object.values(MagicLinkErrorCodes).includes(errorCode) ? (
        <TranslatedTypography variant="h3">
          Your login link may have expired or been used previously. You can request a new link
          below.
        </TranslatedTypography>
      ) : (
        <Typography variant={'h3'}>
          <Trans
            i18nKey="webapp:MagicLinkUnknownError"
            defaults=" Something went wrong. Please try again or reach out to <email>{{supportEmail}}</email> if you
              continue to see this message."
            values={{
              supportEmail: SUPPORT_EMAIL,
            }}
            components={{
              email: <Link href={`mailto:${SUPPORT_EMAIL}`} underline={'none'} noWrap />,
            }}
          />
        </Typography>
      )}
    </Box>
  );
};
