import React, { useState } from 'react';
import { Box, Typography, InputLabel } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { RoundButton, ClickableBox } from '../atoms/Buttons';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import MyCricketColors from '../../data/MyCricketColors';
import LoginError from '../../data/LoginError';
import LeftArrow from '../icons/LeftArrow';
import { ReactComponent as CheckEmail } from '../icons/CheckEmail.svg';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { useLocation } from 'react-router-dom';

const secondaryButtonStyle = {
  'width': '100%',
  'height': '50px',
  'color': MyCricketColors.black,
  'borderColor': MyCricketColors.lightGraySmoke,
  '&:hover': {
    'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
    'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
    'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
    'background': MyCricketColors.white,
  },
};

const MagicLinkForm = ({
  state,
  setState,
  email,
  handleChange,
  sendPasswordlessLoginLink,
  error,
  hasEdited,
  emailFocused,
  emailValid,
  isPrimaryLoginPage,
}) => {
  const [isSent, setSent] = useState(false);
  const { t } = useTranslation();
  const { instrument } = useClientEventLoggerContext();
  const location = useLocation();
  const redirectParams =
    location.state && location.state.referrer
      ? location.search
        ? location.state.referrer + location.search
        : location.state.referrer
      : null;
  return (
    <>
      {isPrimaryLoginPage ? (
        !isSent && (
          <Box mb={4}>
            <TranslatedTypography variant="h2" data-test-id="patientLoginHeader" aria-level="1">
              Log in to MyCricket
            </TranslatedTypography>
          </Box>
        )
      ) : (
        <ClickableBox
          bgcolor={MyCricketColors.white}
          mb={4}
          onClick={instrument({
            eventName: 'clickedBackToLoginFromMagicLink',
            callback: () =>
              setState({ ...state, route: 'main', error: null, showMagicLinkError: false }),
          })}
        >
          <Box display={'flex'}>
            <Box component={'span'} mr={1}>
              <LeftArrow color={MyCricketColors.tealMain} />
            </Box>
            <TranslatedTypography variant={'body1'}>Back to Log in</TranslatedTypography>
          </Box>
        </ClickableBox>
      )}
      {isSent ? (
        <Box textAlign={'center'}>
          <Box display={'flex'} justifyContent={'center'} mb={4}>
            <CheckEmail role={'none'} />
          </Box>
          <Box mb={4}>
            <TranslatedTypography variant={'h2'} i18nKey={'magicLinkConfirmTitle'} aria-level="1">
              Check your email
            </TranslatedTypography>
          </Box>
          <Box mb={3}>
            <TranslatedTypography variant={'body2'} i18nKey={'magicLinkConfirmDescription'}>
              {
                'If there is an account associated with this email, then you’ll receive a link that will log you in.'
              }
            </TranslatedTypography>
          </Box>
          <Box mb={3}>
            <Typography variant={'body2'}>
              <Trans
                t={t}
                i18nKey={'magicLinkConfirmExpirationNotice'}
                defaults={'The link will expire in {{expirationMinutes}} minutes.'}
                values={{ expirationMinutes: 10 }}
              />
            </Typography>
          </Box>
          <Box>
            <RoundButton
              variant={'outlined'}
              type={'button'}
              i18nKey={'magicLinkConfirmCTA'}
              style={secondaryButtonStyle}
              onClick={instrument({
                eventName: 'clickedDidNotReceiveMagicLink',
                callback: () => setSent(false),
              })}
            >
              {"Didn't receive a link?"}
            </RoundButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb={4}>
            {isPrimaryLoginPage ? (
              <TranslatedTypography variant={'body2'} i18nKey={'magicLinkSubtitle'} aria-level="2">
                We can send you an email link to log in, or you can type your password.
              </TranslatedTypography>
            ) : (
              <TranslatedTypography variant={'h2'} i18nKey={'magicLinkSendTitle'} aria-level="1">
                {"Enter your email and we'll send you a login link"}
              </TranslatedTypography>
            )}
          </Box>
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              if (email.length === 0) {
                setState({ ...state, error: LoginError.NEED_EMAIL });
              } else if (!emailValid) {
                // Using invalid email logic which is outside this error logic
                setState({ ...state, error: null });
              } else {
                setState({ ...state, error: null });
                try {
                  await sendPasswordlessLoginLink(email, redirectParams);
                  setSent(true);
                } catch (error) {
                  setState({
                    ...state,
                    error: 'We have encountered an issue, please try again later.',
                  });
                }
              }
            }}
          >
            <Box mb={1}>
              <InputLabel htmlFor={'email-address'}>
                <TranslatedTypography variant={'h3'} i18nKey={'emailAddress'} aria-level="2">
                  Email Address
                </TranslatedTypography>
              </InputLabel>
            </Box>
            <Box>
              <DecoratedChatInput
                id={'email-address'}
                name={'email'}
                type={'email'}
                placeholder={t('emailAddress', 'Email Address')}
                isMultiline={false}
                value={email}
                onChange={handleChange}
                inputStyle={{ height: 50 }}
              />
            </Box>
            {error === LoginError.NEED_EMAIL && (
              <Box color={MyCricketColors.notificationRed} mt={1}>
                <TranslatedTypography>{LoginError.NEED_EMAIL}</TranslatedTypography>
              </Box>
            )}
            {hasEdited && !emailFocused && !emailValid && (
              <Box color={MyCricketColors.notificationRed}>
                <TranslatedTypography>{LoginError.INVALID_EMAIL}</TranslatedTypography>
              </Box>
            )}
            <Box width={'100%'} mt={4}>
              <RoundButton
                type={'submit'}
                i18nKey={'magicLinkSendCTA'}
                style={{ width: '100%', height: 50 }}
              >
                Send me a link
              </RoundButton>
            </Box>
            {isPrimaryLoginPage && (
              <>
                <Box my={3} textAlign={'center'}>
                  <Typography variant={'body1'}>{t('or', 'or')?.toUpperCase()}</Typography>
                </Box>
                <RoundButton
                  type="button"
                  variant={'outlined'}
                  i18nKey={'typePasswordCTA'}
                  style={secondaryButtonStyle}
                  textStyle={{ lineHeight: 1 }}
                  onClick={instrument({
                    eventName: 'clickedTypePasswordFromMagicLink',
                    callback: () => setState({ ...state, route: 'main', error: null }),
                  })}
                >
                  Type My Password
                </RoundButton>
              </>
            )}
            {error && !Object.values(LoginError).includes(error) && (
              <Box color={MyCricketColors.notificationRed} mt={2}>
                <TranslatedTypography>{error}</TranslatedTypography>
              </Box>
            )}
          </form>
        </Box>
      )}
    </>
  );
};

export default MagicLinkForm;
