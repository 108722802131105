import React from 'react';
import { HorizontalChevron } from './Chevrons';
import { ClickableBox } from './Buttons';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from './TranslatedTypography';

export const BackNav = (props) => {
  const { link, history } = props;
  if (!link || !history) {
    console.error('BackNav is missing props: ', link, history);
    return;
  }
  return (
    <ClickableBox
      display="flex"
      alignItems={'center'}
      justifyContent={'left'}
      data-test-id="backNav"
      style={{ backgroundColor: MyCricketColors.lightGrayFog, cursor: 'pointer' }}
      onClick={() => history.push(link)}
    >
      <HorizontalChevron pointsLeft={true} size="large" />
      <TranslatedTypography variant="subtitle1">Back</TranslatedTypography>
    </ClickableBox>
  );
};
