export const AUTH0_ERROR_TYPES = {
  PASSWORD_HISTORY_ERROR: 'PasswordHistoryError',
  PASSWORD_STRENGTH_ERROR: 'PasswordStrengthError',
  PASSWORD_DICTIONARY_ERROR: 'PasswordDictionaryError',
  PERSONAL_DATA_ERROR: 'PersonalDataError',
  PASSWORD_NO_USER_INFO_ERROR: 'PasswordNoUserInfoError',
};

const handlePasswordSignupError = (errorMessage, setPasswordErr) => {
  const t = function (name) {
    if (window && window.i18next) {
      return window.i18next.t(name, 'An error occurred');
    }
  };

  const auth0Error = Object.values(AUTH0_ERROR_TYPES).find(
    (v) => errorMessage && errorMessage.includes(v),
  );
  if (auth0Error) {
    setPasswordErr(t(auth0Error));
    return true;
  }
};

export default handlePasswordSignupError;
