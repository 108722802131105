import { Box } from '@mui/material';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../utils/innerHtmlUtils';
import { ScrollBox } from '../atoms/ScrollBox';
import TopNav from '../organisms/TopNav';
import PatientErrorBoundary from '../../utils/PatientErrorBoundary';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { ModalContext } from '../../utils/contexts/ModalContext';
import URIUtils from '../../utils/URIUtils.js';
import { PatientEvent } from '../../data/Events';
import InterruptManager from '../../utils/InterruptManager';
import ModalOverlayContainer from '../pages/ModalOverlayContainer';
import { restrictTabFocusToElementWithId } from '../../utils/domUtils';

const Page = ({
  title,
  hideNavBarTabs = false,
  showLanguageButton = false,
  children,
  disableScroll = false,
  topNavMessage = '',
  skipPageEventLog = false,
}) => {
  // smartVh and smartVw are a hooks based take on -> https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const { width, height } = useWindowSize();
  const [smartVh, setSmartVh] = useState(height / 100);
  const [smartVw, setSmartVw] = useState(width / 100);
  const location = useLocation();
  const { logEvent } = useClientEventLoggerContext();

  const scrollRef = useRef();
  const [currentModal, setCurrentModal] = useState(null);

  useEffect(() => {
    document.title = title ? `${title} | MyCricket` : 'MyCricket';
  }, [title]);

  useEffect(() => {
    if (+width !== smartVw * 100) {
      setSmartVw(width / 100);
    }
    if (+height !== smartVh * 100) {
      setSmartVh(height / 100);
    }
  }, [width, height, smartVw, smartVh]);

  //log the initial page load
  useEffect(() => {
    if (!skipPageEventLog) {
      logEvent({
        predicate: PatientEvent.VIEW_PAGE,
        object: URIUtils.toMyCricketPageURI(`${location.pathname}`.replace(/\s/g, '')),
        prepositions: {
          page: URIUtils.toMyCricketPageURI(location.pathname),
          referrer: `${location.state?.referrer ?? ''} `,
        },
      });
    }
  }, [location.pathname, location.state?.referrer, logEvent, skipPageEventLog]);

  // This useEffect restricts the focus to the current modal if it is open.
  useEffect(() => {
    if (!currentModal) {
      return;
    }
    const listener = restrictTabFocusToElementWithId('patientModal');
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [currentModal]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={`${100 * smartVw}px`}
      height={`${100 * smartVh}px`}
      paddingTop={`env(safe-area-inset-top)`}
      paddingLeft={`env(safe-area-inset-left)`}
      paddingRight={`env(safe-area-inset-right)`}
    >
      <ModalContext.Provider
        value={{
          showModal: (component, options) =>
            setCurrentModal({ ...(options ?? {}), Component: component }),
          hideModal: () => setCurrentModal(null),
        }}
      >
        <PageScrollContext.Provider
          value={{
            scrollPage: (value) => {
              if (scrollRef.current) {
                scrollRef.current.scrollTop = value;
              }
            },
          }}
        >
          <PatientErrorBoundary>
            <Box aria-hidden={Boolean(currentModal)}>
              <TopNav
                hideTabs={hideNavBarTabs}
                showLanguageButton={showLanguageButton}
                customMessage={topNavMessage}
              />
            </Box>
            <ScrollBox
              ref={scrollRef}
              flex={1}
              disableScroll={disableScroll}
              aria-hidden={Boolean(currentModal)}
            >
              <InterruptManager>{children}</InterruptManager>
            </ScrollBox>
            {currentModal && (
              <ModalOverlayContainer noEscape={currentModal.noEscape}>
                <currentModal.Component />
              </ModalOverlayContainer>
            )}
          </PatientErrorBoundary>
        </PageScrollContext.Provider>
      </ModalContext.Provider>
    </Box>
  );
};

const PageScrollContext = React.createContext({ scrollPage: () => {} });

export const usePageScrollContext = () => {
  return useContext(PageScrollContext);
};

export default Page;
