import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { FaqsContainer } from '../organisms/FaqsContainer';
import { FaqsHeader } from '../organisms/FaqsHeader';
import VideoPlayer from '../organisms/VideoPlayer';
import { useHistory } from 'react-router-dom';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { useForceErrorThrow } from '../../utils/PatientErrorBoundary';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import Events from '../../data/Events';
import { toMyCricketPageURI } from '../../utils/URIUtils';
import LearnModuleCard from '../molecules/LearnModuleCard';

// putting default values here so certain tests dont just explode.
export const ModuleDetailContext = React.createContext({ moduleScope: '', selectedModuleId: '' });

const ModuleDetail = ({ match: { params } }) => {
  const { moduleId: desiredModuleId, faqId: desiredFaqId } = params;
  const [selectedModuleId, setSelectedModuleId] = useState(+desiredModuleId);
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [moduleFaqs, setModuleFaqs] = useState([]);
  const [moduleScope, setModuleScope] = useState('');
  const [courseId, setCourseId] = useState('');
  const [allModules, setAllModules] = useState([]);
  const history = useHistory();
  const setError = useForceErrorThrow();
  const { logEvent } = useClientEventLoggerContext();

  const { t } = useTranslation();

  const changeSelectedModule = (moduleId, isPrevious) => {
    setSelectedModuleId(moduleId);
    history.push(`/${window.location.pathname.split('/')[1]}/` + moduleId);
    setLoading(true);
    logEvent({
      predicate: Events.PatientEvent.SWITCHED_MODULE,
      object: toMyCricketPageURI('/module/' + selectedModuleId),
      prepositions: { direction: isPrevious ? 'previous' : 'next', switchedTo: moduleId },
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await myCricketAuthedFetch(`/i/learn/module/${selectedModuleId}`);
      if (data) {
        setAllModules(data.siblings?.sort((m1, m2) => m1.order - m2.order) || [data]);
        setModuleScope(data.scope);
        setCourseId(data.courseId);
        setModuleFaqs(data.faqs?.sort((faq1, faq2) => faq1.order - faq2.order) || []);
        const _desiredFaqId = +desiredFaqId;
        data.faqs.find((faq) => faq.id === _desiredFaqId) && setSelectedFaqId(_desiredFaqId);

        if (data.video || data.es_video) {
          const { video, es_video: esVideo } = data;
          setVideo({
            id: { default: video?.id, es: esVideo?.id },
            videoURL: { default: video?.media.url, es: esVideo?.media.url },
            mimeType: { default: video?.media.mime, es: esVideo?.media.mime },
            posterURL: { default: video?.poster.url, es: esVideo?.poster.url },
          });
        } else {
          setVideo(null);
        }
        setLoading(false);
      }
    };
    fetchData().catch((e) => {
      // have to manually set and throw error here because of calling an async function in a useEffect
      setError(e);
    });
  }, [desiredFaqId, selectedModuleId, setError]);

  return (
    <MyCricketLoadingContainer loading={loading}>
      <ModuleDetailContext.Provider
        value={{
          selectedModuleId,
          changeSelectedModule,
          allModules,
          selectedFaqId,
          setSelectedFaqId,
          moduleFaqs,
          moduleScope,
          courseId,
        }}
      >
        <Box display="flex" flexDirection="column">
          <FaqsHeader />
          <Box display={'flex'} justifyContent={'center'}>
            <Box width={{ xs: '100%', md: '800px' }} mt={3}>
              {video && <VideoPlayer {...video} />}
              {moduleScope && <FaqsContainer isGenericContent={moduleScope === 'Generic'} />}
              {allModules.length > 0 && (
                <Box my={7} px={{ xs: 2, md: 0 }}>
                  <Typography variant={'h2'} mb={7}>
                    {t('learnModuleOtherTopics', 'Other topics in this lesson')}
                  </Typography>
                  <Grid container spacing={{ xs: 1, md: 1.5 }}>
                    {allModules
                      .filter((module) => module.id !== selectedModuleId)
                      .map((module, index) => (
                        <Grid item key={index} xs={12} md={6}>
                          <LearnModuleCard
                            moduleTitle={t(module.title)}
                            onClick={() => changeSelectedModule(module.id)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </ModuleDetailContext.Provider>
    </MyCricketLoadingContainer>
  );
};

export default ModuleDetail;
