import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { RoundButton, ClickableBox } from '../atoms/Buttons';
import { HorizontalChevron } from '../atoms/Chevrons';
import { RoleLabel } from '../atoms/RoleLabel';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useCareTeamContext } from '../pages/CareTeam';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { MENTOR, PATIENT } from '../../data/Roles';

const ShowBioButton = () => {
  const { triggerBioToShow } = useCareTeamContext();

  return (
    <RoundButton
      variant={'outlined'}
      onClick={() => {
        triggerBioToShow();
      }}
      style={{ marginTop: '0px', padding: '3px 24px 3px 24px' }}
    >
      Bio
    </RoundButton>
  );
};

const ShowFileHistoryButton = () => {
  const { triggerFileHistoryToShow } = useCareTeamContext();

  return (
    <RoundButton
      variant={'outlined'}
      onClick={() => {
        triggerFileHistoryToShow();
      }}
      style={{ marginTop: '0px', padding: '3px 24px 3px 24px' }}
    >
      Files
    </RoundButton>
  );
};

export function CareTeamMessageHeader() {
  const { featureFlags = {} } = usePatientContext();
  const {
    showBio,
    showFileHistory,
    triggerSidebarToShow,
    triggerBioToShow,
    triggerFileHistoryToShow,
    selectedCareTeamMember: chatPartner,
  } = useCareTeamContext();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const openMenu = (anchor) => {
    setMenuOpen(true);
    setMenuAnchor(anchor);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setMenuAnchor(null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="flex-start"
      width="100%"
      minHeight={{ xs: '50px', sm: '50px', md: '61px' }}
    >
      <Box
        display="flex"
        pl={{ xs: 2, sm: 2, md: 3 }}
        pr={{ xs: 1.5, sm: 1.5, md: 3 }}
        py={2}
        bgcolor={MyCricketColors.white}
        height={'100%'}
        flexDirection="row"
        alignItems={'center'}
        justifyContent="space-between"
        borderRadius={showBio || !isDesktop ? '0' : '0 12px 0 0'}
        id="careTeamMessageHeaderWrapper"
        data-test-id={`careTeamMessageHeaderWrapper${chatPartner.roleName}`}
      >
        {isDesktop && (
          <Box display={'flex'} alignSelf={'center'} flexDirection={'row'}>
            <Box sx={{ display: { xs: 'none', lg: 'inherit' } }}>
              <Box mr={1}>
                <TranslatedTypography
                  aria-level="1"
                  role="heading"
                  variant="h2"
                  data-test-id="careTeamMessageHeader"
                  id="chatting-with"
                  aria-labelledby="chatting-with nickname member-role"
                >
                  {'Chatting with'}
                </TranslatedTypography>
              </Box>
            </Box>
            <Box mr={1.5}>
              <Typography id="nickname" variant={'h2'}>{`${chatPartner.nickName}`}</Typography>
            </Box>
            <RoleLabel id="member-role" role={chatPartner.roleName} />
          </Box>
        )}
        {!isDesktop && (
          <Box display={'flex'} width={'100%'}>
            <Box flex={'0 0 1'} display={'flex'}>
              <ClickableBox
                mr={2}
                bgcolor={MyCricketColors.white}
                display={'flex'}
                alignItems={'center'}
                onClick={() => triggerSidebarToShow()}
              >
                <HorizontalChevron pointsLeft />
                <Typography variant={'h3'} aria-level={'2'}>
                  {t('Back')}
                </Typography>
              </ClickableBox>
            </Box>
            <Box
              display={'flex'}
              flex={'1 0 0'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              mr={2}
            >
              <Box mr={1}>
                <Typography
                  variant={'subtitle1'}
                  aria-level={'1'}
                >{`${chatPartner.nickName}`}</Typography>
              </Box>
              <RoleLabel role={chatPartner.roleName} />
            </Box>
          </Box>
        )}
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          {!isDesktop && (
            <>
              {chatPartner.role !== PATIENT && (
                <Box>
                  <ClickableBox
                    width={'36px'}
                    height={'36px'}
                    textAlign={'center'}
                    bgcolor={MyCricketColors.white}
                    aria-label={'view bio and files'}
                    onClick={(event) => openMenu(event.currentTarget)}
                  >
                    <MoreVert fontSize={'large'} />
                  </ClickableBox>
                  <Menu
                    open={isMenuOpen}
                    anchorEl={menuAnchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    onClose={() => closeMenu()}
                  >
                    <MenuItem
                      onClick={() => {
                        triggerBioToShow();
                        closeMenu();
                      }}
                    >
                      {t('Bio')}
                    </MenuItem>
                    {chatPartner.role !== MENTOR && (
                      <MenuItem
                        onClick={() => {
                          triggerFileHistoryToShow();
                          closeMenu();
                        }}
                      >
                        Files
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
              )}
            </>
          )}
          {isDesktop && (
            <>
              {chatPartner.role !== PATIENT && (
                <Box mr={!showFileHistory ? 1 : 0}>{!showBio && <ShowBioButton />}</Box>
              )}
              {chatPartner.role !== MENTOR && chatPartner.role !== PATIENT && (
                <Box>{!showFileHistory && <ShowFileHistoryButton />}</Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {featureFlags['my-cricket-pto-ui'] && chatPartner.isOutOfOffice && (
        <Box
          display="flex"
          minHeight="34px"
          bgcolor={MyCricketColors.mediumGray}
          color={MyCricketColors.white}
          justifyContent="center"
          alignItems="center"
        >
          <TranslatedTypography variant="h3" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Currently Out of Office
          </TranslatedTypography>
        </Box>
      )}
    </Box>
  );
}

export default CareTeamMessageHeader;
