import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import { safelyLinkifyURLsInString } from '../../utils/urlUtils';
import { careTeamMessagePropType } from '../../utils/patientPropTypes';
import MyCricketColors from '../../data/MyCricketColors';

const useStyles = makeStyles((theme) => ({
  textBubble: {
    backgroundColor: (props) =>
      props.isOtherPerson ? theme.palette.white : theme.palette.paleBlue,
    display: 'flex',
    flexAlign: (props) => (props.isOtherPerson ? 0 : 1),
    marginLeft: (props) => (props.isOtherPerson ? 24 : 54),
    marginRight: (props) => (props.isOtherPerson ? 54 : 24),
    flexDirection: 'column',
    padding: '9px 12px',
    maxWidth: '80%',
    borderRadius: '6px',
    float: (props) => (props.isOtherPerson ? 'left' : 'right'),
    width: 'fit-content',
    overflowWrap: 'break-word',
    border: (props) => (props.isOtherPerson ? `1px solid ${MyCricketColors.lightGrayHaze}` : 0),
  },
}));

const CareTeamMessageBubble = ({ message, isOtherPerson, dataTestIndex }) => {
  const classes = useStyles({ isOtherPerson });

  const messageWithLinks = safelyLinkifyURLsInString(message.messageText);
  return (
    <Box className={classes.textBubble}>
      <Typography
        data-testid={`CareTeamMessageText-${message.messageUuid}`}
        data-test-type="CareTeamMessageText"
        data-test-index={dataTestIndex}
        variant={'body2'}
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{ __html: messageWithLinks }}
      />
    </Box>
  );
};

CareTeamMessageBubble.propTypes = {
  message: careTeamMessagePropType.isRequired,
  isOtherPerson: PropTypes.bool.isRequired,
  dataTestIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CareTeamMessageBubble;
