import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { usePrevious } from '../hooks/usePrevious';
import { usePatientContext } from './PatientContextProvider';
import { connectionStates } from '../Chirp';
import {
  MultipartyGroupTypes,
  RetiredDiscussionGroupNames,
  RetiredGroupTypes,
} from '../../data/groupTypeNames';
import { sortMessagesByDeliveryDate } from '../chatUtils';
import ConnectionState from '../../data/ChatConnectionState';
import Debug from 'debug';
const debug = Debug('MyCricket:chirp');

const isNotEmpty = (val) => {
  return !_.isEmpty(val);
};

const mungeGroupsResponse = (groups) => {
  const filteredGroups = _.pickBy(
    groups,
    (group) =>
      !RetiredGroupTypes.includes(group.type) && !RetiredDiscussionGroupNames.includes(group.name),
  );

  return _.mapValues(filteredGroups, (group) => {
    return {
      groupName: group.name,
      ...group,
    };
  });
};
export const mungeMessageObject = ({
  timestamp,
  msgText,
  userId_sender,
  uuid,
  status,
  inReplyToMessageId,
  liked,
  likes,
  file,
  ...rest
}) => {
  const obj = {
    clientTimestamp: timestamp,
    messageText: msgText,
    senderId: userId_sender,
    messageUuid: uuid,
    isRead: status === 'READ',
    inReplyTo: inReplyToMessageId,
    // turn truthy/falsy to real true/false
    likedByUser: !!liked,
    numberOfLikes: +likes,
    ...rest,
  };
  if (file) {
    obj.file = JSON.parse(file);
  }
  return obj;
};
export const mungeUserObject = (user) => {
  return {
    ...user,
    cricketUserId: user.id,
    nickName: user.name,
    roleName: user.role,
    isOutOfOffice: user.isOutOfOffice === 1,
  };
};

// Creates the ChirpContextProvider's state
export const useChirpContextProviderState = () => {
  const [connectionState, setConnectionState] = useState(ConnectionState.INITIALIZING);
  const [loading, setLoading] = useState(true);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [directMessageGroups, setDirectMessageGroups] = useState({});
  const [parsedMessages, setParsedMessages] = useState([]);

  // the extra arrow function may look a little weird here, but it's because setState expects a value or a function which will resolve to a value
  // Since we want to store the actual function as the value we have to wrap it in another arrow function.
  const [sendMessage, setSendMessage] = useState(() => (message) => message);
  const [readMessage, setReadMessage] = useState(() => (message) => {});
  const [likeMessage, setLikeMessage] = useState(() => (message) => {});
  const [unlikeMessage, setUnlikeMessage] = useState(() => (message) => {});
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [groupIdsWithUnreads, setGroupIdsWithUnreads] = useState([]);
  const [careTeamGroupIds, setCareTeamGroupIds] = useState([]);
  const [multiPartyGroupIds, setMultiPartyGroupIds] = useState([]);

  // NOTE: This data uses a ref and not a state, because we don't want it to be continuously updating as people type into input boxes.
  // If this state were to update with every new character typed, it would cause some input boxes to be redefined with every
  // update, which would cause a continuous loss of focus in the input box the user was typing in. In the locations this is used, we
  // are going with the pattern of keeping a local piece of state in the component for the data, and then stashing it up to this ref
  // in case we need to call it back at a later time after the user has left the page with the component on it.
  const composedMessageDataRef = useRef({});

  return {
    connectionState,
    setConnectionState,
    loading,
    setLoading,
    directMessageGroups,
    setDirectMessageGroups,
    sendMessage,
    setSendMessage,
    readMessage,
    setReadMessage,
    parsedMessages,
    setParsedMessages,
    likeMessage,
    setLikeMessage,
    unlikeMessage,
    setUnlikeMessage,
    unreadMessages,
    setUnreadMessages,
    groupIdsWithUnreads,
    setGroupIdsWithUnreads,
    careTeamGroupIds,
    setCareTeamGroupIds,
    multiPartyGroupIds,
    setMultiPartyGroupIds,
    composedMessageDataRef,
    messagesLoaded,
    setMessagesLoaded,
  };
};

export const ChirpContext = React.createContext(useChirpContextProviderState);
ChirpContext.displayName = 'ChirpContext';

export const ChirpContextProviderForApp = (props) => {
  // To clarify - all these Props come from the withChirpFetching chain in ChirpContextProviderWithFetching
  const {
    groups,
    messages,
    users,
    composedMessageData,
    isInitialLoaded,
    chirpSendMessage,
    chirpSetMessageAsRead,
    chirpSetGroupMessagesAsRead,
    chirpLikeMessage,
    chirpUnlikeMessage,
    chirpConnectionStatus,
    chirpChangeUsername,
  } = props;

  /**
   * `groups` changes references every time a message is sent/received or a group is read. When a
   * message is received, `groups` is updated with the new message ID and an updated unread count,
   * then when the group is read `groups` is updated again with `unreads: 0`. Since `unreads` is not
   * used and groups are essentially static, we can minimize updates by comparing group IDs before
   * setting `directMessageGroups`.
   */
  const previousGroups = usePrevious(groups);
  const [myGroups, setMyGroups] = useState({});
  useEffect(() => {
    if (_.isEmpty(groups)) {
      return;
    }

    // _.keys is more forgiving of non-objects than is Object.keys
    if (!_.isEqual(_.keys(groups), _.keys(previousGroups))) {
      // This will almost always only ever be set once
      setMyGroups(groups);
    }
  }, [groups, previousGroups]);

  const [myMessages, setMyMessages] = useState([]);
  useEffect(() => {
    if (_.isEmpty(messages)) {
      return;
    }

    setMyMessages(messages);
  }, [messages]);

  const { userId } = usePatientContext();

  const {
    connectionState,
    setConnectionState,
    loading,
    setLoading,
    directMessageGroups,
    setDirectMessageGroups,
    sendMessage,
    setSendMessage,
    parsedMessages,
    setParsedMessages,
    unreadMessages,
    setUnreadMessages,
    groupIdsWithUnreads,
    setGroupIdsWithUnreads,
    careTeamGroupIds,
    setCareTeamGroupIds,
    multiPartyGroupIds,
    setMultiPartyGroupIds,
    composedMessageDataRef,
    messagesLoaded,
    setMessagesLoaded,
  } = useChirpContextProviderState();

  if (composedMessageData) {
    composedMessageDataRef.current = composedMessageData;
  }

  const getGroupFromSelectedId = useCallback(
    (selectedGroupId) => {
      return myGroups[selectedGroupId] || {};
    },
    [myGroups],
  );

  useEffect(() => {
    debug('ChirpContextProvider::useEffect[connectionState, isInitialLoaded, loading]');
    debug('isInitialLoaded: %s', isInitialLoaded);
    debug('loading: %s', loading);
    debug('connectionState: %s', connectionState);
    if (!isInitialLoaded) {
      setLoading(true);
    } else if (connectionState === ConnectionState.CONNECTED && loading) {
      setLoading(false);
    } else if (connectionState === ConnectionState.RECONNECTING) {
      // We are trying to reconnect so don't change loading state because we might reconnect soon.
    } else if (connectionState === ConnectionState.CONNECTION_ERROR && !loading) {
      // Set to false, since it might be awhile before we reconnect.
      // Check the value of loading, so we don't perform infinite state updates.
      setLoading(true);
    }
  }, [connectionState, isInitialLoaded, loading]);

  useEffect(() => {
    // These are documented https://socket.io/docs/v4/client-api/#event-connect
    // General rule of thumb, things that seem unlikely to resolve on their own get loading true, everything else just gets Connected false
    // These are just a reasonable attempt to classify these states based on documentation. The real world is far messier, and we should freely update these as we learn more.
    if ([connectionStates.Reconnect, connectionStates.Connect].includes(chirpConnectionStatus)) {
      // We either connected successfully or reconnected successfully. So set connected to true, and loading to false
      setConnectionState(ConnectionState.CONNECTED);
    } else if (
      // We are trying to reconnect so set connected to false, but don't change loading state
      // in other words these states should all translate to things aren't good, but they might get better soon
      [connectionStates.ReconnectAttempt, connectionStates.ReconnectError].includes(
        chirpConnectionStatus,
      )
    ) {
      setConnectionState(ConnectionState.RECONNECTING);
    } else if (
      // we get an error set connected to false, and loading to true (because we probably won't be back for a bit )
      // In other words, we don't want to give users false hope in these cases, since Chat likely won't just be back in a second.
      [connectionStates.ReconnectFailed, connectionStates.ConnectionError].includes(
        chirpConnectionStatus,
      )
    ) {
      setConnectionState(ConnectionState.CONNECTION_ERROR);
    }
  }, [chirpConnectionStatus]);

  useEffect(() => {
    if (_.isFunction(chirpSendMessage)) {
      const _sendMessage = async (data, ...args) => {
        const { messageText, file, selectedGroupId, inReplyTo, childEventPrepositions } = data;
        await chirpSendMessage(
          {
            text: messageText,
            file,
            groupId: selectedGroupId,
            groupType: getGroupFromSelectedId(selectedGroupId).type,
            inReplyTo,
            childEventPrepositions,
          },
          ...args,
        );
      };
      setSendMessage(() => _sendMessage);
    }
  }, [chirpSendMessage, getGroupFromSelectedId]);

  useEffect(() => {
    if (isNotEmpty(myGroups) && isNotEmpty(users)) {
      setDirectMessageGroups(mungeGroupsResponse(myGroups));
    }
  }, [myGroups, users]);

  // update the messages
  useEffect(() => {
    if (_.isEmpty(myMessages)) {
      return;
    }

    setParsedMessages(Object.values(myMessages).map(mungeMessageObject));
    setMessagesLoaded(true);
  }, [myMessages]);

  useEffect(() => {
    const multiPartyGroupTypes = Object.values(MultipartyGroupTypes);
    const careTeamGroups = Object.values(myGroups).filter(
      (group) => !multiPartyGroupTypes.includes(group.type),
    );
    const multiPartyGroups = Object.values(myGroups).filter((group) =>
      multiPartyGroupTypes.includes(group.type),
    );
    setCareTeamGroupIds(careTeamGroups.map((g) => g.id));
    setMultiPartyGroupIds(multiPartyGroups.map((g) => g.id));
  }, [myGroups]);

  const readMessage = chirpSetMessageAsRead;
  const readAllMessagesForGroup = useCallback(
    (groupId) => {
      const groupType = getGroupFromSelectedId(groupId).type;
      chirpSetGroupMessagesAsRead(groupId, groupType);
    },
    [getGroupFromSelectedId],
  );
  const likeMessage = chirpLikeMessage;
  const unlikeMessage = chirpUnlikeMessage;

  const getGroupMessages = useCallback(
    (selectedGroupId) => {
      const myMessages = parsedMessages.filter((message) => +message.groupId === +selectedGroupId);
      return sortMessagesByDeliveryDate(myMessages);
    },
    [parsedMessages],
  );
  const getMessageById = useCallback(
    (messageId) => {
      return parsedMessages.find((message) => +message.id === +messageId);
    },
    [parsedMessages],
  );

  const changeUsername = chirpChangeUsername;

  useEffect(() => {
    const unreads = parsedMessages.filter(
      (message) => !message.isRead && message.senderId !== userId,
    );
    setUnreadMessages(unreads);
    const unreadGroupMessages = new Set(unreads.map((message) => message.groupId));
    setGroupIdsWithUnreads([...unreadGroupMessages]);
  }, [parsedMessages, userId]);

  const getUserProfile = useCallback(
    (senderId) => {
      const theUser = users[+senderId] || {};
      return mungeUserObject(theUser);
    },
    [users],
  );

  const getRepliesForMessage = useCallback(
    (messageId) => {
      return Object.values(parsedMessages).filter((message) => +message.inReplyTo === +messageId);
    },
    [parsedMessages],
  );

  const getComposedMessageData = (composedDataKey) => {
    const current = composedMessageDataRef.current[composedDataKey] || {};
    const withText = current.text === undefined ? { ...current, text: '' } : { ...current };
    return current.files === undefined ? { ...withText, files: [] } : { ...withText };
  };

  const setComposedMessageData = (composedDataKey, { text, files }) => {
    const current = composedMessageDataRef.current[composedDataKey] || {};
    const withText = text !== undefined ? { ...current, text } : { ...current };
    composedMessageDataRef.current[composedDataKey] =
      files !== undefined ? { ...withText, files } : { ...withText };
  };

  return (
    <ChirpContext.Provider
      value={{
        directMessageGroups,
        sendMessage,
        readMessage,
        connectionState,
        isConnected: connectionState === ConnectionState.CONNECTED,
        readAllMessagesForGroup,
        loading,
        getGroupFromSelectedId,
        getGroupMessages,
        getUserProfile,
        getMessageById,
        getRepliesForMessage,
        unreadMessages,
        groupIdsWithUnreads,
        likeMessage,
        unlikeMessage,
        careTeamGroupIds,
        multiPartyGroupIds,
        getComposedMessageData,
        setComposedMessageData,
        messagesLoaded,
        changeUsername,
      }}
    >
      {props.children}
    </ChirpContext.Provider>
  );
};

export function useChirpContext() {
  return useContext(ChirpContext);
}
