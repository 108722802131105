import { useEffect, useState } from 'react';
import { myCricketAuthedFetch } from '../fetchingUtils';
import { isAfter, isBefore, startOfDay } from 'date-fns';

// Purposely matching the useQuery API
export default function useAppointments() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({ upcomingAppointments: [], pastAppointments: [] });

  useEffect(() => {
    const fetchData = async () => {
      const result = await myCricketAuthedFetch('/my-account/appointments');
      const upcomingAppointments = result.data
        .filter((element) =>
          isAfter(new Date(element.scheduledForDateTime), startOfDay(new Date())),
        )
        .sort((a, z) => new Date(a.scheduledForDateTime) - new Date(z.scheduledForDateTime));
      // TODO Compare based on actually completed date
      // Note that incomplete aren't returned by the endpoint as of July 2022
      const pastAppointments = result.data
        .filter((element) =>
          isBefore(new Date(element.scheduledForDateTime), startOfDay(new Date())),
        )
        .sort((a, z) => new Date(a.scheduledForDateTime) - new Date(z.scheduledForDateTime));
      setData({ upcomingAppointments, pastAppointments });
      setError(false);
      setLoading(false);
    };

    fetchData().catch((e) => {
      console.error(e);
      setError(true);
      setLoading(false);
      setData({ upcomingAppointments: [], pastAppointments: [] });
    });
  }, []);

  return { data, loading, error };
}
