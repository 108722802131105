import React, { useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InsertDriveFile, Error } from '@mui/icons-material';
import MyCricketColors from '../../data/MyCricketColors';
import { useCareTeamContext } from '../pages/CareTeam';
import { fetchFile } from '../../utils/fileUtils';
import { TranslatedTypography } from './TranslatedTypography';

const useStyles = makeStyles((theme) => ({
  textBubble: {
    backgroundColor: (props) =>
      props.isOtherPerson ? theme.palette.white : theme.palette.paleBlue,
    display: 'flex',
    flexAlign: (props) => (props.isOtherPerson ? 0 : 1),
    marginLeft: (props) => (props.isOtherPerson ? 24 : 54),
    marginRight: (props) => (props.isOtherPerson ? 54 : 24),
    flexDirection: 'column',
    padding: '9px 12px',
    maxWidth: '80%',
    borderRadius: '6px',
    float: (props) => (props.isOtherPerson ? 'left' : 'right'),
    width: 'fit-content',
    overflowWrap: 'break-word',
    border: (props) => (props.isOtherPerson ? `1px solid ${MyCricketColors.lightGrayHaze}` : 0),
  },
}));

const CareTeamMessageFile = ({ fileId, fileName, isOtherPerson }) => {
  const classes = useStyles({ isOtherPerson });
  const { cachedFileResults, setCachedFileResults } = useCareTeamContext();
  const [isLoading, setLoading] = useState(false);
  const error = cachedFileResults[fileId]?.error;

  const downloadAndViewFile = useCallback(async () => {
    if (cachedFileResults[fileId]?.url) {
      window.open(cachedFileResults[fileId]?.url, '_blank');
      return;
    }
    try {
      setLoading(true);
      const url = await fetchFile(fileId);
      setCachedFileResults((prev) => ({ ...prev, [fileId]: { url } }));
      window.open(url, '_blank');
    } catch (error) {
      setCachedFileResults((prev) => ({ ...prev, [fileId]: { error } }));
    } finally {
      setLoading(false);
    }
  }, [fileId, cachedFileResults, setCachedFileResults]);

  return (
    <Box
      className={classes.textBubble}
      style={{ cursor: isLoading ? 'default' : 'pointer' }}
      onClick={() => {
        if (isLoading) {
          return;
        }
        downloadAndViewFile();
      }}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Box component={'span'} display={'flex'} alignItems={'center'} mr={1.5}>
          {error ? <Error /> : <InsertDriveFile />}
        </Box>
        {error ? (
          <Box component={'span'}>
            <TranslatedTypography
              i18nKey={error.status === 401 ? 'chatFileErrorUnauthorized' : 'chatFileErrorUnkonwn'}
              variant={'body2'}
              style={{ whiteSpace: 'pre-line' }}
            >
              {error.status === 401
                ? 'This file is private or may have been deleted'
                : 'There was a problem downloading this file'}
            </TranslatedTypography>
          </Box>
        ) : (
          <Box component={'span'} color={'blue'} style={{ textDecoration: 'underline' }}>
            <Typography variant={'body2'} style={{ whiteSpace: 'pre-line' }}>
              {fileName}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CareTeamMessageFile;
