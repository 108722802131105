import React from 'react';
import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { ClickableBox } from './Buttons';
import MyCricketColors from '../../data/MyCricketColors';

const ExpandGroupRepliesButton = ({ numberOfReplies, onClick }) => {
  return (
    <ClickableBox
      bgcolor={MyCricketColors.lightGrayMist}
      color={MyCricketColors.teal}
      p={2}
      width={'100%'}
      borderTop={`1px solid ${MyCricketColors.lightGrayHaze}`}
      boxShadow={'0px 3px 4px rgba(0, 62, 81, 0.1)'}
      onClick={onClick}
    >
      <Typography variant="subtitle1">
        <Trans
          i18nKey="webapp:viewMoreGroupReplies"
          defaults={'View {{count}} more replies'}
          values={{ count: numberOfReplies }}
        />
      </Typography>
    </ClickableBox>
  );
};

export default ExpandGroupRepliesButton;
