import React from 'react';
import { Box } from '@mui/material';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';

const EnvironmentVariableDisplay = (props) => {
  const { flags } = props;
  const patient = usePatientContext();
  const keys = Object.keys(__ENV__);
  const data = [];
  data.push(<dt key="key-authState">Is User Logged in?</dt>);
  data.push(<dd key="prop-authState">{JSON.stringify(!!patient)}</dd>);
  if (patient) {
    data.push(<dt key="key-whoami">whoami</dt>);
    data.push(
      <dd key="prop-whoami">
        <pre>{JSON.stringify(patient, undefined, 2)}</pre>
      </dd>,
    );
  }
  keys.forEach((key) => {
    data.push(<dt key={`key-${key}`}>{key}</dt>);
    data.push(
      <dd key={`prop-${key}`}>
        <pre>{JSON.stringify(__ENV__[key], undefined, 2)}</pre>
      </dd>,
    );
  });
  const flagKey = Object.keys(flags);
  flagKey.forEach((key) => {
    data.push(<dt key={`keyld-${key}`}>LD - {key}</dt>);
    data.push(
      <dd key={`propld-${key}`}>
        <pre>{JSON.stringify(flags[key], undefined, 2)}</pre>
      </dd>,
    );
  });
  return (
    <Box>
      <div
        style={{
          marginLeft: '10px',
        }}
      >
        <dl>{data}</dl>
      </div>
    </Box>
  );
};

export default EnvironmentVariableDisplay;
