import React from 'react';

const PlayButton = ({ color, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136"
    height="136"
    viewBox="0 0 136 136"
    title={'Video Player Play Button'}
    {...restProps}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="68" cy="68" r="68" fill={color || '#7A4FE5'} />
      <path fill="#FFF" d="M48 36v64l54-32z" />
    </g>
  </svg>
);

export default PlayButton;
