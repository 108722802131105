import { Box } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { BaseButton } from '../atoms/Buttons';
import { StyledCard } from '../atoms/StyledCard';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { ReactComponent as CalendarIcon } from '../icons/CalendarIcon.svg';
import { ReactComponent as CareTeamIcon } from '../icons/CareTeamIcon.svg';
import { ReactComponent as SurveyIcon } from '../icons/SurveyIcon.svg';
import { FaqItem } from './FaqItem';
import { ModuleDetailContext } from '../pages/ModuleDetail';

const OnboardingWelcomeHeader = ({ isWelcomeBack, handleSubmit }) => {
  return (
    <Box pb={7}>
      <Box display="flex" alignContent="flex-start" width="100%" mb={4} p={{ xs: 2, s: 2, md: 0 }}>
        <TranslatedTypography i18nKey={'OnboardingNextSteps'} variant="h2">
          Your next steps:
        </TranslatedTypography>
      </Box>
      <StyledCard nonInteractive={true}>
        <Box py={{ xs: 6.5, md: 5 }} px={{ xs: 8, md: 10 }}>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            alignContent={'center'}
            justifyContent={'space-around'}
          >
            <Box
              display={'flex'}
              flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
              alignItems={'center'}
              mb={{ xs: 7, sm: 7, md: 0 }}
              mr={{ xs: 0, sm: 0, md: 4 }}
              justifyContent={'space-evenly'}
            >
              {/* Wrap the icons in a box to help flexBox work more predictably and to make all the icons the same width */}
              <Box width={'106px'} display="flex" justifyContent={'center'} flexShrink={0}>
                <SurveyIcon />
              </Box>
              <Box
                pt={2}
                pl={{ xs: 1, sm: 1, md: 0 }}
                textAlign={{ xs: 'left', sm: 'left', md: 'center' }}
              >
                <TranslatedTypography variant={'h3'}>
                  Complete a 5 minute health questionnaire
                </TranslatedTypography>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
              alignItems={'center'}
              mb={{ xs: 7, sm: 7, md: 0 }}
              mx={{ xs: 0, sm: 0, md: 4 }}
              justifyContent={'space-evenly'}
            >
              <Box>
                <CareTeamIcon />
              </Box>
              <Box
                pt={2}
                pl={{ xs: 1, sm: 1, md: 0 }}
                textAlign={{ xs: 'left', sm: 'left', md: 'center' }}
              >
                <TranslatedTypography variant={'h3'}>
                  We’ll match you with your first clinician
                </TranslatedTypography>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
              alignItems={'center'}
              ml={{ xs: 0, sm: 0, md: 4 }}
              justifyContent={'space-evenly'}
            >
              <Box width={'106px'} display="flex" justifyContent={'center'} flexShrink={0}>
                <CalendarIcon style={{ flexShrink: 1 }} />
              </Box>
              <Box
                pt={2}
                pl={{ xs: 1, sm: 1, md: 0 }}
                textAlign={{ xs: 'left', sm: 'left', md: 'center' }}
              >
                <TranslatedTypography variant={'h3'}>
                  Expect a call from Cricket within 5 days!
                </TranslatedTypography>
              </Box>
            </Box>
          </Box>
          <Box py={{ xs: 5, md: 3 }}>
            <hr height={'1px'} color={MyCricketColors.lightGrayHaze} />
          </Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <BaseButton
              type="submit"
              onClick={handleSubmit}
              data-test-id="startSurveyButton"
              endIcon={<ArrowForward />}
            >
              {isWelcomeBack ? 'Continue Questionnaire' : 'Start the Questionnaire'}
            </BaseButton>
          </Box>
        </Box>
      </StyledCard>
    </Box>
  );
};

const onboardingModuleId = 1061;

const OnboardingWelcome = ({ onSubmit, isWelcomeBack, ...rest }) => {
  const [moduleFaqs, setModuleFaqs] = useState([]);
  const [courseId, setCourseId] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await myCricketAuthedFetch(`/i/learn/module/${onboardingModuleId}`);
      if (data) {
        setModuleFaqs(data.faqs?.sort((faq1, faq2) => faq1.order - faq2.order) || []);
        setCourseId(data.courseId);
      }
    };
    fetchData();
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();
    await onSubmit();
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      data-test-id={isWelcomeBack ? 'surveyWelcomeBack' : 'surveyWelcome'}
    >
      <Box pt={4} width={{ xs: '100%', md: '720px' }}>
        <OnboardingWelcomeHeader isWelcomeBack={isWelcomeBack} handleSubmit={handleSubmit} />
        <Box
          display="flex"
          alignContent="flex-start"
          width="100%"
          mb={4}
          py={{ xs: 2, s: 2, md: 0 }}
        >
          <TranslatedTypography i18nKey={'OnboardingFaqHeader'} variant="h2">
            Frequently Asked Questions:
          </TranslatedTypography>
        </Box>
        <ModuleDetailContext.Provider
          value={{
            selectedModuleId: onboardingModuleId,
            courseId,
          }}
        >
          {moduleFaqs.map((faq) => (
            <Box key={'wrapper' + faq.id} mb={{ xs: '1px', sm: '1px', md: 1 }}>
              <FaqItem key={faq.id} faq={faq} shouldScrollIntoView={false} ariaHeaderLevel={3} />
            </Box>
          ))}
        </ModuleDetailContext.Provider>
        <Box mb={8} />
      </Box>
    </Box>
  );
};

export default OnboardingWelcome;
