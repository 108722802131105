import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Collapse, Fade } from '@mui/material';
import { BackNav } from '../atoms/BackNav';
import { paths } from '../../utils/routing/routes';
import MyCricketColors from '../../data/MyCricketColors';
import PhoneContactInfoDisplay from '../organisms/PhoneContactInfoDisplay';
import PhoneContactInfoEditor from '../organisms/PhoneContactInfoEditor';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';

const PhoneContactInfoPage = () => {
  const { phoneNumbers } = usePatientContext();

  const [isEditing, setEditing] = useState(false);
  const containerRef = useRef();

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box component={'main'} mt={3} px={2} width={'640px'}>
        <BackNav history={history} link={paths.me()} data-test-id="backNav" />
        <Box
          p={{ xs: 3, md: 6 }}
          bgcolor={MyCricketColors.white}
          borderRadius={'6px'}
          maxWidth={'640px'}
          style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
          ref={containerRef}
        >
          <Box position={'relative'} height={'30px'}>
            <Box position={'absolute'}>
              <Fade in={!isEditing} appear={false}>
                <Typography variant="h2" aria-level="1">
                  {t('phoneInformation', 'Phone Information')}
                </Typography>
              </Fade>
            </Box>
            <Box position={'absolute'}>
              <Fade in={isEditing} appear={false}>
                <Typography variant="h2" aria-level="1">
                  {t('updatePhoneInformation', 'Update Phone Information')}
                </Typography>
              </Fade>
            </Box>
          </Box>
          <Box mt={4}>
            <Collapse in={!isEditing} appear={false}>
              <Fade in={!isEditing} appear={false}>
                <Box>
                  <PhoneContactInfoDisplay
                    primaryPhoneNumber={phoneNumbers.primary}
                    smsPhoneNumber={phoneNumbers.sms}
                    customerIsDoNotText={phoneNumbers.customerIsDoNotText}
                    setEditing={setEditing}
                  />
                </Box>
              </Fade>
            </Collapse>
            <Collapse in={isEditing} appear={false}>
              <Fade in={isEditing} appear={false}>
                <Box>
                  <PhoneContactInfoEditor
                    initialPrimaryPhoneNumber={phoneNumbers.primary}
                    initialSMSPhoneNumber={phoneNumbers.sms}
                    customerIsDoNotText={phoneNumbers.customerIsDoNotText}
                    isEditing={isEditing}
                    setEditing={setEditing}
                  />
                </Box>
              </Fade>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneContactInfoPage;
