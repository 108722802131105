import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from '../../utils/routing/routes';
import NavButton from '../molecules/NavButton';
import HeartFull from '../icons/HeartFull';
import CricketLogo from '../icons/CricketC';
import Groups from '../icons/Groups';
import Book from '../icons/Book';
import Me from '../icons/Me';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { MultipartyGroupTypes } from '../../data/groupTypeNames';
import LanguageToggleButton from '../atoms/LanguageToggleButton';
import MyCricketColors from '../../data/MyCricketColors';
import ConnectionState from '../../data/ChatConnectionState';
import { mapGroupTypeNamesToGroupIds } from '../../utils/chatUtils';
import Toast from '../atoms/Toast';
import { CoachMark } from '../atoms/CoachMark';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useTranslation } from 'react-i18next';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const TopNav = ({ hideTabs, showLanguageButton, customMessage = '' }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { connectionState, unreadMessages, directMessageGroups } = useChirpContext();
  const { shouldShowSchedulingReminder } = usePatientContext();
  const [careTeamUnreadsCount, setCareTeamUnreadsCount] = useState(0);
  const [multiPartyUnreadsCount, setMultiPartyUnreadsCount] = useState(0);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    const _careTeamUnreadsCount = unreadMessages.filter(
      (message) => !Object.values(MultipartyGroupTypes).includes(message.groupType),
    ).length;
    careTeamUnreadsCount !== _careTeamUnreadsCount &&
      setCareTeamUnreadsCount(_careTeamUnreadsCount);
    const multiPartyGroupIds = Object.values(mapGroupTypeNamesToGroupIds(directMessageGroups));
    const _multiPartyUnreadsCount = unreadMessages.filter((message) =>
      multiPartyGroupIds.includes(message.groupId),
    ).length;
    multiPartyUnreadsCount !== _multiPartyUnreadsCount &&
      setMultiPartyUnreadsCount(_multiPartyUnreadsCount);
  }, [unreadMessages, directMessageGroups, careTeamUnreadsCount, multiPartyUnreadsCount]);
  useEffect(() => {
    // Strict false check to allow Connected to be nullable, otherwise we'd always flash this toast while we are connecting
    if (
      connectionState === ConnectionState.CONNECTION_ERROR ||
      connectionState === ConnectionState.RECONNECTING
    ) {
      toast.error(() => <Toast message={t('You have been disconnected from chat')} />, {
        toastId: 'chat-disconnect',
        autoClose: false,
      });
    } else {
      toast.dismiss('chat-disconnect');
    }
  }, [t, connectionState]);
  return (
    <AppBar role="banner" position="sticky" sx={{ backgroundColor: MyCricketColors.darkTeal }}>
      <Toolbar disableGutters sx={{ minHeight: '64px' }}>
        <Box
          width="100%"
          display="flex"
          justifyContent={{ xs: 'start', sm: 'start', md: 'center' }}
          alignItems="center"
        >
          {isDesktop && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              opacity={0.5}
              onClick={() => {
                history.push(paths.homepage());
              }}
              style={{
                position: 'absolute',
                left: 0,
              }}
            >
              <CricketLogo height={'40px'} />
            </IconButton>
          )}
          {hideTabs ? (
            <Box pl={{ xs: 2, sm: 2, md: 0 }}>
              {customMessage !== '' && (
                <TranslatedTypography variant="h1">{customMessage}</TranslatedTypography>
              )}
            </Box>
          ) : (
            <Box
              display="grid"
              gridAutoFlow="column"
              gridAutoColumns="1fr"
              alignItems="center"
              margin="auto"
              gap={{ xs: 0, sm: 0, md: '5px' }}
              width={{ xs: '100%', sm: '100%', md: 'auto' }}
            >
              <NavButton
                icon={<HeartFull />}
                notificationCount={careTeamUnreadsCount}
                to={paths.careTeam()}
                text="Care Team"
              />
              <CoachMark
                text={'You have a new course in your learn tab. Click here to view it.'}
                coachMarkId={1}
                wait={30000}
                redirectTo={paths.learn()}
              >
                <NavButton
                  icon={<Book />}
                  notificationCount={0}
                  to={paths.learn()}
                  text="Learn"
                  activeOn={['learn', 'module', 'courses']}
                />
              </CoachMark>
              <NavButton
                icon={<Groups />}
                notificationCount={multiPartyUnreadsCount}
                to={paths.groupsList()}
                text="Groups"
              />
              <NavButton
                icon={<Me />}
                notificationCount={shouldShowSchedulingReminder ? 1 : 0}
                to={paths.me()}
                text="Me"
                activeOn={['me']}
              />
            </Box>
          )}

          {showLanguageButton && hideTabs ? (
            <Box position={'absolute'} right={0}>
              <LanguageToggleButton
                sx={(theme) => ({
                  'margin': theme.spacing(1.5),
                  'backgroundColor': MyCricketColors.lake,
                  '&:hover': {
                    backgroundColor: MyCricketColors.lake,
                  },
                })}
              />
            </Box>
          ) : (
            // This box is purely for spacing purposes, so we can use the same justification for all variants
            <Box />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export const BlankTopNav = ({ showLanguageButton }) => {
  const isDesktop = useIsDesktop();
  return (
    <AppBar role="banner" position="sticky" sx={{ backgroundColor: MyCricketColors.darkTeal }}>
      <Toolbar disableGutters sx={{ minHeight: '64px' }}>
        <Box
          width="100%"
          display="flex"
          justifyContent={{ xs: 'start', sm: 'start', md: 'center' }}
          alignItems="center"
        >
          {isDesktop && (
            <Box
              display={'flex'}
              alignItems={'center'}
              opacity={0.5}
              style={{
                position: 'absolute',
                left: 0,
              }}
            >
              <CricketLogo height={'40px'} />
            </Box>
          )}
          {showLanguageButton && (
            <Box position={'absolute'} right={0}>
              <LanguageToggleButton
                sx={(theme) => ({
                  'margin': theme.spacing(1.5),
                  'backgroundColor': MyCricketColors.lake,
                  '&:hover': {
                    backgroundColor: MyCricketColors.lake,
                  },
                })}
              />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
