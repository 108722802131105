import React from 'react';
import { Box } from '@mui/material';
import PausedAccess from '../organisms/PausedAccess';

const PausedAccessPage = () => {
  return (
    <Box p={6}>
      <PausedAccess />
    </Box>
  );
};

export default PausedAccessPage;
