import { myCricketFetch } from './fetchingUtils';
import CookieManager from './CookieHelper';

export const localItemsToRemove = ['idToken'];

export async function login(emailAddress, password) {
  const responseBody = await myCricketFetch('/i/account/authorize', {
    method: 'POST',
    body: JSON.stringify({
      emailAddress,
      password,
      applicationName: window._cc.appName,
    }),
  });
  localItemsToRemove.forEach((item) => window.localStorage.removeItem(item));
  // TODO Handle errors (fallback to in-memory at worst)
  window.localStorage.setItem('idToken', responseBody.data.id_token);
  return responseBody;
}

export const sendPasswordlessLoginLink = (emailAddress, referrer) => {
  return myCricketFetch('/i/account/passwordless/send', {
    method: 'POST',
    body: JSON.stringify({
      emailAddress,
      referrer,
      applicationName: window._cc.appName,
      source: 'patient',
    }),
  });
};

export const sendResetPassword = (emailAddress, language) =>
  myCricketFetch('/i/account/password', {
    method: 'POST',
    body: JSON.stringify({
      emailAddress,
      applicationName: window._cc.appName,
      language,
    }),
  });

/**
 * ID Token is also used as an Access Token and a Refresh Token.
 * @param {string?} refreshToken refresh token
 * @returns {Promise<string>} access token if refresh is successful, otherwise throws
 */
export const refreshAccessToken = async (refreshToken) => {
  if (!refreshToken) {
    throw new Error('No Refresh Token');
  }
  const { id_token } = await myCricketFetch('/i/account/refresh_token', {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  return id_token;
};

export function logout() {
  localItemsToRemove.forEach((item) => window.localStorage.removeItem(item));
}

/**
 * If there is a hash property then attempts to decode and return an ID token from it, otherwise return null
 * @returns {null|string}
 */
const getHashedToken = () => {
  const hashParams = new URLSearchParams(window.location.hash.slice(1));
  if (!hashParams.has('id_token')) {
    return null;
  }
  try {
    return JSON.parse(Buffer.from(hashParams.get('id_token'), 'base64').toString());
  } catch {
    return null;
  }
};

export async function resumeSession() {
  try {
    const cookieToken = CookieManager.getRawCookie('my_cricket_id_token');
    const hashedToken = getHashedToken();
    const storedToken = localStorage.getItem('idToken');
    // This will safely reject with an AggregateError even if no tokens can be found
    const accessToken = await Promise.any([
      refreshAccessToken(cookieToken),
      refreshAccessToken(hashedToken),
      refreshAccessToken(storedToken),
    ]);
    localStorage.setItem('idToken', accessToken);
  } catch {
    localStorage.removeItem('idToken');
  } finally {
    CookieManager.deleteCookie('my_cricket_id_token', {
      path: '/',
      domain: __ENV__.cookieDomain.shared,
    });
  }
}
