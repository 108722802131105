import React from 'react';
import { Button, IconButton, Box, styled } from '@mui/material';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from './TranslatedTypography';

// This is our default rounded corner button
// Other shape buttons are styled versions of this button, with different a border radius
export const BaseButton = (props) => {
  const { children, i18nKey, textStyle, ...rest } = props;
  return (
    <BaseButtonNoTranslation {...rest}>
      <TranslatedTypography i18nKey={i18nKey} role="none" variant="subtitle1" style={textStyle}>
        {children}
      </TranslatedTypography>
    </BaseButtonNoTranslation>
  );
};

export const BaseButtonNoTranslation = styled(Button)(({ variant }) => ({
  'borderRadius': '6px',
  'backgroundColor': MyCricketColors.mint,
  'color': MyCricketColors.white,
  'fontSize': '18px',
  'fontWeight': '800',
  'padding': '12px 24px 12px 24px',
  '&:disabled': {
    color: MyCricketColors.white,
    background: MyCricketColors.disabledGray,
  },
  '&:hover': {
    backgroundColor: MyCricketColors.hoverMint,
  },
  ...(variant === 'outlined' && {
    'borderRadius': '6px',
    'backgroundColor': MyCricketColors.white,
    'color': MyCricketColors.teal,
    'border': `2px ${MyCricketColors.teal} solid`,
    'boxSizing': 'border-box',
    '&:hover': {
      border: `2px ${MyCricketColors.teal} solid`,
      boxShadow: `inset 0px 0px 0px 1px ${MyCricketColors.teal}`,
      background: MyCricketColors.white,
    },
  }),
}));

export const SquareButton = styled(BaseButton)({
  borderRadius: '0px',
});

export const RoundButton = styled(BaseButton)({
  borderRadius: '48px',
});

export const SmallButton = styled(BaseButton)({
  borderRadius: '48px',
  fontSize: '16px',
  fontWeight: '800',
});

export const SnackButton = styled(BaseButton)({
  'fontSize': '18px',
  'fontWeight': '800',
  'padding': '6px 12px 6px 12px',
  'margin': 0,
  'backgroundColor': 'transparent',
  'color': MyCricketColors.white,
  'borderColor': MyCricketColors.white,
  '&:hover': {
    boxShadow: `0px 0px 0px 1px ${MyCricketColors.teal}`,
    background: 'transparent',
  },
});

export const CancelButton = styled(BaseButton)({
  'color': MyCricketColors.black,
  'border': `2px ${MyCricketColors.lightGraySmoke} solid`,
  '&:hover': {
    border: `2px ${MyCricketColors.lightGraySmoke} solid`,
    boxShadow: `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
    background: MyCricketColors.white,
  },
});

const BaseIconButton = styled(IconButton)({
  'borderRadius': '6px',
  'background': MyCricketColors.mint,
  'color': MyCricketColors.white,
  'padding': '12px 24px 12px 24px',
  '&:disabled': {
    color: MyCricketColors.white,
    background: MyCricketColors.disabledGray,
  },
  '&:hover': {
    backgroundColor: MyCricketColors.hoverMint,
  },
});

const segmentedButtonStyles = {
  startButton: {
    'color': MyCricketColors.white,
    'backgroundColor': MyCricketColors.lake,
    'borderTopLeftRadius': '9999px',
    'borderBottomLeftRadius': '9999px',
    'borderTopRightRadius': '0',
    'borderBottomRightRadius': '0',
    '&:hover': {
      backgroundColor: MyCricketColors.teal,
    },
  },
  middleButton: {
    'color': MyCricketColors.white,
    'backgroundColor': MyCricketColors.lake,
    'borderLeftStyle': 'solid',
    'borderLeftWidth': '1px',
    'borderLeftColor': `#FFFFFF33`,
    'borderTopLeftRadius': '0',
    'borderBottomLeftRadius': '0',
    'borderTopRightRadius': '0',
    'borderBottomRightRadius': '0',
    '&:hover': {
      backgroundColor: MyCricketColors.teal,
    },
  },
  endButton: {
    'color': MyCricketColors.white,
    'backgroundColor': MyCricketColors.lake,
    'borderLeftStyle': 'solid',
    'borderLeftWidth': '1px',
    'borderLeftColor': `#FFFFFF33`,
    'borderTopLeftRadius': '0',
    'borderBottomLeftRadius': '0',
    'borderTopRightRadius': '9999px',
    'borderBottomRightRadius': '9999px',
    '&:hover': {
      backgroundColor: MyCricketColors.teal,
    },
  },
};

export const SegmentedButton = ({ segments = [] }) => {
  if (Object.values(segments).length < 2) {
    return null;
  }
  const components = [];
  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index];
    const { sx, ...props } = segment.props || { sx: {}, props: {} };
    let baseStyles;
    if (index === 0) {
      baseStyles = segmentedButtonStyles.startButton;
    } else if (index === segments.length - 1) {
      baseStyles = segmentedButtonStyles.endButton;
    } else {
      baseStyles = segmentedButtonStyles.middleButton;
    }
    if (segment.icon) {
      components.push(
        <BaseIconButton key={index} size="large" sx={{ ...baseStyles, ...sx }} {...props}>
          {segment.icon}
        </BaseIconButton>,
      );
    } else {
      components.push(
        <BaseButtonNoTranslation key={index} sx={{ ...baseStyles, ...sx }} {...props}>
          {segment.children}
        </BaseButtonNoTranslation>,
      );
    }
  }
  return (
    <Box borderRadius={'9999px'} boxShadow={'0px 9px 25px #003E513D'} display={'flex'}>
      {components}
    </Box>
  );
};

/**
 * This is a standard Box component that leverages the built-in accessibility
 * features of an HTML <button>
 */
export const ClickableBox = ({ sx, ...props }) => (
  <Box
    component={'button'}
    display={'block'}
    bgcolor={'clear'}
    color={'inherit'}
    font={'inherit'}
    textAlign={'inherit'}
    m={0}
    p={0}
    border={'none'}
    sx={[{ cursor: 'pointer' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  />
);
