import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

const UnreadMessageAlert = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="16"
        height="16"
        rx="8"
        transform="matrix(-1 0 0 1 16 0)"
        fill={MyCricketColors.notificationRed}
      />
    </svg>
  );
};

export default UnreadMessageAlert;
