import { Box, Typography, Link } from '@mui/material';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import React, { useEffect } from 'react';
import { BaseButton } from '../atoms/Buttons';
import HorizontalDividerLine from '../atoms/HorizontalDivider';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import URIUtils from '../../utils/URIUtils.js';
import { TrackingEvent } from '../../data/Events';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../utils/contexts/ModalContext';

export const AgreementsDialog = () => {
  const dreamBackendUrl = window._cc && window._cc.dream ? window._cc.dream.url : '';
  const { logEvent } = useClientEventLoggerContext();
  const { ppVersion, tosVersion, nppVersion, ictsVersion, missingAgreementIds, updateSystemInfo } =
    usePatientContext();
  const { hideModal } = useModalContext();

  const { t } = useTranslation();

  const acceptTerms = async () => {
    await myCricketAuthedFetch(`/i/user/agreements/update`, {
      method: 'POST',
      body: JSON.stringify({
        agree: true,
        tosVerId: tosVersion,
        ppVerId: ppVersion,
        nppVerId: nppVersion,
        ictsVerId: ictsVersion,
      }),
    });
    updateSystemInfo({ missingAgreementIds: [] });
    hideModal();
  };
  useEffect(() => {
    // Only want this event firing once when all the props are actually
    // available
    if (ppVersion && tosVersion && ictsVersion && nppVersion) {
      logEvent({
        predicate: TrackingEvent.AGREEMENTS_DISPLAY_MODAL,
        object: URIUtils.toMyCricketPageURI('TOS'),
        prepositions: {
          page: 'Welcome',
          tosVersion: tosVersion?.toString(), //the only time these wouldn't exist is in a test
          ppVersion: ppVersion?.toString(),
          nppVersion: nppVersion?.toString(),
          ictsVersion: ictsVersion?.toString(),
        },
      });
    }
  }, [logEvent, ppVersion, tosVersion, ictsVersion, nppVersion]);

  const TOSComponent = missingAgreementIds.includes('tos') && (
    <Trans
      i18nKey="new_agreement_tos"
      t={t}
      defaults="<1>Terms of Service</1>"
      components={{
        1: (
          <Link
            href={`${dreamBackendUrl}/i/agreements/tos`}
            target="_blank"
            rel="noreferrer"
            data-test-id="termsOfServiceLink"
            underline={'none'}
          />
        ),
      }}
    />
  );
  const PPComponent = missingAgreementIds.includes('pp') && (
    <Trans
      i18nKey="new_agreement_pp"
      t={t}
      defaults="<1>Privacy Policy</1>"
      components={{
        1: (
          <Link
            href={`${dreamBackendUrl}/i/agreements/pp`}
            target="_blank"
            rel="noreferrer"
            data-test-id="privacyPolicyLink"
            underline={'none'}
          />
        ),
      }}
    />
  );
  const ICTSComponent = missingAgreementIds.includes('icts') && (
    <Trans
      i18nKey="new_agreement_icts"
      t={t}
      defaults="<1>Informed Consent for Telehealth Services</1>"
      components={{
        1: (
          <Link
            href={`${dreamBackendUrl}/i/agreements/icts`}
            target="_blank"
            rel="noreferrer"
            data-test-id="ictsLink"
            underline={'none'}
          />
        ),
      }}
    />
  );
  const NPPComponent = missingAgreementIds.includes('npp') && (
    <Trans
      i18nKey="new_agreement_npp"
      t={t}
      defaults="<1>Notice of Privacy Practice</1>"
      components={{
        1: (
          <Link
            href={`${dreamBackendUrl}/i/agreements/npp`}
            target="_blank"
            rel="noreferrer"
            data-test-id="nppLink"
            underline={'none'}
          />
        ),
      }}
    />
  );

  const agreementsToShow = [TOSComponent, PPComponent, ICTSComponent, NPPComponent].filter(
    (x) => !!x,
  );

  let AgreementsComponent;
  if (agreementsToShow.length === 1) {
    AgreementsComponent = <>{agreementsToShow[0]}. </>;
  } else if (agreementsToShow.length === 2) {
    AgreementsComponent = (
      <>
        {agreementsToShow[0]} <Trans t={t} defaults="and" i18nKey="and" /> {agreementsToShow[1]}.{' '}
      </>
    );
  } else if (agreementsToShow.length === 3) {
    AgreementsComponent = (
      <>
        {agreementsToShow[0]}, {agreementsToShow[1]}, <Trans t={t} defaults="and" i18nKey="and" />{' '}
        {agreementsToShow[2]}.{' '}
      </>
    );
  } else {
    AgreementsComponent = (
      <>
        {agreementsToShow[0]}, {agreementsToShow[1]}, {agreementsToShow[2]},{' '}
        <Trans t={t} defaults="and" i18nKey="and" /> {agreementsToShow[3]}.{' '}
      </>
    );
  }
  return (
    <Box>
      <Box id={'patientModalTitle'}>
        <Typography variant={'body2'} aria-level="1">
          <Trans t={t} defaults="We've updated our " i18nKey="new_agreement_start" />
          {AgreementsComponent}
        </Typography>
      </Box>
      <Box id={'patientModalDescription'}>
        <Typography variant={'body2'}>
          <Trans
            t={t}
            i18nKey="new_agreement_end"
            defaults="To continue please carefully review and agree to the new updates by selecting ‘I agree’ below."
          />
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <HorizontalDividerLine />
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
        >
          <BaseButton data-test-id="theSubmitButton" type="button" onClick={acceptTerms}>
            {'I Agree'}
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
};
