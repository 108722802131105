import React from 'react';
import { Typography, Box, styled } from '@mui/material';
import { Trans } from 'react-i18next';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import MyCricketColors from '../../data/MyCricketColors';

const SpanLink = styled('span')({
  color: MyCricketColors.lake,
  textDecoration: 'underline',
  cursor: 'pointer',
});

const ALink = styled('a')({
  color: MyCricketColors.lake,
});

const PatientHelpMaterial = (props) => {
  const { email } = props;
  return (
    <Box data-testid="helpMaterialWrapper" textAlign={'center'}>
      <Box mb={1}>
        <TranslatedTypography variant="body2" display="inline">
          Having trouble logging into your account?
        </TranslatedTypography>
      </Box>
      <Typography variant="body2" display="inline">
        <Trans>Email us at</Trans>{' '}
        <ALink data-testid="supportEmailLink" href="mailto:support@crickethealth.com">
          support@crickethealth.com
        </ALink>{' '}
        <Trans i18nKey={'sendUsAMessage'}>send us a message via our</Trans>{' '}
        <SpanLink
          role="link"
          tabIndex={0}
          onClick={() => {
            if (window.zE) {
              // For some reason only works with a valid email
              window.zE('webWidget', 'prefill', {
                email: {
                  value: email,
                },
              });
              // activate doesn't work with the ZendeskAPI object from 'react-zendesk'
              window.zE.activate();
            }
          }}
          onKeyPress={() => {}}
        >
          <Trans i18nKey={'chat'}>chat</Trans>
        </SpanLink>
        , <Trans>or visit our</Trans>{' '}
        <ALink
          href="https://crickethealth.zendesk.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
          data-testid="helpCenterLink"
        >
          <Trans>Help Center</Trans>
        </ALink>{' '}
        <Trans>for more information</Trans>.
      </Typography>
    </Box>
  );
};

export default PatientHelpMaterial;
