import React, { useState, useEffect } from 'react';
import { Box, Typography, Collapse, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import UpdateEmailForm from '../molecules/UpdateEmailForm';
import { BaseButtonNoTranslation as BaseButton } from '../atoms/Buttons';
import { isValidEmailAddress } from '../../utils/validation';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { logClientError } from '../../utils/errorLogging';

const UpdateEmailContainer = ({ isEditing, setEditing }) => {
  const { emailAddresses, updateEmailAddress } = usePatientContext();

  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const clearError = () => {
    setErrorMessage(null);
    setErrorCode(null);
  };

  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isEditing) {
      setNewEmailAddress(emailAddresses.active);
      setErrorMessage(null);
      setErrorCode(null);
    }
  }, [isEditing, emailAddresses.active]);

  const updatePatientEmailAddress = async () => {
    if (isLoading || newEmailAddress === emailAddresses.active) {
      return;
    }
    if (!isValidEmailAddress(newEmailAddress)) {
      setErrorMessage(t('emailErrorInvalid'));
      setErrorCode('invalid-email');
      return;
    }
    setLoading(true);
    clearError();
    try {
      await updateEmailAddress(newEmailAddress);
      setEditing(false);
    } catch (error) {
      await logClientError(error);
      if (error.responseBody.errorCode === 'email-unavailable') {
        setErrorMessage(t('emailErrorUnavailable'));
      } else {
        setErrorMessage(t('emailErrorUnknown'));
      }
      setErrorCode(error.responseBody.errorCode || 'unknown');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Collapse in={!isEditing} appear={false}>
        <Fade in={!isEditing} appear={false}>
          <Box>
            <Box display={isDesktop ? 'flex' : 'block'} alignItems={'center'} width={'100%'}>
              <Box flex={'1 0 0'} mr={isDesktop ? 3 : 0} mb={isDesktop ? 0 : 3}>
                <Box mb={1.5}>
                  <Typography variant={'body1'}>{t('emailAddress', 'Email Address')}</Typography>
                </Box>
                <Box style={{ wordBreak: 'break-all' }}>
                  <Typography variant={'body2'}>{emailAddresses.active}</Typography>
                </Box>
                {emailAddresses.pending ? (
                  <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    style={{ wordBreak: 'break-all' }}
                    mt={1.5}
                  >
                    <Typography variant={'body2'}>{emailAddresses.pending}</Typography>
                    <Box
                      bgcolor={MyCricketColors.lightGrayMist}
                      color={MyCricketColors.darkTeal}
                      borderRadius={'6px'}
                      ml={1.5}
                      px={0.5}
                    >
                      <Typography variant={'body1'}>
                        {t('pendingVerification', 'Pending Verification')}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box
                flex={'0 0 1'}
                display={isDesktop ? 'flex' : 'block'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <BaseButton
                  variant={'outlined'}
                  style={{
                    'height': '50px',
                    'color': MyCricketColors.black,
                    'borderColor': MyCricketColors.lightGraySmoke,
                    'lineHeight': 1,
                    '&:hover': {
                      'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'background': MyCricketColors.white,
                    },
                  }}
                  onClick={() => setEditing(true)}
                >
                  {t('changeEmail', 'Change Email')}
                </BaseButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
      <Collapse in={isEditing} appear={false}>
        <Fade in={isEditing} appear={false}>
          <Box
            component={'form'}
            onSubmit={(event) => {
              event.preventDefault();
              updatePatientEmailAddress();
            }}
          >
            <UpdateEmailForm
              emailAddress={newEmailAddress}
              setEmailAddress={setNewEmailAddress}
              errorCode={errorCode}
              errorMessage={errorMessage}
              clearError={clearError}
            />
            <Box mt={4}>
              <hr
                width="100%"
                height="1px"
                color={MyCricketColors.lightGrayFog}
                style={{ margin: 0 }}
              />
              <Box
                display={'flex'}
                flexDirection={'row'}
                width={'100%'}
                justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
                mt={4}
              >
                <Box mr={3}>
                  <BaseButton
                    type="button"
                    variant="outlined"
                    style={{ width: '105px', height: '50px' }}
                    disabled={isLoading}
                    onClick={() => setEditing(false)}
                  >
                    {t('Cancel')}
                  </BaseButton>
                </Box>
                <Box>
                  <BaseButton
                    type="submit"
                    style={{ width: '105px', height: '50px' }}
                    disabled={
                      isLoading || !newEmailAddress || newEmailAddress === emailAddresses.active
                    }
                  >
                    {t('Save')}
                  </BaseButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
    </Box>
  );
};

export default UpdateEmailContainer;
