import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { HorizontalChevron, HorizontalChevronSlider } from '../atoms/Chevrons';
import { MdEmail } from '@react-icons/all-files/md/MdEmail';
import { MdLanguage } from '@react-icons/all-files/md/MdLanguage';
import { MdLock } from '@react-icons/all-files/md/MdLock';
import { MdPerson } from '@react-icons/all-files/md/MdPerson';
import { MdContactPhone } from '@react-icons/all-files/md/MdContactPhone';
import { useHistory } from 'react-router';
import { paths } from '../../utils/routing/routes';
import { supportedLanguageMaps } from '../../data/SupportedLanguages';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { formatPhoneNumber } from '../../utils/formattingUtils';
import { isPatientRole } from '../../data/Roles';

const PreferencesLink = ({ title, subtitle, link, icon }) => {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  return (
    <Box>
      <hr width="100%" height="1px" color={MyCricketColors.lightGrayFog} style={{ margin: 0 }} />
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => history.push(link)}
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
        flexDirection="row"
        py={3}
        style={{ cursor: 'pointer' }}
      >
        <Box alignItems={'center'} display="flex" flexDirection="row">
          <Box
            color={MyCricketColors.lightGraySmoke}
            display={'flex'}
            flexDirection={'column'}
            pr={2}
            lineHeight={25}
          >
            {icon}
          </Box>

          <Box>
            <Typography variant="body1">{title}</Typography>
            <Box>
              <Typography variant="body2">{subtitle}</Typography>
            </Box>
          </Box>
        </Box>
        <HorizontalChevronSlider
          role="button"
          aria-label={`click to access the settings page for ${title}`}
          hovered={hovered}
        >
          <Typography variant={'h2'} role="none">
            <HorizontalChevron />
          </Typography>
        </HorizontalChevronSlider>
      </Box>
    </Box>
  );
};

export const AccountSettings = () => {
  const [language, setLanguage] = useState('');
  const { siteLanguage, userName, userRole } = usePatientContext();
  const { t } = useTranslation();

  useEffect(() => {
    const displayLang = supportedLanguageMaps.find(({ code }) => code === siteLanguage)?.name;
    setLanguage(displayLang);
  }, [siteLanguage]);

  return (
    <Box
      px={{ xs: 3, md: 6 }}
      pt={4}
      pb={3}
      bgcolor={MyCricketColors.white}
      borderRadius={'6px'}
      style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
    >
      <Box pb={4}>
        <TranslatedTypography variant="h2" aria-level={1}>
          Account Settings
        </TranslatedTypography>
      </Box>
      {isPatientRole(userRole) && (
        <PreferencesLink
          title={t('emailAndPassword', 'Email & Password')}
          link={paths.loginInformation()}
          icon={<MdLock size={36} />}
        />
      )}
      <PreferencesLink
        title={t('Display Name')}
        subtitle={userName}
        link={paths.userName()}
        icon={<MdPerson size={36} />}
      />
      <PreferencesLink
        title={t('Site Language')}
        subtitle={language}
        link={paths.languagePreferences()}
        icon={<MdLanguage size={36} />}
      />
      <PreferencesLink
        title={t('Email notification preferences')}
        link={paths.emailPreferences()}
        icon={<MdEmail size={36} />}
      />
    </Box>
  );
};

export const ContactInfoSettings = () => {
  const { phoneNumbers } = usePatientContext();
  const { t } = useTranslation();

  //we only keep the "primary" and sms specific numbers.
  const phoneInfoString = [...new Set([phoneNumbers.sms, phoneNumbers.primary])]
    .filter((phone) => Boolean(phone))
    .map((phone) => formatPhoneNumber(phone))
    .join(', ');

  return (
    <Box
      px={{ xs: 3, md: 6 }}
      pt={4}
      pb={3}
      bgcolor={MyCricketColors.white}
      borderRadius={'6px'}
      style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
    >
      <Box pb={4}>
        <Typography variant="h2" aria-level={1}>
          {t('contactInformation', 'Contact Information')}
        </Typography>
      </Box>
      <PreferencesLink
        title={t('phoneInformation', 'Phone Information')}
        subtitle={phoneInfoString || t('none')}
        link={paths.phoneContactInfo()}
        icon={<MdContactPhone size={36} />}
      />
    </Box>
  );
};
