import React, { useState } from 'react';
import { Box, Typography, Collapse, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import MyCricketColors from '../../data/MyCricketColors';
import { paths } from '../../utils/routing/routes';
import { BackNav } from '../atoms/BackNav';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import UpdateEmailContainer from '../organisms/UpdateEmailContainer';
import UpdatePasswordContainer from '../organisms/UpdatePasswordContainer';
import VerificationEmailCard from '../molecules/VerificationEmailCard';

const LoginInformationPage = () => {
  const { emailAddresses, isEmailRecentlyVerified } = usePatientContext();
  const [isEditingEmail, setEditingEmail] = useState(false);
  const [isEditingPassword, setEditingPassword] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const showEmailVerificationCard =
    !isEditingEmail &&
    !isEditingPassword &&
    (isEmailRecentlyVerified || Boolean(emailAddresses.pending));

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box component={'main'} mt={3} px={2} width={'640px'}>
        <BackNav history={history} link={paths.me()} data-test-id="backNav" />
        <Box
          p={{ xs: 3, md: 6 }}
          bgcolor={MyCricketColors.white}
          borderRadius={'6px'}
          maxWidth={'640px'}
          style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
        >
          <Box position={'relative'} height={'30px'}>
            <Box position={'absolute'}>
              <Fade in={!isEditingEmail && !isEditingPassword} appear={false}>
                <Typography variant="h2" aria-level="1">
                  {t('emailAndPassword', 'Email & Password')}
                </Typography>
              </Fade>
            </Box>
            <Box position={'absolute'}>
              <Fade in={isEditingEmail} appear={false}>
                <Typography variant="h2" aria-level="1">
                  {t('changeEmail', 'Change Email')}
                </Typography>
              </Fade>
            </Box>
            <Box position={'absolute'}>
              <Fade in={isEditingPassword} appear={false}>
                <Typography variant="h2" aria-level="1">
                  {t('changePassword', 'Change Password')}
                </Typography>
              </Fade>
            </Box>
          </Box>
          <Collapse in={showEmailVerificationCard} appear={false}>
            <Fade in={showEmailVerificationCard} appear={false}>
              <Box mt={4}>
                <VerificationEmailCard
                  hasPendingEmail={Boolean(emailAddresses.pending)}
                  hasVerifiedEmail={isEmailRecentlyVerified}
                />
              </Box>
            </Fade>
          </Collapse>
          <Collapse in={!isEditingPassword} appear={false}>
            <Fade in={!isEditingPassword} appear={false}>
              <Box mt={5}>
                <UpdateEmailContainer isEditing={isEditingEmail} setEditing={setEditingEmail} />
              </Box>
            </Fade>
          </Collapse>
          <Collapse in={!isEditingEmail && !isEditingPassword} appear={false}>
            <Fade in={!isEditingEmail && !isEditingPassword} appear={false}>
              <Box mt={5}>
                <hr
                  width="100%"
                  height="1px"
                  color={MyCricketColors.lightGrayFog}
                  style={{ margin: 0 }}
                />
              </Box>
            </Fade>
          </Collapse>
          <Collapse in={!isEditingEmail} appear={false}>
            <Fade in={!isEditingEmail} appear={false}>
              <Box mt={5}>
                <UpdatePasswordContainer
                  isEditing={isEditingPassword}
                  setEditing={setEditingPassword}
                />
              </Box>
            </Fade>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginInformationPage;
