import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { useModalContext } from '../../utils/contexts/ModalContext';
import { BaseButton } from '../atoms/Buttons';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import UsernameConfirmationModal from '../molecules/UsernameConfirmationModal';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { TrackingEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils.js';

const useStyles = makeStyles((theme) => ({
  careTeamMessageEntryForm: {
    padding: '12px',
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.lightGrayHaze}`,
    boxSizing: 'border-box',
    boxShadow: '0px 3px 4px rgba(0, 62, 81, 0.1)',
    borderRadius: '12px',
  },
}));

function PostCompositionArea({ selectedGroupId }) {
  // TODO Consider ways to consolidate logic between this, GroupReplyCompositionArea, and CareTeamMessageEntry
  // https://crickethealth.atlassian.net/browse/PE-235
  const classes = useStyles();
  const { userName } = usePatientContext();
  const { sendMessage, isConnected, getComposedMessageData, setComposedMessageData } =
    useChirpContext();
  const { showModal } = useModalContext();
  const { logEvent } = useClientEventLoggerContext();

  const composedDataKey = `${selectedGroupId}`;
  const { text: initialText } = getComposedMessageData(composedDataKey);
  const [messageText, setMessageText] = useState(initialText);
  const [isSubmitting, setSubmitting] = useState(false);
  const [shouldBlockButton, setShouldBlockButton] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const { text } = getComposedMessageData(composedDataKey);
    setMessageText(text);
  }, [composedDataKey, getComposedMessageData]);

  const handleChange = (event) => {
    setMessageText(event.target.value);
    setComposedMessageData(composedDataKey, { text: event.target.value });
  };

  const clearInput = () => {
    setMessageText('');
    setComposedMessageData(composedDataKey, { text: '' });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const handleSendMessage = async () => {
      const message = {
        messageText: messageText,
        selectedGroupId: selectedGroupId,
      };
      await sendMessage(message);
      clearInput();
    };

    if (isSubmitting || !messageText) {
      return;
    } else if (!userName) {
      logEvent({
        predicate: TrackingEvent.USERNAME_MODAL_LOADING,
        object: URIUtils.toMyCricketPageURI(window.location.pathname),
        prepositions: {
          page: URIUtils.toMyCricketPageURI(window.location.pathname),
        },
      });
      showModal(() => <UsernameConfirmationModal onUpdateUsername={handleSendMessage} />, {});
      return;
    }
    setSubmitting(true);
    try {
      await handleSendMessage();
    } catch (e) {
      console.error('Error sending message: ', e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box marginBottom={3} marginTop={3}>
      <form
        onSubmit={onSubmit}
        data-test-id="postCompositionAreaForm"
        className={classes.careTeamMessageEntryForm}
      >
        <Box display={'flex'} width={'100%'}>
          <DecoratedChatInput
            type="text"
            value={messageText}
            onChange={handleChange}
            data-test-id="postCompositionAreaInput"
            maxRows={5}
            placeholder={t("What's on your mind?")}
            borderless={true}
            minRows={5}
            blockButton={setShouldBlockButton}
          />
        </Box>
        <Box height={'58px'} display={'flex'} flexDirection={'column'} marginTop={'8px'}>
          <Box display={'flex'} alignSelf={'flex-end'}>
            <BaseButton
              disabled={!isConnected || isSubmitting || shouldBlockButton}
              type="submit"
              data-test-id="postCompositionAreaSendButton"
            >
              {isSubmitting ? 'Posting...' : 'Post Message'}
            </BaseButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

PostCompositionArea.propTypes = {
  selectedGroupId: PropTypes.number.isRequired,
};

export default PostCompositionArea;
