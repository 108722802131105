import React from 'react';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { classesPropType } from '../../utils/patientPropTypes';
import * as PropTypes from 'prop-types';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { StyledTooltip, ToolTipContent } from './Tooltips';
import MyCricketColors from '../../data/MyCricketColors';
import { MdAttachFile } from '@react-icons/all-files/md/MdAttachFile';

const styles = (theme) => ({
  uploadButton: {
    'display': 'flex',
    'width': '49px',
    'minWidth': '49px',
    'height': '51px',
    'marginRight': '6px',
    'border': `1px solid ${theme.palette.lightGraySmoke}`,
    'boxSizing': 'border-box',
    'borderRadius': '5px',
    'backgroundColor': theme.palette.white,
    'fontSize': '45px',
    'alignItems': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    'color': MyCricketColors.tealGray,
    '&:hover': {
      borderColor: MyCricketColors.lake,
    },
  },
  // Needs to be an input because that's how the html file upload inner code expects it to be.
  // Needs to be invisible as we can't have have text inside of a file input, hence why the label handles all styling.
  uploadButtonInvisibleInput: {
    display: 'none',
  },
});

export const sendFilesToBackEnd = async (files, groupType, uploadedToUserId) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append('fileNames', file.name);
    formData.append('files', file);
  }
  if (groupType) {
    formData.append('groupType', groupType);
  }
  if (uploadedToUserId) {
    formData.append('uploadedToUserId', uploadedToUserId);
  }
  const res = await myCricketAuthedFetch('/i/admin/patient-file', {
    method: 'POST',
    body: formData,
  });
  return res?.results;
};

function FileUploader(props) {
  /*
  Component assumes you will be storing the uploaded file in the state of the parent consuming it.
  It cannot act independently. You are encouraged to use the sendFileToBackend helper above in said parent.
  */
  const { classes, handleFiles } = props;
  const { t } = useTranslation();

  const onChange = (e) => {
    e.preventDefault();
    handleFiles(e.target.files);
    e.target.value = null;
  };

  return (
    <StyledTooltip
      placement="bottom-start"
      arrow
      title={
        <ToolTipContent>
          {t('careTeamFilesTooltipTitle', {
            defaultValue: 'Attach Files (10 max)',
            replace: { limit: 10 },
          })}
        </ToolTipContent>
      }
    >
      <label className={classes.uploadButton}>
        <input
          onChange={onChange}
          type="file"
          accept={'application/pdf, image/png, image/jpeg, image/gif'}
          multiple={true}
          className={classes.uploadButtonInvisibleInput}
          data-test-id="uploadButtonInput"
          data-testid="uploadButtonInput"
        />
        <MdAttachFile size={36} />
      </label>
    </StyledTooltip>
  );
}

FileUploader.propTypes = {
  classes: classesPropType.isRequired,
  handleFiles: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(FileUploader);
