import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import EmailVerifiedIcon from '../icons/EmailVerified';
import EmailUnverifiedIcon from '../icons/EmailUnverified';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { ClickableBox } from '../atoms/Buttons';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import MyCricketColors from '../../data/MyCricketColors';
import { logClientError } from '../../utils/errorLogging';

const resendVerificationEmail = async () => {
  await myCricketAuthedFetch('/patient/me/email/verification/resend', {
    method: 'POST',
  });
};

const VerificationEmailCard = ({ hasPendingEmail, hasVerifiedEmail }) => {
  const { cancelPendingEmailAddress } = usePatientContext();

  const [isResent, setResent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const { t } = useTranslation();

  if (!hasPendingEmail && !hasVerifiedEmail) {
    return null;
  }

  let message;
  let color;
  let icon;
  if (errorCode) {
    message = t('emailVerificationErrorMessage');
    color = MyCricketColors.orangeCitrus;
    icon = <EmailUnverifiedIcon />;
  } else if (isResent) {
    message = t('emailVerificationResentMessage');
    color = MyCricketColors.orangeCitrus;
    icon = <EmailUnverifiedIcon />;
  } else if (hasPendingEmail) {
    message = (
      <Trans
        i18nKey={'emailUnverifiedMessage'}
        components={{
          resend: (
            <button
              type={'button'}
              style={{
                border: 'none',
                outline: 'none',
                background: 'none',
                cursor: 'pointer',
                padding: 0,
                textDecoration: 'underline',
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontWeight: 'inherit',
              }}
              data-testid={'resend-button'}
              onClick={async () => {
                if (isLoading) {
                  return;
                }
                setLoading(true);
                try {
                  await resendVerificationEmail();
                  setResent(true);
                } catch (error) {
                  await logClientError(error);
                  setErrorCode(error.responseBody?.errorCode || 'unknown');
                } finally {
                  setLoading(false);
                }
              }}
            ></button>
          ),
        }}
      />
    );
    color = MyCricketColors.orangeCitrus;
    icon = <EmailUnverifiedIcon />;
  } else if (hasVerifiedEmail) {
    message = t('emailVerifiedMessage');
    color = MyCricketColors.mint;
    icon = <EmailVerifiedIcon />;
  }

  return (
    <Box
      role={'status'}
      display={'flex'}
      alignItems={'center'}
      border={`1px solid ${color}`}
      p={2.5}
    >
      <Box mr={3}>{icon}</Box>
      <Box color={color}>
        <Typography variant={'subtitle1'} aria-level={'2'}>
          {message}
        </Typography>
      </Box>
      {hasPendingEmail && (
        <ClickableBox
          bgcolor={MyCricketColors.white}
          color={color}
          border={`1px solid ${color}`}
          px={2}
          py={1}
          ml={2}
          onClick={() => cancelPendingEmailAddress()}
        >
          <Typography variant={'subtitle1'} aria-level={'3'}>
            {t('Cancel')}
          </Typography>
        </ClickableBox>
      )}
    </Box>
  );
};

export default VerificationEmailCard;
