import React from 'react';
import { Box, Typography, TextField, InputLabel, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { RoundButton, ClickableBox } from '../atoms/Buttons';
import LeftArrow from '../icons/LeftArrow';
import MyCricketColors from '../../data/MyCricketColors';
import LoginError from '../../data/LoginError';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: MyCricketColors.notificationRed,
  marginTop: theme.spacing(1),
}));

const ResetPasswordForm = (props) => {
  const {
    state,
    setState,
    handleSendResetPassword,
    handleChange,
    email,
    error,
    hasEdited,
    emailFocused,
    emailValid,
    disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <ClickableBox
        bgcolor={MyCricketColors.white}
        mb={4}
        onClick={() => setState({ ...state, route: 'main', error: null })}
      >
        <Box display={'flex'}>
          <Box component={'span'} mr={1}>
            <LeftArrow color={MyCricketColors.tealMain} />
          </Box>
          <TranslatedTypography variant={'body1'}>Back to Log in</TranslatedTypography>
        </Box>
      </ClickableBox>
      <TranslatedTypography variant={'h2'} aria-level="1">
        Reset your password
      </TranslatedTypography>
      <Box mt={1}>
        <Typography variant={'body1'}>
          <Trans i18nKey={'enterEmailAddress'}>Please enter your email address.</Trans>{' '}
          <Trans i18nKey={'sendResetPasswordEmail'}>
            We will send you an email to reset your password.
          </Trans>
        </Typography>
      </Box>
      <form
        id="reset-password-request"
        data-test-id="resetPasswordForm"
        onSubmit={handleSendResetPassword}
      >
        <Box mt={3} width={'100%'}>
          <Box mb={1}>
            <InputLabel htmlFor={'email-address'}>
              <TranslatedTypography i18nKey={'emailAddress'} variant={'h3'} aria-level="2">
                Email Address
              </TranslatedTypography>
            </InputLabel>
          </Box>
          <TextField
            id="email-address"
            autoComplete="username"
            name="email"
            variant="outlined"
            type="email"
            placeholder={t('emailAddress', 'Email Address')}
            data-test-id="emailAddressInput"
            error={
              (hasEdited && !emailFocused && !emailValid) ||
              error === LoginError.NEED_EMAIL ||
              (hasEdited && !emailFocused && error === LoginError.UNAUTHORIZED)
            }
            disabled={disabled}
            sx={{ width: '100%' }}
            InputProps={{ style: { width: '100%', height: '50px' } }}
            value={email}
            onChange={handleChange}
            onFocus={() => setState({ ...state, emailFocused: true })}
            onBlur={() => setState({ ...state, emailFocused: false })}
          />
          {hasEdited && !emailFocused && !emailValid && (
            <ErrorTypography variant={'h3'} data-test-id="invalidEmailMessage">
              <Trans>{LoginError.INVALID_EMAIL}</Trans>
            </ErrorTypography>
          )}
          {error === LoginError.NEED_EMAIL && (
            <ErrorTypography variant={'h3'} data-test-id="needEmailMessage">
              <Trans>{LoginError.NEED_EMAIL}</Trans>
            </ErrorTypography>
          )}
        </Box>
        <Box width={'100%'} mt={4}>
          <RoundButton
            id="reset-password-request-cta"
            data-testid="reset-password-request-cta"
            style={{ height: '50px', width: '100%' }}
            textStyle={{ lineHeight: 1 }}
            onClick={handleSendResetPassword}
            disabled={disabled}
          >
            <Trans>Submit</Trans>
          </RoundButton>
        </Box>
      </form>
    </>
  );
};

export default ResetPasswordForm;
