import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

export const HorizontalDividerLine = ({ bioTitle }) => {
  return (
    <hr
      width="90%"
      height="1px"
      textalign="center"
      color={MyCricketColors.lightGrayHaze}
      margin="12px 0px"
    />
  );
};
export default HorizontalDividerLine;
