import React from 'react';
import OnboardingWelcome from '../molecules/OnboardingWelcome';

export const SurveyWelcomeContainer = ({
  savedSurveyAnswerIds,
  questions,
  answerState,
  getQuestionDependenciesMet,
  goToQuestion,
  goToComplete,
}) => {
  const isWelcomeBack = Object.values(savedSurveyAnswerIds).length > 0;
  return (
    <OnboardingWelcome
      isWelcomeBack={isWelcomeBack}
      onSubmit={() => {
        if (isWelcomeBack) {
          const firstUnansweredQuestionId = questions.find((q) => {
            const myAnswers = answerState[q.questionId];
            const isQuestionUnanswered = myAnswers.every(({ answerValue }) => answerValue == null);
            const areQuestionDependenciesMet = getQuestionDependenciesMet(q.questionId);
            return isQuestionUnanswered && areQuestionDependenciesMet;
          })?.questionId;
          firstUnansweredQuestionId ? goToQuestion(firstUnansweredQuestionId) : goToComplete();
        } else {
          const firstQuestionId = questions[0].questionId;
          goToQuestion(firstQuestionId);
        }
      }}
    />
  );
};

export default SurveyWelcomeContainer;
