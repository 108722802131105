const JWTSessionEvents = require('./JWTSessionEvents');
const rememberLastActivity = require('./rememberLastActivity');
const DEFAULT_AUTO_LOGOUT_CHECK_INTERVAL = 60;
let started = false;

function AutoLogout(config) {
  if (started) {
    console.error('AutoLogout already started, duplicate call');
    return;
  }
  started = true;
  const { getTTL, logout, onWarn, renewToken } = config;

  rememberLastActivity.start();

  JWTSessionEvents.start({
    getTTL,
    getLastActive: () => rememberLastActivity.get(),
    onExpire: logout,
    onWarn,
    onNewActivity: renewToken,
    checkInterval: DEFAULT_AUTO_LOGOUT_CHECK_INTERVAL,
  });
}

AutoLogout.stop = function () {
  started = false;
  JWTSessionEvents.stop();
};

module.exports = AutoLogout;
