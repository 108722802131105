import i18next from 'i18next';
import Fetch from 'i18next-fetch-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let loaded = false;

function main({ useSuspense = false, ns = [] }) {
  if (!loaded && !window.i18next) {
    const backendUrl = __ENV__.endpoints.public.dream.url;
    const backend = {
      loadPath: function (lngs, namespaces) {
        if (namespaces.includes('match')) {
          return `/locales/{{lng}}/{{ns}}.json`;
        } else {
          return `${backendUrl}/lang/{{lng}}/{{ns}}`;
        }
      },
      addPath: `${backendUrl}/lang/add/{{lng}}/{{ns}}`,
      stringify: JSON.stringify,
      allowMultiLoading: false,
    };

    const detection = {
      // detection
      cookieOptions: {
        path: '/',
        sameSite: 'strict',
      },
      caches: ['cookie'],
      order: ['querystring', 'cookie', 'htmlTag', 'navigator'],
      lookupQuerystring: 'lng',
    };

    const config = {
      debug: false,
      preload: ['en', 'es'],
      fallbackLng: 'en',
      ns: ['webapp', ...ns],
      defaultNS: 'webapp',
      backend,
      detection,
      react: {
        useSuspense: useSuspense || false,
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      load: 'currentOnly',
      whitelist: ['en', 'es'],
      saveMissing: false,
      // lng: 'cimode', // turn this on in develop to view all content that has been captured
    };

    i18next
      .use(Fetch)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(config)
      .then(function () {
        // set zendesk widget language
        if (window.zE && window.zE.setLocale) {
          window.zE.setLocale(i18next.language);
        }
      });

    i18next.on('missingKey', function (lngs, namespace, key) {
      const msg = {
        lngs,
        namespace,
        key,
      };
      console.warn(`i18n-missingkey:${lngs}:${namespace}:${key}`, msg);
    });
    //comply with WCAG 3.1.1: Language of Page (Level A)
    i18next.on('languageChanged', (lng) => {
      document.documentElement.setAttribute('lang', lng);
    });
    window.i18next = i18next; // global level. This allow us to work with backbone / handlebars
    loaded = true; // module level
  }
  return i18next;
}
export default main;
