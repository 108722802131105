const CareTeamGroups = Object.freeze({
  NURSE_PT: 'NURSE:PATIENT',
  NURSE_MENTOR: 'NURSE:MENTOR',
  SW_PT: 'SOCIAL_WORKER:PATIENT',
  RD_PT: 'DIETITIAN:PATIENT',
  PHAR_PT: 'PHARMACIST:PATIENT',
  PHAR_NAV_PT: 'PHARMACY_NAVIGATOR:PATIENT',
  MENTOR_PT: 'MENTOR:PATIENT',
});
const RoleToPatientCareTeamGroup = Object.freeze({
  NURSE: CareTeamGroups['NURSE_PT'],
  SOCIAL_WORKER: CareTeamGroups['SW_PT'],
  DIETITIAN: CareTeamGroups['RD_PT'],
  PHARMACIST: CareTeamGroups['PHAR_PT'],
  PHARMACY_NAVIGATOR: CareTeamGroups['PHAR_NAV_PT'],
});
const MultipartyGroupTypes = Object.freeze({
  EXERCISE: 'EXERCISE',
  ORG_MEMBER: 'ORG_MEMBER',
});
const DiscussionGroupNames = Object.freeze({
  GENERAL: 'default-cohort',
  DIET: 'diet',
  TRANSPLANTS: 'transplants',
  MEDICATION_LABS: 'medication-labs',
  ACCESS: 'access',
  CAMP_COUNSELOR: 'camp-counselor',
  WORLD_TRAVELER: 'world-traveler',
  RETIRED_BAKER: 'retired-baker',
  DEMO_CARE: 'demo-cohort-care',
});
const DiscussionGroupDisplayNames = Object.freeze({
  [DiscussionGroupNames.GENERAL]: 'General Discussion',
  [DiscussionGroupNames.DIET]: 'Diet and Nutrition',
  [DiscussionGroupNames.TRANSPLANTS]: 'Transplant',
  [DiscussionGroupNames.MEDICATION_LABS]: 'Medications and Labs',
  [DiscussionGroupNames.ACCESS]: 'Dialysis Access',
  [DiscussionGroupNames.CAMP_COUNSELOR]: 'Camp Counselor',
  [DiscussionGroupNames.WORLD_TRAVELER]: 'World Traveler',
  [DiscussionGroupNames.RETIRED_BAKER]: 'Retired Baker',
  [DiscussionGroupNames.DEMO_CARE]: 'Demo Care Group',
});

const RetiredDiscussionGroupNames = [
  DiscussionGroupNames.CAMP_COUNSELOR,
  DiscussionGroupNames.RETIRED_BAKER,
  DiscussionGroupNames.WORLD_TRAVELER,
];
const RetiredGroupTypes = [CareTeamGroups.NURSE_MENTOR, MultipartyGroupTypes.ORG_MEMBER];

const NotesGroupNames = Object.freeze({
  PATIENT_NOTES: 'PATIENT_NOTES',
});
//joins the titles and descriptions
const metaTexts = {
  default: { title: '', description: '' },
  [DiscussionGroupNames.GENERAL]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.GENERAL],
    description: 'This is a place to share whatever is on your mind',
  },
  [DiscussionGroupNames.DIET]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.DIET],
    description: 'This is a place to discuss diet and food-related topics',
  },
  [DiscussionGroupNames.TRANSPLANTS]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.TRANSPLANTS],
    description: 'This is a place to discuss kidney transplant-related topics or questions',
  },
  [DiscussionGroupNames.MEDICATION_LABS]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.MEDICATION_LABS],
    description: 'This is a place to discuss topics related to medications and lab results',
  },
  [DiscussionGroupNames.ACCESS]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.ACCESS],
    description: 'This is a place to share experiences and ask questions related to dialysis',
  },
  [DiscussionGroupNames.CAMP_COUNSELOR]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.CAMP_COUNSELOR],
    description: 'What treatment would be best for Amanda?',
  },
  [DiscussionGroupNames.WORLD_TRAVELER]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.WORLD_TRAVELER],
    description: 'What treatment would be best for Carl?',
  },
  [DiscussionGroupNames.RETIRED_BAKER]: {
    title: DiscussionGroupDisplayNames[DiscussionGroupNames.RETIRED_BAKER],
    description: 'What treatment would be best for Nancy?',
  },
};

module.exports = {
  CareTeamGroups,
  RoleToPatientCareTeamGroup,
  MultipartyGroupTypes,
  DiscussionGroupNames,
  DiscussionGroupDisplayNames,
  NotesGroupNames,
  metaTexts,
  RetiredGroupTypes,
  RetiredDiscussionGroupNames,
};
