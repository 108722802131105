import React from 'react';

const Check = (props) => (
  <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.36011 8.70641L3.52675 8.86664L9.83248 14.9296L12.7331 17.8399L15.7599 14.9296L30.4732 0.782651L30.6098 0.651367L33.5 3.37243L30.4732 6.28266L15.7599 20.4296L12.7331 23.3399L9.83248 20.4296L3.52675 14.3666L0.5 11.4564L3.36011 8.70641Z"
      fill={props.fill ? props.fill : '#552DB9'}
    />
  </svg>
);

export default Check;
