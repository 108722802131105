import React from 'react';

const Groups = (props) => {
  const { ...restProps } = props;
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      alt="icon of a group chat bubbles"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M15.4375 6.78571C15.4375 3.03661 11.9826 0 7.71875 0C3.45488 0 0 3.03661 0 6.78571C0 8.2404 0.523242 9.58058 1.41016 10.6875C0.912891 11.9683 0.0927734 12.9862 0.0816406 12.9989C0 13.0964 -0.0222656 13.2406 0.0259766 13.3679C0.0742188 13.4951 0.178125 13.5714 0.296875 13.5714C1.65508 13.5714 2.77949 13.0498 3.58848 12.5112C4.7834 13.177 6.19727 13.5714 7.71875 13.5714C11.9826 13.5714 15.4375 10.5348 15.4375 6.78571ZM19.9648 16.1161C20.8518 15.0134 21.375 13.669 21.375 12.2143C21.375 9.37701 19.3896 6.94687 16.5768 5.93326C16.6102 6.21317 16.625 6.49732 16.625 6.78571C16.625 11.277 12.6283 14.9286 7.71875 14.9286C7.31797 14.9286 6.92832 14.8946 6.54238 14.848C7.71133 17.2866 10.4574 19 13.6562 19C15.1777 19 16.5916 18.6098 17.7865 17.9397C18.5955 18.4783 19.7199 19 21.0781 19C21.1969 19 21.3045 18.9194 21.349 18.7964C21.3973 18.6734 21.375 18.5292 21.2934 18.4275C21.2822 18.4147 20.4621 17.4011 19.9648 16.1161Z"
        fill="white"
      />
    </svg>
  );
};

export default Groups;
