import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toClientEvent } from './ClientEventLoggerHelpers';
import { usePatientContext } from './PatientContextProvider';
import amplitude from 'amplitude-js';
import CookieHelper from '../CookieHelper';
import { format } from 'date-fns';
import URIUtils from '../URIUtils.js';
import Debug from 'debug';
const debug = Debug('MyCricket:event');
const ampDebug = Debug('MyCricket:event:amplitude');

const apiKey = window.__ENV__?.analytics?.patient_project?.apiKey;
const shouldSyncAnalytics = window.__ENV__?.analytics?.syncEnabled;
let isAmplitudeInitialized = false;

if (!shouldSyncAnalytics || !apiKey) {
  ampDebug('Amplitude integration is disabled');
}

if (shouldSyncAnalytics && apiKey) {
  ampDebug('Starting Amplitude');
  amplitude.getInstance().init(
    apiKey,
    null,
    {
      includeReferrer: true,
      includeUtm: true,
      logAttributionCapturedEvent: true,
      saveParamsReferrerOncePerSession: false,
      // See https://www.docs.developers.amplitude.com/data/sdks/javascript/#disable-tracking-specific-fields
      trackingOptions: {
        city: false,
        country: true,
        carrier: true,
        device_manufacturer: true,
        device_model: true,
        device_brand: true, // Not listed at link above, I think it's valid...
        dma: false,
        ip_address: false,
        language: true,
        os_name: true,
        os_version: true,
        platform: true,
        region: true,
        version_name: true,
      },
    },
    (instance) => {
      isAmplitudeInitialized = true;
      ampDebug('Amplitude is ready');
      instance.logEvent('amplitudeClientInit');

      // This is intended for the cookie to be available on
      // app.crickethealth, api.crickethealth, etc.
      const url = new URL(window.location);
      const environmentHostname = url.hostname?.split('.').slice(1).join('.');

      // Using Amplitude here is for deviceId/sessionId tracking and for
      // utm/referrer tracking. The former is relayed to our backends via cookies
      // so that our event tracking is sanitized in a centralized location (we have
      // separate frontends) and the former is accomplished by the client
      // initialization which logs an attribution event on init if utm or referral
      // properties exist. See https://whimsical.com/7nsHvrGU1q7UNs4A2HiZPo for an
      // illustration
      CookieHelper.setCookie(
        'cricketDeviceId',
        instance.options.deviceId.toString(),
        false,
        environmentHostname,
      );
      CookieHelper.setCookie(
        'cricketSessionId',
        instance.getSessionId().toString(),
        false,
        environmentHostname,
      );
    },
  );
}

// Default value of a void function so tests dont just explode
export const ClientEventLoggerContext = React.createContext({
  logEvent: () => {},
  logFEOnlyEvent: () => {},
  instrument: (event, callback) => callback && callback(),
});
ClientEventLoggerContext.displayName = 'ClientEventLoggerContext';

async function sendLogEvents(clientEvents) {
  const token = localStorage.getItem('idToken');
  if (token) {
    return fetch(`${window._cc.dream.url}/i/logs`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(clientEvents),
    });
  } else {
    console.warn('idToken not found');
  }
}

async function logAuthenticatedEvent({ predicate, object, prepositions }) {
  debug('Authenticated event: %s %s', predicate, object);
  if (prepositions) {
    const localizedDate = new Date();
    prepositions.usersDay = format(localizedDate, 'i');
    prepositions.usersHour = format(localizedDate, 'H');
  }
  return sendLogEvents([
    toClientEvent({
      predicate: predicate,
      object: object,
      prepositions,
    }),
  ]);
}

async function logUnauthenticatedEvent({ predicate, object, prepositions }) {
  debug('Unauthenticated event: %s %s', predicate, object);
  if (prepositions) {
    const localizedDate = new Date();
    prepositions.usersDay = format(localizedDate, 'i');
    prepositions.usersHour = format(localizedDate, 'H');
  }
  return fetch(`${window._cc.dream.url}/i/logEvent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ predicate, object, prepositions }),
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.status !== 204 ? response.json() : Promise.resolve();
    })
    .catch((e) => {
      console.error('Error processing logEvent in ClientEventLogger. Error: ', e);
    });
}

/**
 * A function for Amplitude only logging that does not touch any backend event handling.
 * Use cases include non-MAU events (page views, A/B testing, button clicks)
 * @param eventName the quick event name (predicate in the DB) you're adding on the frontend
 * @param properties whatever additional properties obj you're adding to usersDay and usersHour
 */
async function logFEOnlyEvent({ eventName, properties }) {
  if (isAmplitudeInitialized) {
    ampDebug('Imperative event: %s', eventName);
    return amplitude.getInstance().logEvent(eventName, {
      ...properties,
      usersDay: format(new Date(), 'i'),
      usersHour: format(new Date(), 'H'),
    });
  }
}

/**
 * A function for Amplitude only logging that does not touch any backend event handling.
 * Use cases include non-MAU events (page views, A/B testing, button clicks)
 * Good for wrapping functions that you don't want/need to insert a log into. (ex OnSubmit, Onclick, etc.)
 * @param eventName the quick event name (predicate in the DB) you're adding on the frontend
 * @param callback whatever function you've just wrapped (ex an onClick)
 * @param properties whatever additional properties obj you're adding to usersDay and usersHour
 */
const instrument = ({ eventName, callback, properties }) => {
  return (...params) => {
    const callbackReturnVal = callback ? callback(...params) : undefined;
    if (isAmplitudeInitialized) {
      ampDebug('Instrumented event: %s', eventName);
      amplitude.getInstance().logEvent(eventName, {
        ...properties,
        usersDay: format(new Date(), 'i'),
        usersHour: format(new Date(), 'H'),
      });
    }
    return callbackReturnVal;
  };
};

export default function ClientEventLoggerProvider({ children }) {
  const { externalId } = usePatientContext();

  // Strictly for debugging
  useEffect(() => {
    debug('ClientEventLoggerProvider mounted');
    return () => {
      // Should never happen
      debug('ClientEventLoggerProvider unmounted');
    };
  }, []);

  useEffect(() => {
    if (isAmplitudeInitialized) {
      ampDebug('Set user ID: %j', externalId);
      amplitude.getInstance().setUserId(externalId);
    }
  }, [externalId]);

  const logEvent = useCallback(
    async (event) => {
      if (externalId) {
        return logAuthenticatedEvent(event);
      } else {
        return logUnauthenticatedEvent(event);
      }
    },
    [externalId],
  );

  // Would normally use useLocation, but this lives above the router
  const logPageEvent = useCallback(
    async (predicate, otherPreps = {}) => {
      const event = {
        predicate,
        object: URIUtils.toMyCricketPageURI(window.location.pathname),
        prepositions: {
          page: URIUtils.toMyCricketPageURI(window.location.pathname),
          ...otherPreps,
        },
      };
      if (externalId) {
        return logAuthenticatedEvent(event);
      } else {
        return logUnauthenticatedEvent(event);
      }
    },
    [externalId],
  );

  const [contextValue, setContextValue] = useState({
    logEvent,
    logPageEvent,
    logFEOnlyEvent,
    instrument,
  });

  useEffect(() => {
    if (
      Object.is(contextValue.logEvent, logEvent) &&
      Object.is(contextValue.logPageEvent, logPageEvent)
    ) {
      debug('Context is equal');
      return;
    }

    debug('Setting new context');
    setContextValue({
      logEvent,
      logPageEvent,
      logFEOnlyEvent,
      instrument,
    });
  }, [logEvent, logPageEvent]);

  return (
    <ClientEventLoggerContext.Provider value={contextValue}>
      {children}
    </ClientEventLoggerContext.Provider>
  );
}

export const useClientEventLoggerContext = () => useContext(ClientEventLoggerContext);
