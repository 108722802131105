import { Box } from '@mui/material';
import URIUtils from '../../utils/URIUtils';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PatientEvent } from '../../data/Events';
import MyCricketColors from '../../data/MyCricketColors';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { paths } from '../../utils/routing/routes';
import { ClickableBox } from '../atoms/Buttons';
import { StyledCard } from '../atoms/StyledCard';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

const ActiveCourseBadge = () => {
  return (
    <Box
      bgcolor={MyCricketColors.lake}
      position={'absolute'}
      color={MyCricketColors.white}
      left={'-24px'}
      top={'-18px'}
      px={2}
      py={1}
      borderRadius={'0% 12px 12px 0%'}
    >
      <TranslatedTypography variant={'subtitle1'}>{'Your active course'}</TranslatedTypography>
    </Box>
  );
};

const CourseCardHeader = ({ isActive, imgUrl, courseName }) => {
  return (
    <>
      <Box pb={6} width={'100%'} display={'flex'} justifyContent={'center'} position={'relative'}>
        {isActive && <ActiveCourseBadge />}
        <img height="130px" width="130px" src={imgUrl} title="Course Icon" alt="Course Icon" />
      </Box>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} pb={1}>
        <TranslatedTypography variant={'subtitle1'}>{courseName}</TranslatedTypography>
      </Box>
    </>
  );
};

export const CourseCard = ({ courseName, courseId, imgUrl, courseDescription, isActive }) => {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const { logEvent } = useClientEventLoggerContext();

  const logEventAndRedirect = () => {
    logEvent({
      predicate: PatientEvent.CHANGED_COURSE_CONTENT,
      object: URIUtils.toMyCricketPageURI('CourseIndex'),
      prepositions: {
        courseName: courseName,
      },
    });
    history.push(paths.learn(courseId));
  };
  return (
    // Using 2 boxes here instead of one box with margin because it maeks the grid more predictable
    // This is because there is no boxSizing that accounts for margin
    <Box
      mr={{ xs: 0, sm: 0, md: 1 }}
      ml={{ xs: 0, sm: 0, md: 1 }}
      flex={1}
      minWidth={{ xs: '100%', sm: '100%', md: '313px' }}
      maxWidth={{ xs: '100%', sm: '100%', md: '313px' }}
      mb={2}
    >
      <StyledCard
        parentHover={hovered}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        borderRadius={'6px'}
      >
        <ClickableBox
          px={4}
          pt={6}
          pb={4}
          height={'100%'}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          justifyContent="space-between"
          alignItems={{ xs: 'center', sm: 'center', md: 'center' }}
          bgcolor={MyCricketColors.white}
          onClick={() => logEventAndRedirect()}
        >
          <Box>
            <CourseCardHeader isActive={isActive} imgUrl={imgUrl} courseName={courseName} />
            <TranslatedTypography variant={'body2'}>{courseDescription}</TranslatedTypography>
          </Box>

          <Box width={'100%'} alignSelf={'flex-end'}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              bgcolor={MyCricketColors.mint}
              color={MyCricketColors.white}
              borderRadius={'6px'}
              px={4}
              py={2}
            >
              <TranslatedTypography variant={'subtitle1'}>View</TranslatedTypography>
            </Box>
          </Box>
        </ClickableBox>
      </StyledCard>
    </Box>
  );
};

export default CourseCard;
