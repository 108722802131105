import React from 'react';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';

const ShowHideButton = ({ isShown, setShown }) => {
  const { t } = useTranslation();
  return (
    <button
      type={'button'}
      style={{
        flex: '0 1 0',
        border: 'none',
        borderRadius: '6px',
        backgroundColor: 'white',
        fontSize: '18px',
        color: MyCricketColors.tealGray,
        cursor: 'pointer',
      }}
      onClick={() => setShown(!isShown)}
    >
      {isShown ? t('HIDE') : t('SHOW')}
    </button>
  );
};

export default ShowHideButton;
