import { useState, useEffect } from 'react';
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
export const useWindowIsResizing = () => {
  const [sizeIteration, setSizeIteration] = useState(1);
  useEffect(() => {
    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);

      setSizeIteration(sizeIteration + 1);

      timeout = setTimeout(() => {
        setSizeIteration(1);
      }, 200);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return sizeIteration;
};
