export const restrictTabFocusToElementWithId = (elementId) => {
  const element = document.getElementById(elementId);
  const focusableElements = element.querySelectorAll(
    'button, input, textarea, select, a, [tabindex]:not([tabindex="-1"]',
  );
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  const handler = (event) => {
    const isTabKey = event.key === 'Tab';
    if (!isTabKey) {
      return;
    }
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement?.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement?.focus();
        event.preventDefault();
      }
    }
  };

  window.addEventListener('keydown', handler);

  // Shift the focus into the desired element, because the current
  // active element could be outside the desired element.
  firstFocusableElement?.focus();
  firstFocusableElement?.blur();
  return handler;
};
