import React from 'react';
import { Box, SvgIcon, useTheme } from '@mui/material';
import { TranslatedTypography } from './TranslatedTypography';
import { ReactComponent as DownArrowIcon } from '../icons/downArrow.svg';

const CareTeamNewMessagesLine = () => {
  const theme = useTheme();
  return (
    <Box px={4}>
      <Box display={'flex'} alignItems={'center'}>
        <Box flex={'0 0 auto'} color={theme.palette.lake}>
          <TranslatedTypography variant={'subtitle1'}>NEW MESSAGES</TranslatedTypography>
        </Box>
        <Box flex={'0 0 auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <SvgIcon component={DownArrowIcon} viewBox={'-6 -3 24 20'} />
        </Box>
        <Box flex={'1 0 auto'} position={'relative'} height={'12px'}>
          <Box
            position={'absolute'}
            bottom={0}
            bgcolor={theme.palette.lake}
            width={'100%'}
            height={'1px'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CareTeamNewMessagesLine;
