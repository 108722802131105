import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { patientPasswordRules } from '../../utils/signUpFormValidationFunctions';
import { ReactComponent as GrayCircle } from '../icons/GrayCircle.svg';
import { ReactComponent as GreenCheck } from '../icons/GreenCheck.svg';
import { ReactComponent as RedX } from '../icons/RedX.svg';

const PasswordRequirements = ({ password, hasError }) => {
  const { t } = useTranslation();
  return (
    <Box>
      {patientPasswordRules.map(({ displayValue, validator }) => {
        let icon = null;
        let textMargin = '16px';
        let iconMargin = '0';
        let testId = '';
        if (validator(password)) {
          icon = <GreenCheck />;
          testId = 'green-check';
        } else if (!validator(password) && !hasError) {
          icon = <GrayCircle />;
          textMargin = '20px';
          iconMargin = '6px';
          testId = 'gray-circle';
        } else if (!validator(password) && hasError) {
          icon = <RedX />;
          testId = 'red-x';
        }
        return (
          <Box key={displayValue} display={'flex'} mb={'5px'}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              ml={iconMargin}
              data-testid={testId}
            >
              {icon}
            </Box>
            <Box display={'flex'} alignItems={'center'} ml={textMargin}>
              <Typography variant={'body2'}>{t(displayValue)}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PasswordRequirements;
