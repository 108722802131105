import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { generateChatBubbleDisplayDate } from '../../utils/chatUtils';
import React from 'react';
import { classesPropType } from '../../utils/patientPropTypes';
import * as PropTypes from 'prop-types';

const styles = (theme) => ({
  careTeamMessageTimestampWrapper: {
    marginLeft: (props) => (props.isOtherPerson ? theme.spacing(4) : theme.spacing(9)),
    marginRight: (props) => (props.isOtherPerson ? theme.spacing(9) : theme.spacing(4)),
    overflow: 'auto',
  },
  careTeamMessageTimestamp: {
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.mediumGray,
    float: (props) => (props.isOtherPerson ? 'left' : 'right'),
  },
});

function CareTeamMessageTimestamp(props) {
  const { classes, timestampToDisplay } = props;
  return (
    <Box
      className={classes.careTeamMessageTimestampWrapper}
      data-test-type="careTeamMessageTimestampWrapper"
    >
      {
        <Typography
          className={classes.careTeamMessageTimestamp}
          data-test-type="careTeamMessageTimestamp"
          data-testid="careTeamMessageTimestamp"
        >
          {generateChatBubbleDisplayDate(timestampToDisplay)}
        </Typography>
      }
    </Box>
  );
}

CareTeamMessageTimestamp.propTypes = {
  classes: classesPropType.isRequired,
  timestampToDisplay: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(CareTeamMessageTimestamp);
