import React from 'react';
import { Box, Typography, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PasswordRequirements from '../atoms/PasswordRequirements';
import ShowHideButton from '../atoms/ShowHideButton';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const UpdatePasswordForm = ({
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  isCurrentPasswordShown,
  setCurrentPasswordShown,
  isNewPasswordShown,
  setNewPasswordShown,
  errorMessage,
  errorCode,
  clearError,
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <Box width={'100%'}>
      <Box mb={4} width={'100%'}>
        <Box mb={1}>
          <InputLabel htmlFor={'current-password'}>
            <Typography variant={'body1'}>{t('currentPassword', 'Current Password')}</Typography>
          </InputLabel>
        </Box>
        <TextField
          id="current-password"
          name={'current-password'}
          variant={'outlined'}
          type={isCurrentPasswordShown ? 'text' : 'password'}
          placeholder={t('currentPassword', 'Current Password')}
          autoComplete={'current-password'}
          style={{ width: !isDesktop ? '100%' : undefined }}
          InputProps={{
            style: { width: isDesktop ? '360px' : '100%', height: '50px' },
            endAdornment: (
              <ShowHideButton isShown={isCurrentPasswordShown} setShown={setCurrentPasswordShown} />
            ),
          }}
          error={errorCode === 'invalid-login'}
          value={currentPassword}
          onChange={(event) => {
            setCurrentPassword(event.target.value);
            clearError();
          }}
        />
      </Box>
      <Box mb={3} width={'100%'}>
        <Box mb={1}>
          <InputLabel htmlFor={'new-password'}>
            <Typography variant={'body1'}>{t('newPassword', 'New Password')}</Typography>
          </InputLabel>
        </Box>
        <TextField
          id="new-password"
          name={'new-password'}
          variant={'outlined'}
          type={isNewPasswordShown ? 'text' : 'password'}
          placeholder={t('newPassword', 'New Password')}
          autoComplete={'new-password'}
          style={{ width: !isDesktop ? '100%' : undefined }}
          InputProps={{
            style: { width: isDesktop ? '360px' : '100%', height: '50px' },
            endAdornment: (
              <ShowHideButton isShown={isNewPasswordShown} setShown={setNewPasswordShown} />
            ),
          }}
          error={errorCode === 'weak-password'}
          value={newPassword}
          onChange={(event) => {
            setNewPassword(event.target.value);
            clearError();
          }}
        />
      </Box>
      <Box>
        <Box mb={'5px'}>
          <Typography variant={'body1'}>{t('passwordRequirementsHeader')}</Typography>
        </Box>
        <PasswordRequirements password={newPassword} hasError={Boolean(errorCode)} />
      </Box>
      {errorMessage && (
        <Box mt={3} color={MyCricketColors.notificationRed}>
          <Typography variant={'body1'}>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpdatePasswordForm;
