import React, { useState, useCallback, useEffect } from 'react';
import { Box, FormControl, FormControlLabel, Collapse, Fade } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ClickableBox } from '../atoms/Buttons';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { BaseCheckbox } from '../atoms/Selects';
import { RotatingChevron } from '../atoms/Chevrons';
import AnimatedBubble from '../atoms/AnimatedBubble';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const PatientMenuCard = ({
  categoryName,
  choices,
  Icon,
  selectedChoices,
  setSelectedChoice,
  additionalNotes,
  setAdditionalNotes,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const isDesktop = useIsDesktop();
  const getBaseShadow = useCallback(() => {
    return isDesktop ? 2 : 0;
  }, [isDesktop]);
  const [hovered, setHovered] = useState(false);

  const [boxShadow, setBoxShadow] = useState(getBaseShadow());
  useEffect(() => {
    if (hovered && isDesktop) {
      setBoxShadow(4);
    } else {
      const shadow = getBaseShadow();
      setBoxShadow(shadow);
    }
  }, [hovered, isDesktop, getBaseShadow]);

  // The category is selected if we have at least one selected choice, or
  // non-whitespace text in the additional notes section.
  const isSelected = selectedChoices.size > 0 || (additionalNotes && /\S/.test(additionalNotes));
  return (
    <Box
      border={
        isOpen ? `1px solid ${MyCricketColors.mint}` : `1px solid ${MyCricketColors.lightGrayHaze}`
      }
      borderRadius={'24px'}
      boxShadow={boxShadow}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      role="region"
      aria-labelledby={`titleOf${categoryName}`}
    >
      <ClickableBox
        sx={{ backgroundColor: 'white', borderRadius: '24px', cursor: 'pointer' }}
        width={'100%'}
        onClick={() => setOpen(!isOpen)}
        aria-expanded={isOpen}
        id="ClickablePatientMenuCard"
      >
        <Box display={'flex'} p={3.5} width={'100%'}>
          <Box
            flex={'0 0 1'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mr={2.5}
          >
            <Icon opacity={isOpen || hovered ? 1 : 0.9} />
          </Box>
          <Box flex={'1 0 0'} display={'flex'} alignItems={'center'}>
            <TranslatedTypography
              variant={'subtitle1'}
              sx={{ fontSize: '20px' }}
              aria-level={3}
              id={`titleOf${categoryName}`}
            >
              {categoryName}
            </TranslatedTypography>
          </Box>
          <Box flex={'0 0 1'} position={'relative'} width={'40px'}>
            <Fade
              in={!isSelected}
              timeout={{ enter: 300, leave: 0 }}
              style={{ transitionDelay: 100 }}
            >
              <Box
                position={'absolute'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                fontSize={'40px'}
                height={'100%'}
                pl={0.5}
              >
                <RotatingChevron
                  aria-controls="ClickablePatientMenuCard"
                  open={isOpen}
                  openRotation={270}
                  closedRotation={180}
                  color={MyCricketColors.lightGraySmoke}
                />
              </Box>
            </Fade>
            <Box
              position={'absolute'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'100%'}
            >
              <AnimatedBubble show={isSelected} color={MyCricketColors.mint} size={'40px'}>
                <Check />
              </AnimatedBubble>
            </Box>
          </Box>
        </Box>
      </ClickableBox>
      <Collapse in={isOpen} timeout={300}>
        <Fade in={isOpen} timeout={500}>
          <Box px={6} pb={6}>
            <Box bgcolor={MyCricketColors.lightGrayFog} height={'2px'} mb={3} />
            <Box>
              <TranslatedTypography variant={'body1'} i18nKey={'patientMenuChoiceCheckboxPrompt'}>
                What specifically are you looking for?
              </TranslatedTypography>
            </Box>
            <Box mb={4}>
              <FormControl component="fieldset">
                {choices.map(({ id, text }, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      label={<TranslatedTypography variant={'body2'}>{text}</TranslatedTypography>}
                      control={
                        <BaseCheckbox
                          checked={selectedChoices.has(id)}
                          onChange={(event) => setSelectedChoice(id, event.target.checked)}
                        />
                      }
                    />
                  );
                })}
              </FormControl>
            </Box>
            <Box>
              <Box mb={1}>
                <TranslatedTypography variant={'body1'} i18nKey={'patientMenuChoiceTextPrompt'}>
                  Need something else? Let us know:
                </TranslatedTypography>
              </Box>
              <Box>
                <DecoratedChatInput
                  placeholder={t('addAComment', 'Add a comment')}
                  rows={3}
                  value={additionalNotes}
                  onChange={(event) => setAdditionalNotes(event.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
    </Box>
  );
};

export default PatientMenuCard;
