import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { Avatar } from '../atoms/Avatar';
import { RoleLabel } from '../atoms/RoleLabel';
import { useCareTeamContext } from '../pages/CareTeam';
import UnreadMessageAlert from '../icons/UnreadMessageAlert';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { PatientEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';

export const CareTeamSidebarCard = ({ group, index }) => {
  const { selectedGroupId, selectGroup, triggerChatToShow } = useCareTeamContext();
  const { userId, userRole, featureFlags = {} } = usePatientContext();
  const { groupIdsWithUnreads, getUserProfile } = useChirpContext();
  const { logEvent } = useClientEventLoggerContext();

  const { cricketGroupId } = group;
  const memberIdToDisplay = group.members.filter((member) => member !== userId)[0];
  const memberToDisplay = getUserProfile(memberIdToDisplay);
  const { avatarURI, nickName, roleName, firstName, lastInitial } = memberToDisplay;

  const [thisGroupHasUnreadMessages, setThisGroupHasUnreadMessages] = useState(false);

  useEffect(() => {
    setThisGroupHasUnreadMessages(groupIdsWithUnreads.indexOf(cricketGroupId) > -1);
  }, [groupIdsWithUnreads, cricketGroupId]);

  const handleSelectGroup = (event) => {
    event.preventDefault();
    triggerChatToShow();
    logEvent({
      predicate: PatientEvent.SELECT_GROUP,
      object: URIUtils.toGroupURI(group.id),
      prepositions: {
        page: URIUtils.toMyCricketPageURI(window.location.pathname),
      },
    });
    selectGroup(group);
  };
  const isDesktop = useIsDesktop();

  const determineBgColor = () => {
    return selectedGroupId === group.cricketGroupId && isDesktop
      ? MyCricketColors.paleBlue
      : MyCricketColors.white;
  };

  return (
    <Box
      role="gridcell"
      aria-label={`Select this card to chat with your ${roleName}, ${nickName}`}
      aria-selected={selectedGroupId === group.cricketGroupId}
      key={`careTeamSidebarGroupDiv${cricketGroupId}`}
      id={`careTeamSidebarGroup${cricketGroupId}`}
      data-testid={`careTeamSidebarBackgroundCard${cricketGroupId}`}
      data-test-id={`careTeamSidebarBackgroundCard${cricketGroupId}`}
      data-test-type="careTeamSidebarGroup"
      data-test-index={index}
      onClick={handleSelectGroup}
      display={'flex'}
      flexDirection={'row'}
      alignContent={'center'}
      style={{ cursor: 'pointer' }}
      mt={{ xs: 1, sm: 1, md: 0.5 }}
      width={'100%'}
      maxHeight={'100%'}
      bgcolor={determineBgColor()}
      borderRadius={'12px'}
      py={1.5}
      flexShrink={0}
    >
      <Box pl={1} position="relative">
        <Avatar source={avatarURI} alt={`Photo of ${roleName} ${nickName}`} />
        {/* This should maybe live as a decorator on the <Avatar> component */}
        {featureFlags['my-cricket-pto-ui'] && memberToDisplay.isOutOfOffice && (
          <Box position="absolute" bottom={0} right={0} height="24px">
            <img
              width={24}
              height={24}
              src="https://cdn.crickethealth.com/1/img/icons/palm-tree@2x.png"
              alt="palm tree - clinician is out of the office"
            />
            {/* This adds a border inset on top of the image. Ideally this
             * would be an outline on the parent box, but outlines apparently
             * don't work on <Box>s. */}
            <Box
              position="absolute"
              top={0}
              left={0}
              width="25px"
              height="25px"
              borderRadius="12.5px"
              border={1.5}
              borderColor={determineBgColor()}
            />
          </Box>
        )}
      </Box>
      <Box key={group.cricketGroupId} display={'flex'} flexDirection={'column'} pl={2}>
        <Typography variant={'body1'}>
          {/* This mentor logic here is just an extra precaution as patients shouldn't have other patients as chats */}
          {userRole === 'MENTOR' && !nickName ? `${firstName} ${lastInitial}.` : nickName}
        </Typography>
        {roleName && <RoleLabel role={roleName} />}
      </Box>
      {thisGroupHasUnreadMessages && (
        <Box
          key={`careTeamSidebarNotification${cricketGroupId}`}
          id={`careTeamSidebarUnreadMessagesDot${cricketGroupId}`}
          data-testid={`careTeamSidebarUnreadMessagesDot${cricketGroupId}`}
          data-test-type={'careTeamSidebarUnreadMessagesDot'}
          display={'flex'}
          marginLeft={'auto'}
          marginRight={'34px'}
          alignSelf={'center'}
        >
          <UnreadMessageAlert />
        </Box>
      )}
    </Box>
  );
};

export default CareTeamSidebarCard;
