import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { myCricketFetch, myCricketAuthedFetch } from '../fetchingUtils';
import { logout } from '../LoginHelpers';
import { logClientError } from '../errorLogging';
import { activeSurveyIds } from '../../data/SurveyData';
import CookieManager from '../CookieHelper';
import rumUtils from '../rumUtils';
import _ from 'lodash';
import Debug from 'debug';
import { supportedLanguageMaps } from '../../data/SupportedLanguages';
import { useTranslation } from 'react-i18next';
import { isPatientRole } from '../../data/Roles';
const debug = Debug('MyCricket:patientcontext');

const initialUserState = {
  userName: '',
  userRole: '',
  userId: null,
  externalId: null,
  emailAddresses: {},
  phoneNumbers: {},
};

// This is awkward, but it allows us to initialize idTokenHasBeenSet to what localStorage is at time
//  of reducer-creation, and not time of module-parsing (which happens before localStorage is set)
const initSystemState = (loading = true) => ({
  // All the initial idToken logic executes before the React application is mounted
  idTokenHasBeenSet: !!localStorage.getItem('idToken'),
  featureFlags: undefined,
  //the first TOS, PP, npp, and icts call relates to checking if there is something new to show users
  //these will stay null if there's nothing to re-accept
  tosVersion: null,
  ppVersion: null,
  nppVersion: null,
  ictsVersion: null,
  missingAgreementIds: [],
  menuCompletionStatus: null,
  surveyCompletionStatus: null,
  pcLoading: loading,
  coachMarkHistory: null,
  magicLinkLoginError: null,
  recommendedModuleIds: [],
  siteLanguage: null, //default to browser or english
  shouldShowSchedulingReminder: null,
  isEmailRecentlyVerified: false,
});

const userInfoReducer = (state, action) => {
  const updatedData = action.payload;
  switch (action.type) {
    case 'update': {
      const newState = { ...state, ...updatedData };
      if (updatedData.name) {
        newState.userName = updatedData.name;
      }
      if (updatedData.role) {
        newState.userRole = updatedData.role;
      }
      if (updatedData.id) {
        newState.userId = updatedData.id;
      }
      return newState;
    }
    case 'clear':
      return initialUserState;
    default:
      throw new Error('Invalid action type for UserInfoReducer');
  }
};

const systemInfoReducer = (state, action) => {
  const updatedData = action.payload;
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...updatedData,
      };
    case 'clear':
      return initSystemState(false);
    default:
      throw new Error('Invalid action type for SystemInfoReducer');
  }
};

function clearLocalStoredInfo() {
  for (const surveyId of activeSurveyIds) {
    localStorage.removeItem(`survey-${surveyId}`);
  }
}

export const usePatientContextProviderState = () => {
  const [userInfo, dispatch] = useReducer(userInfoReducer, initialUserState);
  // Lazy init is required in order to get idToken from localStorage at reducer init time
  const [sysInfo, sysDispatch] = useReducer(systemInfoReducer, undefined, initSystemState);

  const updateUserInfo = (info) => dispatch({ type: 'update', payload: info });
  const updateSystemInfo = (info) => {
    sysDispatch({ type: 'update', payload: info });
  };

  const clearLocalUserInfo = useCallback(() => {
    debug('Starting PatientContextProvider.clearLocalUserInfo()');
    clearLocalStoredInfo();
    logout();
    // Some parts of the app rely on userInfo and some rely on systemInfo,
    // so lets just clear both to be safe
    dispatch({ type: 'clear' });
    sysDispatch({ type: 'clear' });
  }, []);

  // Don't return directly because otherwise everything is inferred to be any
  return {
    clearLocalUserInfo,
    updateUserInfo,
    updateSystemInfo,
    userInfo,
    ...sysInfo,
  };
};

// After a decent amount of investigation this was the closest I could get without TypeScript to having a fully defined context.
// In the real app we never render a context consumer w/o a provider and in tests we use the defaultTestContext providers (which use this same hook)
// The end result is this allows you to hover the useChirpContext hook and see the values returned by this state which will eventually be passed to the provider
// This results in having some rough assurances as to what values are present in the context. However, it should be treated as a tip and not gospel.
// One further caveat, to make the useDefaultState hook more useful in tests we include all the associated set state actions, these are not guaranteed to be present in the actual context as of now.
export const PatientContext = React.createContext(usePatientContextProviderState);
PatientContext.displayName = 'PatientContext';

export const PatientContextProvider = (props) => {
  const { children } = props;
  const {
    idTokenHasBeenSet,
    clearLocalUserInfo,
    userInfo,
    updateUserInfo,
    updateSystemInfo,
    featureFlags,
    tosVersion,
    ppVersion,
    nppVersion,
    ictsVersion,
    missingAgreementIds,
    surveyCompletionStatus,
    menuCompletionStatus,
    pcLoading,
    coachMarkHistory,
    magicLinkLoginError,
    recommendedModuleIds,
    siteLanguage,
    shouldShowSchedulingReminder,
    isEmailRecentlyVerified,
  } = usePatientContextProviderState();
  const { i18n } = useTranslation();

  const fetchPhoneNumbers = async () => {
    try {
      const phoneNumbers = await myCricketAuthedFetch('/i/account/patient-phone-defaults', {
        method: 'GET',
      });
      if (phoneNumbers && phoneNumbers.primary) {
        updateUserInfo({ phoneNumbers });
      } else {
        // then there's no phone data. We should have a number for every patient,
        // but there are patients we don't have a number for yet.
        // set up an empty string, so we know we fetched but have nothing.
        updateUserInfo({ phoneNumbers: { primary: '' } });
      }
    } catch {
      // then there's no phone data. We should have a number for every patient,
      // but there are patients we don't have a number for yet.
      // set up an empty string, so we know we fetched but have nothing.
      updateUserInfo({ phoneNumbers: { primary: '' } });
    }
  };
  const updatePhoneNumbers = async ({ phoneNumberToCall, phoneNumberToText, doNotText }) => {
    await myCricketAuthedFetch('/i/account/patient-phone-defaults', {
      method: 'POST',
      body: JSON.stringify({
        phoneNumberToCall,
        phoneNumberToText,
        doNotText,
      }),
    });
    await fetchPhoneNumbers();
  };
  const getChirpUserInfo = async () => {
    const res = await myCricketAuthedFetch(`/i/chirp/users/me`, {
      method: 'GET',
      retry: true,
    });
    return res[0];
  };

  const fetchUserInfo = async () => {
    const userInfo = await getChirpUserInfo();
    if (userInfo) {
      debug('PatientContextProvider.fetchUserInfo(): Fetched userInfo');
      updateUserInfo(userInfo);
      rumUtils.setUser(userInfo.externalId, userInfo.role);
    }
    return userInfo;
  };

  const fetchEmailAddresses = async () => {
    try {
      const res = await myCricketAuthedFetch('/patient/me/email', { method: 'GET' });
      if (res) {
        updateUserInfo({
          emailAddresses: {
            active: res.activeEmailAddress,
            pending: res.pendingEmailAddress,
          },
        });
      }
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch email addresses' });
    }
  };

  const updateEmailAddress = async (emailAddress) => {
    await myCricketAuthedFetch('/patient/me/email', {
      method: 'POST',
      body: JSON.stringify({ emailAddress }),
    });
    await fetchEmailAddresses();
  };

  const cancelPendingEmailAddress = async () => {
    await myCricketAuthedFetch('/patient/me/email/verification/cancel', {
      method: 'POST',
    });
    await fetchEmailAddresses();
  };

  const verifyEmailAddress = async (idToken, password) => {
    if (!password) {
      // If called without a password, attempt to use the current idToken for auth.
      await myCricketAuthedFetch('/patient/me/email/verification/auth-verify', {
        method: 'POST',
        body: JSON.stringify({ idToken }),
      });
      updateSystemInfo({ isEmailRecentlyVerified: true });
      await fetchEmailAddresses();
    } else {
      // Otherwise call the un-authed version.
      const res = await myCricketFetch('/patient/me/email/verification/verify', {
        method: 'POST',
        body: JSON.stringify({ idToken, password }),
      });
      if (res.idToken) {
        clearLocalUserInfo();
        localStorage.setItem('idToken', res.idToken);
        updateSystemInfo({
          idTokenHasBeenSet: true,
          pcLoading: true,
          isEmailRecentlyVerified: true,
        });
        await fetchEmailAddresses();
      } else {
        throw new Error('No idToken found');
      }
    }
  };

  const fetchLegalAgreements = async () => {
    const { valid, latestTos, latestPp, latestNpp, latestIcts } = await myCricketAuthedFetch(
      '/i/user/agreements/check',
      {
        method: 'GET',
        retry: true,
      },
    );

    debug('PatientContextProvider.fetchLegalAgreements(): set.*Version()');
    updateSystemInfo({
      tosVersion: latestTos,
      ppVersion: latestPp,
      nppVersion: latestNpp,
      ictsVersion: latestIcts,
    });
    if (!valid) {
      const agreementsRes = await myCricketAuthedFetch(`/i/user/agreements`, {
        method: 'GET',
        retry: true,
      });
      const missingAgreementVersionIds = [
        [latestTos, 'tos'],
        [latestPp, 'pp'],
        [latestNpp, 'npp'],
        [latestIcts, 'icts'],
      ]
        .filter(
          ([latestVersionId, agreementKey]) =>
            latestVersionId >
            (agreementsRes.find((el) => el.type === agreementKey)?.agreementVersionId || -1),
        )
        .map(([, agreementKey]) => agreementKey);
      debug(
        'PatientContextProvider.fetchLegalAgreements(): system dispatch update missing agreement version ids',
      );
      updateSystemInfo({ missingAgreementIds: missingAgreementVersionIds });
    }
  };

  const fetchOnboardingProgress = async () => {
    try {
      const surveyIds = activeSurveyIds.join(',');
      const res = await myCricketAuthedFetch(`/i/patient/me/onboarding?surveyIds=${surveyIds}`, {
        method: 'GET',
      });
      if (res) {
        debug(
          'PatientContextProvider.fetchOnboardingProgress(): setSurveyCompletionStatus() and setMenuCompletionStatus()',
        );
        updateSystemInfo({
          surveyCompletionStatus: res.surveyCompletionStatus,
          menuCompletionStatus: res.menuCompletionStatus,
        });
      }
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch onboarding progress' });
    }
  };

  const fetchFeatureFlags = async () => {
    const defaultFlags = {
      'my-cricket-pto-ui': false,
      'my-cricket-menu': false, //this controls the menu and the appearance of the care team matching screen
    };
    try {
      const res = await myCricketAuthedFetch(`/i/patient/me/feature-flags`);
      debug('PatientContextProvider.fetchFeatureFlags(): setFeatureFlags()');
      updateSystemInfo({
        featureFlags: res ? { ...defaultFlags, ...res } : defaultFlags,
      });
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch feature flags' });
      updateSystemInfo({
        featureFlags: defaultFlags,
      });
    }
  };

  const fetchRecommendedModuleIds = async () => {
    try {
      const recommendationsResp = await myCricketAuthedFetch('/learn/recommendations');
      debug('PatientContextProvider.fetchRecommendedModuleIds(): setRecommendedModuleIds()');
      updateSystemInfo({
        recommendedModuleIds:
          recommendationsResp && recommendationsResp.data
            ? recommendationsResp.data.recommendedModuleIds
            : [],
      });
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch recommended module IDs' });
    }
  };

  const fetchAndSetCoachMarkHistory = async () => {
    try {
      const res = await myCricketAuthedFetch(`/i/coachMarkHistory/`, {
        method: 'GET',
      });
      debug('PatientContextProvider.fetchAndSetCoachMarkHistory(): setCoachMarkHistory()');
      updateSystemInfo({
        coachMarkHistory: res.reduce((acc, coachMark) => {
          acc[coachMark.coachMarkId] = true;
          return acc;
        }, {}),
      });
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch coach mark history' });
    }
  };
  // Need to capture the hash BEFORE the router kicks in
  // Another way this can be fixed is if we added a loading state to the app
  const hash = window.location.hash;

  //fetches but also resolves site language.
  //If no language is found in the DB we look for it in an i118n cookie and then a URL param
  const fetchSiteLanguage = async () => {
    try {
      const res = await myCricketAuthedFetch(`/i/patient/me/site-language`, {
        method: 'GET',
      });
      const langCode = res.data.siteLanguage;
      const cookieLanguage = CookieManager.getRawCookie('my_cricket_i18next');

      if (langCode) {
        updateSystemInfo({ siteLanguage: langCode });
      } else if (cookieLanguage) {
        //fall back to the cookie that might exist from previous MyCricket use
        updateSystemInfo({ siteLanguage: cookieLanguage });
        //clean up this cookie
        CookieManager.deleteCookie('my_cricket_i18next', {
          path: '/',
          domain: __ENV__.cookieDomain.shared,
        });
      } else {
        // set language based on url param, only post enrollment
        const hashParamLang = new URLSearchParams(hash?.slice(1)).get('lng');
        if (hashParamLang) {
          updateSystemInfo({ siteLanguage: hashParamLang });
        } else {
          //if all else fails set to a supported browser language or english
          updateSystemInfo({
            siteLanguage:
              supportedLanguageMaps.find(({ code }) => code === i18n.language)?.code || 'en',
          });
        }
      }
    } catch (err) {
      await logClientError(err, { consolePrefix: 'Failed to fetch site language' });
    }
  };

  const fetchPatientReminderInfo = async () => {
    try {
      const res = await myCricketAuthedFetch('/patient-reminders');
      if (res) {
        updateSystemInfo({ shouldShowSchedulingReminder: res.shouldScheduleAppointment });
      }
    } catch (error) {
      await logClientError(error, { consolePrefix: 'Failed to fetch patient reminders' });
    }
  };

  const fetchAllInfo = async () => {
    debug('Starting PatientContextProvider.fetchAllInfo()');
    let localUserInfo;
    try {
      localUserInfo = await fetchUserInfo();
    } catch (error) {
      debug('Error in PatientContextProvider.fetchAllInfo()');
      await logClientError(error, { consolePrefix: 'Error fetching user info:' });
      clearLocalUserInfo();
      return;
    }
    const fetchers = [];
    fetchers.push(fetchRecommendedModuleIds());
    if (!featureFlags) {
      fetchers.push(fetchFeatureFlags());
    }
    if (!ppVersion || !tosVersion || !nppVersion || !ictsVersion) {
      fetchers.push(fetchLegalAgreements());
    }
    if (!surveyCompletionStatus || !menuCompletionStatus) {
      fetchers.push(fetchOnboardingProgress());
    }
    if (_.isEmpty(coachMarkHistory)) {
      fetchers.push(fetchAndSetCoachMarkHistory());
    }
    if (!siteLanguage) {
      fetchers.push(fetchSiteLanguage());
    }
    if (shouldShowSchedulingReminder == null) {
      fetchers.push(fetchPatientReminderInfo());
    }
    if (isPatientRole(localUserInfo?.role) && userInfo.emailAddresses.active == null) {
      fetchers.push(fetchEmailAddresses());
    }
    if (isPatientRole(localUserInfo?.role) && userInfo.phoneNumbers.primary == null) {
      //we need AT LEAST a primary number to start
      fetchers.push(fetchPhoneNumbers());
    }

    await Promise.all(fetchers);
    debug('Completed PatientContextProvider.fetchAllInfo(): setPcLoading(false)');
    updateSystemInfo({
      pcLoading: false,
    });
  };
  // This should run every time an idToken is set. Decides what information
  // needs to be fetched and fetches it, setting pcLoading to false when finished.
  useEffect(() => {
    debug(
      'PatientContextProvider::useEffect[idTokenHasBeenSet]: idTokenHasBeenSet: %s',
      idTokenHasBeenSet,
    );
    if (idTokenHasBeenSet) {
      debug(
        'PatientContextProvider::useEffect[idTokenHasBeenSet]: calling fetchAllInfo(); pcLoading was %s',
        pcLoading,
      );
      fetchAllInfo();
    } else {
      debug(
        'PatientContextProvider::useEffect[idTokenHasBeenSet]: setPcLoading(false); was %s',
        pcLoading,
      );
      updateSystemInfo({
        pcLoading: false,
      });
    }
  }, [idTokenHasBeenSet]);

  useEffect(() => {
    //we can always update the browser language
    if (window.i18next) {
      window.i18next.changeLanguage(siteLanguage);
    }
    //only put in the db if it's not null and we are logged in
    if (siteLanguage && idTokenHasBeenSet) {
      const setDBLanguage = async () => {
        try {
          await myCricketAuthedFetch('/i/patient/me/site-language', {
            method: 'POST',
            body: JSON.stringify({ languageCode: siteLanguage }),
          });
        } catch (err) {
          await logClientError(err, { consolePrefix: 'Error while updating site language: ' });
        }
      };
      setDBLanguage();
    }
  }, [siteLanguage, idTokenHasBeenSet]);

  //gives coachmarks an innate default to mark that coachmark as read and check if other coachmarks should be shown
  const manageCoachmarkHistory = useCallback(async (coachMarkId) => {
    debug('Starting PatientContextProvider.manageCoachmarkHistory()');
    await myCricketAuthedFetch(`/i/coachMarkHistory/`, {
      method: 'POST',
      body: JSON.stringify({ coachMarkId }),
    });
    await fetchAndSetCoachMarkHistory();
  }, []);

  // The router relies on the PatientContext to determine whether the
  // PatientContext is still loading. If so it will display the loading
  // page for us. Also, the ClientEventLogger requires a stable externalId
  // and the ChirpContext expects idTokenHasBeenSet
  if (pcLoading) {
    return (
      <PatientContext.Provider
        value={{ updateSystemInfo, fetchUserInfo, idTokenHasBeenSet, pcLoading, ...userInfo }}
      >
        {children}
      </PatientContext.Provider>
    );
  }

  return (
    // Make sure to add any new bits of the context to value below
    // We currently leave the setState actions out in the real app.
    <PatientContext.Provider
      value={{
        ...userInfo,
        fetchUserInfo,
        updateSystemInfo,
        idTokenHasBeenSet,
        clearLocalUserInfo,
        featureFlags,
        missingAgreementIds,
        pcLoading,
        ppVersion,
        tosVersion,
        nppVersion,
        ictsVersion,
        surveyCompletionStatus,
        menuCompletionStatus,
        refreshOnboardingProgress: fetchOnboardingProgress,
        coachMarkHistory,
        manageCoachmarkHistory,
        magicLinkLoginError,
        recommendedModuleIds,
        fetchRecommendedModuleIds,
        siteLanguage,
        shouldShowSchedulingReminder,
        clearSchedulingReminder: () => updateSystemInfo({ shouldShowSchedulingReminder: false }),
        updatePhoneNumbers,
        updateEmailAddress,
        verifyEmailAddress,
        cancelPendingEmailAddress,
        isEmailRecentlyVerified,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export function usePatientContext() {
  return useContext(PatientContext);
}

export default PatientContextProvider;
