import React, { useState, useEffect } from 'react';
import { Box, Typography, Collapse, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MyCricketColors from '../../data/MyCricketColors';
import { BaseButton } from '../atoms/Buttons';
import { paths } from '../../utils/routing/routes';
import { useHistory } from 'react-router';
import { BackNav } from '../atoms/BackNav';
import Toast from '../atoms/Toast';
import UpdateUserNameContainer from '../organisms/UpdateUserNameContainer';
import { validateNickName } from '../../utils/signUpFormValidationFunctions';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';

const UpdateUserNamePage = () => {
  const { userId, userName, fetchUserInfo } = usePatientContext();
  const { changeUsername } = useChirpContext();
  const [newUserName, setNewUserName] = useState(userName);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEditingUserName, setEditingUserName] = useState(false);

  const clearError = () => {
    setErrorMessage(null);
  };

  useEffect(() => {
    setErrorMessage(null);
  }, [isEditingUserName]);

  const { t } = useTranslation();
  const history = useHistory();

  const updateUserName = async () => {
    if (isLoading) {
      return;
    }

    if (userName === newUserName) {
      setErrorMessage(t('displayNameIsSame'));
      return;
    }

    const userNameErrorCode = validateNickName(newUserName, true);
    if (userNameErrorCode) {
      setErrorMessage(t(userNameErrorCode));
      return;
    }
    setLoading(true);
    clearError();
    try {
      await changeUsername(userId, newUserName);
      await fetchUserInfo();
      setEditingUserName(false);
      toast.success(<Toast icon={'success'} message={t('displayNameUpdateSuccess')} />);
    } catch (error) {
      console.error(error);
      if (error?.includes('display_name_already_exists')) {
        setErrorMessage(t('display_name_already_exists'));
      } else if (error?.includes('display_name_too_long')) {
        setErrorMessage(t('display_name_too_long'));
      } else {
        setErrorMessage(error || 'unknown');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box component={'main'} mt={3} px={2} width={'640px'}>
        <BackNav history={history} link={paths.me()} data-test-id="backNav" />
        <Box
          p={6}
          bgcolor={MyCricketColors.white}
          borderRadius={'6px'}
          maxWidth={'640px'}
          style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 62, 81, 0.1))' }}
        >
          <Box
            position={'relative'}
            height={isEditingUserName ? '30px' : '90px'}
            style={{ transitionDuration: '0.3s' }}
          >
            <Box position={'absolute'}>
              <Fade in={!isEditingUserName} appear={false}>
                <Box>
                  <Typography variant="h2" style={{ marginBottom: 8 }}>
                    {t('displayName', 'Display Name')}
                  </Typography>
                  <Typography variant="body2">
                    {t(
                      'updateDisplayNameDesc',
                      'Your display name is the name other community members will see when you post in groups',
                    )}
                  </Typography>
                </Box>
              </Fade>
            </Box>
            <Box position={'absolute'}>
              <Fade in={isEditingUserName} appear={false}>
                <Typography variant="h2">
                  {t('updateDisplayNameTitle', 'Update Your Display Name')}
                </Typography>
              </Fade>
            </Box>
          </Box>
          <Box mt={5}>
            <UpdateUserNameContainer
              newUserName={newUserName}
              setNewUserName={setNewUserName}
              isEditing={isEditingUserName}
              setEditing={setEditingUserName}
              errorMessage={errorMessage}
              clearError={clearError}
            />
          </Box>
          <Collapse in={isEditingUserName}>
            <Fade in={isEditingUserName}>
              <Box mt={4}>
                <hr
                  width="100%"
                  height="1px"
                  color={MyCricketColors.lightGrayFog}
                  style={{ margin: 0 }}
                />
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  width={'100%'}
                  justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
                  mt={4}
                >
                  <Box mr={3}>
                    <BaseButton
                      variant="outlined"
                      style={{ width: '105px', height: '50px' }}
                      disabled={isLoading}
                      onClick={() => setEditingUserName(false)}
                    >
                      Cancel
                    </BaseButton>
                  </Box>
                  <Box>
                    <BaseButton
                      type="submit"
                      style={{ width: '105px', height: '50px' }}
                      disabled={isLoading}
                      onClick={updateUserName}
                    >
                      Save
                    </BaseButton>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateUserNamePage;
