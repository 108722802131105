import React from 'react';

const LeftArrowIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z"
        fill={props.color}
      />
    </svg>
  );
};

export default LeftArrowIcon;
