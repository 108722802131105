import React from 'react';

const ReplayButton = ({ color, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136"
    height="136"
    viewBox="0 0 136 136"
    {...restProps}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="68" cy="68" r="68" fill={color || '#7A4FE5'} />
      <path
        transform="translate(36 36)"
        fill="#FFF"
        fillRule="nonzero"
        d="M32 0c17.6 0 32 14.4 32 32S49.6 64 32 64c-8.8 0-16.96-3.44-22.72-9.28l5.76-5.76A23.99 23.99 0 0 0 32.08 56c13.28 0 24-10.72 24-24s-10.72-24-24-24c-6.64 0-12.4 2.88-16.72 7.28L24.08 24h-24V0L9.6 9.52C15.36 3.76 23.28 0 32.08 0H32z"
      />
    </g>
  </svg>
);

export default ReplayButton;
