import React from 'react';
import clsx from 'clsx';
import { ReactComponent as OnlineChat } from '../icons/OnlineChat.svg';
import { BaseButton } from '../atoms/Buttons';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

const useStyles = makeStyles((theme) => ({
  button: {
    height: '50px',
  },
  backButton: {
    'color': 'black',
    'borderColor': MyCricketColors.lightGraySmoke,
    '&:hover': {
      'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
      'background': MyCricketColors.white,
    },
  },
}));

function SurveyComplete({ onSubmit, onPrevClick, shouldDirectToDiscuss, ...props }) {
  const classes = useStyles();

  async function handleSubmit(event) {
    event.preventDefault();
    await onSubmit();
  }

  return (
    <Box pt={8} px={{ xs: 2, sm: 2, md: '20%', lg: '25%', xl: '30%' }}>
      <Box p={6} borderRadius={'6px'} bgcolor={MyCricketColors.white}>
        <form onSubmit={handleSubmit} {...props}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={5}>
            <OnlineChat />
          </Box>
          <Box mb={4}>
            <TranslatedTypography
              i18nKey={`completeSurveyTitle`}
              variant="h2"
              align="center"
              gutterBottom
            >
              {`What's next`}
            </TranslatedTypography>
          </Box>
          <Box mb={4}>
            <TranslatedTypography i18nKey={`completeSurveyParagraph1`} variant="body2" paragraph>
              {`Great work finishing your survey. Your responses will be shared with your nurse, so they can start creating a personalized care plan that best fits your lifestyle.`}
            </TranslatedTypography>
          </Box>
          <Box mb={5}>
            <TranslatedTypography
              i18nKey={
                shouldDirectToDiscuss
                  ? 'completeSurveyParagraphDiscuss2'
                  : 'completeSurveyParagraphLearn2'
              }
              variant="body2"
              paragraph
            />
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row" mt={3}>
            <Box
              flex={'1 0 0'}
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <BaseButton
                className={clsx(classes.button, classes.backButton)}
                variant="outlined"
                onClick={onPrevClick}
                startIcon={<ArrowBack />}
              >
                Back
              </BaseButton>
            </Box>
            <Box flex={'1 0 0'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
              <BaseButton
                className={classes.button}
                type="submit"
                data-test-id="survey-complete-button"
                endIcon={<ArrowForward />}
              >
                Next
              </BaseButton>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default SurveyComplete;
