module.exports = {
  INTERNAL_ERROR: 'internal_error',
  INVALID_LOGIN: 'invalid_login',
  INVALID_PASSWORD: 'invalid_password',
  INVALID_PATIENT_STATE_TRANSITION: 'invalid_patient_state_transition',
  INVALID_SIGNUP_CODE: 'invalid_signup_code',
  INVALID_TICKET: 'invalid_ticket',
  PASSWORD_STRENGTH_ERROR: 'password_strength_error',
  UNSUPPORTED_APPLICATION: 'unsupported_application',
  USER_IS_CLOSED: 'user_is_closed',
  USER_IS_UNENROLLED: 'user_is_unenrolled',
  USERNAME_ALREADY_EXISTS: 'username_already_exists',
  USERNAME_TOO_LONG: 'username_too_long',
  ERROR_ACCEPTING_AGREEMENTS: 'error_accepting_agreements',
  SIGN_UP_MAX_ATTEMPTS_EXCEEDED: 'sign_up_max_attempts_exceeded',
  EMAIL_ADDRESS_IN_USE: 'email_address_in_use',
  USER_ALREADY_SIGNED_UP: 'user_already_signed_up',
  CANNOT_PATCH_NON_ACTIVE_TASK: 'Cannot patch non-active task',
  TOO_MANY_ATTEMPTS: 'too_many_attempts',
};
