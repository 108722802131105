import { Box } from '@mui/material';
import React from 'react';
import { VerticalChevron } from './Chevrons';
import { TranslatedTypography } from './TranslatedTypography';

export const FaqTitle = ({ title, open, ariaHeaderLevel }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <TranslatedTypography variant="subtitle1" aria-level={ariaHeaderLevel}>
        {title}
      </TranslatedTypography>
      <TranslatedTypography component={'span'} variant="h1">
        <VerticalChevron open={open} />
      </TranslatedTypography>
    </Box>
  );
};
