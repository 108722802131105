import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import PatientMenu from '../organisms/PatientMenu';
import { menuCategories } from '../../data/PatientMenuData';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { logClientError } from '../../utils/errorLogging';
import useQuery from '../../utils/hooks/useQueryHook';
import { paths } from '../../utils/routing/routes';

const createPatientMenuPayload = (
  categories,
  selectedChoices,
  categoryAdditionalNotes,
  generalAdditionalNotes,
) => {
  const categoriesList = [];
  for (const category of categories) {
    const categoryChoices = selectedChoices[category.id];
    const additionalNotes = categoryAdditionalNotes[category.id]?.trim() || undefined;
    // No choices and no text, so we can skip this category.
    if ((!categoryChoices || categoryChoices.size === 0) && !additionalNotes) {
      continue;
    }
    // Otherwise, build an entry for the current category.
    const categoryData = {
      categoryId: category.id,
      categoryName: category.name,
      choices: categoryChoices
        ? category.choices
            .filter((choice) => categoryChoices.has(choice.id))
            .map((choice) => ({ choiceId: choice.id, choiceText: choice.text }))
        : undefined,
      additionalNotes,
    };
    categoriesList.push(categoryData);
  }
  return {
    data: {
      categories: categoriesList,
      additionalNotes: generalAdditionalNotes ? generalAdditionalNotes.trim() : undefined,
    },
  };
};

const completePatientMenu = async (payload) => {
  await myCricketAuthedFetch('/i/patient/me/menu', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

const PatientMenuPage = () => {
  const {
    pcLoading,
    featureFlags,
    menuCompletionStatus,
    refreshOnboardingProgress,
    fetchRecommendedModuleIds,
  } = usePatientContext();
  const query = useQuery();
  const { t } = useTranslation();
  const [selectedChoices, setSelectedChoices] = useState({});
  const [categoryAdditionalNotes, setCategoryAdditionalNotes] = useState({});
  const [generalAdditionalNotes, setGeneralAdditionalNotes] = useState('');
  const [error, setError] = useState(null);

  const selectChoiceInCategory = (categoryId, choiceId, isSelected) => {
    const currentSet = selectedChoices[categoryId] || new Set();
    const nextSet = isSelected
      ? new Set([...currentSet, choiceId])
      : new Set([...currentSet].filter((existing) => existing !== choiceId));
    setSelectedChoices((prev) => ({
      ...prev,
      [categoryId]: nextSet,
    }));
  };

  const updateAdditionalNotesInCategory = (categoryId, text) =>
    setCategoryAdditionalNotes((prev) => ({ ...prev, [categoryId]: text }));

  const needsMenu =
    featureFlags?.['my-cricket-menu'] &&
    menuCompletionStatus &&
    menuCompletionStatus.isNeeded &&
    !menuCompletionStatus.isComplete;

  if (!pcLoading && !needsMenu) {
    return <Redirect to={{ pathname: paths.careTeam(), search: query.toString() }} />;
  }

  return (
    <MyCricketLoadingContainer loading={pcLoading}>
      <Box
        display={'flex'}
        justifyContent={'center'}
        my={{ xs: 0, md: 3 }}
        width={'100%'}
        data-test-id={'patient-menu'}
        role="main"
      >
        <Box
          width={{ xs: '100%', md: '660px' }}
          p={6}
          bgcolor={MyCricketColors.white}
          borderRadius={{ xs: 0, md: '6px' }}
        >
          <PatientMenu
            categories={menuCategories}
            selectedChoices={selectedChoices}
            categoryAdditionalNotes={categoryAdditionalNotes}
            generalAdditionalNotes={generalAdditionalNotes}
            selectChoiceInCategory={selectChoiceInCategory}
            updateAdditionalNotesInCategory={updateAdditionalNotesInCategory}
            setGeneralAdditionalNotes={setGeneralAdditionalNotes}
            completePatientMenu={async () => {
              try {
                const payload = createPatientMenuPayload(
                  menuCategories,
                  selectedChoices,
                  categoryAdditionalNotes,
                  generalAdditionalNotes,
                );
                await completePatientMenu(payload);
                setError(null);
                await refreshOnboardingProgress();
                await fetchRecommendedModuleIds();
              } catch (error) {
                if (error.status >= 400 && error.status < 500) {
                  // These are 4XX errors from the backend.
                  setError(
                    t(
                      'patientMenuInputErrorMessage',
                      'There is a problem submitting your response.',
                    ),
                  );
                } else {
                  // These are unexpected 5XX or frontend errors.
                  logClientError(error);
                  setError(
                    t(
                      'patientMenuUnknownErrorMessage',
                      "An error has occurred. We've already been notified of the issue, but if it continues please contact support.",
                    ),
                  );
                }
              }
            }}
            error={error}
          />
        </Box>
      </Box>
    </MyCricketLoadingContainer>
  );
};

export default PatientMenuPage;
