import { Box, Divider, LinearProgress, Typography, styled } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { BaseButton, CancelButton } from '../atoms/Buttons';
import SurveyQuestion from '../molecules/SurveyQuestion';

const QuestionLinearProgress = styled(LinearProgress)({
  'height': 6,
  'zIndex': 0,
  'borderRadius': '9999px',
  'backgroundColor': MyCricketColors.lightGrayFog,
  '.MuiLinearProgress-bar': {
    height: 6,
    borderRadius: '9999px',
    backgroundColor: MyCricketColors.lake,
  },
});

export const SurveyQuestionsContainer = ({
  surveyState,
  answersGroupedByQuestionId,
  getQuestionDependenciesMet,
  questions,
  goToQuestion,
  answerState,
  questionAnswerDependencies,
  savedSurveyAnswerIds,
  setAnswerState,
  setError,
  goToStart,
  error,
  submitSurveyAnswer,
  setSavedSurveyAnswerIds,
  goToComplete,
}) => {
  const questionId = surveyState.questionId;
  const [questionText, setQuestiontext] = useState('');
  const myQuestionAnswers = answersGroupedByQuestionId[questionId] || [];

  const currentIndex = questions.map((q) => parseInt(q.questionId)).indexOf(parseInt(questionId));
  const nextQuestionId =
    currentIndex === questions.length - 1 ? null : questions[currentIndex + 1]?.questionId;

  useEffect(() => {
    const questionDependenciesMet = getQuestionDependenciesMet(questionId);
    if (!questionDependenciesMet) {
      goToQuestion(nextQuestionId);
    } else {
      const question = questions.find((q) => parseInt(q.questionId) === parseInt(questionId));
      if (question) {
        setQuestiontext(question.questiontext);
      } else {
        goToQuestion(nextQuestionId);
      }
    }
  }, [getQuestionDependenciesMet, questions, goToQuestion, nextQuestionId, questionId]);

  const hasAnswer = answerState[questionId]?.some((a) => a.answerValue) || false;

  const prevQuestionId =
    currentIndex < 1
      ? null
      : questions
          .slice(0, currentIndex)
          .reverse()
          .find((q) => getQuestionDependenciesMet(q.questionId))?.questionId;

  const onNextClick = async () => {
    if (!hasAnswer) {
      setError('Please select an answer to continue');
      return;
    }
    let nextAnswerState = answerState;
    answerState[questionId].forEach(({ answerId }) => {
      const getQuestionsThatDependOnAnswer = (aId) =>
        questionAnswerDependencies
          .filter((qad) => qad.answerId === aId)
          .map((qad) => qad.questionId);

      const questionIdsThatDependOnMe = getQuestionsThatDependOnAnswer(answerId);

      const clearQuestionState = (qId) => {
        const answers = answerState[qId];
        answers.forEach(({ answerId }) => {
          const questionIdDependencies = getQuestionsThatDependOnAnswer(answerId);
          questionIdDependencies.forEach((qIdDep) => {
            clearQuestionState(qIdDep);
          });
        });
        nextAnswerState = { ...nextAnswerState, [qId]: [] };
      };
      questionIdsThatDependOnMe.forEach((questionId) => clearQuestionState(questionId));
    });

    try {
      const answersForQuestion = answerState[questionId];
      const answersWithSavedId = answersForQuestion.map((answer) => ({
        ...answer,
        surveyAnswerId: savedSurveyAnswerIds[answer.answerId],
      }));
      const { surveyAnswerIds } = await submitSurveyAnswer(answersWithSavedId);
      setSavedSurveyAnswerIds({
        ...savedSurveyAnswerIds,
        ...surveyAnswerIds.reduce(
          (acc, curr) => ({ ...acc, [curr.answerId]: curr.surveyAnswerId }),
          {},
        ),
      });
      // Ensure we retrieve the survey answerId so we call update
      // when traversing the survey back and fourth
      setAnswerState({
        ...nextAnswerState,
        [questionId]: nextAnswerState[questionId].map((data) => ({
          ...data,
        })),
      });
      nextQuestionId ? goToQuestion(nextQuestionId) : goToComplete();
    } catch (error) {
      setError('There was an issue with submitting your answer, please try again.');
    }
  };

  const onPrevClick = () => (prevQuestionId ? goToQuestion(prevQuestionId) : goToStart());

  const onSkipClick = async (skipQuestionAnswerId) => {
    const answerPayload = myQuestionAnswers.map(({ answerId, answerType }) => ({
      answerId,
      answerType,
      answerValue: parseInt(skipQuestionAnswerId) === parseInt(answerId) ? true : false,
      surveyAnswerId: savedSurveyAnswerIds[answerId],
    }));

    setError(null);
    setAnswerState({ ...answerState, [questionId]: answerPayload });
    try {
      const { surveyAnswerIds } = await submitSurveyAnswer(answerPayload);
      setSavedSurveyAnswerIds({
        ...savedSurveyAnswerIds,
        ...surveyAnswerIds.reduce(
          (acc, curr) => ({ ...acc, [curr.answerId]: curr.surveyAnswerId }),
          {},
        ),
      });
      setAnswerState({ ...answerState, [questionId]: answerPayload });
      nextQuestionId ? goToQuestion(nextQuestionId) : goToComplete();
    } catch (error) {
      setError('There was an issue with submitting your answer, please try again.');
    }
  };

  const onMergeAnswerState = (answers) => {
    setError(null);
    // Answers get updated by answer groups, so make sure updates are additive
    let myNextAnswerState;
    if (answerState[questionId]) {
      myNextAnswerState = [
        ...answerState[questionId].filter(
          (a) => !answers.map((ans) => ans.answerId).includes(a.answerId),
        ),
        ...answers,
      ];
    } else {
      myNextAnswerState = { ...answers };
    }

    // If there are any non-skip answers that are true, reset skip answers to
    // false (this occurs when backtracking and updating answers)
    const hasNonSkipAnswer = myNextAnswerState.some(
      ({ answerType, answerValue }) => answerValue == true && answerType !== 'skip',
    );
    if (hasNonSkipAnswer) {
      myNextAnswerState.forEach(({ answerType }, index) => {
        if (answerType === 'skip') {
          myNextAnswerState[index] = {
            ...myNextAnswerState[index],
            answerValue: false,
          };
        }
      });
    }

    setAnswerState({ ...answerState, [questionId]: myNextAnswerState });
  };

  const disableNextButtonClick =
    !hasAnswer ||
    answerState[questionId].some(
      ({ answerType, answerValue }) => answerType === 'skip' && answerValue === true,
    );

  const questionsRemaining = questions.length - currentIndex;

  return (
    <Box display={'flex'} justifyContent={'center'} pt={8} pb={3} px={2}>
      <Box
        width={{ xs: '100%', md: '660px' }}
        p={6}
        borderRadius={'6px'}
        bgcolor={MyCricketColors.white}
      >
        <Box>
          <Typography color="textPrimary" variant="h3">
            <Trans
              i18nKey={
                questionsRemaining === 1
                  ? 'questionCountRemaining_one'
                  : 'questionCountRemaining_other'
              }
              defaults="{{count}} Questions Remaining"
              values={{ count: questionsRemaining }}
            />
          </Typography>
          <Box my={1}>
            <QuestionLinearProgress
              variant="determinate"
              value={Math.max(1, (currentIndex / questions.length) * 100)}
              aria-label={`${Math.max(1, questions.length - currentIndex)} questions remaining`}
            />
          </Box>
        </Box>
        <SurveyQuestion
          key={questionId}
          dataTestId={`question-${questionId}`}
          questionText={questionText}
          questionAnswers={myQuestionAnswers}
          onSkipClick={onSkipClick}
          answerState={answerState[questionId]}
          onMergeAnswerState={onMergeAnswerState}
          error={error}
        />
        <Divider />
        <Box display="flex" flexDirection="row" pt={'18px'}>
          <Box flex={'1 0 0'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <CancelButton
              variant="outlined"
              sx={{ height: '50px' }}
              onClick={onPrevClick}
              startIcon={<ArrowBack />}
            >
              Back
            </CancelButton>
          </Box>
          <Box flex={'1 0 0'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <BaseButton
              sx={{ height: '50px' }}
              disabled={disableNextButtonClick}
              data-has-answer={hasAnswer}
              onClick={disableNextButtonClick ? () => {} : onNextClick}
              endIcon={<ArrowForward />}
            >
              Next
            </BaseButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyQuestionsContainer;
