import { Box, Typography } from '@mui/material';
import React from 'react';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const CareTeamBioCredentials = ({ credentials }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" width={'100%'} pt={2}>
      <TranslatedTypography variant="subtitle1" aria-level="2">
        Credentials
      </TranslatedTypography>
      <ul width={'100%'}>
        {credentials.map((cred, idx) => (
          <li key={'credential' + idx}>
            <Typography variant="body2">{cred}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default CareTeamBioCredentials;
