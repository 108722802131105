import React from 'react';
import * as Sentry from '@sentry/react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import ScrollToTop from '../../components/atoms/ScrollToTop';
import { paths, Routes } from './routes';
import { usePatientContext } from '../contexts/PatientContextProvider';
import { SentryPrivateRoute, PrivateRoute } from './PrivateRoute';
import RedirectWithQuery from './RedirectWithQuery';
import { AutoLogoutComponent } from '../../components/molecules/AutoLogout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../toastStyles.css';

const SentryRoute = Sentry.withSentryRouting(Route);

export const RouteList = () => {
  const { isDevRuntimeEnvironment } = __ENV__;
  return [
    <SentryPrivateRoute
      key={paths.homepage()}
      exact
      path={paths.homepage()}
      component={Routes.HomePageRoute}
    />,
    <SentryRoute exact key={paths.login()} path={paths.login()} component={Routes.LoginRoute} />,
    <SentryRoute
      exact
      key={paths.magicLink()}
      path={paths.magicLink()}
      component={Routes.MagicLinkRedirectRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.paused()}
      path={paths.paused()}
      component={Routes.PausedRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.logout()}
      path={paths.logout()}
      component={Routes.LogoutRoute}
    />,
    isDevRuntimeEnvironment && (
      <PrivateRoute
        exact
        key={paths.inventory()}
        path={paths.inventory()}
        component={Routes.InventoryRoute}
      />
    ),
    isDevRuntimeEnvironment && (
      <Route
        exact
        key={paths.environment()}
        path={paths.environment()}
        component={Routes.EnvironmentRoute}
      />
    ),
    <SentryPrivateRoute
      exact
      key={paths.courses()}
      path={paths.courses()}
      component={() => <RedirectWithQuery path={paths.learn('courses')} />}
    />,
    <SentryPrivateRoute
      exact
      key={paths.recipes()}
      path={paths.recipes()}
      component={({ match: { params } }) => (
        <RedirectWithQuery path={paths.learn('recipes', params?.faqId)} />
      )}
    />,
    <SentryPrivateRoute
      exact
      key={paths.articles()}
      path={paths.articles()}
      component={({ match: { params } }) => (
        <RedirectWithQuery path={paths.learn('articles', params?.faqId)} />
      )}
    />,
    isDevRuntimeEnvironment && (
      <PrivateRoute
        exact
        key={paths.atomic()}
        path={paths.atomic()}
        component={Routes.AtomicRoute}
      />
    ),
    <SentryPrivateRoute
      exact
      key={paths.matching()}
      path={paths.matching()}
      component={Routes.ProviderMatchingRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.careTeam()}
      path={paths.careTeam()}
      component={Routes.CareTeamRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.careTeamWithParam()}
      path={paths.careTeamWithParam()}
      component={Routes.CareTeamRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.module()}
      path={paths.module()}
      component={Routes.LearnModuleDetailRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.learn()}
      path={paths.learn()}
      component={Routes.LearnRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.learn(':category?')}
      path={paths.learn(':category?')}
      component={Routes.LearnRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.learn(':category?', ':selectedItemId?')}
      path={paths.learn(':category?', ':selectedItemId?')}
      component={Routes.LearnRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.emailPreferences()}
      path={paths.emailPreferences()}
      component={Routes.EmailPreferencesRoute}
    />,
    <SentryPrivateRoute exact key={paths.me()} path={paths.me()} component={Routes.MeRoute} />,
    <SentryPrivateRoute
      exact
      key={paths.myNephrologistUpdate()}
      path={paths.myNephrologistUpdate()}
      component={Routes.MyNephrologistUpdate}
    />,
    <SentryPrivateRoute
      exact
      key={paths.myPcpUpdate()}
      path={paths.myPcpUpdate()}
      component={Routes.MyPCPUpdate}
    />,
    <SentryPrivateRoute
      exact
      key={paths.myLabOrder()}
      path={paths.myLabOrder()}
      component={Routes.MyLabOrder}
    />,
    <SentryPrivateRoute
      exact
      key={paths.groupsList()}
      path={paths.groupsList()}
      component={Routes.GroupsListRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.group()}
      path={paths.group()}
      component={Routes.GroupRoute}
    />,
    <SentryPrivateRoute
      exact
      key={paths.groupMessage()}
      path={paths.groupMessage()}
      component={Routes.GroupRoute}
    />,
    <SentryPrivateRoute
      key={paths.languagePreferences()}
      path={paths.languagePreferences()}
      component={Routes.LanguagePreferencesRoute}
    />,
    <SentryPrivateRoute
      key={paths.loginInformation()}
      path={paths.loginInformation()}
      component={Routes.LoginInformationRoute}
    />,
    <SentryPrivateRoute
      key={paths.phoneContactInfo()}
      path={paths.phoneContactInfo()}
      component={Routes.PhoneContactInfoRoute}
    />,
    <SentryPrivateRoute
      key={paths.userName()}
      path={paths.userName()}
      component={Routes.UpdateUserNameRoute}
    />,
    <SentryPrivateRoute
      key={paths.enrollmentSurvey()}
      path={paths.enrollmentSurvey()}
      component={Routes.EnrollmentSurveyRoute}
    />,
    <SentryPrivateRoute
      key={paths.patientMenu()}
      path={paths.patientMenu()}
      component={Routes.PatientMenuRoute}
    />,
    <SentryRoute
      key={paths.passwordReset()}
      path={paths.passwordReset()}
      component={Routes.PasswordResetRoute}
    />,
    <Route
      key={paths.emailVerify()}
      path={paths.emailVerify()}
      component={Routes.EmailVerificationRoute}
    />,
    <SentryRoute
      key={paths.emailPreferences_old()}
      path={paths.emailPreferences_old()}
      component={() => <RedirectWithQuery path={paths.emailPreferences()} />}
    />,
    <SentryRoute
      key={paths.languagePreferences_old()}
      path={paths.languagePreferences_old()}
      component={() => <RedirectWithQuery path={paths.languagePreferences()} />}
    />,
    <SentryRoute
      key={paths.loginInformation_old()}
      path={paths.loginInformation_old()}
      component={() => <RedirectWithQuery path={paths.loginInformation()} />}
    />,
    <SentryRoute
      key={paths.userName_old()}
      path={paths.userName_old()}
      component={() => <RedirectWithQuery path={paths.userName()} />}
    />,
    /* Serve the 404 if we dont have an exact match */
    <SentryRoute key="404" component={Routes.FourOhFourRoute} />,
  ].filter(Boolean);
};

export function MyCricketRouter(props) {
  const { userId, idTokenHasBeenSet } = usePatientContext();
  return (
    <Router history={props.history}>
      <ScrollToTop />
      {idTokenHasBeenSet && userId ? <AutoLogoutComponent {...props} /> : null}
      <Switch>{RouteList(userId)}</Switch>
      <ToastContainer
        className={'cricket-toast-short'}
        position={'bottom-left'}
        theme={'colored'}
        closeButton={false}
        hideProgressBar={true}
        draggable={false}
        icon={false}
      />
    </Router>
  );
}

export const InvisibleLinkWrapper = ({ to, children, ...rest }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }} {...rest}>
      {children}
    </Link>
  );
};

export default MyCricketRouter;
