import { Box } from '@mui/material';
import { findIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { LearnModuleNavigator } from '../molecules/LearnModuleNavigator';
import { ModuleDetailContext } from '../pages/ModuleDetail';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

export const FaqsHeader = () => {
  const { selectedModuleId, allModules } = useContext(ModuleDetailContext);
  const [nextModuleId, setNextModuleId] = useState(null);
  const [currentModuleName, setCurrentModuleName] = useState('');
  const [nextModuleName, setNextModuleName] = useState('');

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (allModules.length && selectedModuleId) {
      const _selectedIndex = findIndex(allModules, (module) => module.id === selectedModuleId);
      const _nextModule =
        _selectedIndex + 1 >= allModules.length ? allModules[0] : allModules[_selectedIndex + 1];
      setNextModuleId(_nextModule.id);
      setNextModuleName(_nextModule.title);
      setCurrentModuleName(allModules[_selectedIndex].title);
    }
  }, [allModules, selectedModuleId]);

  const showNextButton = nextModuleId !== selectedModuleId;

  return (
    <Box
      width="100vw"
      display="flex"
      justifyContent={'space-between'}
      minHeight="68px"
      alignItems="center"
      bgcolor={MyCricketColors.white}
      p={(1.5, 2, 0, 2)}
      boxShadow={2}
      position="sticky"
      alignSelf="flex-start"
      top={0}
      zIndex={99}
    >
      <Box flex={'0 0 1'}>
        <LearnModuleNavigator isBackToCourses />
      </Box>
      <Box flex={'1 0 0'}>
        <TranslatedTypography
          pl={2}
          variant={isDesktop ? 'h2' : 'subtitle2'}
          textAlign={'center'}
          aria-level={'1'}
        >
          {currentModuleName}
        </TranslatedTypography>
      </Box>
      {showNextButton && isDesktop ? (
        <Box flex={'0 0 1'}>
          <LearnModuleNavigator moduleName={nextModuleName} moduleId={nextModuleId} />
        </Box>
      ) : (
        <Box flex={'0 0 1'} width={'30vw'} />
      )}
    </Box>
  );
};
