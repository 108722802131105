import React from 'react';
import { createBrowserHistory } from 'history';
import './fonts/Avenir.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ClientEventLoggerProvider from './utils/contexts/ClientEventLoggerContext';
import { PatientContextProvider } from './utils/contexts/PatientContextProvider';
import theme from './utils/theme';
import MyCricketRouter from './utils/routing/router';
import ChirpContextProvider from './utils/contexts/ChirpContextProviderWithFetching';
import rumUtils from './utils/rumUtils';

const history = createBrowserHistory();
rumUtils.setUp(history);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PatientContextProvider>
        <ClientEventLoggerProvider>
          <ChirpContextProvider>
            <MyCricketRouter history={history} />
          </ChirpContextProvider>
        </ClientEventLoggerProvider>
      </PatientContextProvider>
    </ThemeProvider>
  );
};
export default App;
