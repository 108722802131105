import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { FaqItem } from '../molecules/FaqItem';
import { ModuleDetailContext } from '../pages/ModuleDetail';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const FaqsContainer = ({ isGenericContent = false }) => {
  const { moduleFaqs, selectedFaqId } = useContext(ModuleDetailContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginBottom={5}
      marginTop={4}
      alignItems="center"
      width={'100%'}
      justifyContent="space-between"
    >
      {!isGenericContent && (
        <Box
          display="flex"
          alignContent="flex-start"
          width="100%"
          mb={1}
          p={{ xs: 2, s: 2, md: 0 }}
        >
          <TranslatedTypography variant="h2" i18nKey="frequently_asked_questions">
            Frequently Asked Questions
          </TranslatedTypography>
        </Box>
      )}
      {moduleFaqs.map((faq) => (
        <FaqItem
          isGenericContent={isGenericContent}
          key={faq.id}
          faq={faq}
          shouldScrollIntoView={faq.id === selectedFaqId}
        />
      ))}
    </Box>
  );
};
