import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Login from '../organisms/Login';
import { login } from '../../utils/LoginHelpers';
import { LoginEvent } from '../../data/Events';
import URIUtils from '../../utils/URIUtils';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { paths } from '../../utils/routing/routes';
import useQuery from '../../utils/hooks/useQueryHook';
import { myCricketFetch } from '../../utils/fetchingUtils';
import { logClientError } from '../../utils/errorLogging';

const MyCricketLoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { userId, updateSystemInfo, magicLinkLoginError } = usePatientContext();
  const { logEvent } = useClientEventLoggerContext();
  const directToResetPassword = query.get('directToResetPassword');
  const [featureFlags, setFeatureFlags] = useState({});
  const [announcementBanner, setAnnouncementBanner] = useState('');
  const [magicLinkErrorCode, setMagicLinkErrorCode] = useState(null);
  const referrer = location.state?.referrer;

  useEffect(() => {
    if (magicLinkLoginError) {
      updateSystemInfo({
        magicLinkLoginError: null,
      });
      setMagicLinkErrorCode(magicLinkLoginError);
    }
  }, [magicLinkLoginError, updateSystemInfo]);

  // If user is logged in, they should be sent back to where they came from
  useEffect(() => {
    if (userId) {
      //once we're logged in take us to a homepage
      history.push({
        pathname: referrer || paths.homepage(),
        search: query.toString(),
      });
    }
  }, [history, referrer, query, userId]);

  useEffect(() => {
    let mounted = true;
    if (!userId) {
      const controller = new AbortController();
      const signal = controller.signal;
      const fetchLoginBanner = async () => {
        try {
          const data = await myCricketFetch('/i/user/patient-login-banner', { signal });
          if (mounted && data.announcementBanner) {
            setAnnouncementBanner(data.announcementBanner);
          }
        } catch (error) {
          if (error && error.name !== 'AbortError') {
            logClientError(error);
          }
        }
      };

      const fetchFeatureFlags = async () => {
        const defaultFlags = {};
        try {
          const data = await myCricketFetch('/i/patient/unknown/feature-flags', { signal });
          if (mounted) {
            setFeatureFlags(data ? { ...defaultFlags, ...data } : defaultFlags);
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            logClientError(error);
          }
          if (mounted) {
            console.warn('Failed to retrieve feature flags, setting default flags');
            setFeatureFlags(defaultFlags);
          }
        }
      };

      Promise.all([fetchLoginBanner(), fetchFeatureFlags()]);

      return () => {
        controller.abort();
        mounted = false;
      };
    }
  }, [userId]);

  const loginToPatientApp = async function (emailAddress, password) {
    const loginResult = await login(emailAddress, password);
    updateSystemInfo({
      pcLoading: true,
      idTokenHasBeenSet: Boolean(loginResult?.data?.id_token),
    });
  };

  return (
    <Login
      login={loginToPatientApp}
      directToResetPassword={directToResetPassword}
      featureFlags={featureFlags}
      isUsingMagicLinkV2={false}
      announcementBanner={announcementBanner}
      magicLinkErrorCode={magicLinkErrorCode}
      onAttempted={() => {
        logEvent({
          predicate: LoginEvent.LOGIN_ATTEMPTED,
          object: URIUtils.toMyCricketPageURI('login'),
          prepositions: {
            page: URIUtils.toMyCricketPageURI('login'),
          },
        });
      }}
      onError={() => {
        logEvent({
          predicate: LoginEvent.LOGIN_FAILED,
          object: URIUtils.toMyCricketPageURI('login'),
          prepositions: {
            page: URIUtils.toMyCricketPageURI('login'),
          },
        });
      }}
      onLoadResetPasswordForm={() => {
        logEvent({
          predicate: LoginEvent.LOAD_RESET_PASSWORD_REQUEST_FORM,
          object: URIUtils.toMyCricketPageURI('login'),
          prepositions: {
            page: URIUtils.toMyCricketPageURI('login'),
          },
        });
      }}
    />
  );
};

export default MyCricketLoginPage;
