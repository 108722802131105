import React from 'react';
import { Redirect } from 'react-router-dom';
import { paths } from '../../utils/routing/routes';
import useQuery from '../../utils/hooks/useQueryHook';

const Home = () => {
  const query = useQuery();
  return (
    <Redirect
      data-test-id="redirectToCareTeam"
      to={{ pathname: paths.careTeam(), search: query.toString() }}
    />
  );
};

export default Home;
