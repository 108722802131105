import React, { useState, useEffect } from 'react';
import { Box, Typography, Collapse, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BaseButtonNoTranslation as BaseButton } from '../atoms/Buttons';
import UpdatePasswordForm from '../molecules/UpdatePasswordForm';
import Toast from '../atoms/Toast';
import MyCricketColors from '../../data/MyCricketColors';
import { myCricketAuthedFetch } from '../../utils/fetchingUtils';
import { patientPasswordRules } from '../../utils/signUpFormValidationFunctions';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { logClientError } from '../../utils/errorLogging';

const updatePatientPassword = async (currentPassword, newPassword) => {
  await myCricketAuthedFetch('/patient/me/password', {
    method: 'POST',
    body: JSON.stringify({ currentPassword, newPassword }),
  });
};

const UpdatePasswordContainer = ({ isEditing, setEditing }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isCurrentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [isNewPasswordShown, setNewPasswordShown] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const clearError = () => {
    setErrorMessage(null);
    setErrorCode(null);
  };

  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isEditing) {
      setCurrentPassword('');
      setNewPassword('');
      setCurrentPasswordShown(false);
      setNewPasswordShown(false);
      setErrorMessage(null);
      setErrorCode(null);
    }
  }, [isEditing]);

  const updatePassword = async () => {
    if (isLoading) {
      return;
    }
    const isPasswordWeak = patientPasswordRules.some(({ validator }) => !validator(newPassword));
    if (isPasswordWeak) {
      setErrorCode('weak-password');
      return;
    }
    setLoading(true);
    clearError();
    try {
      await updatePatientPassword(currentPassword, newPassword);
      setCurrentPassword('');
      setNewPassword('');
      setEditing(false);
      toast.success(<Toast icon={'success'} message={t('passwordUpdateSuccess')} />);
    } catch (error) {
      await logClientError(error);
      if (error.responseBody?.errorCode === 'invalid-login') {
        setErrorMessage(t('passwordErrorIncorrect'));
      } else if (error.responseBody?.errorCode === 'used-previously') {
        setErrorMessage(t('passwordErrorUsedPreviously'));
      } else {
        setErrorMessage(t('passwordErrorUnknown'));
      }
      setErrorCode(error.responseBody?.errorCode || 'unknown');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Collapse in={!isEditing} appear={false}>
        <Fade in={!isEditing} appear={false}>
          <Box>
            <Box display={isDesktop ? 'flex' : 'block'} alignItems={'center'} width={'100%'}>
              <Box flex={'1 0 0'} mr={isDesktop ? 3 : 0} mb={isDesktop ? 0 : 3}>
                <Box mb={1.5}>
                  <Typography variant={'body1'}>{t('Password')}</Typography>
                </Box>
                <Box>
                  <Typography variant={'body2'}>•••••••••••</Typography>
                </Box>
              </Box>
              <Box
                flex={'0 0 1'}
                display={isDesktop ? 'flex' : 'block'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <BaseButton
                  variant={'outlined'}
                  style={{
                    'height': '50px',
                    'color': MyCricketColors.black,
                    'borderColor': MyCricketColors.lightGraySmoke,
                    'lineHeight': 1,
                    '&:hover': {
                      'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'background': MyCricketColors.white,
                    },
                  }}
                  onClick={() => setEditing(true)}
                >
                  {t('changePassword', 'Change Password')}
                </BaseButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
      <Collapse in={isEditing} appear={false}>
        <Fade in={isEditing} appear={false}>
          <Box
            component={'form'}
            onSubmit={(event) => {
              event.preventDefault();
              updatePassword();
            }}
          >
            <UpdatePasswordForm
              currentPassword={currentPassword}
              setCurrentPassword={setCurrentPassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              isCurrentPasswordShown={isCurrentPasswordShown}
              setCurrentPasswordShown={setCurrentPasswordShown}
              isNewPasswordShown={isNewPasswordShown}
              setNewPasswordShown={setNewPasswordShown}
              errorMessage={errorMessage}
              errorCode={errorCode}
              clearError={clearError}
            />
            <Box mt={4}>
              <hr
                width="100%"
                height="1px"
                color={MyCricketColors.lightGrayFog}
                style={{ margin: 0 }}
              />
              <Box
                display={'flex'}
                flexDirection={'row'}
                width={'100%'}
                justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
                mt={4}
              >
                <Box mr={3}>
                  <BaseButton
                    type="button"
                    variant="outlined"
                    style={{ width: '105px', height: '50px' }}
                    disabled={isLoading}
                    onClick={() => setEditing(false)}
                  >
                    {t('Cancel')}
                  </BaseButton>
                </Box>
                <Box>
                  <BaseButton
                    type="submit"
                    style={{ width: '105px', height: '50px' }}
                    disabled={isLoading || !currentPassword || !newPassword}
                  >
                    {t('Save')}
                  </BaseButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
    </Box>
  );
};

export default UpdatePasswordContainer;
