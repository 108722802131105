import React, { useEffect, useState } from 'react';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { careTeamMessagePropType, classesPropType } from '../../utils/patientPropTypes';
import { Box, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TranslatedTypography } from './TranslatedTypography';
import { ClickableBox } from './Buttons';
import AnimatedHeart from './AnimatedHeart';

const styles = (theme) => ({
  likeWrapper: {
    cursor: 'pointer',
    background: 'none',
  },
  likeText: {
    marginLeft: '4px',
  },
});

function GroupLikePostButton(props) {
  const { message, classes } = props;
  const { likeMessage, unlikeMessage } = useChirpContext();
  const { likedByUser, numberOfLikes } = message;
  const [isLiking, setIsLiking] = useState(false);

  useEffect(() => {
    if (likedByUser) {
      const timeout = setTimeout(() => {
        setIsLiking(false);
      }, 1200);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [likedByUser]);

  const handleClick = () => {
    if (likedByUser) {
      unlikeMessage(message);
    } else {
      setIsLiking(true);
      likeMessage(message);
    }
  };

  const determineNumberOfLikesDisplay = (numberOfLikes) => {
    if (!numberOfLikes) {
      return (
        <Box display={'flex'} data-test-id={`likeText`}>
          <TranslatedTypography variant="body1" className={classes.likeText}>
            {'Like'}
          </TranslatedTypography>
        </Box>
      );
    } else {
      if (numberOfLikes === 1) {
        return (
          <Box display={'flex'} data-test-id={`likeText`}>
            <Typography variant="body1">{'1'}</Typography>
            <TranslatedTypography variant="body1" className={classes.likeText}>
              {'Like'}
            </TranslatedTypography>
          </Box>
        );
      } else {
        return (
          <Box display={'flex'} data-test-id={`likeText`}>
            <Typography variant="body1">{numberOfLikes}</Typography>
            <TranslatedTypography variant="body1" className={classes.likeText}>
              {'Likes'}
            </TranslatedTypography>
          </Box>
        );
      }
    }
  };

  return (
    <ClickableBox
      display="flex"
      onClick={handleClick}
      marginTop={1}
      pr={1}
      className={classes.likeWrapper}
      data-test-id={`likeWrapper`}
    >
      <AnimatedHeart liked={likedByUser} isLiking={isLiking} />
      <Box display={'flex'} alignSelf={'center'}>
        {determineNumberOfLikesDisplay(numberOfLikes)}
      </Box>
    </ClickableBox>
  );
}

GroupLikePostButton.propTypes = {
  message: careTeamMessagePropType.isRequired,
  classes: classesPropType.isRequired,
};

export default withStyles(styles, { withTheme: true })(GroupLikePostButton);
