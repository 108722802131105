import { Box, Typography, Zoom } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowRight } from '@react-icons/all-files/hi/HiArrowRight';
import { useHistory } from 'react-router-dom';
import { paths } from '../../utils/routing/routes';
import { CareTeamBioAvatar } from './Avatar';
import { BaseButton } from './Buttons';
import { StyledCard } from './StyledCard';
import _ from 'lodash';
import { ReactComponent as CongratulationsIcon } from '../icons/CelebratoryCongratulations.svg';

/**
 * This pattern avoids passing dynamic keys directly to the `t` function
 * to enable future static analysis of i18n keys.
 *
 * Note that when context is passed, it is also used for nested keys.
 */
const Introduction = ({ role }) => {
  const { t } = useTranslation('match', { useSuspense: false });

  switch (_.toLower(role)) {
    case 'dietitian':
      return t('introduction_dietitian');
    case 'nurse':
      return t('introduction_nurse');
    case 'pharmacist':
      return t('introduction_pharmacist');
    case 'pharmacy_navigator':
      return t('introduction_pharmacy_navigator');
    case 'social_worker':
      return t('introduction_social_worker');
    default:
      return t('introduction');
  }
};

const Description = ({ role, name }) => {
  const { t } = useTranslation('match', { useSuspense: false });

  switch (_.toLower(role)) {
    case 'dietitian':
      return t('description_dietitian', {
        name,
      });
    case 'nurse':
      return t('description_nurse', {
        name,
      });
    case 'pharmacist':
      return t('description_pharmacist', {
        name,
      });
    case 'pharmacy_navigator':
      return t('description_pharmacy_navigator', {
        name,
      });
    case 'social_worker':
      return t('description_social_worker', {
        name,
      });
    default:
      return t('description', {
        name,
      });
  }
};

export const ProviderMatchingBioCard = ({
  careTeamMemberAvatarUri,
  careTeamMemberName,
  careTeamMemberRole,
  shouldUseSimplifiedCard,
}) => {
  const history = useHistory();
  const { t } = useTranslation('match', { useSuspense: false });

  const simplifiedCard = () => (
    <Box flex={1} width={{ xs: '100%', sm: '100%', md: '660px' }} mb={2}>
      <Zoom in>
        <Box>
          <StyledCard height={'fit-content'} nonInteractive={true}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              p={6}
              textAlign={'center'}
            >
              <Box mb={2.5}>
                <CongratulationsIcon />
              </Box>
              <Box mb={2.5}>
                <Typography variant="h2" aria-level={1}>
                  {t('thanks')}
                </Typography>
              </Box>
              <Box mb={6}>
                <Typography>{t('simpleDescription')}</Typography>
              </Box>
              <BaseButton onClick={() => history.push(paths.careTeam())}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  {t('button.letsGo')}
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} ml={2}>
                    <HiArrowRight />
                  </Box>
                </Box>
              </BaseButton>
            </Box>
          </StyledCard>
        </Box>
      </Zoom>
    </Box>
  );

  //As of 5/26/22 only the New England Menu Pilot comes along with a simplified version of this card.
  return shouldUseSimplifiedCard ? (
    simplifiedCard()
  ) : (
    <Box flex={1} width={{ xs: '100%', sm: '100%', md: '660px' }} mb={2}>
      <Zoom in>
        <Box>
          <StyledCard height={'fit-content'} nonInteractive={true}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              p={6}
              textAlign={'center'}
            >
              <Box mb={2.5}>
                <CareTeamBioAvatar source={careTeamMemberAvatarUri} />
              </Box>
              <Box mb={2.5}>
                <Typography variant={'h2'}>
                  <Introduction role={careTeamMemberRole} />
                </Typography>
              </Box>
              <Box mb={6}>
                <Typography variant={'body2'} aria-level={1}>
                  <Description name={careTeamMemberName} role={careTeamMemberRole} />
                </Typography>
              </Box>
              <BaseButton onClick={() => history.push(paths.careTeam())}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <Typography>{t('button.next')}</Typography>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} ml={2}>
                    <HiArrowRight />
                  </Box>
                </Box>
              </BaseButton>
            </Box>
          </StyledCard>
        </Box>
      </Zoom>
    </Box>
  );
};

export default ProviderMatchingBioCard;
