import { Box, Typography, Link } from '@mui/material';
import { CalendarToday, Phone } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { AppointmentAvatar } from '../atoms/Avatar';
import { RoleLabel } from '../atoms/RoleLabel';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { roleToDisplayName } from '../../data/Roles';
import { ReactComponent as AppointmentZeroStateIcon } from '../icons/AppointmentZeroState.svg';
import scheduleFirstAppointment from '../icons/ScheduleFirstAppointment.png';
import { SCHEDULING_PHONE_NUMBER } from '../../data/SupportContactInfo';

const formatPhoneToE164 = (phone) => `+1${phone.replace(/\D/g, '')}`;

const AppointmentLogistics = ({ contactMethod, clinicianName, scheduledStart, roleName }) => {
  return (
    <Box pl={3} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Typography
          variant={'subtitle1'}
          aria-level={2}
          data-testid="appointment-card-title"
          style={{ lineHeight: 1.35 }}
        >
          {contactMethod.type === 'custom' ? (
            <Trans
              i18nKey="webapp:AppointmentCardOther"
              defaults="Appointment with {{clinicianName}}"
              values={{ clinicianName }}
              aria-level={2}
            />
          ) : (
            <Trans
              i18nKey="webapp:AppointmentCardPhoneCall"
              defaults="Phone call with {{clinicianName}}"
              values={{ clinicianName }}
            />
          )}
        </Typography>
        <Box pl={1}>
          <RoleLabel role={roleName} />
        </Box>
      </Box>
      <Box display={'flex'} pt={2} flexDirection={'row'} alignItems={'center'}>
        <CalendarToday style={{ color: MyCricketColors.mediumTeal }} />

        <Box pl={1}>
          <Typography variant={'h3'} aria-level={3}>
            {format(parseISO(scheduledStart), 'PPPPp')}
          </Typography>
        </Box>
      </Box>
      {contactMethod.type === 'custom' && (
        <Box pt={2}>
          <Typography variant={'h3'}>
            <Trans
              i18nKey="webapp:appointmentNote"
              defaults="A note from your {{formattedRoleName}}:"
              values={{ formattedRoleName: roleToDisplayName[roleName] }}
            />
          </Typography>
          <Typography variant={'h3'} aria-level={3}>
            {contactMethod.customInfo}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const Divider = () => (
  <hr width="100%" height="1px" color={MyCricketColors.lightGrayFog} style={{ margin: 0 }} />
);

/**
 * A patient will see a CTA to schedule an appointment if they have never
 * completed an appointment, otherwise they'll see No Appointments yet.
 */
export const AppointmentZeroState = ({ shouldScheduleAppointment }) => {
  return (
    <>
      <Divider />
      <Box
        px={{ xs: 3, md: 7 }}
        py={6}
        display="grid"
        gap="18px"
        gridTemplateColumns="min-content auto"
        alignItems="center"
      >
        {shouldScheduleAppointment ? (
          <img
            src={scheduleFirstAppointment}
            alt=""
            width={82}
            height={72}
            style={{ margin: '12px' }}
          />
        ) : (
          <AppointmentZeroStateIcon />
        )}
        {shouldScheduleAppointment ? (
          <Typography variant="h3" component="p">
            <Trans
              i18nKey="webapp:callToScheduleAppointment"
              defaults="Please call us at <phone>{{clinicalPhoneNumber}}</phone> to schedule your appointment."
              values={{ clinicalPhoneNumber: SCHEDULING_PHONE_NUMBER }}
              components={{
                phone: (
                  <Link
                    color="textPrimary"
                    style={{ fontWeight: 800 }}
                    href={`tel:${formatPhoneToE164(SCHEDULING_PHONE_NUMBER)}`}
                    underline="none"
                    noWrap
                  />
                ),
              }}
            />
          </Typography>
        ) : (
          <Box>
            <Box mb={1}>
              <TranslatedTypography variant="subtitle1" aria-level={2}>
                No Appointments yet
              </TranslatedTypography>
            </Box>
            <Box>
              <Typography variant="h3" component="p">
                <Trans
                  i18nKey="webapp:saveToContacts"
                  defaults="Save us to your contacts, <phone>{{clinicalPhoneNumber}}</phone>"
                  values={{ clinicalPhoneNumber: SCHEDULING_PHONE_NUMBER }}
                  components={{
                    phone: (
                      <Link
                        color="textPrimary"
                        href={`tel:${formatPhoneToE164(SCHEDULING_PHONE_NUMBER)}`}
                        underline="none"
                        noWrap
                      />
                    ),
                  }}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export const AppointmentCard = ({ appointmentInfo }) => {
  return (
    <Box>
      <Divider />
      <Box px={{ xs: 3, md: 6 }} py={3} display={'flex'} alignItems={'center'}>
        <AppointmentAvatar
          src={appointmentInfo.avatarURI}
          alt={`Profile photo of ${appointmentInfo.nickName}`}
        />
        <AppointmentLogistics
          contactMethod={appointmentInfo.contactMethod}
          clinicianName={appointmentInfo.nickName}
          scheduledStart={appointmentInfo.scheduledForDateTime}
          roleName={appointmentInfo.roleName}
        />
      </Box>
    </Box>
  );
};

export const AppointmentFooter = () => {
  return (
    <Box>
      <Divider />
      <Box display={'flex'} px={{ xs: 3, md: 6 }} py={3}>
        <Box display={'flex'} alignItems={'center'} color={MyCricketColors.lightGraySmoke} mr={3}>
          <Phone fontSize={'large'} />
        </Box>
        <Box>
          <Box mb={1.5}>
            <TranslatedTypography
              variant={'subtitle1'}
              i18nKey={'makeOrUpdateAppointment'}
              style={{ lineHeight: 1.35 }}
              aria-level={2}
            >
              Need to make or update an appointment?
            </TranslatedTypography>
          </Box>
          <Box>
            <Typography variant={'h3'}>
              <Trans
                i18nKey="webapp:pleaseCallUs"
                defaults="Please call us at <phone>{{phoneNumber}}</phone>"
                values={{ phoneNumber: SCHEDULING_PHONE_NUMBER }}
                components={{
                  phone: (
                    <Link
                      href={`tel:${formatPhoneToE164(SCHEDULING_PHONE_NUMBER)}`}
                      underline={'none'}
                      noWrap
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
