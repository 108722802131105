/**
 * Enum for subscription group channels.
 * @readonly
 * @enum {string}
 */
const Channel = {
  EMAIL: 'email',
  SMS: 'sms',
};

/**
 * Enum for email subscription statuses.
 * @readonly
 * @enum {string}
 */
const EmailStatus = {
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  OPTED_IN: 'opted_in',
  HARD_BOUNCED: 'hard_bounced',
};

/**
 * Enum for subscription group statuses.
 * @readonly
 * @enum {string}
 */
const GroupStatus = {
  SUBSCRIBED: 'Subscribed',
  UNSUBSCRIBED: 'Unsubscribed',
  UNKNOWN: 'Unknown',
};

module.exports = {
  Channel,
  EmailStatus,
  GroupStatus,
};
