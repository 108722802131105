import { Box, Typography } from '@mui/material';
import React from 'react';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const CareTeamBioEducation = ({ education }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" width={'100%'}>
      <TranslatedTypography pt={2} variant="subtitle1" aria-level="2">
        Education
      </TranslatedTypography>
      <ul width={'100%'}>
        {education.map((cred, idx) => (
          <li key={'degree' + idx}>
            <Typography variant="body2">{cred}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default CareTeamBioEducation;
