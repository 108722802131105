import { Box } from '@mui/material';
import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { TiChevronLeft } from '@react-icons/all-files/ti/TiChevronLeft';
import { animated, useSpring } from 'react-spring';

const ChevronOpener = ({ open = false, openRotation, closedRotation, children }) => {
  const style = useSpring({
    display: 'inline-block',
    transformOrigin: '40%',
    WebkitTransformOrigin: '40%',
    msTransformOrigin: '40%',
    lineHeight: '0px',
    backfaceVisibility: 'hidden',
    transform: open ? `rotate(${openRotation}deg)` : `rotate(${closedRotation}deg)`,
    opacity: open ? 1 : 0.5,
  });

  return (
    <animated.span data-testid="chevron-opener" style={style}>
      {children}
    </animated.span>
  );
};

export const RotatingChevron = ({ open, openRotation, closedRotation, color }) => (
  <ChevronOpener open={open} openRotation={openRotation} closedRotation={closedRotation}>
    <TiChevronLeft style={{ color }} />
  </ChevronOpener>
);

export const VerticalChevron = ({ open }) => (
  <RotatingChevron
    open={open}
    openRotation={270}
    closedRotation={90}
    color={MyCricketColors.mint}
  />
);

export const HorizontalChevron = (props) => {
  const { pointsLeft, ...rest } = props;
  let { styles } = props;

  styles = {
    ...styles,
    color: MyCricketColors.mint,
    transform: `rotate(${pointsLeft ? 0 : 180}deg)`,
  };
  return (
    <Box display={'flex'} flexDirection={'column'} {...rest}>
      <TiChevronLeft data-test-id={'theChevron'} style={styles} />
    </Box>
  );
};

export const HorizontalChevronSlider = ({
  float = '',
  slideDistance = 9,
  hovered = false,
  children,
}) => {
  const style = useSpring({
    display: 'inline-block',
    opacity: hovered ? 1 : 0.5,
    float: float,
    transform: hovered ? `translateX(${slideDistance}px)` : `translateX(0px)`,
  });
  return (
    <animated.span data-testid={'chevron-slider'} style={style}>
      {children}
    </animated.span>
  );
};
