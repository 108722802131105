import * as PropTypes from 'prop-types';

export const historyPropType = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const careTeamMessagePropType = PropTypes.shape({
  clientTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupType: PropTypes.string,
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messageText: PropTypes.string,
  selectedGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  senderId: PropTypes.number.isRequired,
  inReplyTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messageSenderAvatarUri: PropTypes.string,
  messageSenderNickname: PropTypes.string,
  messageSenderRolename: PropTypes.string,
});

export const careTeamMemberProptype = PropTypes.shape({
  avatarURI: PropTypes.string,
  cricketUserId: PropTypes.number.isRequired,
  emailAddress: PropTypes.string,
  nickName: PropTypes.string.isRequired,
  nurseId: PropTypes.number,
  reportedLanguageId: PropTypes.number,
  roleName: PropTypes.string.isRequired,
});

export const directMessageGroupPropType = PropTypes.shape({
  cricketGroupId: PropTypes.number.isRequired,
  groupCreationDate: PropTypes.string,
  groupIsHidden: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  groupName: PropTypes.string.isRequired,
  groupTypeId: PropTypes.number,
  initialRevealDate: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.number).isRequired,
  mostRecentRevealDate: PropTypes.string,
  organizationId: PropTypes.number,
  parentGroupId: PropTypes.number,
});

export const classesPropType = PropTypes.shape({});

export const chirpContextPropType = PropTypes.shape({
  connected: PropTypes.bool.isRequired,
  directMessageGroups: PropTypes.shape({}).isRequired,
  sendMessage: PropTypes.func.isRequired,
});
