import React from 'react';
import { useSpring, animated } from 'react-spring';
import { TranslatedTypography } from './TranslatedTypography';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Trans } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';

const useStyles = makeStyles({
  root: {
    '& img': {
      width: '100%',
    },
  },
});

export const FaqAnswer = ({ answer, updatedDate, open = false }) => {
  const classes = useStyles();
  const style = useSpring({
    display: open ? 'inline-block' : 'none',
    cursor: 'text',
    opacity: open ? 1.0 : 0.0,
    configuration: {
      tension: 90,
      friction: 30,
    },
  });

  return (
    <animated.div
      className={classes.root}
      style={style}
      // This lets folks copy+paste from the FAQ answer
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TranslatedTypography variant="body2">
        <Box component={'span'} dangerouslySetInnerHTML={{ ['__html']: answer }} />
      </TranslatedTypography>
      {updatedDate && (
        <Box mb={3} color={MyCricketColors.mediumGray} fontSize={16} fontStyle={'italic'}>
          <Trans
            i18nKey={'webapp:faqUpdatedDate'}
            defaults={'Updated {{updatedDate}}'}
            values={{ updatedDate }}
          />
        </Box>
      )}
    </animated.div>
  );
};
