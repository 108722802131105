import pRetry from 'p-retry';
import Debug from 'debug';
const debug = Debug('MyCricket:fetch');

export const myCricketAuthedFetch = async (path, options = {}) => {
  const idToken = localStorage.getItem('idToken');

  if (!idToken) {
    throw new Error(`No Id Token found, aborting fetch attempt for ${path}`);
  }

  const { headers, ...restOptions } = options;

  const combinedOptions = {
    ...restOptions,
    headers: {
      ...headers,
      Authorization: `Bearer ${idToken}`,
    },
  };

  return myCricketFetch(path, combinedOptions);
};

export const myCricketFetch = async (path, options = {}) => {
  const apiURL = window._cc.api.url;

  const { returnRawResponse, retry = false, signal, ...restOptions } = options;

  const combinedOptions = {
    headers: {},
    signal,
    ...restOptions,
  };

  // To submit FormData, you literally cannot set Content-Type
  let contentType = 'application/json';
  if (options.body instanceof FormData) {
    contentType = null;
  }

  if (contentType) {
    combinedOptions.headers['Content-Type'] = contentType;
  }

  const fetcher = async () => {
    debug(`Request: ${apiURL}${path}`);
    const response = await fetch(`${apiURL}${path}`, combinedOptions);
    debug(`✨ Response: ${apiURL}${path}`);

    const contentType = response.headers.get('content-type');
    const isJSON = contentType?.includes('application/json');

    if (!response.ok) {
      const body = isJSON ? await response.json() : await response.text();
      const message = (isJSON ? body.message : body) || 'Error fetching resource';
      const error = new Error(message);
      error.status = response.status;
      error.responseBody = body;
      throw error;
    }
    if (response.status === 204) {
      return null;
    } else if (returnRawResponse) {
      return response;
    } else {
      if (isJSON) {
        return response.json();
      }
      return response.text();
    }
  };

  return await pRetry(fetcher, {
    onFailedAttempt: (error) => {
      if (error.name === 'AbortError') {
        return;
      }
      if (error.retriesLeft > 0) {
        console.info(
          `Attempt ${error.attemptNumber} to fetch ${apiURL}${path} failed. There are ${error.retriesLeft} retries left.`,
        );
      } else if (retry) {
        console.error(`Failed to fetch ${apiURL}${path} after all retries`);
      }
    },
    retries: retry ? 5 : 0,
    signal,
  });
};
