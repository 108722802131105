import React from 'react';
import { Box } from '@mui/material';
import Roles from '../../data/Roles';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from './TranslatedTypography';

const BaseLabel = ({ isMentor, isReply, isActive, children, ...rest }) => {
  return (
    <Box
      width={'fit-content'}
      borderRadius={'6px'}
      px={0.5}
      height={'fit-content'}
      color={isMentor ? MyCricketColors.kellyGreen : MyCricketColors.teal}
      bgcolor={isReply || isActive ? MyCricketColors.white : MyCricketColors.lightGrayMist}
    >
      {children}
    </Box>
  );
};

const LabelContent = (props) => {
  const { role } = props;
  return (
    <Box px={0.5}>
      <TranslatedTypography variant="subtitle1" aria-level="2" style={{ fontWeight: 'bold' }}>
        {Roles.useRoleDisplayName(role)}
      </TranslatedTypography>
    </Box>
  );
};
// This is our default rounded corner button
// Other shape buttons are styled versions of this button, with different a border radius
export const RoleLabel = (props) => {
  const { role, isReply } = props;
  return role ? (
    Roles.isMentorRole(role) ? (
      <BaseLabel isMentor={true} isReply={isReply}>
        <LabelContent role={role} />
      </BaseLabel>
    ) : (
      //The or Provider is an overly paranoid guard
      <BaseLabel isReply={isReply}>
        <LabelContent role={role} />
      </BaseLabel>
    )
  ) : (
    <></>
  );
};

export const ChatLabel = (props) => {
  const { role, active } = props;
  return active ? (
    Roles.isMentorRole(role) ? (
      <BaseLabel isMentor={true} isActive={true}>
        <LabelContent role={role} />
      </BaseLabel>
    ) : (
      <BaseLabel isActive={true}>
        <LabelContent role={role} />
      </BaseLabel>
    )
  ) : (
    <RoleLabel role={role} />
  );
};
