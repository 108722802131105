import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { paths } from '../../utils/routing/routes';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import GroupCardList from '../organisms/GroupCardList';
import { mapGroupTypeNamesToGroupIds } from '../../utils/chatUtils';
import { invert } from 'lodash';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';

const GroupsListPage = () => {
  const { directMessageGroups, loading } = useChirpContext();
  const history = useHistory();
  const [discussionGroupIdsMap, setDiscussionGroupIdsMap] = useState(null);

  useEffect(() => {
    const _discussionGroupIdsMap = mapGroupTypeNamesToGroupIds(directMessageGroups);
    setDiscussionGroupIdsMap(_discussionGroupIdsMap);
  }, [directMessageGroups]);

  const navigateToGroup = (groupId) => {
    const idToGroupName = invert(discussionGroupIdsMap);
    history.push(paths.group(idToGroupName[groupId]));
  };

  return (
    <MyCricketLoadingContainer loading={loading || !discussionGroupIdsMap}>
      <GroupCardList
        discussionGroupIds={discussionGroupIdsMap}
        setCurrentlySelectedGroup={navigateToGroup}
      />
    </MyCricketLoadingContainer>
  );
};

export default GroupsListPage;
