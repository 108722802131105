import React, { useState } from 'react';
import { Box, InputLabel, Link, TextField, Typography, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { RoundButton } from '../atoms/Buttons';
import LoginError from '../../data/LoginError';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import ShowHideButton from '../atoms/ShowHideButton';
import MyCricketColors from '../../data/MyCricketColors';

const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: MyCricketColors.notificationRed,
  marginTop: theme.spacing(1),
}));

const UnderlineLink = styled('span')({ textDecoration: 'underline', cursor: 'pointer' });

const LoginForm = ({
  state,
  setState,
  handleChange,
  error,
  email,
  hasEdited,
  emailFocused,
  emailValid,
  handleSubmit,
  password,
  passwordFocused,
  onLoadResetPasswordForm,
  disabled,
  featureFlags,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const { instrument } = useClientEventLoggerContext();

  return (
    <>
      <TranslatedTypography variant="h2" data-test-id="patientLoginHeader" aria-level="1">
        Log in to MyCricket
      </TranslatedTypography>
      <form
        onSubmit={handleSubmit}
        data-test-id="signInForm"
        disabled={disabled}
        aria-label="sign in"
      >
        <Box mt={4} width={'100%'}>
          <Box mb={1}>
            <InputLabel htmlFor={'email-address'}>
              <TranslatedTypography i18nKey={'emailAddress'} variant={'h3'} aria-level="2">
                Email Address
              </TranslatedTypography>
            </InputLabel>
          </Box>
          <TextField
            id="email-address"
            autoComplete="username"
            name="email"
            variant="outlined"
            type="email"
            placeholder={t('emailAddress', 'Email Address')}
            data-test-id="emailAddressInput"
            error={
              (hasEdited && !emailFocused && !emailValid) ||
              error === LoginError.NEED_EMAIL ||
              (hasEdited && !emailFocused && error === LoginError.UNAUTHORIZED)
            }
            disabled={disabled}
            sx={{ width: '100%' }}
            InputProps={{ style: { width: '100%', height: '50px' } }}
            value={email}
            onChange={handleChange}
            onFocus={() => setState({ ...state, emailFocused: true })}
            onBlur={() => setState({ ...state, emailFocused: false })}
          />
          {hasEdited && !emailFocused && !emailValid && (
            <ErrorTypography variant={'h3'} data-test-id="invalidEmailMessage">
              <Trans>{LoginError.INVALID_EMAIL}</Trans>
            </ErrorTypography>
          )}
          {error === LoginError.NEED_EMAIL && (
            <ErrorTypography variant={'h3'} data-test-id="needEmailMessage">
              <Trans>{LoginError.NEED_EMAIL}</Trans>
            </ErrorTypography>
          )}
        </Box>
        <Box mt={3}>
          <Box mb={1}>
            <InputLabel htmlFor={'password'}>
              <TranslatedTypography variant={'h3'} aria-level="2">
                Password
              </TranslatedTypography>
            </InputLabel>
          </Box>
          <TextField
            id="password"
            name="password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            placeholder={t('Password')}
            data-test-id="passwordInput"
            autoComplete="current-password"
            autoCorrect="off"
            autoCapitalize="off"
            error={
              (hasEdited && !passwordFocused && error === LoginError.UNAUTHORIZED) ||
              error === LoginError.NEED_PASSWORD
            }
            disabled={disabled}
            sx={{ width: '100%' }}
            InputProps={{
              style: { width: '100%', height: '50px' },
              endAdornment: <ShowHideButton isShown={showPassword} setShown={setShowPassword} />,
            }}
            value={password}
            onChange={handleChange}
            onFocus={() => setState({ ...state, passwordFocused: true })}
            onBlur={() => setState({ ...state, passwordFocused: false })}
          />
          {hasEdited && !passwordFocused && error === LoginError.UNAUTHORIZED && (
            <ErrorTypography variant={'h3'} data-test-id="authenticationFailureMessage">
              <Trans>{error}</Trans>{' '}
              <UnderlineLink
                role="link"
                tabIndex={0}
                onKeyPress={() => {}}
                onClick={() => setState({ ...state, route: 'forgot', error: null })}
              >
                <Trans i18nKey={'tryAgainOrResetPW'}>
                  Please try again or reset your password.
                </Trans>
              </UnderlineLink>
            </ErrorTypography>
          )}
          {error === LoginError.NEED_PASSWORD && (
            <ErrorTypography variant={'h3'} data-test-id="needPasswordMessage">
              <Trans>{LoginError.NEED_PASSWORD}</Trans>
            </ErrorTypography>
          )}
          <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
            <Link
              to="/"
              data-test-id="forgot-password"
              sx={{ cursor: 'pointer', textDecoration: 'none', color: MyCricketColors.tealMain }}
              onClick={() => {
                if (!disabled) {
                  onLoadResetPasswordForm && onLoadResetPasswordForm();
                  setState({ ...state, route: 'forgot', error: null });
                }
              }}
            >
              <TranslatedTypography i18nKey={'forgotPassword'} variant={'h3'}>
                Forgot password?
              </TranslatedTypography>
            </Link>
          </Box>
        </Box>
        <Box mt={3} width={'100%'}>
          <RoundButton
            type="submit"
            i18nKey={'loginCTA'}
            style={{ height: '50px', width: '100%' }}
            textStyle={{ lineHeight: 1 }}
            disabled={disabled}
            id="login-cta"
            aria-label="submit sign in form"
          >
            Log in
          </RoundButton>
          <Box my={3} textAlign={'center'}>
            <Typography variant={'body1'}>{t('or', 'or')?.toUpperCase()}</Typography>
          </Box>
          <RoundButton
            type="button"
            i18nKey={'magicLinkCTA'}
            style={{ height: '50px', width: '100%' }}
            textStyle={{ lineHeight: 1 }}
            disabled={disabled}
            onClick={instrument({
              eventName: 'clickedGoToMagicLinkFromLogin',
              callback: () => setState({ ...state, route: 'magic-link', error: null }),
            })}
          >
            Email me a log in link
          </RoundButton>
        </Box>
      </form>
    </>
  );
};

export default LoginForm;
