import * as Sentry from '@sentry/react';
import stringify from 'fast-safe-stringify';

/**
 * @param {Error|object} err The error to be logged to console and sent to any external sources.
 * @param options
 * @param {boolean?} options.noConsole If set to true, will not log the error to the console on non-prod envs.
 * @param {string?} options.consolePrefix The string to append to the start of the error when logging to the console.
 */
const logClientError = async (err, options = { noConsole: false, consolePrefix: undefined }) => {
  if (!err) {
    return;
  }
  const { noConsole, consolePrefix } = options;
  let error;
  if (err instanceof Error) {
    error = err;
  } else {
    let errorMessage = err;

    // At many points throughout the codebase, we throw a Response object (from an HTTP call) as an
    // error. This check is to circumvent the object being sent to sentry without being decoded.
    if (err.json && typeof err.json === 'function') {
      try {
        errorMessage = await err.json();
      } catch {
        // If we get an error decoding the error, just skip so
        // we don't blow up anything else.
      }
    }

    // Ensure any objects are converted into strings.
    if (errorMessage && typeof errorMessage === 'object') {
      errorMessage = stringify(errorMessage);
    }

    error = new Error(errorMessage);
  }

  // Console should log before Sentry, because Sentry will send the
  // most recent logs with it.
  if (!noConsole) {
    if (consolePrefix) {
      console.error(consolePrefix, error);
    } else {
      console.error(error);
    }
  }
  if (window.Sentry) {
    window.Sentry.captureException(error);
  } else {
    Sentry.captureException(error);
  }
};

export { logClientError };
