import { createTheme } from '@mui/material/styles';
import colors from './colors';
import MyCricketColors from '../data/MyCricketColors';

export const palette = {
  background: {
    default: MyCricketColors.lightGrayFog,
  },
  ...colors,
  ...MyCricketColors,
};

export const spacing = {
  spacing: 6,
};

const typography = () => ({
  fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
  h1: {
    fontSize: 32,
    fontWeight: 800,
  },
  h2: {
    fontSize: 24,
    fontWeight: 800,
  },
  h3: {
    fontSize: 16,
    fontWeight: 500,
  },
  h4: {
    fontSize: 14,
    fontWeight: 400,
  },
  //line height 1.75
  subtitle1: {
    fontSize: 18,
    fontWeight: 800,
  },
  //line height normal
  subtitle2: {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: 'normal',
  },
  body1: {
    fontSize: 18,
    fontWeight: 500,
  },
  body2: {
    fontSize: 18,
    fontWeight: 400,
  },
});

const sharedStyles = {
  listItem: {
    selected: { backgroundColor: 'blue' },
  },
  loginErrorText: palette.notificationRed,
  loginButtonBackground: palette.mint,
  loginButtonHoverBackground: palette.hoverMint,
  loginButtonPressedBackground: palette.pressedMint,
  components: {
    MuiAvatar: {
      root: { borderRadius: '50%' },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '7px 19px', // Extra pixel because there is no border in the implementation
          borderRadius: '2px',
          boxShadow: 'none',
          lineHeight: 'unset',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.focused': {
            border: `2px ${palette.teal} solid`,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: MyCricketColors.black,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '0 24px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 24px 6px',
        },
      },
    },
  },
};

const createMuiThemeWithCustomShadows = () => {
  const baseTheme = createTheme({
    palette,
    spacing: 6,
    typography: typography(),
    ...sharedStyles,
  });
  //https://github.com/mui-org/material-ui/issues/8289
  baseTheme.shadows = baseTheme.shadows.map((shadow) => {
    // Change the base color for shadows
    const shadowSplitOnFirstValue = shadow.split('0.2),');
    if (typeof shadowSplitOnFirstValue[1] === 'string') {
      return shadowSplitOnFirstValue[1].replace(/rgba\(0,0,0,/g, 'rgba(0,62,81,');
    } else {
      return shadow;
    }
  });
  return baseTheme;
};

export default createMuiThemeWithCustomShadows();
