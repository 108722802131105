import React, { useState } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from '@mui/material';
import { BaseRadioButton, BaseCheckbox, DropdownSelect } from '../atoms/Selects';
import { Trans, useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';

const FormControlLabelWrapper = (props) => {
  const { Control, ...labelProps } = props;
  return <FormControlLabel control={<Control />} {...labelProps} />;
};

const RadioEditor = ({ answers, answerState, onSetAnswers }) => {
  const { t } = useTranslation();
  const value = answerState.find((a) => a.answerValue === true)?.answerId;
  const handleChange = (event) => {
    onSetAnswers(
      answers.map(({ answerId, answerType }) => ({
        answerId,
        answerType,
        answerValue: parseInt(event.target.value) === parseInt(answerId) ? true : false,
      })),
    );
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="answer" name="answer" value={parseInt(value)} onChange={handleChange}>
        {answers.map(({ answerId, answerText }) => {
          return (
            <FormControlLabelWrapper
              key={answerId}
              value={parseInt(answerId)}
              label={t(`survey:answer-${answerId}`, answerText)}
              Control={BaseRadioButton}
              data-test-id={`answer-${answerId}`}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const CheckboxWithTextEditor = ({
  answer: { answerText, answerId },
  answerState,
  onSetAnswers,
}) => {
  const { t } = useTranslation();
  const existingAnswer = answerState.find((a) => a.answerId === answerId);
  const [checked, setChecked] = useState(Boolean(existingAnswer?.answerValue));
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'text') {
      onSetAnswers([{ answerId, answerType: 'checkbox-text', answerValue: e.target.value }]);
    } else if (e.target.name === 'checkbox') {
      if (!e.target.checked) {
        setChecked(false);
        onSetAnswers([{ answerId, answerType: 'checkbox-text', answerValue: '' }]);
      } else {
        setChecked(true);
      }
    }
  };

  return (
    <FormControl component="fieldset">
      <Box display="flex" flexDirection="row" alignItems="center">
        <FormControlLabelWrapper
          label={t(`survey:answer-${answerId}`, answerText)}
          name="checkbox"
          Control={(props) => <BaseCheckbox {...props} onChange={handleChange} />}
          checked={checked}
          data-test-id={`answer-${answerId}`}
        />
        <TextField
          name="text"
          variant="outlined"
          size="small"
          value={existingAnswer?.answerValue || ''}
          onChange={handleChange}
          onFocus={() => setChecked(true)}
          inputProps={{
            'data-test-text-field-id': `answer-${answerId}`,
          }}
        />
      </Box>
    </FormControl>
  );
};

const CheckboxEditor = ({ answerState, values, answers, onSetAnswers }) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    const eventAnswer = answers.find(
      ({ answerId }) => parseInt(event.target.value) === parseInt(answerId),
    );
    if (eventAnswer.uniqueAnswer && event.target.checked === true) {
      onSetAnswers(
        answers.map((answer) => {
          const { answerId, answerType } = answer;
          return parseInt(event.target.value) === parseInt(answerId)
            ? {
                answerId,
                answerType,
                answerValue: true,
              }
            : {
                answerId,
                answerType,
                answerValue: false,
              };
        }),
      );
    } else {
      onSetAnswers(
        answers.map((answer) => {
          const { answerId, answerType, uniqueAnswer } = answer;
          if (parseInt(event.target.value) === parseInt(answerId)) {
            return {
              answerId,
              answerType,
              answerValue: event.target.checked ? true : false,
            };
          } else {
            // If we checked on an answer that's not unique uncheck the unique ones.
            if (uniqueAnswer && event.target.checked) {
              return {
                answerId,
                answerType,
                answerValue: false,
              };
            }
            return {
              answerId,
              answerType,
              answerValue: answerState.find((a) => a.answerId === answerId)?.answerValue || false,
            };
          }
        }),
      );
    }
  };
  return (
    <FormControl component="fieldset">
      {answers.map(({ answerId, answerText }) => {
        return (
          <FormControlLabelWrapper
            key={answerId}
            value={parseInt(answerId)}
            label={t(`survey:answer-${answerId}`, answerText)}
            Control={(props) => (
              <BaseCheckbox
                {...props}
                onChange={handleChange}
                data-test-id={`answer-${answerId}`}
              />
            )}
            checked={answerState.find((a) => a.answerId === answerId)?.answerValue === true}
          />
        );
      })}
    </FormControl>
  );
};

const DropdownEditor = ({ answers, answerState, onSetAnswers }) => {
  const { t } = useTranslation();
  return (
    <>
      <DropdownSelect
        placeholder={t('Please Select', 'Please Select')}
        defaultValue={
          answerState.find((a) => a.answerValue === true && a.answerType !== 'skip')?.answerId
        }
        options={answers.map((a) => ({ value: a.answerId, displayValue: a.answerText }))}
        handleChange={(event) => {
          onSetAnswers(
            answers.map((answer) => {
              const { answerId, answerType } = answer;
              return parseInt(event.target.value) === parseInt(answerId)
                ? {
                    answerId,
                    answerType,
                    answerValue: true,
                  }
                : {
                    answerId,
                    answerType,
                    answerValue: false,
                  };
            }),
          );
        }}
      />
    </>
  );
};

const SurveyQuestion = ({
  error,
  questionText,
  questionAnswers,
  onSkipClick,
  answerState,
  onMergeAnswerState,
  dataTestId,
}) => {
  const answerGroupIds = [...new Set(questionAnswers.map((a) => a.answerGroupId))];
  const answerGroups = answerGroupIds.map((answerGroupId) =>
    questionAnswers.filter((q) => q.answerGroupId === answerGroupId),
  );
  return (
    <>
      <Box fontWeight={700} fontSize={'24px'} m={'18px 0'}>
        <Typography variant="h2" data-test-id={dataTestId}>
          <Trans i18nKey={`survey:${dataTestId}`}>{questionText}</Trans>
        </Typography>
      </Box>
      {error && (
        <Box color={'#F9695C'} marginBottom={'18px'}>
          <Typography>{error}</Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {Object.entries(answerGroups).map(([index, answers]) => {
          const firstAnswer = answers[0];
          const { answerType, answerId } = firstAnswer || {};
          if (answerType === 'skip') {
            // Kind of a crutch here but the assumption is that all
            // answer skips will be in their own answer group
            return (
              <Box
                key={index}
                color={MyCricketColors.tealGray}
                mt={'18px'}
                mb={'30px'}
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  color={MyCricketColors.tealGray}
                  variant="subtitle1"
                  data-test-id={`answer-${answerId}`}
                  onClick={() => onSkipClick(firstAnswer.answerId)}
                >
                  <Trans i18nKey={`survey:answer-${firstAnswer.answerId}`}>
                    {firstAnswer.answerText}
                  </Trans>
                </Typography>
              </Box>
            );
          }
          if (answerType === 'radio') {
            return (
              <RadioEditor
                key={index}
                answerState={answerState}
                answers={answers}
                onSetAnswers={onMergeAnswerState}
              />
            );
          } else if (answerType === 'checkbox') {
            return (
              <CheckboxEditor
                key={index}
                answerState={answerState}
                answers={answers}
                onSetAnswers={onMergeAnswerState}
              />
            );
          } else if (answerType === 'checkbox-text') {
            // Assumption is one per answer group
            const answer = answers[0];
            return (
              <CheckboxWithTextEditor
                key={index}
                answer={answer}
                answerState={answerState}
                onSetAnswers={onMergeAnswerState}
              />
            );
          } else if (answerType === 'dropdown') {
            return (
              <DropdownEditor
                key={index}
                answerState={answerState}
                answers={answers}
                onSetAnswers={onMergeAnswerState}
              />
            );
          } else {
            return <RadioEditor answers={answers} onSetAnswers={onMergeAnswerState} />;
          }
        })}
      </Box>
    </>
  );
};

export default SurveyQuestion;
