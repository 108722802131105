import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, InputLabel, FormControlLabel, Collapse, Fade } from '@mui/material';
import { BaseButton } from '../atoms/Buttons';
import MyCricketColors from '../../data/MyCricketColors';
import PhoneNumberInput from '../atoms/PhoneNumberInput';
import { BaseCheckbox } from '../atoms/Selects';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';

const isPhoneNumberValid = (phoneNumber) => /^\d{10}$/.test(phoneNumber);

const PhoneContactInfoEditor = ({
  initialPrimaryPhoneNumber,
  initialSMSPhoneNumber,
  customerIsDoNotText,
  isEditing,
  setEditing,
}) => {
  const { updatePhoneNumbers } = usePatientContext();

  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(initialPrimaryPhoneNumber || '');
  const [smsPhoneNumber, setSMSPhoneNumber] = useState(initialSMSPhoneNumber || '');
  const [isPrimaryPhoneNumberUsedForSMS, setPrimaryPhoneNumberUsedForSMS] = useState(
    initialPrimaryPhoneNumber === initialSMSPhoneNumber,
  );
  const [isOptedOutOfSMS, setOptedOutOfSMS] = useState(
    customerIsDoNotText || !initialSMSPhoneNumber,
  );

  const [isLoading, setLoading] = useState(false);
  const [primaryErrorMessage, setPrimaryErrorMessage] = useState(null);
  const [smsErrorMessage, setSMSErrorMessage] = useState(null);

  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditing) {
      setPrimaryPhoneNumber(initialPrimaryPhoneNumber);
      setSMSPhoneNumber(initialSMSPhoneNumber);
      setPrimaryPhoneNumberUsedForSMS(initialPrimaryPhoneNumber === initialSMSPhoneNumber);
      setOptedOutOfSMS(
        initialPrimaryPhoneNumber === initialSMSPhoneNumber ? false : !initialSMSPhoneNumber,
      );
      setPrimaryErrorMessage(null);
      setSMSErrorMessage(null);
    }
  }, [isEditing, initialPrimaryPhoneNumber, initialSMSPhoneNumber]);

  const savePhoneContactInfo = async () => {
    if (isLoading) {
      return;
    }
    let hasError = false;
    if (!primaryPhoneNumber) {
      setPrimaryErrorMessage(t('phoneNumberErrorPrimaryMissing'));
      hasError = true;
    } else if (!isPhoneNumberValid(primaryPhoneNumber)) {
      setPrimaryErrorMessage(t('phoneNumberErrorInvalidLength'));
      hasError = true;
    }
    if (!isPrimaryPhoneNumberUsedForSMS && !smsPhoneNumber && !isOptedOutOfSMS) {
      setSMSErrorMessage(t('phoneNumberErrorSMSMissing'));
      hasError = true;
    } else if (smsPhoneNumber && !isPhoneNumberValid(smsPhoneNumber)) {
      setSMSErrorMessage(t('phoneNumberErrorInvalidLength'));
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setLoading(true);
    setPrimaryErrorMessage(null);
    setSMSErrorMessage(null);
    try {
      await updatePhoneNumbers({
        phoneNumberToCall: primaryPhoneNumber,
        phoneNumberToText: isPrimaryPhoneNumberUsedForSMS
          ? primaryPhoneNumber
          : isOptedOutOfSMS
          ? ''
          : smsPhoneNumber,
        doNotText: isOptedOutOfSMS,
      });
      setEditing(false);
    } catch (error) {
      setPrimaryErrorMessage(t('phoneNumberErrorGeneral'));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box mb={4}>
        <Box>
          <Box mb={1}>
            <InputLabel htmlFor={'phone-number'}>
              <Typography variant={'body1'}>{t('phoneNumber', 'Phone Number')}</Typography>
            </InputLabel>
          </Box>
          <Box>
            <PhoneNumberInput
              name={'phone-number'}
              variant={'outlined'}
              style={{ width: !isDesktop ? '100%' : undefined }}
              InputProps={{
                style: { width: isDesktop ? '190px' : '100%', height: '50px' },
              }}
              error={Boolean(primaryErrorMessage)}
              value={primaryPhoneNumber}
              onChange={(phoneNumber) => {
                setPrimaryPhoneNumber(phoneNumber);
                setPrimaryErrorMessage(null);
              }}
            />
          </Box>
          {primaryErrorMessage && (
            <Box color={MyCricketColors.notificationRed} mt={1.5}>
              <Typography variant={'body1'}>{primaryErrorMessage}</Typography>
            </Box>
          )}
          {!customerIsDoNotText && (
            <Box ml={0.5}>
              <FormControlLabel
                label={<Typography variant={'body2'}>{t('smsUsePrimaryNumberPrompt')}</Typography>}
                control={
                  <BaseCheckbox
                    size={'36px'}
                    checked={isPrimaryPhoneNumberUsedForSMS}
                    onChange={(event) => {
                      setPrimaryPhoneNumberUsedForSMS(event.target.checked);
                      setOptedOutOfSMS(false);
                      if (!event.target.checked) {
                        setSMSPhoneNumber(null);
                        setSMSErrorMessage(null);
                      }
                    }}
                  />
                }
              />
            </Box>
          )}
        </Box>
        {!customerIsDoNotText && (
          <Collapse in={!isPrimaryPhoneNumberUsedForSMS} appear={false}>
            <Fade in={!isPrimaryPhoneNumberUsedForSMS} appear={false}>
              <Box mt={4}>
                <Box mb={1}>
                  <InputLabel htmlFor={'text-messaging-number'}>
                    <Typography variant={'body1'}>
                      {t('textMessagingNumber', 'Text Messaging Number')}
                    </Typography>
                  </InputLabel>
                </Box>
                <Box>
                  <PhoneNumberInput
                    name={'text-messaging-number'}
                    variant={'outlined'}
                    style={{ width: !isDesktop ? '100%' : undefined }}
                    InputProps={{
                      style: { width: isDesktop ? '190px' : '100%', height: '50px' },
                    }}
                    disabled={isOptedOutOfSMS}
                    error={Boolean(smsErrorMessage)}
                    value={smsPhoneNumber}
                    onChange={(phoneNumber) => {
                      setSMSPhoneNumber(phoneNumber);
                      setSMSErrorMessage(null);
                    }}
                  />
                </Box>
                {smsErrorMessage && (
                  <Box color={MyCricketColors.notificationRed} mt={1.5}>
                    <Typography variant={'body1'}>{smsErrorMessage}</Typography>
                  </Box>
                )}
                <Box ml={0.5}>
                  <FormControlLabel
                    label={<Typography variant={'body2'}>{t('smsOptOutPrompt')}</Typography>}
                    control={
                      <BaseCheckbox
                        size={'36px'}
                        checked={isOptedOutOfSMS}
                        onChange={(event) => {
                          setOptedOutOfSMS(event.target.checked);
                          setSMSErrorMessage(null);
                          if (event.target.checked) {
                            setSMSPhoneNumber(null);
                          }
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            </Fade>
          </Collapse>
        )}
      </Box>
      <hr width="100%" color={MyCricketColors.lightGrayFog} style={{ margin: 0 }} />
      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={{ xs: 'center', sm: 'center', md: 'flex-end' }}
        mt={4}
      >
        <Box mr={3}>
          <BaseButton
            variant="outlined"
            style={{ width: '105px', height: '50px' }}
            disabled={isLoading}
            onClick={() => setEditing(false)}
          >
            Cancel
          </BaseButton>
        </Box>
        <Box>
          <BaseButton
            type="submit"
            style={{ width: '105px', height: '50px' }}
            disabled={isLoading}
            onClick={savePhoneContactInfo}
          >
            Save
          </BaseButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneContactInfoEditor;
