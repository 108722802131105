import React, { useState } from 'react';
import { BaseButton, SquareButton, RoundButton, SegmentedButton } from '../atoms/Buttons';
import { ChatLabel, RoleLabel } from '../atoms/RoleLabel';
import { Avatar } from '../atoms/Avatar';
import { Box, Typography } from '@mui/material';
import { HorizontalChevron, HorizontalChevronSlider, VerticalChevron } from '../atoms/Chevrons';
import { FaqItem } from '../molecules/FaqItem';
import { ModuleDetailContext } from './ModuleDetail';
import Roles from '../../data/Roles';
import { LearnModuleNavigator } from '../molecules/LearnModuleNavigator';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';
import { BackNav } from '../atoms/BackNav';
import { useHistory } from 'react-router';
import { paths } from '../../utils/routing/routes';
import { DropdownSelect } from '../atoms/Selects';
import { Close, Edit, Save, Delete } from '@mui/icons-material';
import { NewBadge } from '../atoms/NewBadge';

const UiInventory = () => {
  const [nextHover, setNextHover] = useState(false);
  const [previousHover, setPreviousHover] = useState(false);
  const [openChevron, setOpenChevron] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(null);

  const faq = {
    title: 'A Cool Title',
    answer: 'A very in depth and informative answer',
    author: {
      role: 'MENTOR',
      name: 'Cool Girl',
      bio: 'Very cool, very knowledgeable about kidneys',
      picture: {
        url: 'https://cdn.crickethealth.com/1/strapi/uploads/thumbnail_Danuta_small_414b35c805.png',
      },
    },
  };
  const faq2 = {
    title: 'A Blood Pressure Cuff',
    answer: `Woah look at this sick blood pressure cuff! Don't you wish you had one?`,
    author: {
      role: 'DOCTOR',
      name: 'Cool Lady',
      bio: 'Very cool, even more knowledgeable about kidneys',
    },
    thumbnail: {
      formats: {
        small: {
          ext: '.jpeg',
          hash: 'small_qa_13-4_bloodpressure_cbd4a42bd9',
          height: 331,
          mime: 'image/jpeg',
          size: 16.13,
          url: '//cdn.crickethealth.com/1/strapi/uploads/small_qa_13-4_bloodpressure_cbd4a42bd9.jpeg',
          width: 500,
        },
        thumbnail: {
          ext: '.jpeg',
          hash: 'thumbnail_qa_13-4_bloodpressure_cbd4a42bd9',
          height: 156,
          mime: 'image/jpeg',
          size: 5.66,
          url: '//cdn.crickethealth.com/1/strapi/uploads/thumbnail_qa_13-4_bloodpressure_cbd4a42bd9.jpeg',
          width: 235,
        },
      },
    },
  };

  return (
    <MyCricketLoadingContainer>
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h1>UI Inventory!</h1>
        </Box>
        <Box display="flex" flexDirection="column" paddingBottom="48px">
          <Box display="flex" flexDirection="column" alignItems="center">
            <h2>Standard Buttons</h2>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-around" paddingTop="24px">
            <h3>Shape</h3>
            <h3>Base (no props)</h3>
            <h3>Outlined/Inverted</h3>
            <h3>Disabled</h3>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-around" paddingTop="24px">
            <h3>Semi-Round (aka BaseButton)</h3>
            <BaseButton>Base (no props)</BaseButton>
            <BaseButton variant="outlined">Outlined/Inverted</BaseButton>
            <BaseButton disabled={true}>Disabled</BaseButton>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-around" paddingTop="24px">
            <h3>Square (aka SquareButton)</h3>
            <SquareButton>Base (no props)</SquareButton>
            <SquareButton variant="outlined">Outlined/Inverted</SquareButton>
            <SquareButton disabled={true}>Disabled</SquareButton>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-around" paddingTop="24px">
            <h3>Rounded (aka RoundedButton)</h3>
            <RoundButton>Base (no props)</RoundButton>
            <RoundButton variant="outlined">Outlined/Inverted</RoundButton>
            <RoundButton disabled={true}>Disabled</RoundButton>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <h2>Special Buttons</h2>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-around" paddingTop="24px">
            <h3>Segmented (aka SegmentedButton)</h3>
            <SegmentedButton segments={[{ children: 'Text with Icon' }, { icon: <Close /> }]} />
            <SegmentedButton segments={[{ children: 'Some Text' }, { children: 'Other Text' }]} />
            <SegmentedButton
              segments={[{ icon: <Edit /> }, { icon: <Save /> }, { icon: <Delete /> }]}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            background="black"
            paddingTop="24px"
          >
            <h2>Base Role Labels</h2>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
              width="100vw"
              bgcolor="white"
              paddingTop="12px"
              paddingBottom="12px"
            >
              {Roles.careTeamRoles.map((role, idx) => (
                <RoleLabel key={role + idx} role={role} />
              ))}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h2>Active Chat Role Labels</h2>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              width="100vw"
              paddingTop="12px"
              paddingBottom="12px"
            >
              {Roles.careTeamRoles.map((role, idx) => (
                <ChatLabel key={role + idx} active={true} role={role} />
              ))}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h2>Inactive Chat Role Labels</h2>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              width="100vw"
              bgcolor="white"
              paddingTop="12px"
              paddingBottom="12px"
            >
              {Roles.careTeamRoles.map((role, idx) => (
                <ChatLabel key={role + idx} active={false} role={role} />
              ))}
            </Box>
          </Box>
          <Box>
            <h2>Group Message Labels</h2>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              width="100vw"
              bgcolor="white"
              paddingTop="12px"
              paddingBottom="12px"
            >
              <NewBadge />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h1>Avatars</h1>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              width="100vw"
              paddingTop="12px"
              paddingBottom="12px"
            >
              <Box>
                <Avatar />
                No source provided image provided
              </Box>
              <Box>
                <Avatar source="https://cdn.crickethealth.com/1/strapi/uploads/thumbnail_Danuta_small_414b35c805.png" />
                With Source image
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h1>Chevrons</h1>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              width="100vw"
              paddingTop="12px"
              paddingBottom="12px"
            >
              <Box>
                <Typography variant="h2">
                  <Box>
                    <HorizontalChevronSlider>
                      <HorizontalChevron pointsLeft />
                    </HorizontalChevronSlider>
                    Horizontal Chevron with pointsLeft and hovered set to false
                  </Box>
                  <Box>
                    <HorizontalChevronSlider hovered={true} slideDistance={-20}>
                      <HorizontalChevron pointsLeft />
                    </HorizontalChevronSlider>
                    Horizontal Chevron with pointsLeft and Hovered set to true
                  </Box>
                  <Box
                    onMouseEnter={() => setPreviousHover(true)}
                    onMouseLeave={() => setPreviousHover(false)}
                  >
                    <HorizontalChevronSlider hovered={previousHover} slideDistance={-20}>
                      <HorizontalChevron pointsLeft />
                    </HorizontalChevronSlider>
                    Horizontal Chevron with pointsLeft and hovered set off State
                  </Box>
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography variant="h2">
                  <Box>
                    Horizontal Chevron without pointsLeft and hovered set to false
                    <HorizontalChevronSlider>
                      <HorizontalChevron />
                    </HorizontalChevronSlider>
                  </Box>
                  <Box>
                    Horizontal Chevron without pointsLeft and hovered set to true
                    <HorizontalChevronSlider hovered={true} slideDistance={20}>
                      <HorizontalChevron />
                    </HorizontalChevronSlider>
                  </Box>
                  <Box
                    onMouseEnter={() => setNextHover(true)}
                    onMouseLeave={() => setNextHover(false)}
                  >
                    <HorizontalChevronSlider hovered={nextHover} slideDistance={20}>
                      Horizontal Chevron without pointsLeft and hovered set by state
                      <HorizontalChevron />
                    </HorizontalChevronSlider>
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                component={'span'}
                variant="h1"
                onClick={() => setOpenChevron(!openChevron)}
              >
                <VerticalChevron open={openChevron} />
              </Typography>
              <Typography onClick={() => setOpenChevron(!openChevron)} variant="h2">
                VerticalChevron with open animation
              </Typography>
              <Typography component={'span'} variant="h1">
                <VerticalChevron open={true} />
              </Typography>
              <Typography variant="h2">VerticalChevron with open set to true</Typography>
              <Typography component={'span'} variant="h1">
                <VerticalChevron open={false} />
              </Typography>
              <Typography variant="h2">VerticalChevron with open set to false</Typography>
            </Box>
          </Box>
          <ModuleDetailContext.Provider value={{ selectedModuleId, setSelectedModuleId }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
              width="100vw"
              paddingTop="12px"
              paddingBottom="12px"
            >
              <Box width="30vw">
                <h1>FAQ Item 1 (avatar, no image)</h1>
                <FaqItem faq={faq} />
              </Box>
              <Box width="30vw">
                <h1>FAQ Item 2 (no avatar, image)</h1>
                <FaqItem faq={faq2} />
              </Box>
            </Box>
          </ModuleDetailContext.Provider>
          <Box display="flex" flexDirection="column" alignItems="center">
            <h1>Learn Module Navigator</h1>
            <Box width="30vw">
              <ModuleDetailContext.Provider value={{ selectedModuleId, setSelectedModuleId }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <LearnModuleNavigator
                    isBackToCourses
                    moduleName={'Previous Module Name'}
                    moduleId={1}
                  />
                  <LearnModuleNavigator moduleName={'Next Module Name'} moduleId={1} />
                </Box>
              </ModuleDetailContext.Provider>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          paddingTop="12px"
          paddingBottom="12px"
        >
          <h1> Me Page Components</h1>
          <Box m={3}>
            <BackNav history={useHistory()} link={paths.homepage()} />
          </Box>
          <Box m={3}>
            <DropdownSelect handlechange={() => {}} options={['hello', 'goodbye']} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          paddingTop="12px"
          paddingBottom="12px"
        >
          <h1>Typography</h1>

          <Box m={3}>
            <Typography variant="h1">H1 (32px 800)</Typography>
          </Box>

          <Box m={3}>
            <Typography variant="h2">H2 (24px 800)</Typography>
          </Box>

          <Box m={3}>
            <Typography variant="h3" style={{ fontWeight: 'bold' }}>
              H3 + Bold (16px 700)
            </Typography>
            <Typography variant="h3" style={{ fontWeight: '400' }}>
              H3 + 400 (16px 400)
            </Typography>
            <Typography variant="h3">H2 (16px 400)</Typography>
          </Box>
          <Box m={3}>
            <Typography variant="subtitle1">Subtitle1 (18px 800)</Typography>
          </Box>
          <Box m={3}>
            <Typography variant="body1">Body1 (18px 500)</Typography>
          </Box>
          <Box m={3}>
            <Typography variant="body2">Body2 (18px 400)</Typography>
          </Box>
          <Box m={3}>
            <Typography variant="h4">h4 (14px 400)</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="120px"
          paddingTop="12px"
          paddingBottom="12px"
        >
          <MyCricketLoadingContainer loading={true} />
          <Box mt={3}>
            <Typography variant="subtitle1">I can do this all day...</Typography>
          </Box>
        </Box>
      </>
    </MyCricketLoadingContainer>
  );
};

export default UiInventory;
