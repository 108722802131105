import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paths } from '../../utils/routing/routes';
import { useHistory } from 'react-router';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { mapGroupTypeNamesToGroupIds } from '../../utils/chatUtils';
import GroupDiscussionContainer from '../organisms/GroupDiscussionContainer';
import { MyCricketLoadingContainer } from '../atoms/MyCricketLoadingIndicator';

export const GroupDiscussionContext = React.createContext({
  newGroupMessagesArray: [],
  setNewGroupMessagesArray: () => {},
});
GroupDiscussionContext.displayName = 'GroupDiscussionContext';

export const useGroupDiscussionContext = () => React.useContext(GroupDiscussionContext);

const GroupPage = () => {
  const { groupName, messageId } = useParams();
  const history = useHistory();
  const { directMessageGroups, loading } = useChirpContext();
  const [discussionGroupIdsMap, setDiscussionGroupIdsMap] = useState(null);
  const [newGroupMessagesArray, setNewGroupMessagesArray] = useState([]);
  const [currentlySelectedGroup, setCurrentlySelectedGroup] = useState(null);
  const [currentlySelectedMessageId, setCurrentlySelectedMessageId] = useState(null);

  useEffect(() => {
    const _discussionGroupIdsMap = mapGroupTypeNamesToGroupIds(directMessageGroups);
    setDiscussionGroupIdsMap(_discussionGroupIdsMap);
  }, [directMessageGroups]);

  useEffect(() => {
    const groupId = discussionGroupIdsMap?.[groupName] || null;
    setCurrentlySelectedGroup(groupId);
  }, [groupName, discussionGroupIdsMap]);

  useEffect(() => {
    const messageIdNumber = parseInt(messageId) || null;
    setCurrentlySelectedMessageId(messageIdNumber);
  }, [messageId]);

  const returnToGroupsList = () => {
    history.push(paths.groupsList());
  };

  return (
    <MyCricketLoadingContainer loading={loading || !currentlySelectedGroup}>
      {loading || !currentlySelectedGroup ? null : (
        <GroupDiscussionContext.Provider
          value={{ newGroupMessagesArray, setNewGroupMessagesArray }}
        >
          <GroupDiscussionContainer
            group={directMessageGroups[currentlySelectedGroup]}
            selectedMessageId={currentlySelectedMessageId}
            returnToGroupsList={returnToGroupsList}
          />
        </GroupDiscussionContext.Provider>
      )}
    </MyCricketLoadingContainer>
  );
};

export default GroupPage;
