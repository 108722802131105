import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';
import { directMessageGroupPropType } from '../../utils/patientPropTypes';
import PostCompositionArea from './PostCompositionArea';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useTranslation } from 'react-i18next';
import GroupPost from '../molecules/GroupPost';
import { HorizontalChevron, HorizontalChevronSlider } from '../atoms/Chevrons';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { DiscussionGroupDisplayNames, metaTexts } from '../../data/groupTypeNames';
import { useGroupDiscussionContext } from '../pages/GroupPage';
import { RoundButton, SegmentedButton } from '../atoms/Buttons';
import { ArrowDownward, Autorenew, Close } from '@mui/icons-material';
import { xorBy } from 'lodash';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import MyCricketColors from '../../data/MyCricketColors';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { PatientEvent } from '../../data/Events';
import { toMessageURI } from '../../utils/URIUtils';

const GroupDiscussionContainer = ({ group, selectedMessageId, returnToGroupsList }) => {
  const { getGroupMessages, readAllMessagesForGroup } = useChirpContext();
  const { setNewGroupMessagesArray } = useGroupDiscussionContext();
  const { logEvent } = useClientEventLoggerContext();

  const list = useRef(null);
  const { userId, userName } = usePatientContext();
  const { cricketGroupId, groupName } = group;
  const [originalPosts, setOriginalPosts] = useState([]);
  const [shouldShowViewNewMessagesButton, setShouldShowViewNewMessagesButton] = useState(false);
  const [lastScrolledToIndex, setLastScrolledToIndex] = useState(0);
  const [whereWasIIndex, setWhereWasIIndex] = useState(0);
  const [firstShownReplyForPostMap, setFirstShownReplyForPostMap] = useState({});

  const getOriginalPosts = (messages) => {
    return messages.filter((msg) => msg.inReplyTo === null);
  };

  const updateMessages = useCallback(() => {
    // Data side effects
    const _messages = getGroupMessages(cricketGroupId).reverse();
    const _unreadMessageIds = _messages.length
      ? _messages.reduce((ids, msg) => {
          if (!msg.isRead && msg.id) {
            ids.push(msg.id);
          }
          return ids;
        }, [])
      : [];
    if (_unreadMessageIds.length > 0) {
      setNewGroupMessagesArray(_unreadMessageIds);
      readAllMessagesForGroup(cricketGroupId);
    }

    const freshOriginalPosts = getOriginalPosts(_messages);
    setOriginalPosts(freshOriginalPosts);

    // Computes the first reply that should be *not* collapsed in a group post.
    // Could be either nothing (no collapsing), the most recent one, or the first unread one.
    const firstShownReplyForPost = {};
    for (const originalPost of freshOriginalPosts) {
      const replyIds = originalPost.replies;
      if (replyIds.length === 1) {
        continue;
      }
      const firstShownMessageId = replyIds.find(
        (replyId, index) => _unreadMessageIds.includes(replyId) || index === replyIds.length - 1,
      );
      if (firstShownMessageId) {
        firstShownReplyForPost[originalPost.id] = firstShownMessageId;
      }
    }
    setFirstShownReplyForPostMap(firstShownReplyForPost);

    // UI side effects
    if (shouldShowViewNewMessagesButton) {
      setWhereWasIIndex(lastScrolledToIndex);
      setShouldShowViewNewMessagesButton(false);
      list.current && list.current.scrollToIndex(0);
    }

    if (selectedMessageId) {
      let originalPostIndex = null;
      let originalPostId = null;
      let isReply = false;
      for (let index = 0; index < freshOriginalPosts.length; index++) {
        const originalPost = freshOriginalPosts[index];
        if (originalPost.id === selectedMessageId) {
          originalPostIndex = index;
          originalPostId = originalPost.id;
          break;
        }
        if (originalPost.replies.includes(selectedMessageId)) {
          originalPostIndex = index;
          originalPostId = originalPost.id;
          isReply = true;
          break;
        }
      }
      if (originalPostIndex != null) {
        if (isReply) {
          // Expand the replies if going to a reply.
          setFirstShownReplyForPostMap((prev) => ({ ...prev, [originalPostId]: undefined }));
        }
        // Go to index + 1 to account for the post composition area.
        list.current?.scrollToIndex({ index: originalPostIndex + 1, align: 'start' });
      }
    }
  }, [
    getGroupMessages,
    cricketGroupId,
    shouldShowViewNewMessagesButton,
    selectedMessageId,
    setNewGroupMessagesArray,
    readAllMessagesForGroup,
    lastScrolledToIndex,
  ]);

  useEffect(() => {
    // Handles initial load
    // if getGroupMessages returns something, but we havent set anything to originalPosts were doing an initial load
    if (originalPosts.length === 0 && getGroupMessages(cricketGroupId).length > 0) {
      updateMessages();
    }
  }, [originalPosts, getGroupMessages, updateMessages, cricketGroupId]);

  useEffect(() => {
    if (originalPosts.length > 0) {
      const freshOriginalPosts = getOriginalPosts(getGroupMessages(cricketGroupId));
      const difference = xorBy(freshOriginalPosts, originalPosts, 'messageUuid');
      if (difference.length > 0) {
        // If any of the new messages are from the logged in user, or if the update was removing a message just updateMessages.
        if (
          difference.filter((msg) => msg.senderId === userId).length > 0 ||
          freshOriginalPosts.length < originalPosts.length
        ) {
          updateMessages();
        } else {
          // If none of the new messages are from the logged in user, show the button and let them decide when to update.
          setShouldShowViewNewMessagesButton(true);
        }
      }
    }
  }, [originalPosts, userId, getGroupMessages, cricketGroupId, updateMessages]);

  const Row = ({ index }) => {
    // minus 1 to account for header
    const message = originalPosts[index - 1];
    const messageId = message ? message.id : null;
    useEffect(() => {
      setLastScrolledToIndex(index);
    }, [index]);

    return (
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={'800px'} px={2}>
          {index === 0 ? (
            <DiscussionHeader
              handleViewAllGroupsClick={returnToGroupsList}
              groupName={groupName}
              cricketGroupId={cricketGroupId}
              userName={userName}
            />
          ) : message != null ? (
            <Box pb={2}>
              <GroupPost
                key={messageId}
                message={message}
                groupName={groupName}
                firstShownReplyId={firstShownReplyForPostMap[messageId]}
                expandMessages={(numberOfReplies) => {
                  setFirstShownReplyForPostMap((prev) => ({ ...prev, [messageId]: undefined }));
                  logEvent({
                    predicate: PatientEvent.EXPAND_REPLIES,
                    object: toMessageURI(messageId),
                    prepositions: {
                      numReplies: numberOfReplies,
                    },
                  });
                }}
              />
            </Box>
          ) : (
            <Box />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      component={'main'}
      display="flex"
      flexDirection="column"
      margin="auto"
      width={'100%'}
      height={'100%'}
    >
      {shouldShowViewNewMessagesButton && (
        <Box
          position={'absolute'}
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
          mt={1.5}
          px={4}
          zIndex={2}
        >
          <RoundButton
            style={{ backgroundColor: MyCricketColors.lake }}
            onClick={() => updateMessages()}
          >
            <Box display="flex" flexDirection="row">
              <Autorenew />
              <TranslatedTypography>Load New Posts</TranslatedTypography>
            </Box>
          </RoundButton>
        </Box>
      )}
      {whereWasIIndex > 0 && (
        <Box
          position={'absolute'}
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
          mt={1.5}
          px={4}
          zIndex={3}
        >
          <SegmentedButton
            segments={[
              {
                props: {
                  startIcon: <ArrowDownward />,
                  onClick: () => {
                    list?.current?.scrollToIndex({
                      index: whereWasIIndex + 1,
                      align: 'center',
                      behavior: 'smooth',
                    });
                    setWhereWasIIndex(0);
                  },
                },
                children: <TranslatedTypography>Where was I?</TranslatedTypography>,
              },
              {
                props: {
                  onClick: () => setWhereWasIIndex(0),
                },
                icon: <Close />,
              },
            ]}
          />
        </Box>
      )}
      <Virtuoso
        // initialItemCount is used when performing SSR (such as in tests).
        // Because the data model does not initially populate directMessageGroups
        // we delay rendering until it is populated so that Virtuoso will render
        // the expected initialItemCount as opposed to 0, which it would otherwise
        // be on first render.
        initialItemCount={originalPosts.length < 3 ? originalPosts.length + 1 : 3}
        totalCount={originalPosts.length + 1}
        itemContent={(index) => <Row index={index} />}
        ref={list}
        overscan={5}
      />
    </Box>
  );
};

const DiscussionHeader = ({ handleViewAllGroupsClick, groupName, cricketGroupId, userName }) => {
  const [leftArrowIsHovered, setLeftArrowIsHovered] = useState(false);
  const { t } = useTranslation();
  return (
    <Box pt={4}>
      <Box
        onClick={handleViewAllGroupsClick}
        display="flex"
        style={{ cursor: 'pointer' }}
        data-test-id="viewAllGroupsButton"
        onMouseEnter={() => setLeftArrowIsHovered(true)}
        onMouseLeave={() => setLeftArrowIsHovered(false)}
      >
        <HorizontalChevronSlider slideDistance={-9} hovered={leftArrowIsHovered}>
          <HorizontalChevron pointsLeft styles={{ height: '30px', width: '30px' }} />
        </HorizontalChevronSlider>
        <TranslatedTypography variant="subtitle1">View All Groups</TranslatedTypography>
      </Box>
      <TranslatedTypography variant="h1">
        {
          //look up the display name for the group type
          DiscussionGroupDisplayNames[groupName] || metaTexts.default.title
        }
      </TranslatedTypography>
      <Typography variant="body2">
        {t(metaTexts[groupName]?.description || metaTexts.default.description)}
        {!userName && t('groupsFirstTimePostDescription')}
      </Typography>
      <PostCompositionArea selectedGroupId={cricketGroupId} userName={userName} />
      <Box pt={1} mb={2}>
        <TranslatedTypography variant="subtitle1" aria-level="2">
          Posts
        </TranslatedTypography>
      </Box>
    </Box>
  );
};

GroupDiscussionContainer.propTypes = {
  group: directMessageGroupPropType.isRequired,
};

export default GroupDiscussionContainer;
