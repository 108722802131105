// Creates event data for the different event types in the structure expected
// by the ws handlers.
import URI from '../URI';
import CookieHelper from '../CookieHelper';

const predicates = Object.freeze({
  SEND_MESSAGE: 'cricket:client:event:predicate:chirp:sendMessage',
  READ_GROUP_MESSAGES: 'cricket:client:event:predicate:chirp:readGroupMessages',
  HIDE_MESSAGE: 'cricket:client:event:predicate:chirp:hideMessage',
  READ_MESSAGE: 'cricket:client:event:predicate:chirp:readMessage',
  LIKE_MESSAGE: 'cricket:client:event:predicate:chirp:likeMessage',
  UNLIKE_MESSAGE: 'cricket:client:event:predicate:chirp:unlikeMessage',
  UNREAD_MESSAGE: 'cricket:client:event:predicate:chirp:unreadMessage',
  SELECT_GROUP: 'cricket:client:event:predicate:selectGroup',
  ANSWERED_SURVEY_QUESTION: 'cricket:client:event:predicate:answeredSurveyQuestion',
  OPEN_PROFILE: 'cricket:client:event:predicate:chirp:openProfile',
  EXPAND_MESSAGE: 'cricket:client:event:predicate:expandMessage',
  EXPAND_REPLIES: 'cricket:client:event:predicate:expandReplies',
  SET_HIDE_FROM_PRO: 'cricket:client:event:predicate:chirp:setHideFromPro',
  HIDE_NOTE: 'cricket:client:event:predicate:chirp:hideNote',
  USERNAME_CHANGED: 'cricket:client:event:predicate:chirp:usernameChanged',
});

const cricketSessionId = CookieHelper.getRawCookie('cricketSessionId');
const cricketDeviceId = CookieHelper.getRawCookie('cricketDeviceId');

export default {
  readGroupMessages: (groupId, groupType, unreadPosts, unreadReplies) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.READ_GROUP_MESSAGES,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: groupType,
        newPostsRead: unreadPosts,
        newRepliesRead: unreadReplies,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:group',
      URI: URI.toGroupURI(groupId),
    },
  }),

  hideMessage: (message) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.HIDE_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  hideNote: (message, reason) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.HIDE_NOTE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        reason,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  readMessage: (message) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.READ_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  unreadMessage: (message) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.UNREAD_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  likeMessage: (message) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.LIKE_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  unlikeMessage: (message) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.UNLIKE_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        groupType: message.groupType,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(message.id),
    },
  }),

  openPublicProfile: (profileUserId) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.OPEN_PROFILE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        userAgent: navigator.userAgent,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:user',
      URI: URI.toUserURI(profileUserId),
    },
  }),
  sendMessage: ({
    text = undefined,
    groupId,
    groupType,
    inReplyTo,
    components,
    richTextJSON = undefined,
    file = undefined,
    requestId,
    childEventPrepositions,
  }) => ({
    _type: 'cricket:client:event:chirp',
    requestId,
    predicate: {
      _type: predicates.SEND_MESSAGE,
      prepositions: {
        clientTimestamp: Date.now().toString(),
        appName: _cc.appName,
        message: {
          text,
          richTextJSON,
          file,
          components,
        },
        groupType,
        inReplyTo: inReplyTo,
        userAgent: navigator.userAgent,
        cricketSessionId,
        cricketDeviceId,
        childEventPrepositions,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:group',
      URI: URI.toGroupURI(groupId),
    },
  }),
  setHideFromPro: (noteId, hideFromPro) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.SET_HIDE_FROM_PRO,
      prepositions: {
        noteId,
        hideFromPro,
        cricketSessionId,
        cricketDeviceId,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:message',
      URI: URI.toMessageURI(noteId),
    },
  }),
  fetch: (field, ids) => ({
    _type: 'cricket:client:chirp:fetch',
    [field]: ids,
  }),
  changeUsername: (cricketUserId, username) => ({
    _type: 'cricket:client:event:chirp',
    predicate: {
      _type: predicates.USERNAME_CHANGED,
      prepositions: {
        username,
      },
    },
    object: {
      _type: 'cricket:client:event:noun:chirp:user',
      URI: URI.toUserURI(cricketUserId),
    },
  }),
};
