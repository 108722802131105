import React from 'react';
import classNames from 'classnames';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Input from './Input';

const ConfirmPassword = ({
  classes,
  confirmPassword,
  confirmPasswordErr,
  confirmPasswordValid,
  handleConfirmPWChange,
  handleConfirmPWFocus,
  inputStyles,
  password,
  setShowConfirmPassword,
  showConfirmPassword,
  showConfirmPWRules,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div className={classes.label}>{t('Confirm New Password')}</div>
      </div>
      <div
        className={classNames(classes.formInput, classes.passwordContainer, {
          [inputStyles.root]: !confirmPasswordErr,
          [classes.passwordError]: confirmPasswordErr,
        })}
      >
        <Input
          className={classes.passwordInputOverrides}
          autoComplete="new-password"
          name="confirm-password"
          onChange={handleConfirmPWChange}
          onFocus={handleConfirmPWFocus}
          placeholder={t('Password')}
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          data-test-id="confirm-password"
        />
        <button
          type="button"
          className={classes.showHidePassword}
          name="showHide"
          onFocus={handleConfirmPWFocus}
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        >
          {showConfirmPassword ? t('HIDE') : t('SHOW')}
        </button>
      </div>

      <Collapse in={showConfirmPWRules} className={classes.passwordRulesContainer}>
        <ul className={classes.ul}>
          <li
            data-test-id={`password-error-confirm-password`}
            className={classNames(classes.li, {
              [classes.liGreenCheck]: confirmPasswordValid(password, confirmPassword),
              [classes.liGrayCircle]:
                !confirmPasswordValid(password, confirmPassword) && !confirmPasswordErr,
              [classes.liRedX]:
                !confirmPasswordValid(password, confirmPassword) && confirmPasswordErr,
            })}
          >
            <span
              className={classNames(classes.liText, {
                [classes.liGrayCircleText]:
                  !confirmPasswordValid(password, confirmPassword) && !confirmPasswordErr,
              })}
            >
              {t('Passwords match')}
            </span>
          </li>
        </ul>
      </Collapse>
    </>
  );
};

export default ConfirmPassword;
