import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { Button } from '@mui/material';
import { BaseButton } from '../atoms/Buttons';
import { HorizontalChevronSlider, HorizontalChevron } from '../atoms/Chevrons';
import CareTeamMessageBubble from '../atoms/CareTeamMessageBubble';
import { baseGenerators } from '../../utils/contexts/ChirpContext.mocks';
import { mungeMessageObject, useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { ScrollBox } from '../atoms/ScrollBox';
import DividerLine from '../atoms/HorizontalDivider';
import ModuleCard from '../molecules/ModuleCard';
import { CareTeamContext } from './CareTeam';
import CareTeamMessagesWindow from '../molecules/CareTeamMessagesWindow';
import LegalDocs from '../molecules/LegalDocs';
import CareTeamMessageFrame from '../organisms/CareTeamMessageFrame';

const Intro = (props) => {
  return (
    <Box>
      <Typography
        variant={'h2'}
      >{`Hello! Welcome to an interactive walkthrough of Atomic Design.`}</Typography>
      <Typography
        variant={'body1'}
      >{`This playground is meant to help explain how Atoms, Molecules, Organisms and Pages all come together to form MyCricket`}</Typography>
    </Box>
  );
};
const Motivations = (props) => {
  return (
    <Box>
      <Typography variant={'h2'}>{`Motivations`}</Typography>
      <Typography
        variant={'body1'}
      >{`Atomic design really has 3 main motivations behind it (and they all kinda play into each other)`}</Typography>
      <Typography
        variant={'body2'}
      >{`1. Encourages a strong seperation of logic from display (atoms generally are focused on display, organisms and pages almost entirely on logic, molecules are somewhere in between)`}</Typography>
      <Typography
        variant={'body2'}
      >{`2. Makes the code easier to reuse. Even if components are not 100% drop in ready for any environment, this pattern will at least help you to be more knowledgeable about how resueable something is likely to be`}</Typography>
      <Typography
        variant={'body2'}
      >{`3. Increased readability/decreased cognative load. Got a display related bug? You can 99% of the time focus your debugging ont he specific atom, got a loading bug? You can safely assume its in an organism or page.`}</Typography>
    </Box>
  );
};

const SubatomicParticles = (props) => {
  return (
    <Box>
      <Typography variant={'h2'}>{`Subatomic Particles`}</Typography>
      <Typography
        variant={'body1'}
      >{`Before we get into all those fancy atoms, let's discuss the more fundamental subatomic particles. In ourcase this mostly means Material UI`}</Typography>
      <DividerLine />

      <Box pt={5}>
        <Button>{`I'm a raw Material UI Button!`}</Button>
        <Button
          variant="contained"
          color={'primary'}
        >{`I'm a raw Material UI Button! with a simple prop`}</Button>
      </Box>
      <Box pt={5}>
        <Typography
          variant={'body1'}
        >{`We won't cover these too much here other than to say, they are all aorund MyCricket (Box being by far the most common), and you should always look to see if they can help you solve your problems`}</Typography>
      </Box>
    </Box>
  );
};

const Atoms = (props) => {
  const [chevronHover, setChevronHover] = useState(false);
  const { userId } = usePatientContext();
  const fakeMsg = mungeMessageObject(baseGenerators.message({ senderId: userId }));
  const fakeMsg2 = mungeMessageObject(baseGenerators.message({ senderId: userId + 2 }));
  return (
    <Box>
      <Typography variant={'h2'}>{`Atoms`}</Typography>
      <Typography
        variant={'body1'}
      >{`Atoms can be a lot of different things, but in general they are best thought of as fundamental building blocks of MyCricket, or the simplest parts of a UI`}</Typography>
      <DividerLine />

      <Box pt={5}>
        <Typography
          variant={'body1'}
        >{`Sometimes they are just styled subatomic particles like so`}</Typography>
        <BaseButton>{'Im a MyCricket base button!'}</BaseButton>
      </Box>
      <Box pt={5}>
        <Box onMouseEnter={() => setChevronHover(true)} onMouseLeave={() => setChevronHover(false)}>
          <HorizontalChevronSlider hovered={chevronHover} slideDistance={20}>
            Sometimes they combine a few to do something cool based on state
            <HorizontalChevron />
          </HorizontalChevronSlider>
        </Box>
      </Box>
      <ScrollBox height={'75px'}>
        <Typography>
          {`And sometimes they are invisible, but let us do things very easily, like make this box scrollable!`}
        </Typography>
        <br />
        <Typography>
          {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor nulla, pharetra non iaculis a, molestie sed felis. Sed sit amet nibh feugiat risus posuere aliquet. Suspendisse ac quam vel nibh vestibulum finibus ac vehicula mauris. Donec lobortis, orci ac vulputate auctor, massa enim pretium nisl, id tincidunt tellus nisl at magna. Integer leo ipsum, sodales eu fringilla sit amet, commodo sit amet erat. Maecenas eget neque ac dolor iaculis imperdiet. Quisque quis feugiat velit. Nullam iaculis tortor et quam aliquam efficitur. In nisi nisi, malesuada eget sem ut, molestie mattis nibh.

Mauris molestie orci sit amet magna laoreet vestibulum. Curabitur at ante tortor. Aenean orci est, vestibulum in eleifend sodales, luctus in justo. Praesent viverra ante in est dapibus interdum quis eu magna. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec sed pharetra elit, in congue justo. Donec lorem orci, posuere sed lorem maximus, dapibus viverra augue. Aenean sagittis magna nunc, quis efficitur orci consequat faucibus. Mauris nec odio eget nisi dignissim auctor. Cras sed leo bibendum nunc bibendum vulputate. Praesent malesuada consectetur eleifend. Suspendisse hendrerit, ipsum sit amet condimentum hendrerit, libero arcu luctus felis, ac suscipit felis mauris eget augue. Nullam quam nulla, consequat et feugiat fringilla, ultricies nec nisi.`}
        </Typography>
      </ScrollBox>
      <DividerLine />
      <Box pt={5}>
        <Typography>
          {
            'Usually they are very reuseable, but not always universally so (These CareTeamMessageBubbles really need a ChirpContextProvider to work right, luckily we have one globally 🤓)'
          }
        </Typography>
        <CareTeamMessageBubble message={fakeMsg} isOtherPerson={false} />
        <CareTeamMessageBubble message={fakeMsg2} isOtherPerson={true} />
      </Box>
    </Box>
  );
};
const Molecules = (props) => {
  const [chevronHover, setChevronHover] = useState(false);
  const { directMessageGroups } = useChirpContext();
  const groupWithMessages = Object.values(directMessageGroups).filter(
    (group) => group.messages.length > 0,
  )[0];
  const aGroupId = groupWithMessages
    ? groupWithMessages.cricketGroupId
    : Object.values(directMessageGroups)[0].cricketGroupId;
  return (
    <Box>
      <Typography variant={'h2'}>{`Molecules`}</Typography>
      <Typography
        variant={'body1'}
      >{`Molecules are where we start getting complicated. Just like in physics molecules represent a new emergent structure from a unique combination of atoms`}</Typography>
      <Typography
        variant={'body2'}
      >{`That 👆 is just a lot of words to say, molecules are made up of several to many atoms, and as a result have much more specialized functionality than that of atoms (at least overall)`}</Typography>
      <DividerLine />

      <Box pt={5}>
        <Typography
          variant={'body1'}
        >{`Molecules will tend to be less reuseable than atoms, and may require some considerations or refactoring to be reused (especially in a very different context). Clicking this for example will take you to a broken page, because the moduleId I set doesn't exist.`}</Typography>
      </Box>
      <Box pt={5}>
        <Box onMouseEnter={() => setChevronHover(true)} onMouseLeave={() => setChevronHover(false)}>
          <ModuleCard
            moduleName={`I'm a molecule that contains an atom we looked at`}
            moduleId={'34'}
            hovered={chevronHover}
          />
        </Box>
      </Box>

      <DividerLine />
      <Box pt={5}>
        <CareTeamContext.Provider value={{ selectedGroupId: aGroupId }}>
          <Typography>
            {
              'These are less reuseable than atoms, for example this component takes no props, and relies on the CareTeamContext, ChirpContext and PatientContext to function. (This is just pulling your first group with messages btw)'
            }
          </Typography>
          <Box
            borderRadius={'0 12px 12px 0'}
            mr={0}
            id="careTeamMessageFrame"
            display={'flex'}
            height={'700px'}
            flex={1}
            maxWidth="100%"
            flexDirection="column"
            alignItems="flex-start"
          >
            <CareTeamMessagesWindow />
          </Box>
        </CareTeamContext.Provider>
      </Box>
      <Box pt={5}>
        <Typography>
          {
            'Sometimes a molecule will contain no defined atoms (like this one) Thats ok! It just means we dont currently think there is any benefit (either of reuability or readability) in splitting it up more.'
          }
        </Typography>
        <LegalDocs />
        <Typography>
          {
            'Futher sometimes a molecule will contain self defined "atoms" as well, this is a way to take advantage of the readability benefits even when they resuability is not applicable.'
          }
        </Typography>
      </Box>
    </Box>
  );
};
const Organisms = (props) => {
  const { directMessageGroups, getUserProfile } = useChirpContext();
  const groupWithMessages = Object.values(directMessageGroups).filter(
    (group) => group.messages.length > 0,
  )[0];
  const aGroup = groupWithMessages ? groupWithMessages : Object.values(directMessageGroups)[0];
  const aGroupId = aGroup.cricketGroupId;

  const selectedCareTeamMember = getUserProfile(aGroup.members[0]);
  return (
    <Box>
      <Typography variant={'h2'}>{`Organisms`}</Typography>
      <Typography
        variant={'body1'}
      >{`Organisms are multiple molecules stitched together. They gnerally perform 2 functions, data and display orchestration. That is to say they are responsible for getting data to the molecules and controlling UI state of multiple molecules on screen at once.`}</Typography>
      <Typography
        variant={'body2'}
      >{`Unfortunately because they tend to be rather large, they are difficult to show off here.`}</Typography>
      <DividerLine />

      <Box pt={5}>
        <CareTeamContext.Provider
          value={{ selectedGroupId: aGroupId, showBio: false, selectedCareTeamMember }}
        >
          <CareTeamMessageFrame />
        </CareTeamContext.Provider>
      </Box>
      <Box pt={5}>
        <Typography>
          {
            'Generally there is one organism per major UI area (so chat window, sidebar and bio in the case of the care team). You should generally expect not to be able to easily drop an organism in to a different screen, they will almost all rely on many preexisting conditions'
          }
        </Typography>
      </Box>
    </Box>
  );
};
const Pages = (props) => {
  const [jokeOver, setJokeOver] = useState(false);
  return (
    <Box>
      <Box onClick={() => setJokeOver(true)}>
        <Typography variant={'h2'}>{`Pages`}</Typography>
        <br />
        <br />
        <Typography variant={'body2'}>{`THIS PAGE INTENTIONALLY LEFT BLANK...`}</Typography>
        <DividerLine />
      </Box>
      {jokeOver && (
        <Box>
          <Box pt={5}>
            <Typography>
              {
                'JK but actually pages arent easy to include in these examples like the rest. Also this entire AtomicIntro is a page 😉'
              }
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const Chapter = (props) => {
  const { title, setSlideFn, selectedSlide } = props;
  return (
    <Box bgcolor={selectedSlide === title ? 'green' : null} onClick={() => setSlideFn(title)} p={2}>
      <Typography>{title}</Typography>
    </Box>
  );
};

const AtomicIntro = (props) => {
  const [slide, setSlide] = useState('Intro');
  return (
    <Box
      minWidth={'100%'}
      minHeight={'100%'}
      display="flex"
      flexDirection="row"
      justifyContent={'center'}
    >
      <Box minWidth={'10%'} height={'100%'} px={3}>
        <Chapter title={'Intro'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Motivations'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Subatomic Particles'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Basic Atoms'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Molecules'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Organisms'} selectedSlide={slide} setSlideFn={setSlide} />
        <Chapter title={'Pages'} selectedSlide={slide} setSlideFn={setSlide} />
        <DividerLine />
      </Box>
      <ScrollBox
        width={'90%'}
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems={'center'}
        justifyContent={'center'}
        py={10}
      >
        {slide === 'Intro' && <Intro />}
        {slide === 'Motivations' && <Motivations />}
        {slide === 'Subatomic Particles' && <SubatomicParticles />}
        {slide === 'Basic Atoms' && <Atoms />}
        {slide === 'Molecules' && <Molecules />}
        {slide === 'Organisms' && <Organisms />}
        {slide === 'Pages' && <Pages />}
      </ScrollBox>
    </Box>
  );
};

export default AtomicIntro;
