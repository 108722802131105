import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AutoLogout from '../../utils/AutoLogout';
import { renewToken, getTTL } from '../../utils/AuthUtils';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import Toast from '../atoms/Toast';

export const AutoLogoutComponent = () => {
  const { clearLocalUserInfo } = usePatientContext();
  const [started, setStarted] = useState(false);
  const { t } = useTranslation();

  const stopAutoLogout = useCallback(() => {
    setStarted(false);
    AutoLogout.stop();
  }, []);
  const cleanHouse = () => {
    stopAutoLogout();
    clearLocalUserInfo();
  };
  const warn = useCallback(() => {
    toast.warn(
      () => (
        <Toast
          icon={'warn'}
          message={t('Your session will expire soon')}
          buttonConfig={{
            title: t('Renew Session'),
            action: renewToken(cleanHouse),
          }}
        />
      ),
      {
        toastId: 'auto-logout',
        className: 'cricket-toast-long',
        autoClose: false,
      },
    );
  }, [stopAutoLogout]);

  const createAutoLogout = useCallback(() => {
    setStarted(true);
    AutoLogout({
      getTTL: getTTL(cleanHouse),
      logout: () => {
        cleanHouse();
      },
      onWarn: warn,
      renewToken: renewToken(cleanHouse),
    });
  }, [warn, stopAutoLogout, clearLocalUserInfo]);

  useEffect(() => {
    if (!started) {
      createAutoLogout();
    }
    // TODO This file is due for a rewrite in PE-741
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAutoLogout]);
  return null;
};
