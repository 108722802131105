const LoginError = {
  UNAUTHORIZED: 'The email and password combination you have entered is incorrect.',
  GENERAL: 'There was an error logging in, please try again or contact support.',
  NEED_PASSWORD: 'Please enter a password before submitting.',
  NEED_EMAIL: 'Please enter an email before submitting.',
  RESET_PASSWORD:
    'There was an error resetting your password. Please try again or email support@crickethealth.com',
  INVALID_EMAIL: 'Please enter a valid email address',
};

export default LoginError;
