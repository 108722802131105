let __mainTimeout;
let __stopped = true;
let __activeWarningIAT = 0;

module.exports = {
  start: (config) => {
    __stopped = false;

    const {
      getTTL, // a function returning relative age, TTL and warnTTL for current token
      getLastActive, // should return time(ms) elapsed since last activity
      onExpire, // will be called if jwt is expired
      onNewActivity, // will be called if onExpire is NOT called, and there is new activity since token was issued, unless there is an active warning against this token
      onWarn, // will be called if onExpire && onNewActivity are NOT called, and warnTTL is in the past
      checkInterval, // how often to check the jwt (seconds)
    } = config;

    const startTimeout = (nextCheck) => {
      clearTimeout(__mainTimeout);
      __mainTimeout = setTimeout(main, nextCheck);
    };

    const main = () => {
      startTimeout(checkInterval * 1000);
      const t_init = Date.now();
      try {
        const result = getTTL();
        if (__stopped) {
          return;
        }
        const t_response = Date.now();
        const latency = (t_response - t_init) / 1000;
        const iat = result.iat;
        const age = result.age + latency;
        const TTL = result.TTL - latency;
        const warnTTL = result.warnTTL - latency;
        const lastActive = getLastActive() / 1000;
        if (TTL <= 0) {
          //the token is expired
          //set up next check based on checkInterval (it probably won't be called since onExpire usually logs out)
          startTimeout(checkInterval * 1000);
          return onExpire();
        }
        if (lastActive < age && iat !== __activeWarningIAT) {
          //there has been activity since the token was issued
          //and there is no active warning on this token
          const nextCheck = Math.min(checkInterval, warnTTL > 0 ? warnTTL : Infinity, TTL);
          startTimeout(nextCheck * 1000);
          return onNewActivity();
        }
        if (warnTTL <= 0) {
          //we are in the warning period and there has been no new activity since issue
          //set up check interval based on checkInterval or token expiration -- whichever comes first
          const nextCheck = Math.min(checkInterval, TTL);
          startTimeout(nextCheck * 1000);
          __activeWarningIAT = iat;
          return onWarn();
        }
        const nextCheck = Math.min(checkInterval, warnTTL, TTL);
        return startTimeout(nextCheck * 1000);
      } catch (error) {
        console.error('Error fetching ttl:', error);
      }
    };
    main();
  },
  stop: () => {
    __stopped = true;
    __activeWarningIAT = 0;
    clearTimeout(__mainTimeout);
  },
};
