import { Box } from '@mui/material';
import React from 'react';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';
import MyCricketColors from '../../data/MyCricketColors';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const CareTeamCloseButton = ({ title, onClick, ariaLabel }) => {
  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      flexDirection={'row-reverse'}
      width="100%"
      pt={3}
      pr={5}
      pb={4}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      role="button"
      aria-label={ariaLabel}
    >
      <VscClose />
      <Box color={MyCricketColors.tealGray} pr={2}>
        <TranslatedTypography variant={'subtitle1'} role="none">
          {title}
        </TranslatedTypography>
      </Box>
    </Box>
  );
};

export default CareTeamCloseButton;
