import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from '../../utils/innerHtmlUtils';
import PatientErrorBoundary from '../../utils/PatientErrorBoundary';
import { MyCricketLoadingIndicator } from '../atoms/MyCricketLoadingIndicator';

/**
 * Simulates the space that the TopNav component would use.
 * @returns {JSX.Element}
 */
const Spacer = () => <Box height="64px" />;

const LoadingPage = ({ title, children = null }) => {
  // smartVh and smartVw are a hooks based take on -> https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const { width, height } = useWindowSize();
  const [smartVh, setSmartVh] = useState(height / 100);
  const [smartVw, setSmartVw] = useState(width / 100);

  useEffect(() => {
    document.title = title ? `${title} | MyCricket` : 'MyCricket';
  }, [title]);

  useEffect(() => {
    if (+width !== smartVw * 100) {
      setSmartVw(width / 100);
    }
    if (+height !== smartVh * 100) {
      setSmartVh(height / 100);
    }
  }, [width, height, smartVw, smartVh]);
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={`${100 * smartVw}px`}
      height={`${100 * smartVh}px`}
      paddingTop={`env(safe-area-inset-top)`}
      paddingLeft={`env(safe-area-inset-left)`}
      paddingRight={`env(safe-area-inset-right)`}
    >
      <PatientErrorBoundary>
        <Spacer />
        <MyCricketLoadingIndicator />
        {/* We want to render children here in case children contains code
            we want to run while something is loading (i.e. magic link login completion) */}
        {children}
      </PatientErrorBoundary>
    </Box>
  );
};

export default LoadingPage;
