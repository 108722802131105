import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { usePatientContext } from './contexts/PatientContextProvider';
import { useModalContext } from './contexts/ModalContext';
import { paths } from './routing/routes';
import { SurveyId } from '../data/SurveyData';
import Roles from '../data/Roles';
import useQuery from './hooks/useQueryHook';
import { ProviderMatchCoachMarkId } from '../data/CoachMarks';
import { AgreementsDialog } from '../components/organisms/AgreementsDialog';

const arePathsEqual = (path1, path2) => {
  const trimmedPath1 = path1.endsWith('/') ? path1.slice(0, -1) : path1;
  const trimmedPath2 = path2.endsWith('/') ? path2.slice(0, -1) : path2;
  return trimmedPath1 === trimmedPath2;
};

const InterruptManager = ({ children }) => {
  const currentPath = window.location.pathname;
  const query = useQuery();
  const {
    pcLoading,
    surveyCompletionStatus,
    menuCompletionStatus,
    coachMarkHistory,
    featureFlags,
    userRole,
  } = usePatientContext();

  const shouldCompleteSurvey =
    !pcLoading &&
    surveyCompletionStatus &&
    surveyCompletionStatus[SurveyId.ENROLLMENT] &&
    surveyCompletionStatus[SurveyId.ENROLLMENT].isNeeded &&
    !surveyCompletionStatus[SurveyId.ENROLLMENT].isComplete;

  const shouldCompleteMenu =
    featureFlags?.['my-cricket-menu'] &&
    !pcLoading &&
    menuCompletionStatus &&
    menuCompletionStatus.isNeeded &&
    !menuCompletionStatus.isComplete;

  const shouldShowMatchingScreen =
    userRole &&
    Roles.isPatientRole(userRole) &&
    !shouldCompleteSurvey &&
    coachMarkHistory &&
    !coachMarkHistory[ProviderMatchCoachMarkId];

  if (shouldCompleteSurvey) {
    if (!arePathsEqual(currentPath, paths.enrollmentSurvey())) {
      // Direct to enrollment survey if needed.
      // Clear the saved state for the survey so we go back to the start.
      localStorage.removeItem(`survey-${SurveyId.ENROLLMENT}`);
      return <Redirect to={{ pathname: paths.enrollmentSurvey(), search: query.toString() }} />;
    }
  } else if (shouldCompleteMenu) {
    if (!arePathsEqual(currentPath, paths.patientMenu())) {
      return <Redirect to={{ pathname: paths.patientMenu(), search: query.toString() }} />;
    }
  } else if (shouldShowMatchingScreen) {
    if (!arePathsEqual(currentPath, paths.matching())) {
      return <Redirect to={{ pathname: paths.matching(), search: query.toString() }} />;
    }
  }

  return <AgreementsInterruptHandler>{children}</AgreementsInterruptHandler>;
};

const AgreementsInterruptHandler = ({ children }) => {
  const { pcLoading, missingAgreementIds } = usePatientContext();
  const { showModal } = useModalContext();

  useEffect(() => {
    if (!pcLoading && missingAgreementIds && missingAgreementIds.length > 0) {
      showModal(() => <AgreementsDialog />, { noEscape: true });
    }
  }, [pcLoading, missingAgreementIds]);

  return children;
};

export default InterruptManager;
