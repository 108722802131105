//This is all based on live dev data for this user
// pro link for this pt: https://pro.dev.crickethealth.com:3009/patients/206/snapshot?externalId=49f75bf3-fc4b-4f6f-9631-60e19d4b4bd6
import { Roles } from '../../data/Roles';
import {
  CareTeamGroups,
  MultipartyGroupTypes,
  DiscussionGroupNames,
} from '../../data/groupTypeNames';
import { v4 } from 'uuid';
import _ from 'lodash';

const generateRandomId = () => {
  // plus 50 to give us some wiggle room for 100% guaranteed conflict free IDs
  return Math.floor(Math.random() * 10000) + 500;
};

// Generators
export const generateMockUser = ({ userId = generateRandomId(), name = 'Johnny', role }) => {
  if (!role) {
    console.error(`YOU MUST INDICATE A ROLE, I SHAN'T PICK FOR YOU!`);
    console.error(userId, name, role);
    return;
  }
  const data = {
    avatar: '/img/avatars/avatar-blue-1.svg',
    avatarURI: '/img/avatars/avatar-blue-1.svg',
    externalId: v4(),
    id: userId,
    isEMRLinkAuthorizedProvider: 0,
    isOutOfOffice: 0,
    name: name,
    privateGroups: '70',
    publicProfile: null,
    role: role,
  };
  return data;
};
export const generateMockMessage = ({
  msgText = 'Something fancy',
  senderId,
  inReplyToMessageId = null,
  likes = 0,
  likedBySelf = false,
  group = newMockedGroupsArray[0],
  read = false,
  id = generateRandomId(),
  deliveryDate = '2021-08-16T13:50:00.000Z',
}) => {
  if (!senderId) {
    console.error(`YOU MUST INDICATE A SENDER, I SHAN'T PICK FOR YOU!`);
  }
  const data = {
    URI: `chirp://message/${id}`,
    components: [],
    deliveryDate,
    groupId: group.id,
    groupType: group.type,
    id: id,
    inReplyToMessageId: inReplyToMessageId,
    liked: likedBySelf,
    likes: likes + (likedBySelf ? 1 : 0),
    messageId: id,
    msgText: msgText,
    // Currently we just ignore this and leave it blank. Mostly becasue we only need it from the other direction
    replies: [],
    status: read ? 'READ' : 'UNREAD',
    timestamp: new Date(deliveryDate).getTime(),
    userId_sender: senderId,
    uuid: v4(),
    // mungeMessageObject fields
    senderId,
    likedByUser: likedBySelf,
  };
  return data;
};

export const generateMockGroup = ({
  groupName = CareTeamGroups.NURSE_PT,
  groupType = CareTeamGroups.NURSE_PT,
  memberIdsArr = [mockedNurse.id, mockedPatient.id],
  messageIdsArr = [],
  id = generateRandomId(),
}) => {
  const theGroup = {
    URI: `chirp://group/${id}`,
    creationDate: '2019-06-25T23:44:55.000Z',
    cricketGroupId: id,
    groupSubject: null,
    id: id,
    members: memberIdsArr,
    messages: messageIdsArr,
    name: groupName,
    organizationId: 3,
    type: groupType,
    // mungeGroupsResponse fields
    groupName,
  };
  return theGroup;
};

// Mocks that we always have
export const mockedSocialWorker = generateMockUser({ role: Roles.SOCIAL_WORKER });
export const mockedNurse = generateMockUser({ role: Roles.NURSE });
export const mockedPharmacist = generateMockUser({ role: Roles.PHARMACIST });
export const mockedPharmacyNavigator = generateMockUser({ role: Roles.PHARMACY_NAVIGATOR });
export const mockedDietitian = generateMockUser({ role: Roles.DIETITIAN });
export const mockedPatient = generateMockUser({ role: Roles.PATIENT });
export const mockedMentor = generateMockUser({ role: Roles.MENTOR });

export const newMockedGroupsArray = [
  generateMockGroup({
    groupName: CareTeamGroups.NURSE_PT,
    groupType: CareTeamGroups.NURSE_PT,
    memberIdsArr: [mockedNurse.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: CareTeamGroups.MENTOR_PT,
    groupType: CareTeamGroups.MENTOR_PT,
    memberIdsArr: [mockedMentor.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: CareTeamGroups.RD_PT,
    groupType: CareTeamGroups.RD_PT,
    memberIdsArr: [mockedDietitian.id, mockedPatient.id],
  }),
];
export const mockedMultipartyGroupsArray = [
  generateMockGroup({
    groupName: DiscussionGroupNames.DIET,
    groupType: MultipartyGroupTypes.EXERCISE,
    memberIdsArr: [mockedDietitian.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: DiscussionGroupNames.GENERAL,
    groupType: MultipartyGroupTypes.EXERCISE,
    memberIdsArr: [mockedNurse.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: DiscussionGroupNames.TRANSPLANTS,
    groupType: MultipartyGroupTypes.EXERCISE,
    memberIdsArr: [mockedDietitian.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: DiscussionGroupNames.ACCESS,
    groupType: MultipartyGroupTypes.EXERCISE,
    memberIdsArr: [mockedDietitian.id, mockedPatient.id],
  }),
  generateMockGroup({
    groupName: DiscussionGroupNames.MEDICATION_LABS,
    groupType: MultipartyGroupTypes.EXERCISE,
    memberIdsArr: [mockedDietitian.id, mockedPatient.id],
  }),
];

// Very annoyingly Users and Messages use ID, where as groups use cricketGroupId so we need 2 functions to transform the arrays to JSON like objects
export const transformGroupsArrayToResponse = (dataArray) => {
  return dataArray.reduce(function (result, group) {
    result[group.cricketGroupId] = result[group.cricketGroupId] || group;
    return result;
  }, {});
};
export const generateMockResponse = (dataArray) => {
  return dataArray.reduce(function (result, group) {
    result[group.id] = result[group.id] || group;
    return result;
  }, {});
};
export const mockedGroupsResponse = transformGroupsArrayToResponse(newMockedGroupsArray);
export const mockedMultipartyGroupsResponse = transformGroupsArrayToResponse(
  mockedMultipartyGroupsArray,
);
export const mockedUsersResponse = generateMockResponse([
  mockedNurse,
  mockedPatient,
  mockedDietitian,
  mockedMentor,
  mockedPharmacist,
  mockedPharmacyNavigator,
  mockedSocialWorker,
]);
export const mockedMessagesResponse = generateMockResponse([
  generateMockMessage({ senderId: mockedNurse.id, group: newMockedGroupsArray[0], read: false }),
  generateMockMessage({ senderId: mockedNurse.id, group: newMockedGroupsArray[0], read: false }),
  generateMockMessage({ senderId: mockedDietitian.id, group: newMockedGroupsArray[1] }),
  generateMockMessage({ senderId: mockedDietitian.id, group: newMockedGroupsArray[1] }),
  generateMockMessage({ senderId: mockedDietitian.id, group: newMockedGroupsArray[1] }),
]);
export const mockedMultipartyMessagesResponse = generateMockResponse([
  generateMockMessage({
    senderId: mockedNurse.id,
    group: mockedMultipartyGroupsArray[0],
    read: false,
  }),
  generateMockMessage({
    senderId: mockedNurse.id,
    group: mockedMultipartyGroupsArray[0],
    read: false,
  }),
  generateMockMessage({ senderId: mockedDietitian.id, group: mockedMultipartyGroupsArray[1] }),
  generateMockMessage({ senderId: mockedDietitian.id, group: mockedMultipartyGroupsArray[1] }),
  generateMockMessage({ senderId: mockedDietitian.id, group: mockedMultipartyGroupsArray[1] }),
]);
export const aLotOfMessages = (numMessages = 200, group = mockedMultipartyGroupsArray[1]) => {
  const senders = group.members;

  return _.times(numMessages, () =>
    generateMockMessage({
      senderId: senders[Math.floor(Math.random() * senders.length)],
      group: group,
    }),
  );
};

// TODO: as a final cleanup step, consider removing the other exports and only having these 2
export const baseMocks = {
  users: mockedUsersResponse,
  messages: mockedMessagesResponse,
  groups: mockedGroupsResponse,
  multipartyGroups: mockedMultipartyGroupsResponse,
  multipartyMessages: mockedMultipartyMessagesResponse,
};

export const baseGenerators = {
  user: generateMockUser,
  message: generateMockMessage,
  group: generateMockGroup,
};
