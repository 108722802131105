import React from 'react';

const CricketLogo = () => (
  <svg width="164" height="44" viewBox="0 0 164 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.986 43.9999C18.5092 43.9977 15.0824 43.1715 11.9866 41.589C8.89074 40.0065 6.21407 37.7127 4.17601 34.8959C2.13795 32.079 0.796551 28.8192 0.261785 25.3837C-0.272981 21.9482 0.0141178 18.4349 1.09954 15.1319C2.18497 11.8288 4.03781 8.83 6.50615 6.38138C8.97449 3.93276 11.988 2.10403 15.2997 1.04513C18.6114 -0.0137678 22.1269 -0.27268 25.5579 0.289623C28.989 0.851925 32.238 2.21943 35.0384 4.28001C36.5896 5.42497 37.6224 7.13925 37.9097 9.04572C38.1969 10.9522 37.7151 12.8947 36.5701 14.4459C35.4251 15.9971 33.7109 17.0299 31.8044 17.3172C29.8979 17.6044 27.9554 17.1226 26.4042 15.9776C25.1255 15.0328 23.576 14.5257 21.986 14.5318C20.0324 14.5733 18.1728 15.3785 16.8058 16.7749C15.4388 18.1712 14.6732 20.0475 14.6732 22.0016C14.6732 23.9557 15.4388 25.832 16.8058 27.2284C18.1728 28.6247 20.0324 29.4299 21.986 29.4714C23.5772 29.4754 25.1274 28.9673 26.4076 28.0223C27.9588 26.8778 29.9011 26.3963 31.8073 26.6839C33.7134 26.9715 35.4273 28.0045 36.5718 29.5557C37.7163 31.1069 38.1977 33.0492 37.9102 34.9553C37.6226 36.8615 36.5896 38.5753 35.0384 39.7199C31.2619 42.5146 26.6842 44.0157 21.986 43.9999Z"
      fill="#A4D7E4"
    />
    <path
      d="M44.1378 21.8552V21.8249C44.1239 21.0544 44.2661 20.289 44.5559 19.5749C44.8458 18.8608 45.2772 18.2128 45.8241 17.67C46.3711 17.1271 47.0223 16.7006 47.7386 16.4161C48.4548 16.1317 49.2212 15.9952 49.9916 16.0148C51.3363 15.9592 52.6601 16.362 53.7459 17.1573C53.8994 17.2703 54.0247 17.4174 54.1118 17.587C54.1989 17.7565 54.2456 17.944 54.248 18.1346C54.2476 18.2939 54.2156 18.4516 54.1539 18.5984C54.0923 18.7453 54.0021 18.8785 53.8887 18.9904C53.7753 19.1022 53.6408 19.1905 53.4931 19.2501C53.3454 19.3098 53.1873 19.3395 53.0281 19.3378C52.7629 19.335 52.5051 19.2503 52.29 19.0951C51.6378 18.5781 50.8306 18.2956 49.9983 18.293C48.1044 18.293 46.7395 19.8669 46.7395 21.7912V21.8249C46.7395 23.7492 48.0707 25.3534 49.9983 25.3534C50.8921 25.3724 51.7609 25.0576 52.4349 24.4704C52.5484 24.3643 52.6823 24.2823 52.8285 24.2296C52.9746 24.1768 53.13 24.1544 53.2851 24.1635C53.4402 24.1727 53.5918 24.2133 53.7307 24.2829C53.8696 24.3525 53.9929 24.4496 54.0932 24.5684C54.1934 24.6871 54.2684 24.825 54.3136 24.9736C54.3589 25.1223 54.3734 25.2786 54.3564 25.433C54.3394 25.5874 54.2911 25.7368 54.2145 25.872C54.138 26.0072 54.0347 26.1254 53.911 26.2195C52.9 27.1024 51.7137 27.6315 49.9175 27.6315C49.1546 27.6466 48.3965 27.5076 47.6887 27.2227C46.9809 26.9378 46.3378 26.5129 45.7981 25.9736C45.2585 25.4342 44.8332 24.7914 44.5479 24.0837C44.2626 23.3761 44.1231 22.6181 44.1378 21.8552Z"
      fill="#003E51"
    />
    <path
      d="M56.7012 19.9622C56.7012 19.6386 56.8297 19.3283 57.0585 19.0995C57.2873 18.8707 57.5976 18.7422 57.9211 18.7422C58.2447 18.7422 58.555 18.8707 58.7838 19.0995C59.0126 19.3283 59.1411 19.6386 59.1411 19.9622V20.3497C59.4781 19.5139 60.0713 18.7456 60.7925 18.7456C61.0847 18.7397 61.3686 18.8428 61.589 19.0348C61.8093 19.2269 61.9502 19.4941 61.9843 19.7844C62.0184 20.0746 61.9432 20.3672 61.7733 20.605C61.6034 20.8429 61.3511 21.009 61.0655 21.0709C59.8286 21.3607 59.1411 22.3077 59.1411 24.04V26.3181C59.1411 26.6417 59.0126 26.952 58.7838 27.1808C58.555 27.4096 58.2447 27.5381 57.9211 27.5381C57.5976 27.5381 57.2873 27.4096 57.0585 27.1808C56.8297 26.952 56.7012 26.6417 56.7012 26.3181V19.9622Z"
      fill="#003E51"
    />
    <path
      d="M63.8555 16.7875C63.8555 16.0832 64.4486 15.6484 65.2035 15.6484C65.9584 15.6484 66.5515 16.0832 66.5515 16.7875V16.8179C66.5515 17.5256 65.9584 17.9738 65.2035 17.9738C64.4486 17.9738 63.8555 17.5256 63.8555 16.8179V16.7875ZM63.997 19.9622C63.997 19.6386 64.1255 19.3283 64.3543 19.0995C64.5831 18.8707 64.8934 18.7422 65.217 18.7422C65.5405 18.7422 65.8509 18.8707 66.0796 19.0995C66.3084 19.3283 66.437 19.6386 66.437 19.9622V26.3181C66.437 26.6417 66.3084 26.952 66.0796 27.1808C65.8509 27.4096 65.5405 27.5381 65.217 27.5381C64.8934 27.5381 64.5831 27.4096 64.3543 27.1808C64.1255 26.952 63.997 26.6417 63.997 26.3181V19.9622Z"
      fill="#003E51"
    />
    <path
      d="M68.7965 23.1853V23.155C68.7882 22.5624 68.8994 21.9742 69.1232 21.4255C69.3471 20.8768 69.6792 20.3787 70.0997 19.9611C70.5202 19.5435 71.0204 19.2147 71.5707 18.9946C72.1209 18.7744 72.7098 18.6672 73.3023 18.6795C74.366 18.6248 75.4121 18.9671 76.2377 19.64C76.36 19.742 76.4582 19.8699 76.5252 20.0144C76.5922 20.1589 76.6264 20.3165 76.6252 20.4758C76.6248 20.6206 76.5957 20.7639 76.5397 20.8975C76.4836 21.0311 76.4017 21.1522 76.2987 21.254C76.1956 21.3558 76.0735 21.4362 75.9392 21.4907C75.805 21.5451 75.6613 21.5724 75.5165 21.571C75.2613 21.5704 75.0136 21.485 74.8121 21.3284C74.3859 20.9702 73.8455 20.7765 73.2888 20.7824C72.0689 20.7824 71.2027 21.8575 71.2027 23.1247V23.1584C71.2027 24.4727 72.052 25.5174 73.3832 25.5174C73.9695 25.5223 74.5382 25.318 74.9874 24.9411C75.1827 24.7973 75.4188 24.7194 75.6614 24.7187C75.8713 24.7192 76.0761 24.7837 76.2484 24.9037C76.4206 25.0236 76.5522 25.1933 76.6255 25.39C76.6987 25.5868 76.7102 25.8012 76.6584 26.0046C76.6066 26.208 76.4939 26.3908 76.3354 26.5284C75.9276 26.9037 75.4498 27.1947 74.9293 27.385C74.4089 27.5753 73.856 27.661 73.3023 27.6372C72.7106 27.6541 72.1215 27.551 71.5708 27.3339C71.02 27.1169 70.5189 26.7905 70.0978 26.3744C69.6767 25.9583 69.3443 25.4612 69.1206 24.9131C68.897 24.365 68.7867 23.7772 68.7965 23.1853Z"
      fill="#003E51"
    />
    <path
      d="M78.8493 16.8544C78.8406 16.689 78.8657 16.5236 78.923 16.3682C78.9803 16.2128 79.0686 16.0707 79.1826 15.9506C79.2966 15.8305 79.4338 15.7348 79.586 15.6694C79.7381 15.604 79.902 15.5703 80.0676 15.5703C80.2332 15.5703 80.3971 15.604 80.5493 15.6694C80.7014 15.7348 80.8387 15.8305 80.9526 15.9506C81.0666 16.0707 81.155 16.2128 81.2123 16.3682C81.2696 16.5236 81.2946 16.689 81.2859 16.8544V22.1791L84.319 19.2943C84.4499 19.1338 84.6128 19.0024 84.7973 18.9084C84.9818 18.8144 85.1839 18.7599 85.3907 18.7484C85.5334 18.747 85.6749 18.7743 85.8069 18.8287C85.9388 18.8831 86.0585 18.9634 86.1588 19.0649C86.259 19.1665 86.3379 19.2871 86.3906 19.4198C86.4434 19.5524 86.4689 19.6942 86.4657 19.8369C86.4551 20.0417 86.4016 20.242 86.3088 20.4249C86.2159 20.6078 86.0857 20.7691 85.9265 20.8985L84.0022 22.5498L86.2939 25.6469C86.482 25.8664 86.5901 26.1433 86.6005 26.4322C86.6006 26.5802 86.5709 26.7268 86.5133 26.8631C86.4556 26.9995 86.3712 27.1229 86.265 27.226C86.1587 27.3291 86.0328 27.4098 85.8948 27.4633C85.7568 27.5168 85.6094 27.5421 85.4614 27.5376C85.2495 27.5484 85.0386 27.5007 84.8519 27.3997C84.6653 27.2986 84.51 27.1481 84.4032 26.9646L82.2666 24.0293L81.2893 24.9089V26.3075C81.2729 26.6195 81.1374 26.9133 80.9107 27.1284C80.684 27.3435 80.3835 27.4634 80.071 27.4634C79.7585 27.4634 79.458 27.3435 79.2313 27.1284C79.0046 26.9133 78.8691 26.6195 78.8527 26.3075L78.8493 16.8544Z"
      fill="#003E51"
    />
    <path
      d="M92.839 27.6374C90.2575 27.6374 88.3467 25.8243 88.3467 23.1922V23.1552C88.3467 20.7017 90.0957 18.6797 92.5997 18.6797C95.4677 18.6797 96.7382 21.0387 96.7382 22.8182C96.7461 22.9712 96.7223 23.1243 96.6683 23.2678C96.6143 23.4113 96.5312 23.542 96.4242 23.6518C96.3172 23.7616 96.1887 23.8481 96.0467 23.9059C95.9047 23.9637 95.7523 23.9915 95.5991 23.9876H90.7698C90.8454 24.4802 91.103 24.9266 91.4918 25.2385C91.8805 25.5505 92.372 25.7054 92.8693 25.6726C93.4947 25.6781 94.1041 25.4754 94.6015 25.0963C94.7716 24.9642 94.9828 24.8962 95.1981 24.9042C95.3242 24.9019 95.4495 24.9252 95.5664 24.9728C95.6832 25.0204 95.7892 25.0913 95.8777 25.1812C95.9663 25.271 96.0357 25.378 96.0817 25.4955C96.1276 25.613 96.1492 25.7386 96.145 25.8647C96.1446 26.0022 96.1142 26.1379 96.056 26.2624C95.9978 26.387 95.9132 26.4974 95.808 26.5859C94.9802 27.2876 93.9239 27.6617 92.839 27.6374ZM94.4095 22.471C94.2679 21.3791 93.6242 20.6444 92.5997 20.6444C91.5752 20.6444 90.9147 21.3656 90.7361 22.471H94.4095Z"
      fill="#003E51"
    />
    <path
      d="M98.8339 24.9985V20.9241H98.7564C98.6144 20.9322 98.4723 20.9112 98.3387 20.8624C98.2052 20.8136 98.083 20.7381 97.9796 20.6405C97.8763 20.5428 97.7939 20.4251 97.7376 20.2945C97.6814 20.1639 97.6523 20.0232 97.6523 19.881C97.6523 19.7389 97.6814 19.5982 97.7376 19.4676C97.7939 19.337 97.8763 19.2193 97.9796 19.1216C98.083 19.024 98.2052 18.9485 98.3387 18.8997C98.4723 18.8509 98.6144 18.8299 98.7564 18.838H98.8339V17.7629C98.8339 17.4394 98.9624 17.1291 99.1912 16.9003C99.42 16.6715 99.7303 16.543 100.054 16.543C100.377 16.543 100.688 16.6715 100.917 16.9003C101.145 17.1291 101.274 17.4394 101.274 17.7629V18.838H102.349C102.615 18.8531 102.866 18.9696 103.049 19.1636C103.232 19.3575 103.334 19.6142 103.334 19.881C103.334 20.1479 103.232 20.4046 103.049 20.5985C102.866 20.7924 102.615 20.9089 102.349 20.9241H101.274V24.6042C101.274 25.167 101.513 25.44 102.059 25.44C102.14 25.44 102.302 25.44 102.349 25.44C102.617 25.44 102.874 25.5465 103.064 25.7361C103.253 25.9257 103.36 26.1829 103.36 26.451C103.356 26.6477 103.294 26.8389 103.183 27.0012C103.071 27.1634 102.915 27.2896 102.733 27.3643C102.289 27.5364 101.814 27.6188 101.338 27.607C99.8449 27.5834 98.8339 26.9869 98.8339 24.9985Z"
      fill="#003E51"
    />
    <path
      d="M110.974 16.7703C110.974 16.6023 111.04 16.4411 111.159 16.3223C111.278 16.2035 111.439 16.1367 111.607 16.1367C111.775 16.1367 111.936 16.2035 112.055 16.3223C112.174 16.4411 112.241 16.6023 112.241 16.7703V21.1986H118.721V16.7703C118.721 16.6023 118.788 16.4411 118.907 16.3223C119.026 16.2035 119.187 16.1367 119.355 16.1367C119.523 16.1367 119.684 16.2035 119.803 16.3223C119.922 16.4411 119.989 16.6023 119.989 16.7703V26.8806C119.989 27.0486 119.922 27.2097 119.803 27.3286C119.684 27.4474 119.523 27.5141 119.355 27.5141C119.187 27.5141 119.026 27.4474 118.907 27.3286C118.788 27.2097 118.721 27.0486 118.721 26.8806V22.3849H112.241V26.8772C112.241 27.0452 112.174 27.2064 112.055 27.3252C111.936 27.444 111.775 27.5108 111.607 27.5108C111.439 27.5108 111.278 27.444 111.159 27.3252C111.04 27.2064 110.974 27.0452 110.974 26.8772V16.7703Z"
      fill="#003E51"
    />
    <path
      d="M126.741 27.6372C124.463 27.6372 122.6 25.8881 122.6 23.3234V23.2897C122.6 20.9003 124.285 18.9727 126.58 18.9727C129.033 18.9727 130.432 20.9779 130.432 23.1785C130.431 23.3317 130.369 23.4783 130.261 23.5862C130.152 23.6942 130.005 23.7548 129.852 23.7548H123.853C124.029 25.5511 125.296 26.5621 126.772 26.5621C127.664 26.57 128.524 26.2233 129.161 25.5983C129.254 25.5144 129.373 25.4646 129.498 25.4567C129.608 25.4529 129.716 25.4835 129.807 25.5442C129.899 25.6049 129.969 25.6926 130.008 25.7952C130.047 25.8977 130.053 26.0099 130.025 26.116C129.997 26.2221 129.937 26.3168 129.852 26.3869C129.446 26.7966 128.961 27.1189 128.426 27.3339C127.891 27.549 127.318 27.6522 126.741 27.6372ZM129.195 22.8415C129.067 21.3317 128.201 20.0174 126.546 20.0174C125.104 20.0174 124.012 21.2205 123.85 22.8415H129.195Z"
      fill="#003E51"
    />
    <path
      d="M132.144 25.0321V24.9984C132.144 23.2493 133.586 22.3023 135.689 22.3023C136.551 22.289 137.411 22.4025 138.24 22.6393V22.3495C138.24 20.8599 137.327 20.0882 135.77 20.0882C135.028 20.0873 134.295 20.2459 133.62 20.5532C133.549 20.5839 133.474 20.6011 133.397 20.6038C133.255 20.603 133.119 20.5471 133.017 20.4477C132.915 20.3484 132.856 20.2135 132.851 20.0713C132.852 19.9625 132.884 19.8562 132.944 19.7653C133.004 19.6744 133.089 19.6027 133.188 19.5591C134.037 19.1814 134.956 18.9895 135.884 18.9963C137.054 18.9963 137.954 19.3029 138.58 19.9129C139.207 20.5229 139.43 21.261 139.43 22.336V26.9092C139.432 26.9885 139.419 27.0675 139.39 27.1414C139.362 27.2154 139.318 27.2828 139.263 27.3395C139.207 27.3962 139.141 27.441 139.068 27.4714C138.995 27.5017 138.916 27.5168 138.837 27.5159C138.759 27.5196 138.682 27.5073 138.609 27.4799C138.537 27.4525 138.471 27.4104 138.415 27.3564C138.359 27.3024 138.315 27.2375 138.286 27.1658C138.256 27.0941 138.242 27.0171 138.243 26.9396V26.1847C137.893 26.6444 137.439 27.0139 136.917 27.2625C136.395 27.511 135.822 27.6314 135.244 27.6136C133.701 27.6136 132.144 26.7306 132.144 25.0321ZM138.257 24.3884V23.5931C137.464 23.3658 136.642 23.2524 135.817 23.2561C134.263 23.2561 133.397 23.9301 133.397 24.9714V25.0018C133.397 26.0465 134.358 26.6565 135.48 26.6565C137.007 26.6261 138.257 25.7061 138.257 24.3884Z"
      fill="#003E51"
    />
    <path
      d="M142.652 16.2749C142.652 16.1114 142.717 15.9545 142.833 15.8388C142.949 15.7232 143.106 15.6582 143.269 15.6582C143.433 15.6582 143.59 15.7232 143.705 15.8388C143.821 15.9545 143.886 16.1114 143.886 16.2749V26.8941C143.889 26.9757 143.876 27.0571 143.846 27.1333C143.817 27.2095 143.772 27.2789 143.715 27.3373C143.658 27.3956 143.59 27.4417 143.514 27.4727C143.439 27.5037 143.358 27.519 143.276 27.5175C143.194 27.5194 143.112 27.5045 143.035 27.4738C142.959 27.4431 142.889 27.3973 142.831 27.339C142.773 27.2808 142.727 27.2113 142.696 27.1348C142.666 27.0583 142.651 26.9764 142.652 26.8941V16.2749Z"
      fill="#003E51"
    />
    <path
      d="M147.99 25.2419V20.2239H147.316C147.173 20.2188 147.038 20.1595 146.937 20.0582C146.836 19.9568 146.778 19.8208 146.773 19.6779C146.774 19.534 146.832 19.3961 146.933 19.294C147.035 19.1919 147.172 19.1337 147.316 19.132H147.99V17.1908C147.988 17.1083 148.002 17.0261 148.032 16.9493C148.063 16.8725 148.108 16.8026 148.166 16.7437C148.224 16.6849 148.293 16.6383 148.369 16.6069C148.446 16.5754 148.528 16.5596 148.61 16.5606C148.774 16.5666 148.93 16.6354 149.044 16.7526C149.159 16.8699 149.224 17.0267 149.227 17.1908V19.1454H151.377C151.523 19.1463 151.663 19.2036 151.767 19.3055C151.871 19.4073 151.932 19.5457 151.936 19.6914C151.93 19.8353 151.869 19.9713 151.764 20.0706C151.66 20.1699 151.521 20.2249 151.377 20.2239H149.227V25.0802C149.227 26.0912 149.79 26.4585 150.622 26.4585C150.877 26.4488 151.13 26.4115 151.377 26.3473C151.517 26.3482 151.651 26.4042 151.75 26.5033C151.849 26.6023 151.905 26.7364 151.906 26.8764C151.906 26.9815 151.873 27.084 151.813 27.1701C151.753 27.2563 151.668 27.3219 151.569 27.3584C151.182 27.515 150.767 27.5918 150.349 27.5841C149.001 27.5841 147.99 26.9236 147.99 25.2419Z"
      fill="#003E51"
    />
    <path
      d="M154.838 16.2732C154.831 16.1882 154.843 16.1027 154.871 16.0222C154.899 15.9418 154.944 15.868 155.002 15.8055C155.06 15.743 155.13 15.6932 155.208 15.6591C155.286 15.625 155.371 15.6074 155.456 15.6074C155.541 15.6074 155.626 15.625 155.704 15.6591C155.782 15.6932 155.852 15.743 155.91 15.8055C155.968 15.868 156.013 15.9418 156.041 16.0222C156.069 16.1027 156.081 16.1882 156.074 16.2732V20.5869C156.353 20.0826 156.766 19.6647 157.266 19.3788C157.767 19.0929 158.336 18.9501 158.912 18.9659C160.917 18.9659 162.09 20.3139 162.09 22.2888V26.8924C162.096 26.9774 162.085 27.0628 162.057 27.1433C162.029 27.2238 161.984 27.2976 161.926 27.36C161.868 27.4225 161.798 27.4724 161.72 27.5065C161.641 27.5405 161.557 27.5581 161.472 27.5581C161.386 27.5581 161.302 27.5405 161.224 27.5065C161.146 27.4724 161.075 27.4225 161.017 27.36C160.959 27.2976 160.915 27.2238 160.887 27.1433C160.858 27.0628 160.847 26.9774 160.853 26.8924V22.582C160.853 21.0419 160.021 20.0814 158.562 20.0814C157.102 20.0814 156.074 21.1228 156.074 22.6797V26.8924C156.078 26.9739 156.064 27.0553 156.035 27.1316C156.006 27.2078 155.961 27.2772 155.904 27.3356C155.847 27.3939 155.778 27.44 155.703 27.471C155.627 27.502 155.546 27.5173 155.464 27.5158C155.382 27.5176 155.3 27.5028 155.223 27.4721C155.147 27.4415 155.077 27.3957 155.018 27.3375C154.96 27.2793 154.914 27.2098 154.883 27.1334C154.852 27.0569 154.836 26.9749 154.838 26.8924V16.2732Z"
      fill="#003E51"
    />
  </svg>
);

export default CricketLogo;
