import { Box, Typography } from '@mui/material';
import React from 'react';
import MyCricketColors from '../../data/MyCricketColors';

export const CareTeamBioProfessionalTitle = ({ bioTitle }) => {
  return (
    <Box pl={3} pr={4} textAlign="center" pt={1} color={MyCricketColors.mediumGray}>
      <Typography variant={'body2'}>{bioTitle}</Typography>
    </Box>
  );
};
export default CareTeamBioProfessionalTitle;
