import { Box, Typography } from '@mui/material';
import React from 'react';

export const CareTeamBioTeamMemberName = ({ nickName, degrees }) => {
  const appendDegrees = () => {
    return degrees ? `, ${degrees}` : '';
  };
  return (
    <Box pt={2} pl={3} pr={4} textAlign="center">
      <Typography variant={'h2'} aria-level={'1'}>{`${nickName} ${appendDegrees()}`}</Typography>
    </Box>
  );
};
export default CareTeamBioTeamMemberName;
