import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

// TODO This should not be reused for both <select> and <input> as they style very differently
// Also, investigate <select> on Firefox, it is very different from Chrome
const styles = (theme) => ({
  root: {
    'border': `1px solid ${theme.palette.blueWhite}`,
    'borderRadius': '3px',
    'backgroundColor': theme.palette.white,
    'boxShadow': 'none',
    // Remove top shadow from iOS Safari (box-shadow: none isn't good enough)
    '&[type=text],&[type=password]': {
      appearance: 'none',
    },
    'fontSize': '19px',
    'minWidth': '0', // For Firefox
    'width': '100%',
    'height': '43px',
    'padding': '0 11px',
    'outline': 'none',
    '&:focus': {
      borderColor: theme.palette.blue,
    },
  },
  invalid: {
    'borderColor': theme.palette.red,
    'backgroundColor': theme.palette.backgroundRed,
    '&:focus': {
      borderColor: theme.palette.red,
    },
  },
});

const Input = ({ classes, className, invalid, ...props }) => {
  return (
    <input
      className={classNames(
        classes.root,
        {
          [classes.invalid]: invalid,
        },
        className,
      )}
      {...props}
    />
  );
};

export default withStyles(styles)(Input);
