import { makeStyles } from '@mui/styles';
import MyCricketColors from '../data/MyCricketColors';

export const useTealCheckBoxStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '44px',
    height: '44px',
    background: '#FFFFFF',
    border: `2px solid ${MyCricketColors.mediumTeal}`,
  },
  checkedIcon: {
    width: '44px',
    height: '44px',
    background: `${MyCricketColors.paleTeal}`,
    border: `2px solid ${MyCricketColors.mediumTeal}`,
  },
});
