import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { useModalContext } from '../../utils/contexts/ModalContext';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { careTeamMessagePropType, classesPropType } from '../../utils/patientPropTypes';
import { BaseButton } from '../atoms/Buttons';
import { DecoratedChatInput } from '../atoms/DecoratedChatInput';
import UsernameConfirmationModal from '../molecules/UsernameConfirmationModal';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { TrackingEvent } from '../../data/Events';

const styles = (theme) => ({
  careTeamMessageEntryForm: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '12px',
    backgroundColor: theme.palette.lightGrayMist,
    border: `1px solid ${theme.palette.lightGrayHaze}`,
    boxSizing: 'border-box',
    borderRadius: '0 0 12px 12px',
    boxShadow: '0px 3px 4px rgba(0, 62, 81, 0.1)',
  },
  submitButton: {
    margin: '0 2px 0 6px',
    maxHeight: '49px',
  },
});

function GroupReplyCompositionArea({ classes, messageBeingRepliedTo }) {
  const selectedGroupId = messageBeingRepliedTo.groupId;
  const messageId = messageBeingRepliedTo.id;
  const { userName } = usePatientContext();
  const { sendMessage, isConnected, getComposedMessageData, setComposedMessageData } =
    useChirpContext();
  const { showModal } = useModalContext();
  const { logPageEvent } = useClientEventLoggerContext();

  const composedDataKey = `${selectedGroupId}-${messageId}`;
  const { text: initialText } = getComposedMessageData(composedDataKey);
  const [messageText, setMessageText] = useState(initialText);
  const [isButtonBlocked, setIsButtonBlocked] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const { text } = getComposedMessageData(composedDataKey);
    setMessageText(text);
  }, [composedDataKey, getComposedMessageData]);

  const handleChange = (event) => {
    setMessageText(event.target.value);
    setComposedMessageData(composedDataKey, { text: event.target.value });
  };

  const clearInput = () => {
    setMessageText('');
    setComposedMessageData(composedDataKey, { text: '' });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const handleSendMessage = async () => {
      const message = {
        messageText: messageText,
        selectedGroupId: selectedGroupId,
        inReplyTo: messageBeingRepliedTo.URI,
      };
      await sendMessage(message);
      clearInput();
    };

    if (!messageText) {
      return;
    } else if (!userName) {
      logPageEvent(TrackingEvent.USERNAME_MODAL_LOADING);
      showModal(() => <UsernameConfirmationModal onUpdateUsername={handleSendMessage} />, {});
      return;
    }
    try {
      await handleSendMessage();
    } catch (e) {
      console.error('Error sending message: ', e);
    }
  };

  return (
    <Box>
      <form
        onSubmit={onSubmit}
        data-test-id={`groupReplyCompositionAreaForm-${messageBeingRepliedTo.id}`}
        className={classes.careTeamMessageEntryForm}
      >
        <Box display="flex">
          <DecoratedChatInput
            type="text"
            value={messageText}
            onChange={handleChange}
            data-test-id={`groupReplyCompositionAreaInput-${messageBeingRepliedTo.id}`}
            data-test-type="groupReplyCompositionAreaInput"
            minRows={1}
            maxRows={5}
            placeholder={t('Write a Reply')}
            blockButton={setIsButtonBlocked}
          />
          <Box marginLeft={1}>
            <BaseButton
              disabled={!isConnected || isButtonBlocked}
              type="submit"
              data-test-id={`groupReplyCompositionAreaSubmitButton-${messageBeingRepliedTo.id}`}
              data-test-type="groupReplyCompositionAreaSubmitButton"
              className={classes.submitButton}
            >
              Send
            </BaseButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

GroupReplyCompositionArea.propTypes = {
  classes: classesPropType.isRequired,
  messageBeingRepliedTo: careTeamMessagePropType.isRequired,
  lastStagedMessageUuid: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(GroupReplyCompositionArea);
