import React, { Component } from 'react';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

/*
HOC for ensuring that props which may change as data is loaded stay consistent
with each other. It does this by taking an `isLoaded` function, and then
as new data is loaded, it buffers an old version of props until the data
finishes loading, at which point it passes the new, consistent data to the
inner component.

It also passes an `isInitialLoaded` prop, which starts out `false` and becomes
true the first time that the props are loaded and consistent. While it is
`false`, there is no guarantee that the passed in props will be consistent.
*/
export default function withBufferPropsUntilLoaded(isLoaded, InnerComponent) {
  return class extends Component {
    static displayName = `BufferPropsUntilLoaded(${getDisplayName(InnerComponent)})`;

    constructor(props) {
      super(props);

      this.state = {
        isInitialLoaded: isLoaded(props),
        props: props,
      };
    }

    static getDerivedStateFromProps(props, state) {
      if (isLoaded(props)) {
        return {
          isInitialLoaded: true,
          props: props,
        };
      }

      return null;
    }

    render() {
      return <InnerComponent {...this.state.props} isInitialLoaded={this.state.isInitialLoaded} />;
    }
  };
}
