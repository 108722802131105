import { Box } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

export const StyledCard = ({
  scrollToRef,
  nonInteractive = false,
  children,
  parentHover,
  ...rest
}) => {
  const isDesktop = useIsDesktop();
  const getBaseShadow = useCallback(() => {
    return isDesktop ? 2 : 0;
  }, [isDesktop]);
  const [hovered, setHovered] = useState(false);

  const [boxShadow, setBoxShadow] = useState(getBaseShadow());
  useEffect(() => {
    // note that in theory the && isDesktop is not needed, but it makes testing much easier.
    if (!nonInteractive && hovered && isDesktop) {
      setBoxShadow(4);
    } else {
      const shadow = getBaseShadow();
      setBoxShadow(shadow);
    }
  }, [hovered, isDesktop, getBaseShadow, nonInteractive]);
  useEffect(() => {
    setHovered(parentHover);
  }, [parentHover, nonInteractive]);
  // TODO change this to useSpring for the box-shadow.
  return (
    <Box
      ref={scrollToRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      borderRadius={{ xs: '0px', sm: '0px', md: '6px' }}
      border={1}
      // borderTop={{ md: 0 }}
      borderLeft={{ xs: 0, sm: 0, md: 0 }}
      borderBottom={{ xs: 0, sm: 0, md: 0 }}
      borderRight={{ xs: 0, sm: 0, md: 0 }}
      height={'100%'}
      borderColor={MyCricketColors.lightGraySmoke}
      mt={{ xs: 0, sm: 0, md: 1 }}
      bgcolor={MyCricketColors.white}
      width={'100%'}
      boxShadow={boxShadow}
      data-test-id={'aStyledCard'}
      // regrettably this is the only way I could give a Box a pointer cursor.
      sx={{ cursor: nonInteractive ? 'default' : 'pointer' }}
      {...rest}
    >
      {children}
    </Box>
  );
};
