import { myCricketAuthedFetch } from './fetchingUtils';

export const isImageFile = (fileType) =>
  ['image/png', 'image/jpeg', 'image/gif'].includes(fileType);

export const fetchFile = async (fileId) => {
  const res = await myCricketAuthedFetch(`/i/admin/patient-file/${fileId}`, {
    returnRawResponse: true,
  });
  const fileBlob = await res.blob();
  return URL.createObjectURL(fileBlob);
};
