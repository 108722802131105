import React, { useState } from 'react';
import { TrackingEvent } from '../../data/Events';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import MyCricketColors from '../../data/MyCricketColors';
import { BaseButtonNoTranslation as BaseButton } from './Buttons';
import { supportedLanguageMaps } from '../../data/SupportedLanguages';
import URIUtils from '../../utils/URIUtils.js';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useTranslation } from 'react-i18next';

const LanguageToggleButton = ({
  languages = supportedLanguageMaps,
  pageURI = URIUtils.toMyCricketPageURI(''),
  sx,
}) => {
  const { i18n } = useTranslation();
  const { siteLanguage, updateSystemInfo } = usePatientContext();
  const { logEvent } = useClientEventLoggerContext();
  const [currLangIndex, setCurrLangIndex] = useState(
    languages.findIndex((lang) => lang.code === i18n.language) || 0,
  );

  // If there are no languages to switch between, we don't
  // need to render the component.
  if (languages.length < 2) {
    return null;
  }

  const nextIndex = (currLangIndex + 1) % languages.length;
  const nextLang = languages[nextIndex];

  const updateLang = () => {
    if (nextIndex == null || !nextLang?.code) {
      return;
    }
    setCurrLangIndex(nextIndex);
    logEvent({
      predicate: TrackingEvent.TRACKING_CHANGE_LANGUAGE,
      object: pageURI,
      prepositions: {
        langCode: nextLang.code,
        page: URIUtils.toMyCricketPageURI(`LanguagePreference`),
      },
    });
    //we don't want this to be the first spot to set language
    //ie don't site siteLanguage if this is the login page
    if (siteLanguage) {
      updateSystemInfo({ siteLanguage: nextLang.code });
    } else if (window.i18next) {
      //if this IS the login page, just change the language on the page
      window.i18next.changeLanguage(nextLang.code);
    }
    // Set the language used by the Zendesk widget.
    if (window.zE && window.zE.setLocale) {
      window.zE.setLocale(nextLang.code);
    }
  };

  return (
    <BaseButton
      sx={[
        {
          'backgroundColor': MyCricketColors.darkTeal,
          'padding': '6px 10px',
          '&:hover': {
            backgroundColor: MyCricketColors.darkTeal,
          },
          'minWidth': '100px',
          'minHeight': '44px',
        },
        sx || {},
      ]}
      onClick={updateLang}
    >
      {nextLang?.name}
    </BaseButton>
  );
};

export default LanguageToggleButton;
