import { useEffect } from 'react';
import { myCricketFetch } from '../../utils/fetchingUtils';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { paths } from '../../utils/routing/routes';
import { useHistory } from 'react-router';
import { logClientError } from '../../utils/errorLogging';
import useQuery from '../../utils/hooks/useQueryHook';

export const MagicLinkErrorCodes = {
  noIdTokenError: 'no-id-token',
  linkExpiredError: 'link-expired',
};

const MagicLinkRedirectPage = () => {
  const { idTokenHasBeenSet, updateSystemInfo } = usePatientContext();
  const { logFEOnlyEvent } = useClientEventLoggerContext();
  const hash = window.location.hash;
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const encodedQuery = query.get('redirect');
    const redirectPath = encodedQuery ? decodeURIComponent(encodedQuery) : null;
    const executeRedirect = () => {
      if (redirectPath) {
        history.push(redirectPath);
      } else {
        history.push(paths.careTeam());
      }
    };

    const completePasswordlessLogin = async () => {
      try {
        const hashParams = new URLSearchParams(hash?.slice(1));
        const error = hashParams.get('error');
        if (error === 'unauthorized') {
          const error = new Error('Link expired or invalid');
          error.code = MagicLinkErrorCodes.linkExpiredError;
          throw error;
        } else if (error) {
          throw new Error('An error has occurred');
        }
        const idToken = hashParams.get('id_token');
        if (!idToken) {
          const error = new Error('No idToken found');
          error.code = MagicLinkErrorCodes.noIdTokenError;
          throw error;
        }
        const res = await myCricketFetch('/i/account/passwordless/complete', {
          method: 'POST',
          body: JSON.stringify({ idToken, applicationName: window._cc.appName }),
        });
        if (res) {
          const resignedToken = res.idToken;
          localStorage.setItem('idToken', resignedToken);
          updateSystemInfo({ idTokenHasBeenSet: true });
          executeRedirect();
        }
      } catch (error) {
        const isLinkExpired = [
          MagicLinkErrorCodes.noIdTokenError,
          MagicLinkErrorCodes.linkExpiredError,
        ].includes(error.code);
        updateSystemInfo({ magicLinkLoginError: error.code ? error.code : error });
        logFEOnlyEvent({
          eventName: 'errorMagicLink',
          properties: { reason: isLinkExpired ? 'expired' : 'unknown' },
        });
        if (!isLinkExpired) {
          logClientError(error);
        }
        history.push(paths.login());
      }
    };

    // Specifically look for false here, since that means we definitely don't
    // have a token. While undefined means we are still figuring it out and
    // should wait because we might end up already being logged in.
    if (idTokenHasBeenSet === false) {
      completePasswordlessLogin();
    } else if (idTokenHasBeenSet) {
      executeRedirect();
    }
  }, [hash, idTokenHasBeenSet, history, logFEOnlyEvent, updateSystemInfo, query]);

  return null;
};

export default MagicLinkRedirectPage;
