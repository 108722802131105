import React from 'react';

const PauseButton = ({ color, ...restProps }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="136"
    height="136"
    viewBox="0 0 136 136"
    title={'Video Player Pause Button'}
    {...restProps}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="68" cy="68" r="68" fill={color || '#7A4FE5'} />
      <path transform="translate(36 36)" fill="#FFF" d="M0 0h26v64H0zM38 0h26v64H38z" />
    </g>
  </svg>
);

export default PauseButton;
