import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../atoms/Buttons';
import MyCricketColors, { withAlpha } from '../../data/MyCricketColors';
import { formatPhoneNumber } from '../../utils/formattingUtils';
import { MdCheckCircle } from '@react-icons/all-files/md/MdCheckCircle';
import { MdCancel } from '@react-icons/all-files/md/MdCancel';

const PhoneContactInfoDisplay = ({
  primaryPhoneNumber,
  smsPhoneNumber,
  setEditing,
  customerIsDoNotText,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} width={'100%'}>
        <Box flex={'1 0 0'}>
          <Box>
            <Typography variant={'body1'}>{t('phoneNumber', 'Phone Number')}</Typography>
          </Box>
          <Box mt={1}>
            <Typography variant={'body2'}>
              {primaryPhoneNumber ? formatPhoneNumber(primaryPhoneNumber) : t('none')}
            </Typography>
          </Box>
        </Box>
        <Box flex={'0 0 1'} display={'flex'} justifyContent={'flex-end'}>
          <BaseButton
            variant={'outlined'}
            style={{
              'height': '50px',
              'color': MyCricketColors.black,
              'borderColor': MyCricketColors.lightGraySmoke,
              'lineHeight': 1,
              '&:hover': {
                'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                'background': MyCricketColors.white,
              },
            }}
            onClick={() => setEditing(true)}
          >
            Update
          </BaseButton>
        </Box>
      </Box>
      {!customerIsDoNotText && (
        <Box mt={4}>
          <Box>
            <Typography variant={'body1'}>
              {t('textMessagingNumber', 'Text Messaging Number')}
            </Typography>
          </Box>
          <Box mt={1}>
            {primaryPhoneNumber == null ? (
              <Typography variant={'body2'}>{t('none')}</Typography>
            ) : primaryPhoneNumber === smsPhoneNumber ? (
              <SMSStatusDisplay
                Icon={MdCheckCircle}
                message={t('smsUsePrimaryNumberDesc')}
                color={MyCricketColors.kellyGreen}
              />
            ) : smsPhoneNumber ? (
              <Typography variant={'body2'}>{formatPhoneNumber(smsPhoneNumber)}</Typography>
            ) : (
              <SMSStatusDisplay
                Icon={MdCancel}
                message={t('smsOptOutPrompt')}
                color={MyCricketColors.notificationRed}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const SMSStatusDisplay = ({ Icon, message, color }) => (
  <Box display={'flex'}>
    <Box
      flex={'0 0 1'}
      display={'flex'}
      px={2}
      py={1}
      bgcolor={withAlpha(color, 0.1)}
      borderRadius={'12px'}
    >
      <Box display={'flex'} alignItems={'center'} mr={2}>
        <Icon color={color} size={24} />
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'body2'}>{message}</Typography>
      </Box>
    </Box>
  </Box>
);

export default PhoneContactInfoDisplay;
