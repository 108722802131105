import React from 'react';
import { Box, Typography, Collapse, Fade, InputLabel } from '@mui/material';
import { BaseButtonNoTranslation as BaseButton } from '../atoms/Buttons';
import UpdateUserNameForm from '../molecules/UpdateUserNameForm';
import MyCricketColors from '../../data/MyCricketColors';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';

const UpdateUserNameContainer = ({
  newUserName,
  setNewUserName,
  isEditing,
  setEditing,
  errorMessage,
  clearError,
}) => {
  const { t } = useTranslation();
  const { userName } = usePatientContext();

  return (
    <Box>
      <InputLabel htmlFor={'display-name'}>
        <Typography variant={'body1'}>{t('displayName', 'Display Name')}</Typography>
      </InputLabel>
      <Collapse in={!isEditing} appear={false}>
        <Fade in={!isEditing} appear={false}>
          <Box mb={4}>
            <Box display={'flex'} width={'100%'}>
              <Box flex={'1'} display={'flex'} alignItems={'center'}>
                <Typography variant={'body2'}>
                  {userName ? userName : t('notYetChosen', 'Not yet chosen')}
                </Typography>
              </Box>
              <Box flex={'1'} display={'flex'} justifyContent={'flex-end'}>
                <BaseButton
                  variant={'outlined'}
                  style={{
                    'height': '50px',
                    'color': MyCricketColors.black,
                    'borderColor': MyCricketColors.lightGraySmoke,
                    'lineHeight': 1,
                    '&:hover': {
                      'webkit-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'moz-box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'box-shadow': `inset 0px 0px 0px 1px ${MyCricketColors.lightGraySmoke}`,
                      'background': MyCricketColors.white,
                    },
                  }}
                  onClick={() => setEditing(true)}
                >
                  {t('update', 'Update')}
                </BaseButton>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Collapse>
      <Collapse in={isEditing} appear={false}>
        <Fade in={isEditing} appear={false}>
          <Box>
            <UpdateUserNameForm
              newUserName={newUserName}
              setNewUserName={setNewUserName}
              errorMessage={errorMessage}
              clearError={clearError}
            />
          </Box>
        </Fade>
      </Collapse>
    </Box>
  );
};

export default UpdateUserNameContainer;
