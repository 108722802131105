import React from 'react';

const Me = (props) => {
  const { ...restProps } = props;
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.5 9C11.9862 9 14 6.98625 14 4.5C14 2.01375 11.9862 0 9.5 0C7.01375 0 5 2.01375 5 4.5C5 6.98625 7.01375 9 9.5 9ZM9.5 11.25C6.49625 11.25 0.5 12.7575 0.5 15.75V18H18.5V15.75C18.5 12.7575 12.5037 11.25 9.5 11.25Z"
        fill="white"
      />
    </svg>
  );
};

export default Me;
