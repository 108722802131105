import React from 'react';
import { Box } from '@mui/material';

const CareTeamBioPictureFrame = ({ picSrc, picAlt }) => {
  return picSrc ? <img maxwidth="90%" hegith="auto" src={picSrc} alt={picAlt} /> : null;
};

export const CareTeamBioPersonalPics = ({ picSrc, picAlt }) => {
  return (
    <Box pt={2} width={'100%'} display={'flex'} justifyContent={'center'}>
      <CareTeamBioPictureFrame picSrc={picSrc} picAlt={picAlt} />
    </Box>
  );
};

export default CareTeamBioPersonalPics;
