import { Box } from '@mui/material';
import React, { useState } from 'react';
import { paths } from '../../utils/routing/routes';
import { InvisibleLinkWrapper } from '../../utils/routing/router';
import { HorizontalChevron, HorizontalChevronSlider } from '../atoms/Chevrons';
import { StyledCard } from '../atoms/StyledCard';
import { TranslatedTypography } from '../atoms/TranslatedTypography';

export const ModuleCard = ({ moduleName, moduleId, recommended }) => {
  const [hovered, setHovered] = useState(false);
  return (
    // Using 2 boxes here instead of one box with margin because it maeks the grid more predictable
    // This is because there is no boxSizing that accounts for margin
    <Box
      p={{ xs: 0.25, sm: 0.25, md: 0.75 }}
      minHeight={{ xs: 0, sm: 0, md: '150px' }}
      width={{ xs: '100%', sm: '100%', md: '33%' }}
    >
      <Box height={'100%'} width={'100%'}>
        <StyledCard
          parentHover={hovered}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <InvisibleLinkWrapper to={paths.module(moduleId, '')}>
            <Box
              p={2}
              height={'100%'}
              display="flex"
              flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
              justifyContent="space-between"
              alignItems={{ xs: 'center', sm: 'center', md: 'stretch' }}
            >
              <Box>
                <TranslatedTypography variant={'subtitle1'}>{moduleName}</TranslatedTypography>
                {recommended && (
                  <TranslatedTypography
                    style={{ fontStyle: 'italic', fontWeight: 400 }}
                    variant={'subtitle2'}
                  >
                    Recommended
                  </TranslatedTypography>
                )}
              </Box>
              <TranslatedTypography variant="h1">
                <Box pr={'25px'}>
                  <HorizontalChevronSlider float="right" hovered={hovered} slideDistance={9}>
                    <HorizontalChevron />
                  </HorizontalChevronSlider>
                </Box>
              </TranslatedTypography>
            </Box>
          </InvisibleLinkWrapper>
        </StyledCard>
      </Box>
    </Box>
  );
};

export default ModuleCard;
