import React from 'react';
import useChirpFetching from './hooks/useChirpFetching';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export const getPropNameFromFetchError = (errorName) => {
  const name = errorName.split('FetchError')[0];
  return name !== errorName ? name : null;
};

/*
HOC for loading data that might be updated over the chirp websocket. Used
like

NewComponent = withChirpFetching({
  // options go here
})(Component)

where the possible options are:

* loader: (required) A function that takes a list of object IDs and returns a
    promise that resolves to the list of objects with those ids.
* idsToFetch: (required) A function that takes in the props passed to the HOC
    and returns a list of object IDs that should be loaded.
* entityType: (required) The name of the type of entity that this component is
    responsible for fetching and for refetching from the websocket. Should be
    plural (i.e. "organizations" not "organization"). This should also be the
    key on the object that is received on a chirp refresh that refers to the
    ids of this type.
* propName: (optional) The name of the prop to pass down to the InnerComponent.
    If not supplied then the entityType is used as the prop name.

The wrapped component component receives an prop named [options.entityType]
that is a map of id -> object for all the loaded objects in
options.idsToFetch().
*/
export default function withChirpFetching(options) {
  return function (InnerComponent) {
    function Component(props) {
      const { data, error, refetch } = useChirpFetching(props, options);
      return (
        <InnerComponent
          {...props}
          {...{
            [options.propName || options.entityType]: data,
            [`${options.propName || options.entityType}FetchError`]: error,
            [`${options.propName || options.entityType}Refetch`]: refetch,
          }}
        />
      );
    }
    Component.displayName = `${options.loader.name}(${getDisplayName(InnerComponent)})`;
    return Component;
  };
}
