import React, { useState } from 'react';
import { BaseButton } from '../atoms/Buttons';
import { Box } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import { toMyCricketPageURI } from '../../utils/URIUtils';
import PatientCricketLogo from '../icons/CricketLogo';
import LanguageToggleButton from '../atoms/LanguageToggleButton';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import PasswordForm from './PasswordForm';
import handlePasswordSignupError from '../../utils/handlePasswordSignupError';
import { validatePassword } from '../../utils/signUpFormValidationFunctions';
import { LoginEvent } from '../../data/Events';
import { HELP_CENTER_URL } from '../../data/SupportContactInfo';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { Roles } from '../../data/Roles';
import { useEffect } from 'react';
const {
  LOAD_RESET_PASSWORD_FORM,
  SUBMITTED_NEW_PASSWORD_ATTEMPTED,
  SUBMITTED_NEW_PASSWORD_FAILURE,
  SUBMITTED_NEW_PASSWORD_SUCCESS,
  SUBMITTED_NEW_PASSWORD_PASSWORD_DOES_NOT_MATCH,
  SUBMITTED_NEW_PASSWORD_WEAK_PASSWORD,
} = LoginEvent;
//much of this file is copy/pasted from lib/www/pages/ResetPassword and its components.
//feel free to rewrite this styling to fit the rest of the MyCricket paradigm, this is just the fastest option
const useInputStyles = makeStyles((theme) => ({
  root: {
    'border': `1px solid ${theme.palette.opaqueGray}`,
    'backgroundColor': theme.palette.paleGreyThree,
    '&:placeholder': {
      color: theme.palette.blueyGrey,
    },
  },
}));
const styles = (theme) => ({
  body: {
    'borderRadius': '5px',
    'display': 'flex',
    'flexDirection': 'column',
    'margin': '36px auto 18px',
    'padding': '24px',
    'maxWidth': '428px',
    'backgroundColor': 'white',
    '@media (max-width: 600px)': {
      padding: '16px',
      maxWidth: '339px',
      border: 'none',
      margin: '18px auto 15px',
    },
    '@media (max-width: 320px)': {
      padding: '12px',
      maxWidth: '290px',
    },
  },
  footerContainer: { display: 'flex', margin: '0 auto' },
  footer: {
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    marginTop: 12,
    maxWidth: 340,
    textAlign: 'center',
  },
  headerContainer: { display: 'flex', width: '100%', height: '80px' },
  logoFlexContainer: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  languageFlexContainer: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logoContentContainer: {
    padding: '10px 24px 0 24px',
  },
  languageContentContainer: {
    padding: '0 24px',
  },
});

const ResetPassword = (props) => {
  const { logEvent } = useClientEventLoggerContext();
  //get the ticket number from the location using the browser's built in api

  useEffect(() => {
    logEvent({
      predicate: LOAD_RESET_PASSWORD_FORM,
      object: toMyCricketPageURI('resetpassword'),
      prepositions: {},
    });
  }, [logEvent]);

  const { ticketNumber, classes } = props;
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  const onRequestResetPassword = (userInput) =>
    fetch(new URL('/i/account/password', window._cc.api.url).toString(), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInput),
    });

  const handleSubmit = async () => {
    logEvent({
      predicate: SUBMITTED_NEW_PASSWORD_ATTEMPTED,
      object: toMyCricketPageURI('resetpassword'),
      prepositions: {},
    });
    const isValid = validatePassword(
      password,
      setPasswordErr,
      [],
      true,
      false,
      {
        logPasswordTooWeak: (prepositions) =>
          logEvent({
            predicate: SUBMITTED_NEW_PASSWORD_WEAK_PASSWORD,
            object: toMyCricketPageURI('resetpassword'),
            prepositions,
          }),
        logPasswordDoesNotMatch: () =>
          logEvent({
            predicate: SUBMITTED_NEW_PASSWORD_PASSWORD_DOES_NOT_MATCH,
            object: toMyCricketPageURI('resetpassword'),
            prepositions: {},
          }),
      },
      Roles.PATIENT, //this just ensures we use validation rules for the patient applications
      confirmPassword,
      setConfirmPasswordErr,
    );
    if (!isValid) {
      return;
    }

    const userInput = {
      ticket: ticketNumber,
      password,
      applicationName: window._cc.appName,
    };
    const res = await onRequestResetPassword(userInput);
    if (res.ok) {
      logEvent({
        predicate: SUBMITTED_NEW_PASSWORD_SUCCESS,
        object: toMyCricketPageURI('resetpassword'),
        prepositions: {},
      });

      const json = await res.json();
      if (!json.data?.id_token) {
        throw new Error('id_token not found in response for successful reset password request');
      }
      //TODO re-enable logging in right after pwReset. We have scope issues rn that prevent this from happening
      window.location = '/';
    } else {
      const { errorMessage } = await res.json();
      logEvent({
        predicate: SUBMITTED_NEW_PASSWORD_FAILURE,
        object: toMyCricketPageURI('resetPasswordPage'),
        prepositions: { errorMessage },
      });

      const passwordSignUpErrorHandled = handlePasswordSignupError(errorMessage, setPasswordErr);
      if (!passwordSignUpErrorHandled) {
        //general error
        setPasswordErr(
          'There was an issue submitting your registration request. Please contact support.',
        );
      }
    }
  };
  return (
    <Box data-test-id="reset-password-page">
      <header className={classes.headerContainer}>
        <div className={classes.logoFlexContainer}>
          <div className={classes.logoContentContainer}>{<PatientCricketLogo />}</div>
        </div>
        <div className={classes.languageFlexContainer}>
          <div className={classes.languageContentContainer}>
            <LanguageToggleButton pageURI={toMyCricketPageURI('login')} />
          </div>
        </div>
      </header>
      <div className={classes.body}>
        <TranslatedTypography variant="h2">Create your new password</TranslatedTypography>

        <Box mt={1} mb={2}>
          <TranslatedTypography> Enter a new password below </TranslatedTypography>
        </Box>
        <PasswordForm
          password={password}
          onSetPassword={setPassword}
          passwordErr={passwordErr}
          confirmPassword={confirmPassword}
          onSetConfirmPassword={setConfirmPassword}
          confirmPasswordErr={confirmPasswordErr}
          onBlur={() => {}}
          dirty={[]}
          setDirty={() => {}}
          inputStyles={useInputStyles()}
          // Translate in child component
          passwordHeaderText={'New Password'}
          resetPassword={true}
          required={false}
          userRoleName={Roles.PATIENT} //This just ensures we use validation rules for the patient applications
        />
        <BaseButton mt={0} onClick={handleSubmit}>
          Change Password
        </BaseButton>
        <div className={classes.footerContainer}>
          <div className={classes.footer}>
            <TranslatedTypography>
              Having trouble logging into your account? Email us at
            </TranslatedTypography>
            <a href="mailto:support@crickethealth.com">support@crickethealth.com</a>
            <TranslatedTypography> or visit our</TranslatedTypography>
            <a href={HELP_CENTER_URL}>
              <TranslatedTypography>Help Center</TranslatedTypography>
            </a>
            <TranslatedTypography>for more information</TranslatedTypography>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withStyles(styles)(ResetPassword);
