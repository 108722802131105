import React from 'react';
import { Typography, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import Checkmark from '../icons/CircleCheckMark';

const ResetPasswordConfirmation = () => {
  return (
    <Box textAlign={'center'} data-test-id="afterResetSentWrapper">
      <Checkmark margin={'0 auto'} />
      <Box mt={2}>
        <Typography variant="h2">
          <Trans i18nKey={'checkEmail'}>Check Your Email</Trans>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant={'body1'}>
          <Trans i18nKey={'checkSecureLink'}>
            Check the secure link we sent you to reset your password. If you did not receive an
            email, check your spam folder.
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default ResetPasswordConfirmation;
