import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import LearnModuleCard from '../molecules/LearnModuleCard';
import { useHistory } from 'react-router';
import { paths } from '../../utils/routing/routes';

const CoursesDisplay = ({ courses }) => {
  const { recommendedModuleIds } = usePatientContext();
  const history = useHistory();
  const { t } = useTranslation();

  if (!courses) {
    return null;
  }
  return (
    <Box width={'100%'} py={5}>
      {courses.map((course, index) => (
        <Box key={index}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            mt={{ xs: index !== 0 ? 8 : 0, md: index !== 0 ? 14 : 0 }}
          >
            <Box width={{ xs: '100%', md: '720px' }} mx={{ xs: 2, md: 0 }}>
              <Box display={'flex'} alignItems={'center'} mb={3}>
                <Box display={{ xs: 'none', md: 'block' }} mr={5}>
                  <img
                    width="80px"
                    height="80px"
                    src={course.cover?.url}
                    title="Course Icon"
                    alt="Course Icon"
                  />
                </Box>
                <Box>
                  <Typography variant={'h1'}>{t(course.title)}</Typography>
                </Box>
              </Box>
              <Box>
                <Grid container spacing={{ xs: 1, md: 1.5 }}>
                  {course.modules.map((module) => (
                    <Grid item key={module.id} xs={12} md={6}>
                      <LearnModuleCard
                        moduleTitle={t(module.title)}
                        isRecommended={recommendedModuleIds.includes(+module.id)}
                        onClick={() => history.push(paths.module(module.id, ''))}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CoursesDisplay;
