import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePatientContext } from '../../utils/contexts/PatientContextProvider';
import { useModalContext } from '../../utils/contexts/ModalContext';
import { TranslatedTypography } from '../atoms/TranslatedTypography';
import { useTranslation } from 'react-i18next';
import { BaseButton, CancelButton, ClickableBox } from '../atoms/Buttons';
import CloseIcon from '../icons/CloseIcon';
import MyCricketColors from '../../data/MyCricketColors';
import { useChirpContext } from '../../utils/contexts/ChirpContextProvider';
import { validateNickName } from '../../utils/signUpFormValidationFunctions';
import { useClientEventLoggerContext } from '../../utils/contexts/ClientEventLoggerContext';
import { TrackingEvent } from '../../data/Events';

const useStyles = makeStyles((theme) => ({
  errorMessageText: {
    color: theme.palette.red,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}));

const UsernameConfirmationModal = ({ onUpdateUsername }) => {
  const classes = useStyles();

  const { userId, fetchUserInfo } = usePatientContext();
  const { changeUsername } = useChirpContext();
  const { hideModal } = useModalContext();
  const { logPageEvent } = useClientEventLoggerContext();

  const { t } = useTranslation();

  const [nickName, setNickName] = useState('');
  const [nickNameError, setNickNameError] = useState(null);
  const [nickNameDirty, setNickNameDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!nickNameDirty) {
      logPageEvent(TrackingEvent.USERNAME_MODAL_LOADED);
    }
  }, [logPageEvent, nickNameDirty]);

  const handleChange = (event) => {
    setNickName(event.target.value);
    setNickNameError(validateNickName(event.target.value, nickNameDirty));
    setNickNameDirty(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);
    try {
      if (nickNameDirty && !nickNameError) {
        await logPageEvent(TrackingEvent.USERNAME_MODAL_SUBMITTIED);
        await changeUsername(userId, nickName);
        await fetchUserInfo();
        await onUpdateUsername();
        hideModal();
      } else {
        logPageEvent(TrackingEvent.USERNAME_MODAL_VALIDATION_FAILURE, {
          validationError: nickNameError,
        });
      }
    } catch (e) {
      logPageEvent(TrackingEvent.USERNAME_MODAL_SUBMIT_ERROR, {
        error: e.toString(),
      });
      if (e?.includes('display_name_already_exists')) {
        setNickNameError(t('display_name_already_exists'));
      } else if (e?.includes('display_name_too_long')) {
        setNickNameError(t('display_name_too_long'));
      } else {
        setNickNameError(e || 'unknown');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = async () => {
    await logPageEvent(TrackingEvent.USERNAME_MODAL_ABANDONED);
    hideModal();
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" role="main">
      <ClickableBox
        width="100%"
        display="flex"
        flexDirection="reverse-row"
        justifyContent="right"
        onClick={handleCancel}
        sx={{ backgroundColor: MyCricketColors.white }}
      >
        <CloseIcon className={classes.closeIcon} onClick={handleCancel} />
      </ClickableBox>
      <TranslatedTypography variant="h2" sx={{ mb: 2.5 }} aria-level={1} id={'patientModalTitle'}>
        Set your display name
      </TranslatedTypography>
      <TranslatedTypography
        variant="body2"
        i18nKey={'displayNameModalDescription'}
        id={'patientModalDescription'}
      >
        Your display name is the name other community members will see when you post. What should we
        call you?
      </TranslatedTypography>
      <TranslatedTypography variant="h3" sx={{ mt: 4, mb: 1 }} aria-level={2}>
        Display Name
      </TranslatedTypography>
      <TextField
        variant="outlined"
        placeholder={t('Display Name')}
        onChange={handleChange}
        name="nickName"
        value={nickName}
        error={Boolean(nickNameError)}
      />
      {nickNameError && (
        <TranslatedTypography
          variant="body1"
          sx={{ color: MyCricketColors.notificationRed, mt: 1 }}
        >
          {nickNameError}
        </TranslatedTypography>
      )}
      <Box mt={4} width={'100%'} display="flex" flexDirection="row" justifyContent="space-between">
        <CancelButton
          type="button"
          variant="outlined"
          sx={{ flex: '1', height: '50px', mr: '10px' }}
          onClick={handleCancel}
        >
          Cancel
        </CancelButton>
        <BaseButton
          type="submit"
          sx={{ flex: '1', height: '50px' }}
          onClick={handleSubmit}
          className={classes.submitButton}
          disabled={isSubmitting}
        >
          Submit
        </BaseButton>
      </Box>
    </Box>
  );
};

export default UsernameConfirmationModal;
