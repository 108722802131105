import React from 'react';
import { Box, Typography, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyCricketColors from '../../data/MyCricketColors';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';
import { isValidEmailAddress } from '../../utils/validation';

const UpdateEmailForm = ({
  emailAddress,
  setEmailAddress,
  errorMessage,
  errorCode,
  clearError,
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <Box mb={2}>
      <Box width={'100%'}>
        <Box mb={1}>
          <InputLabel htmlFor={'email-address'}>
            <Typography variant={'body1'}>{t('Email Address', 'Email Address')}</Typography>
          </InputLabel>
        </Box>
        <TextField
          id="email-address"
          name={'email-address'}
          variant={'outlined'}
          type={'email'}
          placeholder={t('emailAddress', 'Email Address')}
          autoComplete={'email'}
          style={{ width: !isDesktop ? '100%' : undefined }}
          InputProps={{
            style: { width: isDesktop ? '360px' : '100%', height: '50px' },
          }}
          error={errorCode === 'invalid-email'}
          value={emailAddress}
          onChange={(event) => {
            setEmailAddress(event.target.value);
            if (errorCode === 'invalid-email') {
              if (isValidEmailAddress(event.target.value)) {
                clearError();
              }
            } else {
              clearError();
            }
          }}
        />
      </Box>
      {errorMessage && (
        <Box mt={3} color={MyCricketColors.notificationRed} data-testId={'error-message'}>
          <Typography variant={'body1'}>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpdateEmailForm;
