import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { FaqItem } from '../molecules/FaqItem';
import { tabBarHeight } from '../pages/LearnPage';
import { useIsDesktop } from '../../utils/hooks/useIsDesktop';

const GenericLearnContentDisplay = ({ content, initialSelectedItemId, useLargeDisplay }) => {
  const [selectedItemId, setSelectedItemId] = useState(+initialSelectedItemId);
  const [scrollToItem, setScrollToItem] = useState(null);
  const itemRef = useCallback((item) => setScrollToItem(item), []);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (scrollToItem) {
      // Set a small delay to account for sometimes needing to scroll horizontally to this page.
      setTimeout(() => {
        scrollToItem?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        setScrollToItem(null);
        setSelectedItemId(null);
      }, 200);
    }
  }, [scrollToItem]);

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box width={{ xs: '100%', md: '720px' }} py={{ xs: 0, md: 5 }}>
        {content?.map((item, index) => (
          <Box
            key={index}
            ref={selectedItemId === item.id ? itemRef : undefined}
            sx={{ scrollMarginTop: tabBarHeight }}
          >
            <FaqItem
              faq={item}
              isGenericContent
              useLargeDisplay={isDesktop ? useLargeDisplay : false}
              shouldStartOpen={selectedItemId === item.id}
              ariaHeaderLevel={'3'}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GenericLearnContentDisplay;
