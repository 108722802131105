import React from 'react';
import { Box } from '@mui/material';
import MyCricketColors from '../../data/MyCricketColors';
import ErrorDisplay from '../atoms/ErrorDisplay';

const FourOhFour = () => {
  return (
    <Box
      p={0}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      backgroundcolor={MyCricketColors.lightGrayMist}
    >
      <ErrorDisplay
        title={'Page not found'}
        description={
          'It seems like we cannot find what you searched. The page you were looking for doesn’t exist, isn’t available or was loading incorrectly.'
        }
      />
    </Box>
  );
};

export default FourOhFour;
